import createPersistedState from 'vuex-persistedstate';

const initialState = {
  downloadItems: [],
  showDownloadSnackbar: false,
  downloadLoaders: [],
  isAddedToLibraryDownload: false,
  premiumFtLibConfPopupData: {
    message: '',
    btnText: '',
    goToLibrary: () => {},
    cancel: () => {},
  },
  gdriveFileDownloads: {},
  snackbarDownloadStatusHeader: 'slides.preparingDownload',
  navFromDownloadSnackbarOP: {
    navigated: false,
    type: '',
  },
  navFromDownloadSnackbarSlides: {
    navigated: false,
    type: '',
  },
  navFromDownloadSnackbarBPSld: {
    navigated: false,
    type: '',
  },
  navFromDownloadSnackbarBPDck: {
    navigated: false,
    type: '',
  },
  navFromDownloadSnackbarPrezs: {
    navigated: false,
    type: '',
  },
  addedToLibOrFavSnackbar: {},
  navFromAddToLibOrFavSnackbar: {
    navigated: false,
    type: '',
    origin: '',
  },
};
export const commonDownloads = {
  namespaced: true,
  state: initialState,
  getters: {
    getDownloadItems: (state) => state.downloadItems ? state.downloadItems : [],
    getShowDownloadSnackbar: (state) => state.showDownloadSnackbar,
    getDownloadLoaders: (state) => state.downloadLoaders ? state.downloadLoaders : [],
    getIsAddedToLibraryDownload: (state) => state.isAddedToLibraryDownload,
    getPremiumFtLibConfPopupData: (state) => state.premiumFtLibConfPopupData ? state.premiumFtLibConfPopupData : initialState.premiumFtLibConfPopupData,
    getGdriveFileDownloads: (state) => state.gdriveFileDownloads ? state.gdriveFileDownloads : {},
    getSnackbarDownloadStatusHeader: (state) => state.snackbarDownloadStatusHeader ? state.snackbarDownloadStatusHeader : 'slides.preparingDownload',
    getNavFromDownloadSnackbarOP: (state) => state.navFromDownloadSnackbarOP ? state.navFromDownloadSnackbarOP : { navigated: false, type: '' },
    getNavFromDownloadSnackbarSlides: (state) => state.navFromDownloadSnackbarSlides ? state.navFromDownloadSnackbarSlides : { navigated: false, type: '' },
    getNavFromDownloadSnackbarBPSld: (state) => state.navFromDownloadSnackbarBPSld ? state.navFromDownloadSnackbarBPSld : { navigated: false, type: '' },
    getNavFromDownloadSnackbarBPDck: (state) => state.navFromDownloadSnackbarBPDck ? state.navFromDownloadSnackbarBPDck : { navigated: false, type: '' },
    getNavFromDownloadSnackbarPrezs: (state) => state.navFromDownloadSnackbarPrezs ? state.navFromDownloadSnackbarPrezs : { navigated: false, type: '' },
    getAddedToLibOrFavSnackbar: (state) => state.addedToLibOrFavSnackbar ? state.addedToLibOrFavSnackbar : {},
    getNavFromAddToLibOrFavSnackbar: (state) => state.navFromAddToLibOrFavSnackbar ? state.navFromAddToLibOrFavSnackbar : { navigated: false, type: '', origin: '' },
  },
  mutations: {
    SET_NAV_FROM_ADD_TO_LIB_OR_FAV_SNACKBAR(state, val) {
      state.navFromAddToLibOrFavSnackbar = val;
    },
    REMOVE_SNACKBAR_FROM_STACK(state, val) {
      state.addedToLibOrFavSnackbar = {};
    },
    SET_ADDED_TO_LIB_OR_FAV_SNACKBAR(state, val) {
      if (Object.keys(state.addedToLibOrFavSnackbar).length > 0) {
        state.addedToLibOrFavSnackbar = {};
      }
      state.addedToLibOrFavSnackbar = val;
    },
    SET_NAV_FROM_DOWNLOAD_SNACKBAR_OP(state, val) {
      state.navFromDownloadSnackbarOP = val;
    },
    SET_NAV_FROM_DOWNLOAD_SNACKBAR_SLIDES(state, val) {
      state.navFromDownloadSnackbarSlides = val;
    },
    SET_NAV_FROM_DOWNLOAD_SNACKBAR_BPSLD(state, val) {
      state.navFromDownloadSnackbarBPSld = val;
    },
    SET_NAV_FROM_DOWNLOAD_SNACKBAR_BPDCK(state, val) {
      state.navFromDownloadSnackbarBPDck = val;
    },
    SET_NAV_FROM_DOWNLOAD_SNACKBAR_PREZS(state, val) {
      state.navFromDownloadSnackbarPrezs = val;
    },
    SET_SNACKBAR_DOWNLOAD_STATUS_HEADER(state, status) {
      let headerText = '';
      if (status === 'failed') {
        headerText = 'common.downloadFailedHeader';
      } else if (status === 'cancelled') {
        headerText = 'common.downloadCancelledHeader';
      } else if (status === 'complete') {
        headerText = 'slides.downloadCompleted';
      } else if (status === 'downloading') {
        headerText = 'common.downloadProgressHeader';
      } else if (status === 'assembling') {
        headerText = 'slides.preparingDownload';
      }
      state.snackbarDownloadStatusHeader = headerText;
    },
    SET_GDRIVE_FILE_DOWNLOADS(state, name) {
      const item = state.gdriveFileDownloads[name];
      if (item) {
        state.gdriveFileDownloads = {
          ...state.gdriveFileDownloads,
          [name]: item + 1,
        }
      } else {
        state.gdriveFileDownloads = {
          ...state.gdriveFileDownloads,
          [name]: 1,
        }
      }
    },
    RESET_ADDED_TO_LIBRARY_SNACKBAR_STATE(state) {
      state.isAddedToLibraryDownload = false;
      state.premiumFtLibConfPopupData = {
        message: '',
        btnText: '',
        goToLibrary: () => {},
        cancel: () => {},
      };
    },
    SET_PREMIUM_FT_LIB_CONF_POPUP_DATA(state, data) {
      const { message, btnText, goToLibrary, cancel } = data;
      state.premiumFtLibConfPopupData = {
        message,
        btnText,
        goToLibrary,
        cancel,
      };
    },
    SET_IS_ADDED_TO_LIBRARY_DOWNLOAD(state, flag) {
      state.isAddedToLibraryDownload = flag;
    },
    SET_DOWNLOAD_LOADERS(state, loader) {
      const { loaderId, downloading } = loader;
      const ldr = [...state.downloadLoaders].find((ld) => ld.loaderId === loaderId);
      if (ldr) {
        state.downloadLoaders = [...state.downloadLoaders].map((ld) => {
          if (ld.loaderId === loaderId) {
            return {
              ...ld,
              downloading: downloading,
            };
          }
          return {
            ...ld,
          };
        });
      } else {
        const ldrs = [...state.downloadLoaders];
        ldrs.push({ loaderId, downloading: true });
        state.downloadLoaders = [...ldrs];
      }
    },
    SET_DOWNLOAD_ITEMS_MULTIPLE(state, items) {
      state.downloadItems = [...state.downloadItems, ...items];
    },
    SET_DOWNLOAD_ITEMS(state, item) {
      const currDownloadItems = [...state.downloadItems];
      currDownloadItems.push({ ...item });
      state.downloadItems = [...currDownloadItems];
    },
    SET_SHOW_DOWNLOAD_SNACKBAR(state, toShow) {
      state.showDownloadSnackbar = toShow;
    },
    UPDATE_DOWNLOAD_ITEM_STATUS(state, obj) {
      const { itemIndex, newStatus } = obj;
      const indexToUpdate = [...state.downloadItems].findIndex((dwnldItem) => itemIndex && dwnldItem.itemIndex && dwnldItem.itemIndex === itemIndex);
      if (state.downloadItems[indexToUpdate]) {
        state.downloadItems[indexToUpdate].status = newStatus;
      }
    },
    RESET_STATE_COMMON_DOWNLOADS(state) {
      state.downloadItems = [];
      state.showDownloadSnackbar = false;
      state.downloadLoaders = [];
    },
  },
  actions: {
    setNavFromAddToLibOrFavSnackbar({ commit }, val) {
      commit('SET_NAV_FROM_ADD_TO_LIB_OR_FAV_SNACKBAR', val);
    },
    removeSnackbarFromStack({ commit }) {
      commit('REMOVE_SNACKBAR_FROM_STACK');
    },
    setAddedToLibOrFavSnackbar({ commit }, val) {
      commit('SET_ADDED_TO_LIB_OR_FAV_SNACKBAR', val);
    },
    setNavFromDownloadSnackbarOP({ commit }, val) {
      commit('SET_NAV_FROM_DOWNLOAD_SNACKBAR_OP', val);
    },
    setNavFromDownloadSnackbarSlides({ commit }, val) {
      commit('SET_NAV_FROM_DOWNLOAD_SNACKBAR_SLIDES', val);
    },
    setNavFromDownloadSnackbarBPSld({ commit }, val) {
      commit('SET_NAV_FROM_DOWNLOAD_SNACKBAR_BPSLD', val);
    },
    setNavFromDownloadSnackbarBPDck({ commit }, val) {
      commit('SET_NAV_FROM_DOWNLOAD_SNACKBAR_BPDCK', val);
    },
    setNavFromDownloadSnackbarPrezs({ commit }, val) {
      commit('SET_NAV_FROM_DOWNLOAD_SNACKBAR_PREZS', val);
    },
    setSnackbarDownloadStatusHeader({ commit }, status) {
      commit('SET_SNACKBAR_DOWNLOAD_STATUS_HEADER', status);
    },
    setGdriveFileDownloads({ commit }, name) {
      commit('SET_GDRIVE_FILE_DOWNLOADS', name);
    },
    resetAddedToLibrarySnackbarState({ commit}) {
      commit('RESET_ADDED_TO_LIBRARY_SNACKBAR_STATE');
    },
    setPremiumFtLibConfPopupData({ commit }, data) {
      commit('SET_PREMIUM_FT_LIB_CONF_POPUP_DATA', data);
    },
    setIsAddedToLibraryDownload({ commit }, flag) {
      commit('SET_IS_ADDED_TO_LIBRARY_DOWNLOAD', flag);
    },
    setDownloadLoaders({ commit }, loader) {
      commit('SET_DOWNLOAD_LOADERS', loader)
    },
    setDownloadItemsMultiple({ commit }, items) {
      commit('SET_DOWNLOAD_ITEMS_MULTIPLE', items);
      dispatch('setSnackbarDownloadStatusHeader', 'assembling');
    },
    setDownloadItems({ commit, dispatch }, item) {
      commit('SET_DOWNLOAD_ITEMS', item);
      dispatch('setSnackbarDownloadStatusHeader', 'assembling');
    },
    setShowDownloadSnackbar({ commit }, toShow) {
      commit('SET_SHOW_DOWNLOAD_SNACKBAR', toShow);
    },
    updateDownloadItemStatus({ commit, dispatch }, obj) {
      commit('UPDATE_DOWNLOAD_ITEM_STATUS', obj);
      dispatch('setSnackbarDownloadStatusHeader', obj.newStatus);
    },
    resetStateCommonDownloads({ commit }) {
      commit('RESET_STATE_COMMON_DOWNLOADS');
    },
  },
  persist: createPersistedState({
    key: 'vuex_commonDownload',
    paths: [],
  }),
};
