<template>
  <div class="card-item">
    <v-card
      :ripple="false"
      @click="handleClick"
      :class="`${selected ? 'selected' : ''} ${title ? 'img-with-text' : ''} ${
        selected === false ? 'blur' : ''
      } `"
    >
      <v-img
        :src="src"
        contain
        class="text-white card-img"
        :class="`text-white  ${title ? 'card-img-text' : 'card-img'} `"
        lazy-src="/assets/img/slides/placeholder-slide.svg"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <clip-loader :color="`#21a7e0`" :width="20" :height="20" />
          </v-row>
        </template>
      </v-img>
      <v-card-title class="card-title" v-if="title">
        {{ title }}
      </v-card-title>
      <v-card-text class="card-desc" v-if="subtext">
        {{ subtext }}
      </v-card-text>
      <v-card-text class="card-estimate-time" v-if="estimateTime">
        {{ estimateTime }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

export default {
  name: 'TemplateCard',
  components: {
    ClipLoader,
  },
  props: {
    selected: { type: Boolean, default: null },
    src: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    subtext: {
      type: String,
    },
    estimateTime: {
      type: String,
    },
    cardData: null,
  },
  methods: {
    handleClick() {
      this.$emit('selectedCardData', this.cardData);
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  .v-card {
    &.img-with-text {
      padding: 10px;
      width: 450px;
    }
  }
  .card-img {
    width: 447px;
    &.card-img-text {
      width: 150px;
    }
  }
}
@media screen and (min-width: 1440px) {
  .v-card {
    &.img-with-text {
      padding: 10px;
      width: calc(100% - 40px);
    }
  }
  .card-img {
    width: 550px;
    &.card-img-text {
      width: 350px;
    }
  }
}
.v-card {
  max-width: 560px;
  width: 100%;
  margin: auto;
  //aspect-ratio: 16/9;

  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 5px solid transparent;

  &.img-with-text {
    padding: 10px;
  }
  .card-title {
    font-family: 'Lato';
    font-style: normal;

    font-size: 22px;
    text-align: center;
    color: #000000;
  }
  .card-desc {
    font-family: 'Lato';
    font-style: normal;
    font-size: 18px;

    text-align: center;
    color: #000000;
    width: 95%;
  }

  .card-estimate-time {
    padding-top: 0px;
    color: #000000;
  }
  .card-img {
    aspect-ratio: 16/9;
  }
  &:hover {
    box-shadow: 3px -2px 15px 3px #b6b6b6, -3px 2px 15px 3px #b6b6b6;
  }
}
.selected {
  border: 5px solid rgba(33, 167, 224, 1);
  box-shadow: 3px -2px 15px 3px #b6b6b6, -3px 2px 15px 3px #b6b6b6 !important;
}

.blur {
  opacity: 0.35;
  transition: opacity 0.2s ease-in;
}
.v-card--link::before {
  background: white;
}
</style>
