// import { Storage } from 'aws-amplify';
import Cry from 'crypto';
import { getLoggedInUserToken } from './api';

const APP_CALYREX_SECRET_KEY = process.env.VUE_APP_CALYREX_SECRET_KEY;
const APP_CALYREX_DOMAIN = process.env.VUE_APP_CALYREX_DOMAIN;
// const APP_EXTERNAL_SERVICE_BUCKET = process.env.VUE_APP_EXTERNAL_SERVICE_BUCKET;
const DOMAIN = `https://${APP_CALYREX_DOMAIN}`;

const generateAccessKey = (ts, user, uri, ip) => {
  const plainText = [ts, user, uri, ip].join('|');
  return new Promise((resolve) => {
    const iv = Cry.randomBytes(16).toString('hex').substr(0, 16);
    const cipher = Cry.createCipheriv(
      'AES-128-CBC',
      Buffer.from(APP_CALYREX_SECRET_KEY, 'base64'),
      iv,
    );
    const encrypted =
      iv +
      Buffer.concat([cipher.update(plainText), cipher.final()]).toString(
        'base64',
      );
    resolve(encodeURIComponent(encrypted));
  });
};

export const getFileURL = async (user, uri, ip, type = 'Product') => {
  if (type.toLowerCase() === 'storage') {
    const awsAssetsDistribution = process.env.VUE_APP_AWS_STORAGE_DISTRIBUTION;
    const token = await getLoggedInUserToken();
    return `${awsAssetsDistribution}${
      uri.startsWith('/') ? '' : '/'
    }${uri}?accesskey=${token}`;
  }
  if (
    type.toLowerCase() === 'iocupload' ||
    uri.indexOf('/protected/slide-builder') !== -1 ||
    uri.indexOf('protected/premium-feature/tc/') !== -1 ||
    uri.indexOf('/comply/') !== -1
  ) {
    const awsAssetsDistribution = process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
    const token = await getLoggedInUserToken();
    return `${awsAssetsDistribution}${
      uri.startsWith('/') ? '' : '/'
    }${uri}?accesskey=${token}`;
  }
  // if (type.toLowerCase() === 'op') {
  //   uri = uri.replace('/public/', '');
  //   if (uri.startsWith('public/')) {
  //     uri = uri.replace('public/', '');
  //   }
  //   const thumbnail = await Storage.get(uri, {
  //     bucket: APP_EXTERNAL_SERVICE_BUCKET,
  //   });
  //   return thumbnail;
  // }

  uri = uri.replace('/protected/slide-builder');
  const ts = Math.floor(Date.now() / 1000).toString();
  const accessKey = await generateAccessKey(ts, user, uri, ip);
  return `${DOMAIN}${
    uri.startsWith('/') ? '' : '/'
  }${uri}?accesskey=${accessKey}`;
};

export const getCalyrexFileURL = async (domain, user, uri, ip) => {
  const ts = Math.floor(Date.now() / 1000).toString();
  const accessKey = await generateAccessKey(ts, user, uri, ip);
  return `${domain}${
    uri.startsWith('/') ? '' : '/'
  }${uri}?accesskey=${accessKey}`;
};
