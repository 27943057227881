export const designerTemplateLimit = 2;

export const slideDataSet = [
  'agenda/deck1_0004_5',
  'insights/deck1_0132_6',
  'org_chart/deck1_0086_3',
  'financials/deck1_0060_3',
  'horizons/deck1_0155_3',
  'principles/deck1_0094_5',
  'solutions/deck1_0179_6',
  'people/deck1_0032_4',
  'quant/deck1_0006_5',
  'impact/deck1_0020_4',
];
export const designerTemplateType = {
  standard: 'Prezent Standard templates',
  existing: 'Existing Designer Template',
  designer: 'Auto-designed Brand template',
};
