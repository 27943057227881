import axios from 'axios';
import { Auth } from 'aws-amplify';

const exemptURLs = [
  '/trial/signup',
  '/trial/validate',
  '/trial/invalidate',
  '/trial/register',
  '/exportUsersForPhaedrus',
  '/users/sso-user-check',
  '/audiences/update',
  '/audience/contact-us',
  '/custom-registration',
  '/audience/signin',
  '/public/fingerprint/slides',
  '/themes/info',
  '/api2/enterprise/signup-validate',
  '/api2/enterprise/signup',
  '/api2/standalone/survey/verifylink',
  '/api2/standalone/survey/updatelink',
  '/api2/ratings/standalone/survey/add',
  '/api2/ratings/standalone/survey/update',
  '/api2/ratings/standalone/survey/skip',
  '/api2/external_user_byemail',
  '/api2/external_users',
  '/fingerprint/audience/share',
  '/fingerprint/audiences',
  'audience-fingerprint',
  '/fingerprint/preview',
  'api2/fingerprint-additional-data',
  '/api2/tax-rate',
  '/api3/countries',
  '/api3/states',
  '/api3/external/get-external-fp-test',
  '/premium-registration',
  '/get-premium-registration',
  '/standard-registration',
  '/get-standard-registration',
  '/api3/internal/update-internal-fp',
];

/**
 * Get logged in users accessToken
 * @returns string -
 */
// eslint-disable-next-line func-names
export const getLoggedInUserToken = async function () {
  let user = '';
  await Auth.currentAuthenticatedUser()
    .then((respUser) => {
      user = respUser.signInUserSession.idToken.jwtToken;
    })
    .catch((err) => {
      console.log(err);
      user = '';
    });
  return user;
};

const API = axios.create({
  baseURL: process.env.VUE_APP_AWS_PREZENT_API,
});

API.interceptors.request.use(
  async (request) => {
    const jwt = await getLoggedInUserToken();
    let exempted = false;
    for (let i = 0; i < exemptURLs.length; i++) {
      if (request.url.includes(exemptURLs[i])) {
        exempted = true;
        break;
      }
    }
    // Exempting URL since its supposed to be unauthorised
    if (!jwt && !exempted) {
      window.location = '/signin/10001';
    }
    request.headers.Authorization = `Bearer ${jwt}`;
    request.headers['Access-Control-Allow-Origin'] = '*';
    return request;
  },
  (error) => Promise.reject(error),
);

API.interceptors.response.use(
  (response) => response.data,
  (error) => {
    let errRes = '';
    if (typeof error === 'object' && error.message !== undefined) {
      errRes = error.message;
    }

    try {
      if (errRes && errRes.includes('not authenticated')) {
        // Redirect to login page throwing session expired error code
        window.location = '/signin/10001';
      }
    } catch (err) {
      console.log(err);
    }
    return Promise.reject(error);
  },
);

const API_PRODUCT_SERVICES = axios.create({
  baseURL: process.env.VUE_APP_PRODUCT_SERVICES_HOST,
});

API_PRODUCT_SERVICES.interceptors.request.use(
  async (request) => {
    const jwt = await getLoggedInUserToken();
    let exempted = false;
    for (let i = 0; i < exemptURLs.length; i++) {
      if (request.url.includes(exemptURLs[i])) {
        exempted = true;
        break;
      }
    }
    // Exempting URL since its supposed to be unauthorised
    if (!jwt && !exempted) {
      window.location = '/signin/10001';
    }
    request.headers.Authorization = `Bearer ${jwt}`;
    request.headers['Access-Control-Allow-Origin'] = '*';
    return request;
  },
  (error) => Promise.reject(error),
);

API_PRODUCT_SERVICES.interceptors.response.use(
  (response) => response.data,
  (error) => {
    let errRes = '';
    if (typeof error === 'object' && error.message !== undefined) {
      errRes = error.message;
    }
    try {
      if (errRes && errRes.includes('not authenticated')) {
        // Redirect to login page throwing session expired error code
        window.location = '/signin/10001';
      }
    } catch (err) {
      console.log(err);
    }
    return Promise.reject(error);
  },
);

const API_MERGE_SLIDE_SERVICE = axios.create({
  baseURL: process.env.VUE_APP_MERGE_SLIDE_SERVICE_URL,
});

API_MERGE_SLIDE_SERVICE.interceptors.request.use(
  async (request) => {
    const jwt = await getLoggedInUserToken();
    let exempted = false;
    for (let i = 0; i < exemptURLs.length; i++) {
      if (request.url.includes(exemptURLs[i])) {
        exempted = true;
        break;
      }
    }
    // Exempting URL since its supposed to be unauthorised
    if (!jwt && !exempted) {
      window.location = '/signin/10001';
    }
    request.headers.Authorization = `Bearer ${jwt}`;
    request.headers['Access-Control-Allow-Origin'] = '*';
    return request;
  },
  (error) => Promise.reject(error),
);

API_MERGE_SLIDE_SERVICE.interceptors.response.use(
  (response) => response.data,
  (error) => {
    let errRes = '';
    if (typeof error === 'object' && error.message !== undefined) {
      errRes = error.message;
    }
    try {
      if (errRes && errRes.includes('not authenticated')) {
        // Redirect to login page throwing session expired error code
        window.location = '/signin/10001';
      }
    } catch (err) {
      console.log(err);
    }
    return Promise.reject(error);
  },
);

const TC_COMPLY_API_SERVICES = axios.create({
  baseURL: process.env.VUE_APP_TC_API,
});

TC_COMPLY_API_SERVICES.interceptors.request.use(
  async (request) => {
    const jwt = await getLoggedInUserToken();
    let exempted = false;
    for (let i = 0; i < exemptURLs.length; i++) {
      if (request.url.includes(exemptURLs[i])) {
        exempted = true;
        break;
      }
    }
    // Exempting URL since its supposed to be unauthorised
    if (!jwt && !exempted) {
      window.location = '/signin/10001';
    }
    request.headers.Authorization = `Bearer ${jwt}`;
    request.headers['Access-Control-Allow-Origin'] = '*';
    return request;
  },
  (error) => Promise.reject(error),
);

TC_COMPLY_API_SERVICES.interceptors.response.use(
  (response) => response.data,
  (error) => {
    let errRes = '';
    if (typeof error === 'object' && error.message !== undefined) {
      errRes = error.message;
    }

    try {
      if (errRes && errRes.includes('not authenticated')) {
        // Redirect to login page throwing session expired error code
        window.location = '/signin/10001';
      }
    } catch (err) {
      console.log(err);
    }
    return Promise.reject(error);
  },
);

export default API;
export {
  API_PRODUCT_SERVICES,
  API_MERGE_SLIDE_SERVICE,
  TC_COMPLY_API_SERVICES,
};
