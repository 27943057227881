<template>
  <div class="template-preview-popup-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="loader-container" v-if="loading">
      <clip-loader
        :loading="loading"
        :color="`#21a7e0`"
        :width="'60'"
        :height="'60'"
        :size="'50px'"
        class="cliploader"
      />
    </div>
    <div v-else class="template-preview-popup-wrapper-content">
      <div class="popup-carousel-wrapper">
        <v-carousel
          height="auto"
          hide-delimiters
          class="slide-img"
          light
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          v-model="currentImage"
        >
          <v-carousel-item v-for="(tImage, index) in themeImages" :key="index">
            <v-img
              :src="tImage"
              width="624px"
              class="v-slide-img"
              lazy-src="/assets/img/slides/placeholder-slide.svg"
            >
              <!--               height="275px"
              width="720px" -->
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0 align-placeholder"
                  align="center"
                  justify="center"
                >
                  <clip-loader :color="`#21a7e0`" :width="20" :height="20" />
                </v-row>
              </template>
            </v-img>
          </v-carousel-item>
        </v-carousel>
        <div class="carousel-nav-section">
          <img
            @click="setCurrentImage(j)"
            :src="
              currentImage === j
                ? `/assets/img/trial/dot-indicator-active.svg`
                : `/assets/img/trial/dot-indicator-inactive.svg`
            "
            alt
            v-for="(img, j) in themeImages"
            :key="j"
          />
        </div>
      </div>
      <div class="popup-data-wrapper">
        <div class="data-title">
          <p class="name">{{ selectedCard.name }}</p>
          <p class="createdBy">Created by {{ selectedCard.uploadedByName }}</p>
        </div>
        <div
          class="palette-wrapper"
          v-if="
            selectedCard.theme_color_palette &&
            selectedCard.theme_color_palette.length
          "
        >
          <p>Shapes Color Palette</p>
          <div class="color-wrap">
            <div
              :key="`${color + index}`"
              v-for="(color, index) in selectedCard.theme_color_palette"
              class="color-fill"
              :style="{ background: `#${color}` }"
            ></div>
          </div>
        </div>
        <div
          class="palette-wrapper"
          v-if="
            selectedCard.chart_color_palette &&
            selectedCard.chart_color_palette.length
          "
        >
          <p>Charts Color Palette</p>
          <div class="color-wrap">
            <div
              :key="`${color + index}`"
              v-for="(color, index) in selectedCard.chart_color_palette"
              class="color-fill"
              :style="{ background: `#${color}` }"
            ></div>
          </div>
        </div>
        <div class="font-wrapper" v-if="selectedCard.header_font">
          <p class="font-title">Font Style</p>
          <p class="font-body">
            <span :style="{ 'font-family': selectedCard.header_font }">
              {{ selectedCard.header_font }} (Header),</span
            >
            <span :style="{ 'font-family': selectedCard.body_font }">
              {{ selectedCard.body_font }}
              (Body)
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { GraphQLService } from '../../../services/GraphQLService';
import { performSearch } from '@/graphql/queries';
import { getFileURL } from '@/utils/calyrex';
import { convertPdfToImage } from '../../../utils/pdf-to-png';
import { getThumbnail } from '../../../store/modules/helper';
import { slideDataSet } from './constants.js';

export default {
  name: 'TemplatePreviewPopup',
  computed: {
    ...mapState('users', ['currentUser']),
    slideIdsOrder() {
      // create an object and map slide id to sort position
      return Object.fromEntries(
        slideDataSet.map((slideData, index) => [
          slideData.split('/')[0],
          index,
        ]),
      );
    },
  },
  components: {
    ClipLoader,
  },
  props: {
    selectedCard: {
      type: Object,
    },
  },
  data() {
    return {
      currentImage: 0,
      themeImages: [],
      loading: false,
      slideIdLimit: 4,
    };
  },
  methods: {
    setCurrentImage(index) {
      this.currentImage = index;
    },
    handleClose() {
      this.$modal.hide('TemplatePreviewPopup');
    },
    getSlideId(uniqueID) {
      // check if slide id exists in unique id
      for (const slideId in this.slideIdsOrder) {
        if (uniqueID.includes(slideId)) {
          return slideId;
        }
      }
      return null; // In case no id is found
    },
    getSortedSlides(slides) {
      // sort the slides according to the index of the slide ids
      return slides.sort((aStr, bStr) => {
        const slideA = this.getSlideId(aStr?.unique_id);
        const slideB = this.getSlideId(bStr?.unique_id);
        return this.slideIdsOrder[slideA] - this.slideIdsOrder[slideB];
      });
    },
    createSlideIds() {
      // create id as per format
      return slideDataSet.map((section) => {
        const [slideId, nodes] = section.split('/');
        return `${this.currentUser.company.name}_${slideId}_${this.selectedCard.code}_${nodes}_${this.selectedCard.code}`;
      });
    },
    async fetchLandscapeImage(asset) {
      let landscapeAsset = '';
      if (asset.prefs.source === 'uploaded') {
        const uploadedLandscape = await getFileURL(
          this.currentUser.user.cognitoID,
          asset.landscape,
          this.currentUser.userIp,
          'IOCUpload',
        );
        landscapeAsset = await convertPdfToImage(uploadedLandscape);
      } else if (
        asset.prefs.source === 'generated' ||
        asset.prefs.source === 'synthesis' ||
        asset.prefs.source === 'redesign' ||
        asset.prefs.source === 'comply'
      ) {
        if (
          asset.landscape.startsWith('/newImageReplaceOutput/output/') ||
          asset.landscape.startsWith('/output/pptx/')
        ) {
          landscapeAsset = await getThumbnail(asset.landscape);
        } else {
          landscapeAsset = await getFileURL(
            this.currentUser.user.cognitoID,
            asset.landscape,
            this.currentUser.userIp,
            'IOCUpload',
          );
        }
      } else {
        landscapeAsset = await getFileURL(
          this.currentUser.user.cognitoID,
          asset.landscape,
          this.currentUser.userIp,
        );
      }
      return landscapeAsset;
    },
    fetchNoIdSlideURLs(limit) {
      // this is called when no result is returned with given slideIds
      const savedQueryPayloadWithoutCaregories = {
        andprefs: {
          theme: this.selectedCard.code,
          has_cartoon: ['yes', 'no'],
          is_know: ['yes', 'no'],
        },
        notprefs: {
          category: ['prezautoconversion', 'prezfingerprinttest'],
        },
        limit,
        is_recommender_search: false,
      };
      const serializedWithoutCaregories = JSON.stringify(
        savedQueryPayloadWithoutCaregories,
      );
      this.fetchSlideURLs(serializedWithoutCaregories).then((newSlides) => {
        if (newSlides.length) {
          newSlides.forEach(async (item) => {
            if (item?.landscape) {
              const landscapeURL = await this.fetchLandscapeImage(item);
              this.themeImages.push(landscapeURL);
            }
          });
        }
        this.loading = false;
      });
    },
    async fetchSlideURLs(serialized) {
      return new Promise((resolve, reject) => {
        GraphQLService.requestWithToken(
          graphqlOperation(performSearch, {
            parameters: serialized,
          }),
        )
          .then((res) => {
            const result = JSON.parse(res?.data?.performSearch);
            const slides = result?.body?.payloads || [];
            resolve(slides);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  async mounted() {
    this.loading = true;
    const thumbnailURL = await getFileURL(
      null,
      `public/${this.selectedCard.thumbnail}`,
      null,
      'storage',
    );
    this.themeImages.push(thumbnailURL);
    const savedQueryPayload = {
      andprefs: {
        theme: this.selectedCard.code,
        id: this.createSlideIds(),
        is_parent_slide: [true, false],
      },
      limit: 10,
      is_recommender_search: false,
    };
    const serialized = JSON.stringify(savedQueryPayload);
    this.fetchSlideURLs(serialized).then((slides) => {
      if (slides.length) {
        // show only four
        const slidesList = this.getSortedSlides([...slides]).slice(
          0,
          this.slideIdLimit,
        );
        slidesList.forEach(async (item) => {
          if (item?.landscape) {
            const landscapeURL = await this.fetchLandscapeImage(item);
            this.themeImages.push(landscapeURL);
          }
        });

        if (slidesList.length < 4) {
          // if the call with slide ids return less than 4 results
          this.fetchNoIdSlideURLs(this.slideIdLimit - slidesList.length);
        } else {
          this.loading = false;
        }
      } else {
        this.fetchNoIdSlideURLs(this.slideIdLimit);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.template-preview-popup-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px 24px 24px 24px;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    color: #41484d;
  }

  .template-preview-popup-wrapper-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .popup-carousel-wrapper {
    margin: 20px 20px 20px 20px;
    .slide-img {
      box-shadow: -0.56px -0.56px 2.25px 0px #0000001a,
        0.56px 0.56px 2.25px 0px #0000001a;
      border-radius: 4px;
      .v-slide-img {
        aspect-ratio: 16/9;
      }
    }
  }
  .popup-data-wrapper {
    margin: 0px 20px 20px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    .data-title {
      display: flex;
      flex-direction: column;
      gap: 5px;
      letter-spacing: 0.01em;
      text-align: left;
      font-family: Lato;
      padding-bottom: 10px;
      .name {
        font-family: Lato;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
      }
      .createdBy {
        font-family: Lato;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
      }
    }
    .palette-wrapper {
      display: flex;
      align-items: center;
      column-gap: 20px;
      p {
        flex-basis: 170px;
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.2px;
        text-align: left;
      }
      .color-wrap {
        display: flex;
        .color-fill {
          height: 24px;
          width: 24px;
          border-radius: 50%;
          margin: 5px;
          box-shadow: 1.34px 1.34px 5.35px 0px #0000001a;
          border: 1px solid #0000001a;
        }
      }
    }
    .font-wrapper {
      display: flex;
      align-items: center;
      column-gap: 20px;
      .font-title {
        flex-basis: 170px;
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.2px;
        text-align: left;
      }
      .font-body {
        margin-left: 5px;
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 20.8px;
        text-align: left;
      }
    }
  }
}

::v-deep .v-window {
  .v-window__prev,
  .v-window__next {
    button {
      .v-icon {
        font-size: 24px !important;
        color: white;
      }
    }
  }
  .v-window__prev {
    left: 0x;
  }
  .v-window__next {
    right: 0;
  }
}
.carousel-nav-section {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 10px;
  > img {
    cursor: pointer;
  }
}
.align-placeholder {
  align-items: center;
  justify-content: center;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>
