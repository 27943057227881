import createPersistedState from 'vuex-persistedstate';

const initialState = {
  isDirtyIcon: false,
  showDialogIcon: false,
  shouldReturnToSlideIcon: false,
  searchTermIcon: '',
  sourceIcon: '',
};
export const replaceIconStore = {
  namespaced: true,
  state: initialState,
  getters: {
    getIsDirtyIcon: (state) =>
      state ? state.isDirtyIcon : initialState.isDirtyIcon,
    getShowDialogIcon: (state) =>
      state ? state.showDialogIcon : initialState.showDialogIcon,
    getShouldReturnToSlideIcon: (state) =>
      state
        ? state.shouldReturnToSlideIcon
        : initialState.shouldReturnToSlideIcon,
    getSearchTermIcon: (state) =>
      state
        ? {
            searchTermIcon: state.searchTermIcon,
            sourceIcon: state.sourceIcon,
          }
        : {
            searchTermIcon: initialState.searchTermIcon,
            sourceIcon: initialState.sourceIcon,
          },
  },
  mutations: {
    OPEN_DIALOG_ICON(state) {
      state.showDialogIcon = true;
    },
    CLOSE_DIALOG_ICON(state) {
      state.showDialogIcon = false;
    },
    RESET_ICON(state) {
      if (state) {
        state.isDirtyIcon = false;
      }
    },
    RESET_RETURN_ICON(state) {
      if (state) {
        state.shouldReturnToSlideIcon = false;
      }
    },
    MAKE_DIRTY_ICON(state) {
      state.isDirtyIcon = true;
    },
    SET_RETURN_TO_SLIDE_ICON(state) {
      state.shouldReturnToSlideIcon = true;
    },
    SET_SEARCH_DATA_ICON(state, searchData) {
      const { searchTerm, source } = searchData;
      state.searchTermIcon = searchTerm;
      state.sourceIcon = source;
    },
  },
  actions: {
    resetIcon({ commit }) {
      commit('RESET_ICON');
    },
    resetReturnIcon({ commit }) {
      commit('RESET_RETURN_ICON');
    },
    makeDirtyIcon({ commit }) {
      commit('MAKE_DIRTY_ICON');
    },
    openDialogIcon({ commit }, shouldReturnToSlideIcon = false) {
      commit('OPEN_DIALOG_ICON');
      if (shouldReturnToSlideIcon) commit('SET_RETURN_TO_SLIDE_ICON');
    },
    closeDialogIcon({ commit }) {
      commit('CLOSE_DIALOG_ICON');
    },
    setIconSearchData({ commit }, searchData) {
      commit('SET_SEARCH_DATA_ICON', searchData);
    },
  },
  persist: createPersistedState({
    key: 'vuex_replaceIconStore',
    paths: [],
  }),
};
