<template>
  <v-app class="vApp">
    <v-main>
      <div v-if="isLoading || isFailed" class="loading-wrapper">
        <div class="logo-loading">
          <img src="/assets/img/prezent_stack.svg" alt width="248px" />
        </div>
        <p v-if="isFailed" class="expired-link">
          Redirecting you to teams.prezent.ai...
        </p>
        <clip-loader
          v-if="isLoading || isFailed"
          :loading="true"
          :color="`#21a7e0`"
          :width="'60'"
          :height="'60'"
          :size="'50px'"
          class="cliploader"
        />
      </div>
      <div v-if="!isLoading && !isFailed" class="enterpriseRegistration">
        <div class="header">
          <img
            src="/assets/img/prezent_logo.svg"
            alt
            width="200px"
            height="44px"
          />
        </div>

        <div class="content">
          <div class="enterpriseSignup">
            <div class="staticContent">
              <div class="image">
                <v-img src="/assets/img/signup-image.svg" alt />
              </div>
            </div>

            <div class="enterpriseSignupForm">
              <v-form ref="form" class="vForm" lazy-validation>
                <div class="registrationHeading">
                  <h1>
                    <span>Sign up to Prezent</span>
                  </h1>
                </div>
                <div class="label">First name</div>
                <v-text-field
                  v-model="firstName"
                  :label="firstName ? '' : 'Enter your first name'"
                  :rules="formRules.firstName"
                  required
                  single-line
                  color="#000"
                  class="mb-4"
                  id="first"
                />
                <div class="label">Last name</div>
                <v-text-field
                  v-model="lastName"
                  :label="lastName ? '' : 'Enter your last name'"
                  :rules="formRules.lastName"
                  required
                  single-line
                  color="#000"
                  class="mb-4"
                  id="last"
                />
                <div class="label">Email</div>
                <v-text-field
                  :value="email"
                  :label="email ? '' : 'Enter your work email'"
                  :disabled="true"
                  required
                  single-line
                  color="#000"
                  class="mb-4"
                  id="email"
                />
                <div class="label">Company name</div>
                <v-text-field
                  :value="companyname"
                  :label="companyname ? '' : 'Enter your company name'"
                  :disabled="true"
                  required
                  single-line
                  color="#000"
                  class="mb-4"
                  id="companyname"
                />
                <div class="terms">
                  <p>
                    By clicking below, you agree to our<br /><a
                      href="https://prezent.ai/terms-of-service/"
                      target="_blank"
                    >
                      Terms of Service </a
                    >and
                    <a href="https://prezent.ai/privacy-policy/" target="_blank"
                      >Privacy Policy</a
                    >.
                  </p>
                </div>
                <v-btn
                  @click="submit"
                  :disabled="!firstName || !lastName || !isValidForm"
                  color="primary"
                  rounded
                  min-width="144"
                  height="50"
                  id="submit"
                  :loading="isSignupLoading"
                >
                  Sign up
                </v-btn>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { isEmojiExist } from '../components/utils';
import SuccessEnterpriseSignupModal from './components/EnterpriseSignup/SuccessEnterpriseSignupModal.vue';
import Service from '../API/enterprise-signup-api';

export default {
  name: 'EnterpriseSignup',
  props: {},
  components: {
    ClipLoader,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      companyname: '',
      formRules: {
        firstName: [
          (v) => !isEmojiExist(v) || 'Emojis are not allowed',
          (v) => !!v || 'First name is required',
          (v) => v.length <= 50 || 'Input must be less than 50 characters',
        ],
        lastName: [
          (v) => !isEmojiExist(v) || 'Emojis are not allowed',
          (v) => !!v || 'Last name is required',
          (v) => v.length <= 50 || 'Input must be less than 50 characters',
        ],
      },
      isLoading: true,
      isFailed: false,
      isSignupLoading: false,
    };
  },
  computed: {
    enterpriseSignupId() {
      return this.$route.query.id || null;
    },
    isValidForm() {
      return this.$refs.form.validate();
    },
  },
  mounted() {
    this.setDetails();
  },
  methods: {
    setDetails() {
      if (this.enterpriseSignupId) {
        Service.methods
          .getDetails(this.enterpriseSignupId)
          .then((response) => {
            this.email = response.data.email;
            this.companyname = response.data.companyname;
          })
          .catch(() => {
            this.isFailed = true;
            setTimeout(() => {
              this.$router.push('/signin', () => {});
            }, 3000);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.isFailed = true;
        this.isLoading = false;
        setTimeout(() => {
          this.$router.push('/signin', () => {});
        }, 3000);
      }
    },
    submit() {
      this.isSignupLoading = true;
      const payload = {
        id: this.enterpriseSignupId,
        email: this.email,
        firstname: this.firstName,
        lastname: this.lastName,
      };
      Service.methods
        .signup(payload)
        .then(() => {
          this.$modal.show(
            SuccessEnterpriseSignupModal,
            {},
            {
              name: 'SuccessEnterpriseSignupModal',
              width: '683px',
              height: '605px',
            },
          );
        })
        .catch(() => {})
        .finally(() => {
          this.isSignupLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.logo {
  padding: 30px 30px 0px 40px;
  width: fit-content;
}
.logo-loading {
  padding-bottom: 20px;
}
.expired-link {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.loading-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
}

.enterpriseRegistration {
  overflow: auto;
  padding: 2rem;
}

.enterpriseSignup {
  display: flex;
  justify-content: space-evenly;

  .showMessage {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: none !important;
    width: 40% !important;
  }

  .enterpriseSignupForm {
    margin: 0px 50px;
    max-width: 300px;
    width: 25%;

    button {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      letter-spacing: normal;
      line-height: normal;
      margin-top: 38px;
      text-align: center;
      text-transform: none;
    }

    .label {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: left;
    }

    .registered {
      align-items: center;
      display: flex;
      flex-direction: column;
      font-size: 24px;
      text-align: center;
      .image {
        margin-top: 40px;
        margin-bottom: 10px;
      }
    }

    .terms {
      margin-top: 20px;

      p {
        margin-bottom: 0px;
      }
      a {
        text-decoration: none;
      }
    }
    .vForm {
      .registrationHeading {
        margin-bottom: 25px;
        padding-top: 10px;
      }
    }
  }

  .staticContent {
    .heading {
      color: #000000;
      font-size: 32px;
      font-weight: 700;
      letter-spacing: -0.02em;
      line-height: 38px;
      margin-bottom: 16px;
    }

    .subHeading {
      color: #000000;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
    }
  }
}
</style>
