import Fuse from 'fuse.js';

class LocalSearch {
  fuse;

  constructor(values, keys, threshold = 0) {
    if (!values || !keys) {
      throw new Error('Required values and keys for local search');
    }
    this.fuse = new Fuse(values, {
      keys,
      includeMatches: true,
      threshold,
      ignoreLocation: true,
    });
  }

  search(value) {
    return this.fuse.search(value).map((e) => e.item);
  }
}

export default LocalSearch;
