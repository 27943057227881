<template>
  <div class="em-card">
    <div class="em-card__checkimage">
      <v-img
        src="/assets/img/feather_check-circle.svg"
        alt
        max-width="60px"
        max-height="60px"
      />
    </div>
    <p>
      Yay! Your organization already has an Enterprise subscription with Prezent
    </p>

    <div>
      <p>
        We have emailed you a link. Please use it to complete the sign-up
        process and begin using Prezent
      </p>
    </div>
    <a @click="backToLogin">Back to main login screen</a>
    <div class="try-for-free">
      Don’t have an account?&nbsp;
      <a :href="getTryForFreeLink()">Try for free</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EnterpriseSignupEmailConfirmation',

  methods: {
    backToLogin() {
      this.$emit('onBackToLogin');
    },
    getTryForFreeLink() {
      const appDomain = process.env.VUE_APP_DOMAIN;
      return `${appDomain}/trialregistration`;
    },
  },
};
</script>

<style lang="scss" scoped>
.em-card {
  max-width: 340px;
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 2rem 0;
  font-size: 1rem;

  &__checkimage {
    margin: 1.5rem 0;
  }
}
.try-for-free {
  text-align: center;
  color: $gray-dark-login-text;
  display: block;
  a {
    color: $dark-blue;
    text-transform: none;
  }
}
</style>
