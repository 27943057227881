import { Client } from 'typesense';
import { typeSenseConfig } from '../../runtimeConfig';
import API, { getLoggedInUserToken } from '../../utils/api';

export const getTypeSenseAPIKey = async () => {
  try {
    const { data } = await API.get('/api2/typesense-api-key');

    return {
      apiKey: data.api_key,
      expiry: data.expiry,
    };
  } catch (e) {
    console.log(e);
    return {};
  }
};

/** @type {Client} */
let typeSenseClient;
let expiry = 0;
let token = null;
const {
  typesense_host: typeSenseHost,
  typesense_port: typeSensePort,
  typesense_protocol: typeSenseProtocol,
} = typeSenseConfig;

export const TypeSenseServer = {
  async setUpClient() {
    const apiRsp = await getTypeSenseAPIKey();
    expiry = apiRsp.expiry;
    typeSenseClient = new Client({
      nodes: [
        {
          host: typeSenseHost,
          port: typeSensePort,
          protocol: typeSenseProtocol,
        },
      ],
      apiKey: apiRsp.apiKey,
      connectionTimeoutSeconds: 15,
      numRetries: 5,
    });
  },

  async getClient() {
    const newToken = await getLoggedInUserToken();

    if (token !== newToken) {
      expiry = 0;
    }

    if (new Date().getTime() > expiry) {
      await this.setUpClient();
    }

    token = newToken;
    return typeSenseClient;
  },
};
