export const DialogContents = {
  exampleContents: [
    {
      title: 'Healthcare Example',
      question:
        '<span style="color: #29b6f6">How do we improve patient experience in our hospital to </span>drive 20% growth in revenue by Dec 2022?',
      examples: [
        {
          title: 'Focused',
          question: 'How do we improve patient experience in our hospital?',
        },
        {
          title: 'intuitive',
          question: 'How do we grow revenue by 20%?',
        },
        {
          title: 'Timebound',
          question: 'How do we grow by Dec 2022?',
        },
      ],
    },
    {
      title: 'Media Example',
      question:
        '<span style="color: #29b6f6">How do we reshape our marketing programs to </span>attract 50% more viewers to our channel in next 6 months?',
      examples: [
        {
          title: 'Focused',
          question: 'How do we reshape our marketing programs?',
        },
        {
          title: 'intuitive',
          question: 'How do we attract 50% more viewers?',
        },
        {
          title: 'Timebound',
          question: 'What do we do in next 6 months?',
        },
      ],
    },
    {
      title: 'Aerospace Example',
      question:
        '<span style="color: #29b6f6">What guidelines should we provide to airline staff</span> to ensure 100% regulartory compliance for the launch next year?',
      examples: [
        {
          title: 'Focused',
          question: 'What guidelines should we provide th airline staff?',
        },
        {
          title: 'intuitive',
          question: 'How do we ensure 100% compliance with regulations?',
        },
        {
          title: 'Timebound',
          question: 'How can we be ready for the launch next year?',
        },
      ],
    },
  ],
  storyContents: {
    ladder: {
      image: 'story-whatsowhat.png',
      title: 'What-So<br/>What-Now What',
      description:
        'This Story Template is best used to persuade an audience.<br/> Use it to translate data to insights to action',
      beginning: [
        {
          title: 'What',
          subTitle: 'Consider adding',
          items: [
            {
              title: 'Data insights',
              data: '',
            },
            {
              title: 'Customer information',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: [
                'Data Insights: Recent survey indicates that Drug A’s market share has decreased from 20% to 10%',
                'Customer Information: Physician interviews reveal patients are not staying on drug due to side effects',
              ],
            },
            {
              title: 'High Tech',
              contents: ['TBD'],
            },
          ],
        },
      ],
      middle: [
        {
          title: 'So What',
          subTitle: 'Consider adding',
          items: [
            {
              title: 'Implications',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: [
                'Implications: We need to address patient side effects or Drug A’s market share will continue to decline',
              ],
            },
            {
              title: 'High Tech',
              contents: ['TBD'],
            },
          ],
        },
      ],
      end: [
        {
          title: 'Now What',
          subTitle: 'Description',
          items: [
            {
              title: 'Call to Action',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: [
                'Call to Action: Focus on educating physicians about side effect management',
              ],
            },
            {
              title: 'High Tech',
              contents: ['TBD'],
            },
          ],
        },
      ],
    },
    pillars: {
      image: 'story-pillars.png',
      title: 'Pillars',
      description: 'This Story Template is best used to make a recommendation',
      beginning: [
        {
          title: 'Overall vision/recommendation',
          subTitle: 'Consider adding',
          items: [
            {
              title: 'What is the overarching vision?',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: ['Become the #1 Treatment of Choice for patients'],
            },
            {
              title: 'High Tech',
              contents: ['Launch product into the consumer segment'],
            },
          ],
        },
      ],
      middle: [
        {
          title: 'For each pillar, consider',
          subTitle: 'Consider adding',
          items: [
            {
              title: 'Supporting points that reinforce recommendation',
              data: '',
            },
            {
              title: 'Current -> Future',
              data: '',
            },
            {
              title: 'Activities to achieve vision',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              subTitle: 'Supporting points include:',
              contents: [
                'Facilitate treatment procedure',
                'Drive efficacy story',
                'Limit financial burden',
              ],
            },
            {
              title: 'High Tech',
              contents: ['TBD'],
            },
          ],
        },
      ],
      end: [
        {
          title: 'Next steps/Timeline',
          subTitle: 'Consider adding',
          items: [
            {
              title: 'Supporting steps and activities to achieve vision',
              data: '',
            },
            {
              title: 'Calendar of events',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: [
                'January – Market Research to understand top concerns',
                'February – Pilot new efficacy messages',
                'March – Learn and iterate',
              ],
            },
            {
              title: 'High Tech',
              contents: ['TBD'],
            },
          ],
        },
      ],
    },
    pastPresentFuture: {
      image: 'story-ppp.png',
      title: 'Past Present<br/>Future',
      description:
        'This Story Template is best used when you want to inform the audience in a chronological fashion. Best used for working team meetings to bring everyone along',
      beginning: [
        {
          title: 'Past context',
          subTitle: 'Consider adding',
          items: [
            {
              title: 'What happened',
              data: '',
            },
            {
              title: 'Background',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: ['New competitor entered the market last year'],
            },
            {
              title: 'High Tech',
              contents: ['TBD'],
            },
          ],
        },
      ],
      middle: [
        {
          title: 'Present situation',
          subTitle: 'Consider adding',
          items: [
            {
              title: 'What’s happening currently',
              data: '',
            },
            {
              title: 'Current status',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: [
                'New competitor has gained share rapidly – current share 10%',
              ],
            },
            {
              title: 'High Tech',
              contents: ['TBD'],
            },
          ],
        },
      ],
      end: [
        {
          title: 'Future plan',
          subTitle: 'Description',
          items: [
            {
              title: 'What’s next',
              data: '',
            },
            {
              title: 'Future scenarios',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: [
                'New competitor expected to gain 30% of market at its peak',
              ],
            },
            {
              title: 'High Tech',
              contents: ['TBD'],
            },
          ],
        },
      ],
    },
    flower: {
      image: 'story-flower.png',
      title: 'Flower',
      description:
        'This Story Template is best used when you want to explain or persuade from different points of view',
      beginning: [
        {
          title: 'Overall takeaway',
          subTitle: 'Consider adding',
          items: [
            {
              title: 'What is the synthesis of the document',
              data: '',
            },
            {
              title: 'What is the recommendation and/or vision',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: [
                'Global Type 2 Diabetes Market will double to $60B by 2025, presenting a large opportunity',
              ],
            },
            {
              title: 'High Tech',
              contents: ['TBD'],
            },
          ],
        },
      ],
      middle: [
        {
          title: 'For each petal, consider',
          subTitle: 'Consider adding',
          items: [
            {
              title: 'Supporting points that reinforce overall core',
              data: '',
            },
            {
              title: 'Various perspectives (e.g., external, internal)',
              data: '',
            },
            {
              title: 'Multiple different stakeholders',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              subTitle: 'Supporting points include:',
              contents: [
                'Health trends: Increasing obesity, rising geriatric population',
                'Drugs/Pipeline: Total of 60+ drugs in pipeline at various stages',
                'Expert interviews: Interviews with scientific experts and doctors validate growth',
              ],
            },
            {
              title: 'High Tech',
              contents: ['TBD'],
            },
          ],
        },
      ],
      end: [
        {
          title: 'Reinforce overall takeaway',
          subTitle: 'Description',
          items: [
            {
              title:
                'Reiterate beginning with different language to create more urgency',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: [
                'The Diabetes market presents a great opportunity and expected to double to $60B by 2025',
              ],
            },
            {
              title: 'High Tech',
              contents: ['TBD'],
            },
          ],
        },
      ],
    },
    explanation: {
      image: 'story-ladder.png',
      title: 'Explanation',
      description:
        'This Story Template is best used to explain, inform or align an audience<br/>Use it to present a process/plan or teach something new',
      beginning: [
        {
          title: 'Context:',
          subTitle: 'Consider adding',
          items: [
            {
              title: 'What is the background',
              data: '',
            },
            {
              title: 'Where are we now',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: [
                'Sales uptake of recently launched drug lower than expected',
              ],
            },
            {
              title: 'High Tech',
              contents: [
                'Promising new technology could be source of new business line',
              ],
            },
          ],
        },
        {
          title: 'Objectives:',
          items: [
            {
              title: 'What are the goals',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: [
                'Understand why sales uptake is low – where in the patient journey',
              ],
            },
            {
              title: 'High Tech',
              contents: ['Explain the steps to productize new technology'],
            },
          ],
        },
        {
          title: 'Roadmap:',
          items: [
            {
              title: 'Overview of steps',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: ['Four steps in the patient journey'],
            },
            {
              title: 'High Tech',
              contents: ['Three steps to productize technology'],
            },
          ],
        },
      ],
      middle: [
        {
          title: 'For each step:',
          subTitle: 'Consider adding',
          items: [
            {
              title: 'Description',
              data: '',
            },
            {
              title: 'Assumptions',
              data: '',
            },
            {
              title: 'Questions',
              data: '',
            },
            {
              title: 'Key activities/actions',
              data: '',
            },
            {
              title: 'Areas of opportunity/gaps',
              data: '',
            },
            {
              title: 'Insights',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              subTitle: 'Supporting points include:',
              contents: [
                'Step 1 (Patient screening):  Screening is recommended but is <5%<br/>Step 2 (Diagnosis):  Biomarker analysis used and typically conducted by Internal Medicine<br/>Step 3 (Treatment Plan):  Devised by oncologist based on efficacy and financial concerns<br/>Step 4 (Disease Management): Nurses play a critical role',
              ],
            },
            {
              title: 'High Tech',
              contents: [
                'Step 1: Business Planning and alignment<br/>Step 2: Create a Solution Tools<br/>Step 3: Ensure Field Readiness',
              ],
            },
          ],
        },
      ],
      end: [
        {
          title: 'Assessment:',
          subTitle: 'Description',
          items: [
            {
              title:
                'Assess each step against criteria e.g., ability to impact, Size of opportunity',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: [
                'Step 1 (Screening) and Step 3 (Treatment Plan) are impacting sales uptake',
              ],
            },
            {
              title: 'High Tech',
              contents: [
                'Step 1 (Business Plan) is critical to ensuring success',
              ],
            },
          ],
        },
        {
          title: 'Actions:',
          items: [
            {
              title: 'Actions/Next Steps',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: [
                'Improve access to screening Reduce friction in Treatment',
              ],
            },
            {
              title: 'High Tech',
              contents: [
                'Further flesh out step 1 and gain alignment with stakeholders',
              ],
            },
          ],
        },
      ],
    },
    bridge: {
      image: 'story-bridge.png',
      title: 'Bridge',
      description:
        'This Story Template is best used to inspire and persuade an audience.<br/>Use it to share a vision of what can be',
      beginning: [
        {
          title: 'Current State (What is)',
          subTitle: 'Consider adding',
          items: [
            {
              title: 'Current Focus Areas',
              data: '',
            },
            {
              title: 'Current Team Structure',
              data: '',
            },
            {
              title: 'Challenges & Opportunities',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: [
                'Focus Areas: Value based care, economic sustainability<br/><br/>Current Team Structure:  Organized by customer and functional expertise<br/><br/>Challenges & Opportunities: Limited focus on customer (patient and physician) need',
              ],
            },
            {
              title: 'High Tech',
              contents: ['TBD'],
            },
          ],
        },
      ],
      middle: [
        {
          title: 'Future State (What can be)',
          subTitle: 'Consider adding',
          items: [
            {
              title: 'Vision',
              data: '',
            },
            {
              title: 'Delivery Model',
              data: '',
            },
            {
              title: 'Desired Team Structure',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: [
                'Vision: Deliver the right content to the right stakeholders at the right time<br/><br/>Desired Team Structure:  Flexible teams that self assemble based on customer needs, Centers of excellence on relevant topics',
              ],
            },
            {
              title: 'High Tech',
              contents: ['TBD'],
            },
          ],
        },
      ],
      end: [
        {
          title: 'Bridge the gap between what is and what can be	',
          subTitle: 'Description',
          items: [
            {
              title: 'Gap Assessment',
              data: '',
            },
            {
              title: 'Tactics to implement vision',
              data: '',
            },
            {
              title: 'Implementation Plan/Key Milestones',
              data: '',
            },
          ],
          examples: [
            {
              title: 'Healthcare',
              contents: [
                'Gap Assessment:  Key gaps along the customer journey include prevention and risk reduction<br/><br/>Tactics to implement vision: Brochures and focused messages on prevention programs<br/><br/>Implementation Plan/Key Milestones:  Finalize vendors in Q1, Pilot programs in Q2, Full rollout in Q3',
              ],
            },
            {
              title: 'High Tech',
              contents: ['TBD'],
            },
          ],
        },
      ],
    },
  },
  my_presentations: [
    {
      id: 1,
      name: 'Brand Plan for Product X',
      type: 'Brand Plan',
      slide: '/assets/img/slides1.png',
      relatedSlides: [2, 3, 4, 5, 6],
    },
    {
      id: 2,
      name: 'Sales',
      type: 'Brand Plan',
      slide: '/assets/img/slides2.png',
      relatedSlides: [3, 4, 5, 6, 7],
    },
    {
      id: 3,
      name: 'Project M',
      type: 'Brand Plan',
      slide: '/assets/img/slides3.png',
      relatedSlides: [4, 5, 6, 7, 8],
    },
    {
      id: 4,
      name: 'Brand Plan for Product X',
      type: 'Brand Plan',
      slide: '/assets/img/slides4.png',
      relatedSlides: [5, 6, 7, 8, 9],
    },
    {
      id: 5,
      name: 'Sales',
      type: 'Brand Plan',
      slide: '/assets/img/slides5.png',
      relatedSlides: [6, 7, 8, 9, 10],
    },
    {
      id: 6,
      name: 'Project M',
      type: 'Brand Plan',
      slide: '/assets/img/slides6.png',
      relatedSlides: [7, 8, 9, 10, 11],
    },
    {
      id: 7,
      name: 'Brand Plan for Product X',
      type: 'Brand Plan',
      slide: '/assets/img/slides7.png',
      relatedSlides: [8, 9, 10, 11, 12],
    },
    {
      id: 8,
      name: 'Sales',
      type: 'Brand Plan',
      slide: '/assets/img/slides8.png',
      relatedSlides: [9, 10, 11, 12, 13],
    },
    {
      id: 9,
      name: 'Project M',
      type: 'Brand Plan',
      slide: '/assets/img/slides9.png',
      relatedSlides: [10, 11, 12, 13, 14],
    },
  ],
  review_presentations: [
    {
      id: 10,
      name: 'Q1 Sales',
      type: 'Brand Plan',
      creator: 'Bob Smith',
      slide: '/assets/img/slides10.png',
      relatedSlides: [1, 2, 3, 4, 5],
    },
    {
      id: 11,
      name: 'Presentation for the Board',
      type: 'Brand Plan',
      creator: 'Bob Smith',
      slide: '/assets/img/slides11.png',
      relatedSlides: [2, 3, 4, 5, 6],
    },
    {
      id: 12,
      name: 'Pitch',
      type: 'Brand Plan',
      creator: 'Bob Smith',
      slide: '/assets/img/slides12.png',
      relatedSlides: [3, 4, 5, 6, 7],
    },
    {
      id: 13,
      name: 'Trends',
      type: 'Brand Plan',
      creator: 'Bob Smith',
      slide: '/assets/img/slides13.png',
      relatedSlides: [4, 5, 6, 7, 8],
    },
    {
      id: 14,
      name: 'Q1 Sales',
      type: 'Brand Plan',
      creator: 'Bob Smith',
      slide: '/assets/img/slides14.png',
      relatedSlides: [5, 6, 7, 8, 9],
    },
    {
      id: 15,
      name: 'Presentation for the Board',
      type: 'Brand Plan',
      creator: 'Bob Smith',
      slide: '/assets/img/slides15.png',
      relatedSlides: [6, 7, 8, 9, 10],
    },
    {
      id: 16,
      name: 'Pitch',
      type: 'Brand Plan',
      creator: 'Bob Smith',
      slide: '/assets/img/slides16.png',
      relatedSlides: [7, 8, 9, 10, 11],
    },
    {
      id: 17,
      name: 'Trends',
      type: 'Brand Plan',
      creator: 'Bob Smith',
      slide: '/assets/img/slides17.png',
      relatedSlides: [8, 9, 10, 11, 12],
    },
  ],
  imageProducts: [
    {
      id: 1,
      image: '/assets/img/slider0.png',
      relatedProducts: [2, 3, 4, 5, 6],
      ratings: '2',
      downloadLink: '',
    },
    {
      id: 2,
      image: '/assets/img/slider1.png',
      relatedProducts: [1, 3, 4, 5, 6],
      ratings: '3',
      downloadLink: '',
    },
    {
      id: 3,
      image: '/assets/img/slider2.png',
      relatedProducts: [1, 2, 4, 5, 6],
      ratings: '4',
      downloadLink: '',
    },
    {
      id: 4,
      image: '/assets/img/slider3.png',
      relatedProducts: [1, 2, 3, 5, 6],
      ratings: '5',
      downloadLink: '',
    },
    {
      id: 5,
      image: '/assets/img/slider4.png',
      relatedProducts: [1, 2, 3, 4, 6],
      ratings: '2.2',
      downloadLink: '',
    },
    {
      id: 6,
      image: '/assets/img/slider5.png',
      relatedProducts: [1, 2, 3, 4, 5],
      ratings: '4.2',
      downloadLink: '',
    },
    {
      id: 7,
      image: '/assets/img/slider6.jpg',
      relatedProducts: [6, 5, 4, 3, 2],
      ratings: '3.5',
      downloadLink: '',
    },
    {
      id: 8,
      image: '/assets/img/slider7.jpg',
      relatedProducts: [7, 1, 4, 3, 2],
      ratings: '4.6',
      downloadLink: '',
    },
    {
      id: 9,
      image: '/assets/img/slider8.jpg',
      relatedProducts: [2, 5, 1, 4, 7],
      ratings: '5',
      downloadLink: '',
    },
    {
      id: 10,
      image: '/assets/img/slider8.jpg',
      relatedProducts: [2, 5, 1, 4, 7],
      ratings: '5',
      downloadLink: '',
    },
    {
      id: 11,
      image: '/assets/img/slider8.jpg',
      relatedProducts: [2, 5, 1, 4, 7],
      ratings: '5',
      downloadLink: '',
    },
    {
      id: 12,
      image: '/assets/img/slider8.jpg',
      relatedProducts: [2, 5, 1, 4, 7],
      ratings: '5',
      downloadLink: '',
    },
    {
      id: 13,
      image: '/assets/img/slider8.jpg',
      relatedProducts: [2, 5, 1, 4, 7],
      ratings: '5',
      downloadLink: '',
    },
    {
      id: 14,
      image: '/assets/img/slider8.jpg',
      relatedProducts: [2, 5, 1, 4, 7],
      ratings: '5',
      downloadLink: '',
    },
    {
      id: 15,
      image: '/assets/img/slider8.jpg',
      relatedProducts: [2, 5, 1, 4, 7],
      ratings: '5',
      downloadLink: '',
    },
    {
      id: 16,
      image: '/assets/img/slider8.jpg',
      relatedProducts: [2, 5, 1, 4, 7],
      ratings: '5',
      downloadLink: '',
    },
    {
      id: 17,
      image: '/assets/img/slider8.jpg',
      relatedProducts: [2, 5, 1, 4, 7],
      ratings: '5',
      downloadLink: '',
    },
  ],
  slides: {
    beginning: [
      {
        name: 'Title',
        id: 1,
        slide: '/assets/img/slides0.png',
        relatedSlides: [2, 3, 4, 5, 6],
      },
      {
        name: 'Agenda',
        id: 2,
        slide: '/assets/img/slides1.png',
        relatedSlides: [7, 8, 9, 10, 11],
      },
      {
        name: 'Vision',
        id: 3,
        slide: '/assets/img/slides2.png',
        relatedSlides: [12, 13, 14, 15],
      },
      {
        name: 'Executive Summary',
        id: 4,
        slide: '/assets/img/slides3.png',
        relatedSlides: [16, 17, 18, 19, 20],
      },
      {
        name: 'Landscape',
        id: 5,
        slide: '/assets/img/slides4.png',
        relatedSlides: [21, 22, 23, 24, 25],
      },
    ],
    middle: [
      {
        name: 'Competition',
        id: 6,
        slide: '/assets/img/slides5.png',
        relatedSlides: [2, 6, 8, 7, 12],
      },
      {
        name: 'Customer Profile',
        id: 7,
        slide: '/assets/img/slides6.png',
        relatedSlides: [11, 14, 17, 12, 15],
      },
      {
        name: 'Product Overview',
        id: 8,
        slide: '/assets/img/slides7.png',
        relatedSlides: [21, 12, 19, 2, 5],
      },
      {
        name: 'Value Proposition',
        id: 9,
        slide: '/assets/img/slides8.png',
        relatedSlides: [7, 6, 5, 4, 2],
      },
      {
        name: 'Positioning',
        id: 10,
        slide: '/assets/img/slides9.png',
        relatedSlides: [25, 24, 23, 22, 21],
      },
    ],
    end: [
      {
        name: 'Risks and Benefits',
        id: 11,
        slide: '/assets/img/slides10.png',
        relatedSlides: [1, 2, 3, 4, 5],
      },
      {
        name: 'Dependencies',
        id: 12,
        slide: '/assets/img/slides11.png',
        relatedSlides: [2, 3, 4, 5, 6],
      },
      {
        name: 'Resources',
        id: 13,
        slide: '/assets/img/slides12.png',
        relatedSlides: [3, 4, 5, 6, 7],
      },
      {
        name: 'Enablement',
        id: 14,
        slide: '/assets/img/slides13.png',
        relatedSlides: [4, 5, 6, 7, 8],
      },
      {
        name: 'Milestones',
        id: 15,
        slide: '/assets/img/slides14.png',
        relatedSlides: [11, 12, 13, 8, 6],
      },
      {
        name: "What's Next",
        id: 16,
        slide: '/assets/img/slides15.png',
        relatedSlides: [29, 28, 27, 26, 25],
      },
    ],
    idea: [
      {
        name: 'Approach',
        id: 17,
        slide: '/assets/img/slides16.png',
        relatedSlides: [1, 2, 3, 4, 5],
      },
      {
        name: 'Context',
        id: 18,
        slide: '/assets/img/slides17.png',
        relatedSlides: [2, 3, 4, 5, 6],
      },
      {
        name: 'Imperatives',
        id: 19,
        slide: '/assets/img/slides18.png',
        relatedSlides: [3, 4, 5, 6, 7],
      },
      {
        name: 'Pricing',
        id: 20,
        slide: '/assets/img/slides19.png',
        relatedSlides: [4, 5, 6, 7, 8],
      },
      {
        name: 'Promotions',
        id: 21,
        slide: '/assets/img/slides20.png',
        relatedSlides: [5, 6, 7, 8, 9],
      },
      {
        name: 'Critical Success Factors',
        id: 22,
        slide: '/assets/img/slides21.png',
        relatedSlides: [1, 2, 3, 4, 5],
      },
      {
        name: '30-60-90 Day',
        id: 23,
        slide: '/assets/img/slides22.png',
        relatedSlides: [23, 12, 5, 2, 8],
      },
    ],
  },
  videoProducts: [
    {
      id: 1,
      image: '/assets/img/video-thumbnail-0.jpg',
      videoLink:
        'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
      relatedProducts: [2, 3, 4, 5, 6],
      ratings: '3',
      downloadLink: '',
    },
    {
      id: 2,
      image: '/assets/img/video-thumbnail-1.jpg',
      videoLink:
        'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
      relatedProducts: [1, 3, 4, 5, 6],
      ratings: '5',
      downloadLink: '',
    },
    {
      id: 3,
      image: '/assets/img/video-thumbnail-2.jpg',
      videoLink:
        'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
      relatedProducts: [1, 2, 4, 5, 6],
      ratings: '4',
      downloadLink: '',
    },
    {
      id: 4,
      image: '/assets/img/video-thumbnail-3.jpg',
      videoLink:
        'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
      relatedProducts: [1, 2, 3, 5, 6],
      ratings: '3',
      downloadLink: '',
    },
    {
      id: 5,
      image: '/assets/img/video-thumbnail-4.jpg',
      videoLink:
        'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
      relatedProducts: [1, 2, 3, 4, 6],
      ratings: '2',
      downloadLink: '',
    },
    {
      id: 6,
      image: '/assets/img/video-thumbnail-5.jpg',
      videoLink:
        'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
      relatedProducts: [1, 2, 3, 4, 5],
      ratings: '1',
      downloadLink: '',
    },
  ],
  profileContents: {
    basics: {
      firstName: 'Jane',
      lastName: 'Doe',
      jobTitle: 'Director',
      company: 'Prezentium',
      function: 'Egnieering',
      email: 'Jane@prezentium.com',
    },
    fingerprint: {
      title: 'director',
      logo: '/assets/img/lion.png',
      description:
        'Pragmatic leaders with a bias for action. Directors process information by analyzing the data and translating into concrete actionable steps. They appreciate the rich tapestry of bold communication.',
      preferences: [
        {
          title: 'Visual Preference',
          options: ['Vibrant', 'Minimal'],
          items: [
            'Open to bold imagery',
            'Prefer color to monostone',
            'Put emphasis on layouts',
          ],
          logo: '/assets/img/logo.jpg',
        },
        {
          title: 'Story Choice',
          options: ['Inductive', 'Deductive'],
          items: [
            'Like punchline early',
            'Well supported by data',
            'Story supporting answer',
          ],
          logo: '/assets/img/logo.jpg',
        },
        {
          title: 'Data Style',
          options: ['Dense', 'Light'],
          items: [
            'Stories supported with data',
            'OK with data-dense slides',
            'More data the better',
          ],
          logo: '/assets/img/logo.jpg',
        },
      ],
      charts: [
        {
          title: 'director',
          sub: '10',
          all: '15',
          logo: '/assets/img/lion.png',
        },
        {
          title: 'navigator',
          sub: '15',
          all: '10',
          logo: '/assets/img/lion.png',
        },
        {
          title: 'scholar',
          sub: '15',
          all: '10',
          logo: '/assets/img/lion.png',
        },
        {
          title: 'performer',
          sub: '10',
          all: '15',
          logo: '/assets/img/lion.png',
        },
        {
          title: 'architect',
          sub: '10',
          all: '15',
          logo: '/assets/img/lion.png',
        },
        {
          title: 'surgeon',
          sub: '15',
          all: '10',
          logo: '/assets/img/lion.png',
        },
        {
          title: 'scientist',
          sub: '15',
          all: '10',
          logo: '/assets/img/lion.png',
        },
        {
          title: 'producer',
          sub: '10',
          all: '15',
          logo: '/assets/img/lion.png',
        },
      ],
    },
    topTips: [
      {
        id: 0,
        tip: 'Tip #1',
      },
      {
        id: 1,
        tip: 'Tip #2',
      },
      {
        id: 2,
        tip: 'Tip #3',
      },
    ],
    metricsBilling: {
      overallScore: {
        count: 59,
      },
      teamEngagement: {
        count: 81,
        measures: 4,
      },
      teamLearningHealth: {
        count: 22,
        measures: 4,
      },
      timeSaved: {
        count: 35,
        measures: 5,
      },
      info: [
        {
          id: 0,
          name: 'Jane Doe',
          engagement: 'high',
          questionsAssessed: '3',
          storyTemplates: '5',
          slidesDownloaded: '45',
          videosSeen: '2',
          overnightSlides: '18',
          meetingsSynthesized: '1',
          expertSessions: '1',
        },
        {
          id: 1,
          name: 'Jane Doe',
          engagement: 'high',
          questionsAssessed: '2',
          storyTemplates: '3',
          slidesDownloaded: '23',
          videosSeen: '3',
          overnightSlides: '12',
          meetingsSynthesized: '2',
          expertSessions: '2',
        },
        {
          id: 2,
          name: 'Ryan Reynolds',
          engagement: 'high',
          questionsAssessed: '2',
          storyTemplates: '2',
          slidesDownloaded: '32',
          videosSeen: '2',
          overnightSlides: '22',
          meetingsSynthesized: '2',
          expertSessions: '2',
        },
        {
          id: 3,
          name: 'Adam Sandler',
          engagement: 'medium',
          questionsAssessed: '1',
          storyTemplates: '1',
          slidesDownloaded: '23',
          videosSeen: '3',
          overnightSlides: '25',
          meetingsSynthesized: '3',
          expertSessions: '3',
        },
        {
          id: 4,
          name: 'Deepti Juturu',
          engagement: 'medium',
          questionsAssessed: '1',
          storyTemplates: '1',
          slidesDownloaded: '22',
          videosSeen: '1',
          overnightSlides: '15',
          meetingsSynthesized: '2',
          expertSessions: '2',
        },
        {
          id: 5,
          name: 'Amal Clooney',
          engagement: 'low',
          questionsAssessed: '1',
          storyTemplates: '1',
          slidesDownloaded: '5',
          videosSeen: '2',
          overnightSlides: '10',
          meetingsSynthesized: '5',
          expertSessions: '5',
        },
        {
          id: 6,
          name: '',
          engagement: '',
          questionsAssessed: '10',
          storyTemplates: '13',
          slidesDownloaded: '100',
          videosSeen: '13',
          overnightSlides: '102',
          meetingsSynthesized: '15',
          expertSessions: '9',
        },
      ],
    },
    privacySetting: [
      {
        title: 'What information will be visible to your company members?',
        value: '0',
        options: [
          {
            title: 'Full Profile and Top Tips',
            value: '0',
          },
          {
            title: 'Limited Profile and Top Tips',
            value: '1',
          },
          {
            title: 'Limited Profile',
            value: '2',
          },
        ],
      },
      {
        title:
          'What information will be visible to members outside your company?',
        value: '2',
        options: [
          {
            title: 'Full Profile and Top Tips',
            value: '0',
          },
          {
            title: 'Limited Profile and Top Tips',
            value: '1',
          },
          {
            title: 'Limited Profile',
            value: '2',
          },
        ],
      },
    ],
  },
  getSlides: {
    type: 'slide',
    title: 'GET SLIDES MADE OVERNIGHT',
    file: null,
    instruction: '',
  },
  getMeeting: {
    type: 'meeting',
    title: 'GET MEETING RECORDING SYNTHESIZED OVERNIGHT',
    file: null,
    instruction: '',
  },
  stories: {
    beginning: [
      {
        title: 'Title',
        checked: false,
        label: '',
      },
      {
        title: 'Agenda',
        checked: false,
        label: '',
      },
      {
        title: 'Context',
        checked: false,
        label: '',
      },
      {
        title: 'Background',
        checked: false,
        label: '',
      },
      {
        title: 'Objectives',
        checked: false,
        label: '',
      },
      {
        title: 'Executive Summary',
        checked: false,
        label: '',
      },
      {
        title: 'Strategy',
        checked: false,
        label: '',
      },
    ],
    middle: [
      {
        title: 'Competition',
        checked: false,
        label: '',
      },
      {
        title: 'Market Research',
        checked: false,
        label: '',
      },
      {
        title: 'Market Size',
        checked: false,
        label: '',
      },
      {
        title: 'Phases',
        checked: false,
        label: '',
      },
      {
        title: 'Scorecard',
        checked: false,
        label: '',
      },
      {
        title: 'Steps',
        checked: false,
        label: '',
      },
      {
        title: 'SWOT',
        checked: false,
        label: '',
      },
    ],
    end: [
      {
        title: 'Dependencies',
        checked: false,
        label: '',
      },
      {
        title: 'Timeline',
        checked: false,
        label: '',
      },
      {
        title: 'Recommendation',
        checked: false,
        label: '',
      },
      {
        title: 'RACI',
        checked: false,
        label: '',
      },
      {
        title: "What's next",
        checked: false,
        label: '',
      },
      {
        title: 'Questions',
        checked: false,
        label: '',
      },
      {
        title: 'Thank you',
        checked: false,
        label: '',
      },
    ],
  },
  learnData: [
    {
      id: 'novice',
      title: 'Noice',
      subTitle: '(Ashi)',
      modules: [
        {
          title: 'A More Beautiful Question',
          items: [
            {
              title: 'What happened to questions? (2 minutes)',
              subTitle:
                'Understand the behavioral reasons that have led to a decline in asking questions',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                title: 'What happened to Question',
                link: 'learningvideos/1A. What happened to Questions.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Why the core question matters? (4 minutes)',
              subTitle:
                'Learn what is a core question and its crucial importance',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                title: 'Why the Core Question Matters',
                link: 'learningvideos/1B. Why the Core Question Matters.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title:
                'Powerful questions with the H.I.T.S framework (3 minutes)',
              subTitle: 'Gain insight into the traits of a powerful question',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                title: 'Write high FIT Questions',
                link: 'learningvideos/1C. Write high FIT Questions.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Practice writing questions (5 – 10 minutes)',
              subTitle:
                'ZenSense™ AI gives you suggestions on how to improve your questions',
              buttonCaption: 'practice',
              finished: false,
              type: 'practice',
              question: '',
              icon: 'watch',
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle: 'Hear from Joseph Bradley (author “Questioneering”)',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle: 'Complete the quiz to earn a badge',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle: 'Hear from Joseph Bradley (author “Questioneering”)',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                title: 'Hear from Joseph Bradley',
                link: 'learningvideos/1C. Write high FIT Questions.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
          ],
        },
        {
          title: 'Introduction to Structuring',
          items: [
            {
              title: 'Everyone can structure (2 minutes)',
              subTitle:
                'Understand the examples of structuring in our daily lives',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                title: 'Everyone can structure well',
                link: 'learningvideos/2A. Everyone can structure well.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Benefits of structuring (3 minutes)',
              subTitle:
                'Learn the benefits of structuring of problem to enhance business communication',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                title: 'Benefits of Structuring',
                link: 'learningvideos/2B. Benefits of Structuring.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'The M.E.C.E principle (3 minutes)',
              subTitle:
                'Understand how the “Mutually Exclusive Comprehensively Exhaustive” principle works',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                title: 'MECE Principle',
                link: 'learningvideos/2C. MECE Principle.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Introduction to issue trees (4 minutes)',
              subTitle: 'Learn what are issue trees and how to create them',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                title: 'MECE Principle',
                link: 'learningvideos/2C. MECE Principle.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Practice building issue trees (5 minutes)',
              subTitle:
                'Build issue trees to structure different core questions',
              buttonCaption: 'practice',
              finished: false,
              type: 'practice',
              exerciseData: [
                {
                  nodeDataArray: [
                    {
                      key: 1,
                      name: 'How can New Co. grow revenue?',
                      target: 'How can New Co. grow revenue?',
                      color: 'white',
                    },
                    {
                      key: 2,
                      name: '',
                      target: '# of customers?',
                      parent: 1,
                      color: 'white',
                    },
                    {
                      key: 3,
                      name: '',
                      target: 'Average customer spend?',
                      parent: 1,
                      color: 'white',
                    },
                    {
                      key: 4,
                      name: '',
                      target: 'Total market volume?',
                      parent: 2,
                      color: 'white',
                    },
                    {
                      key: 5,
                      name: '',
                      target: 'IBM market share?',
                      parent: 2,
                      color: 'white',
                    },
                    {
                      key: 6,
                      name: '',
                      target: '# of products bought per customer?',
                      parent: 3,
                      color: 'white',
                    },
                    {
                      key: 7,
                      name: '',
                      target: '$ spend per product?',
                      parent: 3,
                      color: 'white',
                    },
                  ],
                  myPaletteArray: [
                    { key: '1', name: '# of customers?' },
                    { key: '2', name: 'Total market volume?' },
                    { key: '3', name: '# of products bought per customer?' },
                    { key: '4', name: 'IBM market share?' },
                    { key: '5', name: 'Average customer spend?' },
                    { key: '6', name: '$ spend per product?' },
                  ],
                },
                {
                  nodeDataArray: [
                    {
                      key: 1,
                      name: 'How can New Co. reduce operating costs?',
                      target: 'How can New Co. reduce operating costs?',
                      color: 'white',
                    },
                    {
                      key: 2,
                      name: '',
                      target: 'Marketing and sales costs?',
                      parent: 1,
                      color: 'white',
                    },
                    {
                      key: 3,
                      name: '',
                      target: 'R&D costs?',
                      parent: 1,
                      color: 'white',
                    },
                    {
                      key: 4,
                      name: '',
                      target: 'Admin costs?',
                      parent: 1,
                      color: 'white',
                    },
                    {
                      key: 5,
                      name: '',
                      target: 'Sales compensation?',
                      parent: 2,
                      color: 'white',
                    },
                    {
                      key: 6,
                      name: '',
                      target: 'Advertising costs?',
                      parent: 2,
                      color: 'white',
                    },
                    {
                      key: 7,
                      name: '',
                      target: 'Researcher salaries?',
                      parent: 3,
                      color: 'white',
                    },
                    {
                      key: 8,
                      name: '',
                      target: 'Lab costs?',
                      parent: 3,
                      color: 'white',
                    },
                    {
                      key: 9,
                      name: '',
                      target: 'Rent?',
                      parent: 4,
                      color: 'white',
                    },
                    {
                      key: 10,
                      name: '',
                      target: 'Utilities?',
                      parent: 4,
                      color: 'white',
                    },
                  ],
                  myPaletteArray: [
                    { key: '1', name: 'Marketing and sales costs?' },
                    { key: '2', name: 'Admin costs?' },
                    { key: '3', name: 'Advertising costs?' },
                    { key: '4', name: 'Lab costs?' },
                    { key: '5', name: 'Utilities?' },
                    { key: '6', name: 'R&D costs?' },
                    { key: '7', name: 'Sales compensation?' },
                    { key: '8', name: 'Researcher salaries?' },
                    { key: '9', name: 'Rent?' },
                  ],
                },
                {
                  nodeDataArray: [
                    {
                      key: 1,
                      name: 'How can New Co. improve customer experience?',
                      target: 'How can New Co. improve customer experience?',
                      color: 'white',
                    },
                    {
                      key: 2,
                      name: '',
                      target: 'In-store experience?',
                      parent: 1,
                      color: 'white',
                    },
                    {
                      key: 3,
                      name: '',
                      target: 'Driving experience?',
                      parent: 1,
                      color: 'white',
                    },
                    {
                      key: 4,
                      name: '',
                      target: 'Service experience?',
                      parent: 1,
                      color: 'white',
                    },
                    {
                      key: 5,
                      name: '',
                      target: 'Sales experience?',
                      parent: 2,
                      color: 'white',
                    },
                    {
                      key: 6,
                      name: '',
                      target: 'Purchase process?',
                      parent: 2,
                      color: 'white',
                    },
                    {
                      key: 7,
                      name: '',
                      target: 'Interior features?',
                      parent: 3,
                      color: 'white',
                    },
                    {
                      key: 8,
                      name: '',
                      target: 'Exterior features?',
                      parent: 3,
                      color: 'white',
                    },
                    {
                      key: 9,
                      name: '',
                      target: 'Phone support efficacy?',
                      parent: 4,
                      color: 'white',
                    },
                    {
                      key: 10,
                      name: '',
                      target: 'Dealer service speed?',
                      parent: 4,
                      color: 'white',
                    },
                  ],
                  myPaletteArray: [
                    { key: '1', name: 'In-store experience?' },
                    { key: '2', name: 'Service experience?' },
                    { key: '3', name: 'Purchase process?' },
                    { key: '4', name: 'Exterior features?' },
                    { key: '5', name: 'Dealer service speed?' },
                    { key: '6', name: 'Driving experience?' },
                    { key: '7', name: 'Sales experience?' },
                    { key: '8', name: 'Interior features?' },
                    { key: '9', name: 'Phone support efficacy?' },
                  ],
                },
              ],
              icon: 'watch',
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle: 'Hear from XYZ on',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle: 'Complete the quiz to earn a badge',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle: 'Hear from XYZ on',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                title: 'Hear from XYZ on',
                link: 'learningvideos/2C. MECE Principle.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
          ],
        },
        {
          title: 'Basic Problem-Solving Techniques',
          items: [
            {
              title: 'Remember the Dolphin (2 minutes)',
              subTitle:
                'Understand the importance of zooming out and zooming in',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                title: 'Remember the Dolphin',
                link: 'learningvideos/3A. Remember the Dolphin.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Work Planning (3 minutes)',
              subTitle: 'Learn the best-practices for focused work planning',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                title: 'Focused Work Planning',
                link: 'learningvideos/3B. Focused Work Planning.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: '80-20 Principle (4 minutes)',
              subTitle:
                'Understand the prioritization or “Pareto” principle helps maximize impact',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                title: '80-20 Principle',
                link: 'learningvideos/3C. 80-20 Principle.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle: 'Hear from XYZ on power of problem-solving',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle: 'Complete the quiz to earn a badge',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Understanding the Audience',
          items: [
            {
              title: 'Audience is the Hero (2 minutes)',
              subTitle: 'Understand the role of the audience and the speaker',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                title: 'Audience is the Hero',
                link: 'learningvideos/4A. Audience is the Hero.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Segment the Audience(3 minutes)',
              subTitle:
                'Learn why segmenting the audience leads to greater impact',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                title: 'Segment the Audience',
                link: 'learningvideos/4B. Segment the Audience.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Five tips to present to executives (4 minutes)',
              subTitle:
                'Learn battle-tested tips to ace your executive presentations',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                title: 'Five Tips to Present to Executive',
                link: 'learningvideos/4C. Five Tips to Present to Executives.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle: 'Hear from XYZ on power of understanding the audience',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle: 'Complete the quiz to earn a badge',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Communicating Ideas in Slides',
          items: [
            {
              title: 'Introduction to diagrams (4 minutes)',
              subTitle:
                'Learn about the 28 types of diagrams to communicate ideas',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                title: 'Introduction to Slide Layouts',
                link: 'learningvideos/5A. Introduction to Slide Layouts.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'You can dodge bullets (3 minutes)',
              subTitle: 'Understand the pros and cons of using bullet points',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                title: 'You can dodge bullet',
                link: 'learningvideos/5B. You can dodge bullets.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'The art of slide titles (3 minutes)',
              subTitle: 'Learn the best practices to write slide titles',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                title: 'The art of slide titles',
                link: 'learningvideos/5A. Introduction to Slide Layouts.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Top mistakes in using diagrams (x minutes)',
              subTitle:
                'Understand the top 5 common mistakes in using diagrams',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                title: 'Top mistakes in using diagrams',
                link: 'learningvideos/5A. Introduction to Slide Layouts.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Practice the art of slide titles  (5 minutes)',
              subTitle: 'Give the proper title to a slide',
              buttonCaption: 'practice',
              finished: false,
              type: 'practice',
              exerciseData: [
                {
                  nodeDataArray: [
                    {
                      key: 1,
                      name: '',
                      target: 'Market Value Trends',
                      color: 'white',
                    },
                    {
                      key: 2,
                      name: '',
                      target: 'Tesla exceeds market value of others',
                      color: 'white',
                    },
                    {
                      key: 3,
                      name: '',
                      target: 'Are we in a Tesla hyper-bubble?',
                      color: 'white',
                    },
                  ],
                  myPaletteArray: [
                    {
                      key: 1,
                      name: 'Market Value Trends',
                    },
                    {
                      key: 2,
                      name: 'Tesla exceeds market value of others',
                    },
                    {
                      key: 3,
                      name: 'Are we in a Tesla hyper-bubble?',
                    },
                  ],
                },
                {
                  nodeDataArray: [
                    {
                      key: 1,
                      name: '',
                      target: 'We believe Tesla is a fast-growing company',
                      color: 'white',
                    },
                    {
                      key: 2,
                      name: '',
                      target: 'Market Value Trends',
                      color: 'white',
                    },
                  ],
                  myPaletteArray: [
                    {
                      key: 1,
                      name: 'We believe Tesla is a fast-growing company',
                    },
                    {
                      key: 2,
                      name: 'Market Value Trends',
                    },
                  ],
                },
              ],
              icon: 'watch',
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle:
                'Hear from Deepti Juturu on power of using the right diagrams',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle: 'Complete the quiz to earn a badge',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Presenting Ideas with Confidence',
          items: [
            {
              title: 'Sprezzatura (2 minutes)',
              subTitle:
                'Discover the secret to George Clooney’s effortless communication',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Overcoming fear of speaking (3 minutes)',
              subTitle:
                'Learn the techniques used by professional to overcome fear of speaking',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                title: 'Secret to George Clooney_s Effortless Style',
                link: 'learningvideos/6A. Secret to George Clooney_s Effortless Style.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Channel the audience (3 minutes)',
              subTitle:
                'Discover the techniques to connect with and channel your audience',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                title: 'OVercome the fear of public speaking',
                link: 'learningvideos/6B. OVercome the fear of public speaking.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Body language matters! (x minutes)',
              subTitle:
                'Understand the top 5 common mistakes in using diagrams',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                title: 'Body Language Matters',
                link: 'learningvideos/6C. Body Language Matters.mp4',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle:
                'Hear from Deepti Juturu on power of using the right diagrams',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle:
                'Hear from XXX on tips to present ideas with confidence',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'beinner',
      title: 'Beginner',
      subTitle: '(Hassu)',
      modules: [
        {
          title: 'Secrets of the Question Space',
          items: [
            {
              title: 'What is the Question Space (4 minutes)',
              subTitle:
                'Discover that no question lives in a vacuum. It lives in a question space',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Why the Question Space matters (3 minutes)',
              subTitle:
                'Learn how the “London Horse Manure” crisis shows the importance of the question space',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Influencing Stakeholders (3 minutes)',
              subTitle:
                'Learn the top three techniques to understand and influence stakeholders',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle: 'Complete the quiz to earn a badge',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Issue Trees',
          items: [
            {
              title: 'Introduction to Issue Trees (5 minutes)',
              subTitle: 'Learn what are issue trees and how to create them',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Pruning Issue Trees (3 minutes)',
              subTitle:
                'Discover how you can resolve problems faster by pruning issue trees',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Practice building Issue Trees (5 minutes)',
              subTitle:
                'Build issue trees to structure different core questions',
              buttonCaption: 'practice',
              finished: false,
              type: 'practice',
              exerciseData: [
                {
                  nodeDataArray: [
                    {
                      key: 1,
                      name: 'How can New Co. grow revenue?',
                      target: 'How can New Co. grow revenue?',
                      color: 'white',
                    },
                    {
                      key: 2,
                      name: '',
                      target: '# of customers?',
                      parent: 1,
                      color: 'white',
                    },
                    {
                      key: 3,
                      name: '',
                      target: 'Total market volume?',
                      parent: 1,
                      color: 'white',
                    },
                    {
                      key: 4,
                      name: '',
                      target: '# of products bought per customer?',
                      parent: 2,
                      color: 'white',
                    },
                    {
                      key: 5,
                      name: '',
                      target: 'IBM market share?',
                      parent: 2,
                      color: 'white',
                    },
                    {
                      key: 6,
                      name: '',
                      target: 'Average customer spend?',
                      parent: 3,
                      color: 'white',
                    },
                    {
                      key: 7,
                      name: '',
                      target: '$ spend per product?',
                      parent: 3,
                      color: 'white',
                    },
                  ],
                  myPaletteArray: [
                    { key: '1', name: '# of customers?' },
                    { key: '2', name: 'Total market volume?' },
                    { key: '3', name: '# of products bought per customer?' },
                    { key: '4', name: 'IBM market share?' },
                    { key: '5', name: 'Average customer spend?' },
                    { key: '6', name: '$ spend per product?' },
                  ],
                },
                {
                  nodeDataArray: [
                    {
                      key: 1,
                      name: 'How can New Co. reduce operating costs?',
                      target: 'How can New Co. reduce operating costs?',
                      color: 'white',
                    },
                    {
                      key: 2,
                      name: '',
                      target: 'Marketing and sales costs?',
                      parent: 1,
                      color: 'white',
                    },
                    {
                      key: 3,
                      name: '',
                      target: 'Admin costs?',
                      parent: 1,
                      color: 'white',
                    },
                    {
                      key: 4,
                      name: '',
                      target: 'Advertising costs?',
                      parent: 1,
                      color: 'white',
                    },
                    {
                      key: 5,
                      name: '',
                      target: 'Lab costs?',
                      parent: 2,
                      color: 'white',
                    },
                    {
                      key: 6,
                      name: '',
                      target: 'Utilities?',
                      parent: 2,
                      color: 'white',
                    },
                    {
                      key: 7,
                      name: '',
                      target: 'R&D costs?',
                      parent: 3,
                      color: 'white',
                    },
                    {
                      key: 8,
                      name: '',
                      target: 'Sales compensation?',
                      parent: 3,
                      color: 'white',
                    },
                    {
                      key: 9,
                      name: '',
                      target: 'Researcher salaries?',
                      parent: 4,
                      color: 'white',
                    },
                    {
                      key: 10,
                      name: '',
                      target: 'Rent?',
                      parent: 4,
                      color: 'white',
                    },
                  ],
                  myPaletteArray: [
                    { key: '1', name: 'Marketing and sales costs?' },
                    { key: '2', name: 'Admin costs?' },
                    { key: '3', name: 'Advertising costs?' },
                    { key: '4', name: 'Lab costs?' },
                    { key: '5', name: 'Utilities?' },
                    { key: '6', name: 'R&D costs?' },
                    { key: '7', name: 'Sales compensation?' },
                    { key: '8', name: 'Researcher salaries?' },
                    { key: '9', name: 'Rent?' },
                  ],
                },
                {
                  nodeDataArray: [
                    {
                      key: 1,
                      name: 'How can New Co. improve customer experience?',
                      target: 'How can New Co. improve customer experience?',
                      color: 'white',
                    },
                    {
                      key: 2,
                      name: '',
                      target: 'In-store experience?',
                      parent: 1,
                      color: 'white',
                    },
                    {
                      key: 3,
                      name: '',
                      target: 'Service experience?',
                      parent: 1,
                      color: 'white',
                    },
                    {
                      key: 4,
                      name: '',
                      target: 'Purchase process?',
                      parent: 1,
                      color: 'white',
                    },
                    {
                      key: 5,
                      name: '',
                      target: 'Exterior features?',
                      parent: 2,
                      color: 'white',
                    },
                    {
                      key: 6,
                      name: '',
                      target: 'Dealer service speed?',
                      parent: 2,
                      color: 'white',
                    },
                    {
                      key: 7,
                      name: '',
                      target: 'Driving experience?',
                      parent: 3,
                      color: 'white',
                    },
                    {
                      key: 8,
                      name: '',
                      target: 'Sales experience?',
                      parent: 3,
                      color: 'white',
                    },
                    {
                      key: 9,
                      name: '',
                      target: 'Interior features?',
                      parent: 4,
                      color: 'white',
                    },
                    {
                      key: 10,
                      name: '',
                      target: 'Phone support efficacy?',
                      parent: 4,
                      color: 'white',
                    },
                  ],
                  myPaletteArray: [
                    { key: '1', name: 'In-store experience?' },
                    { key: '2', name: 'Service experience?' },
                    { key: '3', name: 'Purchase process?' },
                    { key: '4', name: 'Exterior features?' },
                    { key: '5', name: 'Dealer service speed?' },
                    { key: '6', name: 'Driving experience?' },
                    { key: '7', name: 'Sales experience?' },
                    { key: '8', name: 'Interior features?' },
                    { key: '9', name: 'Phone support efficacy?' },
                  ],
                },
              ],
              icon: 'watch',
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle: 'Hear from Joseph Bradley (author “Questioneering”)',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle: 'Complete the quiz to earn a badge',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Intermediate Problem-Solving Techniques',
          items: [
            {
              title: 'The Magic of Triangulation (3 minutes)',
              subTitle:
                'Discover the technique that can save time and reduce error-rate',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Introduction to biases (3 minutes)',
              subTitle: 'Discover how our mind tricks us when we problem-solve',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'More about biases (6 minutes)',
              subTitle: 'Learn more about biases and how to de-bias ourselves',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle: 'Hear from Joseph Bradley (author “Questioneering”)',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle: 'Complete the quiz to earn a badge',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Structuring Storylines',
          items: [
            {
              title: 'Governing Thoughts (4 minutes)',
              subTitle:
                'Discover the technique that can organize your presentation',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Logical Groupings and Logical Arguments (5 minutes)',
              subTitle: 'Discover different ways of structuring storylines',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'The art of slide titles (4 minutes)',
              subTitle: 'Learn the best practices to write slide titles',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Practice the art of slide titles (5 minutes)',
              subTitle: 'Give the proper title to a slide',
              buttonCaption: 'practice',
              finished: false,
              type: 'practice',
              exerciseData: [
                {
                  nodeDataArray: [
                    {
                      key: 1,
                      name: '',
                      target: 'Market Value Trends',
                      color: 'white',
                    },
                    {
                      key: 2,
                      name: '',
                      target: 'Tesla exceeds market value of others',
                      color: 'white',
                    },
                    {
                      key: 3,
                      name: '',
                      target: 'Are we in a Tesla hyper-bubble?',
                      color: 'white',
                    },
                  ],
                  myPaletteArray: [
                    {
                      key: 1,
                      name: 'Market Value Trends',
                    },
                    {
                      key: 2,
                      name: 'Tesla exceeds market value of others',
                    },
                    {
                      key: 3,
                      name: 'Are we in a Tesla hyper-bubble?',
                    },
                  ],
                },
                {
                  nodeDataArray: [
                    {
                      key: 1,
                      name: '',
                      target: 'We believe Tesla is a fast-growing company',
                      color: 'white',
                    },
                    {
                      key: 2,
                      name: '',
                      target: 'Market Value Trends',
                      color: 'white',
                    },
                  ],
                  myPaletteArray: [
                    {
                      key: 1,
                      name: 'We believe Tesla is a fast-growing company',
                    },
                    {
                      key: 2,
                      name: 'Market Value Trends',
                    },
                  ],
                },
              ],
              icon: 'watch',
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle: 'Hear from Joseph Bradley (author “Questioneering”)',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle: 'Complete the quiz to earn a badge',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Communicating Data in Slides',
          items: [
            {
              title: 'Communicating data (8 minutes)',
              subTitle:
                'Discover the layouts that can be used to communicate data',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Five tips to display data (4 minutes)',
              subTitle:
                'Learn five tips that will help you display data correctly and with impact',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle: 'Hear from Joseph Bradley (author “Questioneering”)',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle: 'Complete the quiz to earn a badge',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Acing Virtual Presentations',
          items: [
            {
              title: 'Five tips to ace virtual presentations (4 minutes)',
              subTitle:
                'Discover five common mistakes and suggestions to ace video presentations',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Four adjustments for video presentations (5 minutes)',
              subTitle:
                'Learn four adjustments to beat virtual meeting fatigue',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle: 'Hear from Joseph Bradley (author “Questioneering”)',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle: 'Complete the quiz to earn a badge',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'intermediate',
      title: 'Intermediate',
      subTitle: '(Sensei)',
      modules: [
        {
          title: 'Advanced Questions',
          items: [
            {
              title: 'D.A.R.E to ask questions (4 minutes)',
              subTitle:
                'Discover four types of questions to add sparkle to your communication',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Three arrows of communication (3 minutes)',
              subTitle:
                'Learn when to use statements, synthesis and questions in communication',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle: 'Hear from Joseph Bradley (author “Questioneering”)',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle: 'Complete the quiz to earn a badge',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Hypotheses Trees',
          items: [
            {
              title: 'Getting started with Hypotheses Trees (4 minutes)',
              subTitle:
                'Learn how issue trees can help you structure any business problem',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Pitfalls in using Hypotheses Trees (3 minutes)',
              subTitle:
                'Discover top mistakes made in using hypotheses trees and how to resolve them',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Issue Trees vs. Hypotheses Trees (4 minutes)',
              subTitle:
                'Understand the pros and cons of Issue and Hypotheses trees',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle: 'Hear from Joseph Bradley (author “Questioneering”)',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle: 'Complete the quiz to earn a badge',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Advanced Problem-Solving Techniques',
          items: [
            {
              title: 'Heuristics for problem solving (4 minutes)',
              subTitle: 'Learn some heuristics that advance problem solving',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Questions-based problem-solving (3 minutes)',
              subTitle:
                'Discover how to use questions to accelerate problem solving',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Pre-Mortems (x minutes)',
              subTitle:
                'Understand the pre-mortem technique to improve problem solving',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Four traits of a 10x problem solver (x minutes)',
              subTitle:
                'Discover the four traits that distinguish a 10x problem solver',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle: 'Hear from Joseph Bradley (author “Questioneering”)',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle: 'Complete the quiz to earn a badge',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Anatomy of a Story',
          items: [
            {
              title: 'Situation-complication-resolution variants (4 minutes)',
              subTitle:
                'Learn how variants of Situation-Complication-Resolution can be used for your story',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Heroes journey (3 minutes)',
              subTitle:
                'Discover how heroes journey can be used for inspirational storylines',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Executive Summary (x minutes)',
              subTitle: 'Learn how to write powerful executive summaries',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Summary vs. Essence (x minutes)',
              subTitle:
                'Understand how to use summary and essence in your presentations',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle: 'Hear from Joseph Bradley (author “Questioneering”)',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle: 'Complete the quiz to earn a badge',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Advanced Presentation Design',
          items: [
            {
              title: 'Five Elements of design (4 minutes)',
              subTitle: 'Learn the five elements of great design',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Formatting tips and tricks (3 minutes)',
              subTitle:
                'Discover formatting best-practices to save you time and effort',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Working with images (x minutes)',
              subTitle: 'Understand how to use images in presentations',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Working with animation (x minutes)',
              subTitle:
                'Learn how to use animation to make your presentations pop',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle: 'Hear from Joseph Bradley (author “Questioneering”)',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle: 'Complete the quiz to earn a badge',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Advanced Persuasion Techniques',
          items: [
            {
              title: 'Yes, and … (4 minutes)',
              subTitle: 'Learn persuasion techniques from improvisation',
              buttonCaption: 'watch',
              finished: false,
              type: 'movie',
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Read the room (3 minutes)',
              subTitle: 'Discover techniques to read the room and pick-up cues',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Make an unexpected move (x minutes)',
              subTitle:
                'Learn from Bobby Fischer about the power of unexpected moves',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title: 'Pre-suade before you persuade (x minutes)',
              subTitle:
                'Discover techniques to persuade your audience before you start presenting',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'watch',
            },
            {
              title:
                '<div class="premium-title"><div class="level-item-highlight">Premium Content.</div><div>Expert Corner: Hear perspectives from industry experts</div>',
              subTitle: 'Hear from Joseph Bradley (author “Questioneering”)',
              buttonCaption: 'watch',
              type: 'movie',
              finished: false,
              movieInfo: {
                link: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
                thumbUp: 90,
                thumbDown: 1,
                views: 112,
              },
              icon: 'premium',
            },
            {
              title: 'Quiz (5 minutes)',
              subTitle: 'Complete the quiz to earn a badge',
              buttonCaption: 'take quiz',
              finished: false,
              type: 'quiz',
              icon: 'quiz',
              items: [
                {
                  title:
                    '1. How were John Antioco and James Keyes fundamentally different in their approach to grow Blockbuster?',
                  default: 0,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Different teams',
                    'B. Different core questions',
                    'C. Different suppliers',
                    'D. Different partners',
                  ],
                },
                {
                  title:
                    '2. When is the right time to ask questions in a project?',
                  default: 1,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Early and often',
                    'B. Never',
                    'C. Middle stages only',
                    'D. Final stages only',
                  ],
                },
                {
                  title:
                    '3. What are the characteristics of good core questions?',
                  default: 2,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. Detailed with all facts',
                    'B. Has multiple goals',
                    'C. Includes who it is addressed to',
                    'D. Clear goal, metric with attainable target, time-bound and succinct',
                  ],
                },
                {
                  title:
                    '4. A fictional company New Co. is targeting profitable growth. Which of the following is the best core question for New Co.?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. How do we achieve revenue growth?',
                    'B. How do we grow profitably in next 3 years?',
                    'C. How do we grow revenue by 10% while achieving 50% profits by 2023?',
                    'D. How do we become profitable by 2023?',
                  ],
                },
                {
                  title:
                    '5. Why does the percentage of questions drop from 70%-90% as a child to 15% - 25% as an adult?',
                  default: 3,
                  answer: null,
                  isCorrect: -1,
                  options: [
                    'A. “Problem solvers” are perceived as more valuable employees',
                    'B. We are trained to value answers in schools and tests',
                    'C. Questions feel like a waste of time',
                    'D. All options provided',
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  slideDetails: [
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 5,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/1.png',
    },
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 4,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/2.png',
    },
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 5,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/3.png',
    },
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 3,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/4.png',
    },
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 6,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/6.png',
    },
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 6,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/5.png',
    },
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 6,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/7.png',
    },
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 6,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/8.png',
    },
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 5,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/9.png',
    },
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 4,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/10.png',
    },
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 4,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/11.png',
    },
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 4,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/12.png',
    },
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 3,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/13.png',
    },
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 3,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/14.png',
    },
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 3,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/15.png',
    },
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 5,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/16.png',
    },
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 3,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/17.png',
    },
    {
      asset: {
        keytype: 'K_SLIDEDATA',
        choice: {
          visual: 4.25,
          data: 1,
          story: 0,
        },
        prefs: {
          has_graphs: 'no',
          shapes: 'simple',
          has_shading: 'no',
          has_image_bus: 'yes',
          has_cartoon: 'no',
          ink_ratio: 'high',
          image_size: 'medium',
          has_icons: 'yes',
          has_tables: 'no',
          has_three_d: 'no',
          color: 'medium',
        },
        id: 3623,
        unique_id: 'pz_agenda_040',
        categories: ['agenda'],
        constructs: ['category'],
        filename: 'Agenda_040.pptx',
        rno: 3,
        node_count: 4,
        rwt: 80,
        score: 9999948,
        name: 'Agenda_040',
        file: 'Agenda_040.pptx',
        pptFile: 'Agenda_040.pptx',
      },
      isTrending: false,
      isFavorite: false,
      rating: 4.2,
      thumbsUp: 0,
      thumbsDown: 0,
      thumbnail: '/assets/img/slideDetails/18.png',
    },
  ],
};
