<template>
  <v-app :class="{ circle: currentIndex <= 1 }">
    <!-- <template v-if="currentIndex <= 1">
      <div class="circle-top">
        <div class="circle"></div>
        <div class="dot"></div>
      </div>
      <div class="circle-bottom">
        <div class="circle"></div>
        <div class="dot"></div>
      </div>
    </template> -->
    <v-main>
      <div class="home-wrapper">
        <Transition name="fadeScreen" mode="out-in" :duration="900">
          <div class="main-wrapper" :key="questionIndex">
            <div class="logo">
              <img
                width="200px"
                height="44px"
                src="/assets/img/prezent_logo.svg"
                alt
              />
            </div>
            <div class="section-wrapper first-screen" v-if="currentIndex === 0">
              <div class="fingerprint-title">
                {{ $t('fingerprint.hi') }} {{ currentAudienceName }},
              </div>
              <div class="fingerprint-sub-title">
                <div class="fingerprint-sub-title-content">
                  {{ $t('fingerprint.welcomeText') }}
                </div>
              </div>
              <div class="sub-header">
                {{ $t('fingerprint.subHeader') }}
              </div>
              <img
                class="section-image"
                src="/assets/img/fingerprint-test-initial.svg"
                width="470px"
                height="340px"
                alt
              />
              <v-btn
                color="primary"
                rounded
                width="110"
                height="40"
                class="lets-go-button"
                @click="handleCurrentIndex"
              >
                {{ $t('fingerprint.letsGo') }}
              </v-btn>
            </div>
            <div
              class="section-wrapper second-screen"
              v-if="currentIndex === 1"
            >
              <div class="pa-4">
                <div class="header">
                  {{ currentAudienceName }}, {{ $t('fingerprint.openingText') }}
                </div>
                <div class="description">
                  {{ $t('fingerprint.tailoringPresentation') }}
                </div>
                <div class="items">
                  <div class="item">
                    <div class="title">
                      {{ $t('fingerprint.visualPreference') }}
                    </div>
                    <img src="/assets/img/welcome-item-vibrant.svg" alt />
                  </div>
                  <div class="item">
                    <div class="title">{{ $t('fingerprint.storyChoice') }}</div>
                    <img src="/assets/img/welcome-item-deductive.svg" alt />
                  </div>
                  <div class="item">
                    <div class="title">{{ $t('fingerprint.dataStyle') }}</div>
                    <img src="/assets/img/welcome-item-narrative.svg" alt />
                  </div>
                </div>
                <v-btn
                  class="primary-button"
                  rounded
                  color="#21a7e0"
                  height="48"
                  min-width="134"
                  @click="handleCurrentIndex"
                  :disabled="initialLoading"
                >
                  {{ $t('fingerprint.myFingerprint') }}
                </v-btn>
                <div class="action-detail">
                  {{ $t('fingerprint.estimatedTime') }}
                </div>
                <div class="final-description">
                  {{ $t('fingerprint.changeAnswerLater') }}
                </div>
              </div>
            </div>
            <div v-if="firstSectionCompleted">
              <div class="loading-header">
                {{ currentAudienceName }},
                {{ $t('fingerprint.yourPreferencesAreFascinating') }}
              </div>
              <img
                src="/assets/img/fingerPrintTest/fascinating_preferences.svg"
                alt
              />
            </div>
            <div v-if="personalPrefCompleted">
              <div class="loading-header">
                {{ $t('fingerprint.thanksForSharingYourPreferences') }}
                {{ currentAudienceName }}!<br />{{
                  $t('fingerprint.yourColleaguesWillBePleased')
                }}
              </div>
              <div class="loading-sub-header">
                {{ $t('fingerprint.personalizeYourPrezentExperience') }}
              </div>
              <img
                src="/assets/img/fingerPrintTest/pleased_colleagues.svg"
                alt
              />
            </div>
            <div v-if="basicDetailCompleted">
              <div class="loading-header">
                {{ $t('fingerprint.invitingColleagues') }} <br />
                {{ $t('fingerprint.invitingColleagues2') }}
              </div>
              <img
                src="/assets/img/fingerPrintTest/fascinating_preferences.svg"
                alt
              />
            </div>
            <div v-if="inviteColleagureCompleted">
              <div class="loading-header">
                <template>
                  {{ $t('fingerprint.sendingInvitation') }}
                </template>
              </div>
              <img
                src="/assets/img/fingerPrintTest/invitation_on_its_way.svg"
                alt
              />
            </div>
            <div v-if="progressScreenIndex !== -1">
              <template v-for="(progress, index) of progressScreens">
                <template>
                  <div v-show="index === progressScreenIndex" :key="index">
                    <div class="loading-header">
                      {{ $t('fingerprint.generatingYourFingerprint') }}
                    </div>
                    <img :src="progress.banner" />
                  </div>
                </template>
              </template>
            </div>
            <div
              class="question-wrapper"
              v-if="
                currentIndex === 2 &&
                !firstSectionCompleted &&
                !personalPrefCompleted &&
                !basicDetailCompleted &&
                !inviteColleagureCompleted &&
                progressScreenIndex === -1
              "
            >
              <div class="buttonAndHeading">
                <div
                  class="back-button"
                  @click="handlePreview"
                  v-html="$t(back)"
                />
                <div class="question-header">{{ sectionName }}</div>
              </div>
              <div class="progress-wrapper">
                <v-progress-linear
                  :value="questionProgress"
                  :height="8"
                  rounded
                />
              </div>
              <!-- <div class="question-index">
                Question {{ questionIndex + 1 }} / 6
              </div>-->
              <template v-if="!encourageShareFingerprint">
                <div class="question-content">
                  <div class="question-content-header">
                    <div
                      class="header"
                      v-html="$t(questions[questionIndex].header)"
                    />
                    <div
                      class="sub-header"
                      :class="
                        questionIndex > 6 && questionIndex < 13
                          ? 'underline'
                          : ''
                      "
                    >
                      <p
                        v-html="$t(questions[questionIndex].subHeader)"
                        @click="showContent = !showContent"
                        v-click-outside="closeContent"
                      ></p>
                    </div>
                    <div
                      v-if="
                        showContent && questionIndex > 6 && questionIndex < 13
                      "
                      class="why-we-ask-content"
                    >
                      <div>
                        {{ $t('fingerprint.easierForColleagues') }} <br />
                        {{ $t('fingerprint.findYouOnPrezent') }}<br />
                        {{ $t('fingerprint.personalizeYourExperience') }}
                      </div>
                    </div>
                  </div>
                  <div class="images-wrapper" v-if="questionIndex < 6">
                    <img
                      :class="`question-image ${
                        answers[`question${questionIndex}`] === 1
                          ? 'border'
                          : answers[`question${questionIndex}`] === 2
                          ? 'blur'
                          : ''
                      }`"
                      :src="questions[questionIndex].leftImage"
                      alt
                      @click="handleQuestion(`question${questionIndex}`, 1)"
                      width="459px"
                      height="260px"
                    />
                    <img
                      :class="`question-image ${
                        answers[`question${questionIndex}`] === 2
                          ? 'border'
                          : answers[`question${questionIndex}`] === 1
                          ? 'blur'
                          : ''
                      }`"
                      :src="questions[questionIndex].rightImage"
                      alt
                      @click="handleQuestion(`question${questionIndex}`, 2)"
                      width="459px"
                      height="260px"
                    />
                  </div>
                  <div class="selections-wrapper">
                    <div v-if="questionIndex === 6" class="header">
                      {{ $t('fingerprint.selectFromCommonPreferences') }}
                    </div>
                    <div v-if="questionIndex === 6" class="selections">
                      <div
                        :class="`selection ${getClassName(selection)}`"
                        v-for="(selection, index) in selections"
                        :key="index"
                        @click="
                          handleQuestion(`question${questionIndex}`, selection)
                        "
                      >
                        <span>
                          <v-img
                            class="mx-4"
                            :src="
                              selection.image
                                ? selection.image
                                : 'assets/img/preferences-added_by_user.svg'
                            "
                            height="30px"
                            width="30px"
                          />
                        </span>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <span class="selection-title" v-on="on">
                              {{ selection.title }}
                            </span>
                          </template>
                          <span>{{ selection.title }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div
                      v-if="
                        questionIndex > 6 &&
                        questionIndex < 11 &&
                        questionIndex != 9
                      "
                      class="cards-wrapper"
                      :class="questionIndex == 8 ? 'industry' : ''"
                    >
                      <v-hover
                        v-for="item in category"
                        :key="item.name"
                        open-delay="50"
                      >
                        <div
                          class="cards"
                          :class="isSelectedCard(item) ? 'selected-card' : ''"
                          @click="selectItem(item)"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <div class="item-name" v-on="on">
                                {{ item.name }}
                              </div>
                            </template>
                            <span>{{ item.name }}</span>
                          </v-tooltip>
                          <img
                            :src="
                              item.imagePath
                                ? item.imagePath
                                : '/assets/img/fingerPrintTest/custom.svg'
                            "
                            width="96px"
                            height="92px"
                          />
                        </div>
                      </v-hover>
                    </div>
                    <div v-if="questionIndex === 9" class="selections">
                      <div
                        v-for="item in category"
                        :key="item.name"
                        class="selection group-items"
                        :class="isSelectedCard(item) ? 'highlight' : ''"
                        @click="selectItem(item)"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <span class="selection-title" v-on="on">
                              {{ item.name }}
                            </span>
                          </template>
                          <span>{{ item.name }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div
                      v-if="questionIndex === 11"
                      class="job-function-wrapper"
                    >
                      <v-combobox
                        :return-object="false"
                        v-model="jobTitleSelected"
                        :items="jobTitles"
                        :menu-props="{
                          maxHeight: '200',
                          maxWidth: '400',
                          width: '400',
                        }"
                        color="black"
                        item-text="name"
                        item-value="name"
                        :placeholder="$t('fingerprint.jobTitle')"
                        clearable
                      />
                    </div>
                    <div
                      id="addCustomPref"
                      class="write-own-btn"
                      v-if="
                        !isCreating && questionIndex > 5 && questionIndex < 10
                      "
                    >
                      <div class="write-own-header">
                        <span class="pe-2">
                          <v-img
                            v-if="!inputFocused"
                            src="assets/img/preferences-Other.svg"
                            height="30px"
                            width="35px"
                          />
                          <v-img
                            v-else
                            src="assets/img/preferences-other-filled.svg"
                            height="30px"
                            width="35px"
                          />
                        </span>
                        <span>{{ $t('fingerprint.addYourOwn') }}</span>
                      </div>
                      <div class="new-pref-text">
                        <v-textarea
                          v-model="ownPreference"
                          :placeholder="
                            questionIndex === 6
                              ? $t('fingerprint.haveAClearAgenda')
                              : $t('fingerprint.typeYourOwn')
                          "
                          full-width
                          solo
                          two-line
                          @focus="setBackgroundColour"
                          @blur="removeBackgroundColour"
                        />
                      </div>
                      <div
                        v-if="ownPreference.length && isValidString"
                        class="add-pref"
                      >
                        <button @click="onEnter()">
                          <span>
                            <v-icon class="icon" v-text="`mdi-plus`" />
                          </span>
                          <span>{{ $t('fingerprint.add') }}</span>
                        </button>
                      </div>
                    </div>
                    <div class="create-own" v-if="isCreating">
                      <v-text-field
                        v-model="ownPreference"
                        :label="$t('fingerprint.enterYourOwn')"
                        single-line
                        color="#000"
                        @keyup.enter="onEnter"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <div class="encourage-share-fingerprint" v-else>
                <template v-if="questionIndex === 12">
                  <div class="encourage-title">
                    {{ $t('fingerprint.fingerprintsYouLikeToSee') }}
                  </div>
                  <div class="encourage-sub-title">
                    {{ $t('fingerprint.enterTheWorkEmail') }}
                  </div>
                  <div class="add-heart__title">
                    <v-icon medium :color="'blue'">{{ heartIcon }}</v-icon>
                    <div class="add-heart__title_subtext">
                      {{ $t('fingerprint.weDonateForEachFingerprint') }}
                      <span class="learn_more" @click="openShareMenu">{{
                        $t('fingerprint.learnMore')
                      }}</span>
                    </div>
                  </div>
                  <div
                    class="encourage-sub-title learn_more"
                    @click="showContent = !showContent"
                    v-click-outside="closeContent"
                  >
                    {{ $t('fingerprint.whyWeAsk') }}
                  </div>
                  <div v-if="showContent" class="why-we-ask-content">
                    <div>
                      {{ $t('fingerprint.whyWeAskContent1') }} <br /><br />
                      {{ $t('fingerprint.whyWeAskContent2') }}
                    </div>
                  </div>
                  <v-form
                    ref="form"
                    v-model="isNotValid"
                    lazy-validation
                    @submit.prevent="submit"
                    class="invite-form"
                  >
                    <v-container class="email-list-container">
                      <v-row
                        v-for="(colleague, index) in colleaguesList"
                        :key="index"
                      >
                        <v-col class="name-col col-3">
                          <v-text-field
                            :rules="firstnameRequired"
                            class="custom-text-field"
                            :label="$t('fingerprint.colleagueFirstName')"
                            :placeholder="$t('fingerprint.colleagueFirstName')"
                            required
                            v-model="colleaguesList[index].firstname"
                            :id="'name-input-' + index"
                          ></v-text-field>
                        </v-col>
                        <v-col class="name-col col-3">
                          <v-text-field
                            :rules="lastnameRequired"
                            :label="$t('fingerprint.colleagueLastName')"
                            :placeholder="$t('fingerprint.colleagueLastName')"
                            required
                            v-model="colleaguesList[index].lastname"
                            :id="'lastname-input-' + index"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          class="email-col col-4"
                          :id="'email-col-' + index"
                        >
                          <v-text-field
                            :rules="emailRules"
                            :label="$t('fingerprint.colleagueWorkEmail')"
                            :placeholder="$t('fingerprint.colleagueWorkEmail')"
                            @change="(value) => handleEmailChange(value, index)"
                            @keydown.enter="addColleague('enter')"
                            v-model="colleaguesList[index].email"
                            :id="'email-input-' + index"
                            class="label"
                            clearable
                            required
                            :maxlength="80"
                          ></v-text-field>
                        </v-col>
                        <v-col class="close-col col-1">
                          <v-btn
                            v-if="colleaguesList.length > 3"
                            class=""
                            icon
                            @click="removeColleague(index)"
                          >
                            <!-- <v-icon dark> mdi-close </v-icon> -->
                            <img src="/assets/img/cancel_btn.svg" />
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>

                    <div class="add-more">
                      <v-btn
                        text
                        color="primary"
                        class="add-btn"
                        @click="addColleague"
                        :disabled="colleaguesList.length >= 10"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                        {{ $t('fingerprint.addMore') }}
                      </v-btn>
                    </div>
                  </v-form>
                </template>
                <template
                  v-if="
                    questionIndex === 13 && inviteColleagureCompleted === false
                  "
                >
                  <div class="encourage-title">
                    {{ $t('fingerprint.WhoWouldYouLikeToHaveAccess') }}
                  </div>
                  <div class="encourage-sub-title">
                    {{ $t('fingerprint.buildPresentationYouLove') }}
                  </div>
                  <share-finger-print
                    type="form"
                    origin="fingerPrintTest"
                    :isAudience="true"
                    :updateText="$t('fingerprint.viewMyFingerprint')"
                    :loading="shareFingerprintLoading"
                    @updated="handleFingerprintUpdate"
                    @subModalOpen="openEmailModal"
                  />
                </template>
              </div>
              <div v-if="questionIndex > 5" class="question-buttons">
                <v-btn
                  color="primary"
                  rounded
                  class="next-button"
                  @click="handleCurrentQuestionIndex"
                  v-if="!encourageShareFingerprint || questionIndex === 12"
                  :disabled="isNextButtonDisabled"
                >
                  {{ $t('fingerprint.next') }}
                </v-btn>
                <div
                  v-if="
                    questionIndex === 12 &&
                    isNotValid &&
                    colleaguesList.filter((colleague) => {
                      if (
                        colleague.email &&
                        colleague.email !== '' &&
                        colleague.firstname &&
                        colleague.firstname !== '' &&
                        colleague.lastname &&
                        colleague.lastname !== ''
                      ) {
                        return {
                          ...colleague,
                        };
                      }
                    }).length > 0
                  "
                  @click="openEmailPreviewModal"
                  class="preview-email"
                >
                  <span class="preview-email-text"> Preview email</span>
                  <!-- {{ 'Preview email' }} -->
                  <v-tooltip
                    bottom
                    max-width="250"
                    content-class="tooltip-content"
                    fixed
                    open-delay="500"
                    close-delay="300"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="icon"
                        v-text="`mdi-information-outline`"
                      />
                    </template>
                    <span>{{
                      $t(
                        'fingerprint.previewTheEmailThatIsSentToYourConnections',
                      )
                    }}</span>
                  </v-tooltip>
                </div>
                <div
                  v-if="questionIndex > 6 && questionIndex < 13"
                  class="skip-button"
                  @click="handleSkipClick"
                  v-html="$t(skip)"
                />
              </div>
            </div>
            <div
              class="result-wrapper"
              v-if="currentIndex === 3 && progressScreenIndex === -1"
            >
              <FingerPrint
                :fingerprint="currentTestAudience.fingerPrint"
                :audience="currentTestAudience"
                :personalPrefs="personalPrefs"
                :isPrivateAudience="false"
                :shareable="true"
                :hideActions="true"
                :answers="answers"
                :isAudience="true"
                origin="add_new_aud"
              />
              <div class="footer-description">
                <template
                  v-if="
                    !$route.query.initialIndex ||
                    $route.query.initialIndex !== '3'
                  "
                >
                  {{ $t('fingerprint.thankYouText') }}<br />
                </template>
                {{ $t('fingerprint.interestedInHowPrezentCanElevate') }}
              </div>
              <div class="footer">
                <v-btn
                  class="register-trail-btn mb-3"
                  rounded
                  color="#20a7e0"
                  width="300"
                  height="50"
                  @click="handleRegisterTrail"
                  :loading="isLoading"
                >
                  {{ $t('fingerprint.startTrial') }}
                </v-btn>
                <div class="fingerprint-center-item" @click="handleTouch">
                  {{ $t('fingerprint.getInTouch') }}
                </div>
                <div class="fingerprint-center-item" @click="gotoRetakeTest">
                  {{ $t('fingerprint.retakeFingerprintTest') }}
                </div>
              </div>
            </div>
            <div class="get-in-touch-wrapper" v-if="currentIndex === 4">
              <div class="get-in-touch-title">
                {{ $t('fingerprint.thanksForYourInterest') }}
                <br />{{ $t('fingerprint.weWillGetInTouch') }}
              </div>
              <img src="/assets/img/get-in-touch.svg" alt />
            </div>
          </div>
        </Transition>
      </div>
    </v-main>
    <div class="privacy-policy-footer">
      <div class="privacy-policy">
        <a href="https://prezent.ai/privacy-policy" target="_blank">{{
          $t('fingerprint.privacyPolicy')
        }}</a>
      </div>
      <div>&#169; {{ $t('fingerprint.prezent') }}</div>
    </div>
  </v-app>
</template>

<script>
import { chunk, filter, find, findIndex, orderBy, snakeCase } from 'lodash';
import { mdiCardsHeart } from '@mdi/js';
import { mapActions, mapState } from 'vuex';
import FingerPrint from '../components/Main/FingerPrints/FingerPrint.vue';
import { AnalyticsHandler } from '../components/common/Analytics/AnalyticsHandler';
import TrialApi from '../API/trial-api';
import { getFileURL } from '@/utils/calyrex';
import {
  getFingerprintSlides,
  getUserCompanyInfo,
  audienceContactUs,
  // getDonationDetails,
  getFingerPrintAdditionalData,
} from '@/utils/api-helper';
import EmailPreviewModal from '../components/common/EmailPreviewModal.vue';
import { fpQuestions, fpSelections, fpTypes } from '@/mock/fingerprint';
import {
  MatomoAnalyticsHandler,
  FP_RESULT_START_FREE_TRIAL,
  FP_RESULT_GET_IN_TOUCH,
} from '../components/common/Analytics/MatomoAnalyticsHandler';
import ShareFingerPrint from '../components/Main/FingerPrints/ShareFingerPrint.vue';
import {
  updateAudienceFingerPrint,
  updateFingerprintShareDetails,
} from '../utils/api-helper';
import {
  TD_PLACEMENT,
  TD_AUD_EMAIL,
  TD_AUD_FIRST_NAME,
  TD_AUD_LAST_NAME,
  TD_AUD_COMPANY_NAME,
  TD_AUD_ADDITION_TYPE,
  TD_AUDIENCE_ADD_TYPE_REQUEST,
  TD_AUDIENCE_TYPE,
  TD_AUDIENCE_TYPE_INDIVIDUAL,
  TD_FLOW,
  TD_ADD_AUDIENCE_REQUESTED,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_FP_TEST,
  TD_FINGERPRINT_INDUSTRY,
  TD_FINGERPRINT_GROUP,
  TD_FINGERPRINT_REGION,
  TD_FINGERPRINT_JOBTITLE,
  TD_ORIGIN_FINGERPRINT,
  TD_SHARE_THE_GIFT_OF_FINGERPRINT,
  NA,
} from '../components/common/Analytics/MatomoTrackingDataHelper';
import { frameUserPreferencesResponse } from '../utils/common';
import { trackGeneralEvents } from '../components/common/Analytics/GeneralEvents';
import LearnMorePop from '../components/Main/PrezentWithHeart/LearnMorePop.vue';
import { isEmojiExist, isEmailValid } from '../components/utils';

const EMPTY_COLLEAGUE = {
  type: 'user',
  id: '',
};
const ENTITY_TYPE = 'audience';

export default {
  name: 'FingerPrintTest',
  components: { FingerPrint, ShareFingerPrint },
  data() {
    return {
      initialLoading: false,
      isLoading: false,
      isNotValid: true,
      agenda: '',
      currentIndex: 0,
      retakeCount: 0,
      inputFocused: false,
      basePath: '',
      question_progess: 0,
      questionIndex: 0,
      encourageShareFingerprint: false,
      answers: {
        question6: [],
      },
      shareFingerprintLoading: false,
      back: 'common.backButton',
      skip: 'common.skip',
      ownPreference: '',
      // ownPreferenceCount: 0,
      isCreating: false,
      questions: [],
      selections: [],
      showContent: false,
      firstSectionCompleted: false,
      personalPrefCompleted: false,
      basicDetailCompleted: false,
      inviteColleagureCompleted: false,
      generatingFingerPrint: false,
      industries: [],
      functions: [],
      groups: [],
      regions: [],
      jobTitles: [],
      collegueEmails: [],
      industrySelected: '',
      functionSelected: '',
      groupSelected: [],
      regionSelected: [],
      jobTitleSelected: '',
      progressScreenIndex: -1,
      progressScreens: [
        {
          banner: '/assets/img/fingerPrintTest/generating_fingerprint_01.svg',
          waitTime: 1000,
        },
        {
          banner: '/assets/img/fingerPrintTest/generating_fingerprint_02.svg',
          waitTime: 1000,
        },
        {
          banner: '/assets/img/fingerPrintTest/generating_fingerprint_03.svg',
          waitTime: 1000,
        },
        {
          banner: '/assets/img/fingerPrintTest/generating_fingerprint_04.svg',
          waitTime: 1000,
        },
      ],
      heartIcon: mdiCardsHeart,
      colleaguesList: [
        { ...EMPTY_COLLEAGUE },
        { ...EMPTY_COLLEAGUE },
        { ...EMPTY_COLLEAGUE },
      ],
      emailRules: [
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
        (v) => !!v || this.$t('warnings.emailRequired'),
        (v) => isEmailValid(v) || !v || this.$t('warnings.emailMustBeValid'),
        (v) =>
          this.isDuplicateEmail(v) || this.$t('warnings.emailAlreadyEntered'),
      ],
      firstnameRequired: [
        (v) => !!v || this.$t('warnings.firstNameRequired'),
        (v) => (v && v.length <= 50) || this.$t('warnings.inputMustBeLessThan'),
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
      ],
      lastnameRequired: [
        (v) => !!v || this.$t('warnings.lastNameRequired'),
        (v) => (v && v.length <= 50) || this.$t('warnings.inputMustBeLessThan'),
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
      ],
    };
  },
  watch: {
    questionIndex() {
      this.showContent = false;
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 900);
    },
    showContent() {
      if (this.showContent) {
        trackGeneralEvents.fingerPrintTestWhyWeAskClick(this.currentUser, {
          [TD_PLACEMENT]: this.getPlacement(),
          [TD_COMMON_COLUMN_NAME]: TD_FLOW,
          [TD_COMMON_COLUMN_VALUE]: TD_ADD_AUDIENCE_REQUESTED,
        });
      }
    },
  },
  mixins: [TrialApi],
  computed: {
    isValidString() {
      return this.ownPreference.trim() !== '';
    },
    isExistingPref() {
      return this.selections.some(
        (pref) =>
          pref?.title === this.ownPreference?.replace(/\s+/g, ' ').trim(),
      );
    },
    isAlreadySelectedPref() {
      return this.personalPrefs.some(
        (pref) => pref === this.ownPreference?.replace(/\s+/g, ' ').trim(),
      );
    },
    questionProgress() {
      if (this.questionIndex === 0) return 16;
      if (this.questionIndex === 1) return 32;
      if (this.questionIndex === 2) return 50;
      if (this.questionIndex === 3) return 64;
      if (this.questionIndex === 4) return 80;
      if (this.questionIndex === 5) return 100;
      if (this.questionIndex === 6) return 16;
      if (this.questionIndex === 7) return 32;
      if (this.questionIndex === 8) return 50;
      if (this.questionIndex === 9) return 64;
      if (this.questionIndex === 10) return 80;
      if (this.questionIndex === 11) return 100;
      if (this.questionIndex === 12) return 50;
      if (this.questionIndex === 13) return 100;
      return 0;
    },
    category() {
      if (this.questionIndex === 7) return this.industries;
      if (this.questionIndex === 8) return this.functions;
      if (this.questionIndex === 9) {
        const groupsDefined = this.fingerPrintAdditionalData?.groups.find(
          (item) => item.function === this.functionSelected,
        );
        if (groupsDefined?.groupValues.length) {
          console.log('groupsDefined', groupsDefined?.groupValues);
          const uniqueList = groupsDefined?.groupValues;
          uniqueList.sort((a, b) => a.name.localeCompare(b.name));
          return uniqueList;
        }
        return this.groups;
      }
      if (this.questionIndex === 10) return this.regions;
      return [];
    },
    sectionName() {
      if (this.questionIndex < 6) {
        return this.$t('profile.fingerprintTabContent.myFingerprint');
      }
      if (this.questionIndex > 5 && this.questionIndex < 12) {
        return this.$t('fingerprint.basicDetails');
      }
      return this.$t('fingerprint.addColleaguesAndShareFingerprint');
    },
    currentAudienceName() {
      return (
        this.currentTestAudience.firstName.charAt(0).toUpperCase() +
        this.currentTestAudience.firstName.slice(1)
      );
    },
    creatorName() {
      if (this.currentTestAudience.userID === this.currentUser.user?.id)
        return this.$t('fingerprint.me');
      return this.currentTestAudience.userID;
    },
    currentAnimalInfo() {
      return find(this.animals, { id: this.currentAnimal });
    },
    isNextButtonDisabled() {
      if (this.questionIndex === 6)
        return this.answers[`question${this.questionIndex}`].length < 1;
      if (this.questionIndex === 7) return !this.industrySelected;
      if (this.questionIndex === 8) return !this.functionSelected;
      if (this.questionIndex === 9) return !this.groupSelected.length;
      if (this.questionIndex === 10) return !this.regionSelected.length;
      if (this.questionIndex === 11) return !this.jobTitleSelected;
      if (this.questionIndex === 12) {
        let isEmpty = false;
        this.colleaguesList.forEach((colleague) => {
          if (
            !(
              !colleague.email &&
              !colleague.firstname &&
              !colleague.lastname
            ) &&
            !(colleague.email && colleague.firstname && colleague.lastname)
          ) {
            isEmpty = true;
          }
        });
        if (isEmpty) {
          return true;
        }
        const tempColleague = this.colleaguesList.filter(
          (ele) => ele.email && this.isVaildEmail(ele.email),
        );
        if (
          tempColleague.length ===
            this.colleaguesList.filter((ele) => ele.email).length &&
          tempColleague.length !== 0
        ) {
          return false;
        }
      }
      return true;
    },
    personalPrefs() {
      return (
        this.currentTestAudience.personalPreference ||
        this.answers.question6.map((item) => item.title)
      );
    },
    ...mapState('users', [
      'currentTestAudience',
      'sessionKey',
      'currentUser',
      'currentTheme',
      'fingerPrintAdditionalData',
    ]),
    defaultColleaguesList() {
      return [
        { ...EMPTY_COLLEAGUE },
        { ...EMPTY_COLLEAGUE },
        { ...EMPTY_COLLEAGUE },
      ];
    },
  },
  methods: {
    ...mapActions('users', [
      'setCurrentTestAudienceFingerprint',
      'setCurrentTestAudiencePersonalPreferences',
      'setCurrentTestAudience',
      'setFingerPrintAdditionalDetails',
    ]),
    async handleEmailChange(value, index) {
      this.colleaguesList[index].email = value.toLowerCase();
      this.colleaguesList[index].id = value.toLowerCase();
    },
    isEmailValid(v) {
      if (v) {
        return (
          this.emailRules[0](v) === true &&
          this.emailRules[1](v) === true &&
          this.emailRules[2](v) === true &&
          this.emailRules[3](v) === true
        );
      }
      return false;
    },
    closeContent() {
      this.showContent = false;
    },
    isDuplicateEmail(v) {
      if (v) {
        return !(
          this.colleaguesList.filter((item) => item.id === v).length > 1
        );
      }

      return true;
    },
    isVaildEmail(v) {
      if (isEmailValid(v)) {
        return true;
      }
      return false;
    },
    getGeneralGroups() {
      const generalGroups = this.fingerPrintAdditionalData?.groups.find(
        (item) => item.function === 'General',
      );
      return generalGroups?.groupValues;
    },
    loadProgressScreens(index) {
      if (index === 3) {
        this.progressScreenIndex = -1;
        this.encourageShareFingerprint = false;
        return;
      }
      setTimeout(() => {
        this.loadProgressScreens(this.progressScreenIndex++);
      }, this.progressScreens[this.progressScreenIndex].waitTime);
    },
    // To get the origin for matomo events capture
    getPlacement() {
      let placement = '';
      switch (this.questionIndex) {
        case 6:
          placement = 'Top preferences';
          break;
        case 7:
          placement = 'Industry';
          break;
        case 8:
          placement = 'Function';
          break;
        case 9:
          placement = 'Group';
          break;
        case 10:
          placement = 'Region';
          break;
        case 11:
          placement = 'Job Title';
          break;
        case 12:
          placement = 'Audience';
          break;
        default:
          break;
      }
      return placement;
    },
    handleSkipClick() {
      trackGeneralEvents.fingerPrintTestSkipClick(this.currentUser, {
        [TD_PLACEMENT]: this.getPlacement(),
        [TD_COMMON_COLUMN_NAME]: TD_FLOW,
        [TD_COMMON_COLUMN_VALUE]: TD_ADD_AUDIENCE_REQUESTED,
      });
      if (this.questionIndex === 7) this.industrySelected = '';
      if (this.questionIndex === 8) this.functionSelected = '';
      if (this.questionIndex === 9) this.groupSelected = [];
      if (this.questionIndex === 10) this.regionSelected = [];
      if (this.questionIndex === 11) {
        this.jobTitleSelected = '';
        this.basicDetailCompleted = true;
        setTimeout(() => {
          this.basicDetailCompleted = false;
        }, 3000);
        this.encourageShareFingerprint = true;
      }
      this.questionIndex++;
    },
    selectItem(item) {
      if (this.questionIndex === 7 && this.industrySelected !== item?.name) {
        this.industrySelected = item?.name;
      } else if (this.questionIndex === 7) this.industrySelected = '';
      if (this.questionIndex === 8 && this.functionSelected !== item?.name) {
        this.functionSelected = item?.name;
      } else if (this.questionIndex === 8) this.functionSelected = '';
      if (
        this.questionIndex === 9 &&
        !this.groupSelected.includes(item?.name)
      ) {
        this.groupSelected.push(item?.name);
      } else if (this.questionIndex === 9) {
        const index = this.groupSelected.indexOf(item?.name);
        this.groupSelected.splice(index, 1);
      }
      if (
        this.questionIndex === 10 &&
        !this.regionSelected.includes(item?.name)
      ) {
        this.regionSelected.push(item?.name);
      } else if (this.questionIndex === 10) {
        const index = this.regionSelected.indexOf(item?.name);
        this.regionSelected.splice(index, 1);
      }
      if (this.questionIndex === 11 && this.jobTitleSelected !== item?.name) {
        this.jobTitleSelected = item?.name;
      } else if (this.questionIndex === 11) this.jobTitleSelected = '';
    },
    addColleague(from) {
      if (this.colleaguesList.length < 10) {
        this.colleaguesList.push({ ...EMPTY_COLLEAGUE });

        setTimeout(() => {
          const element = this.$el.querySelector(
            `#name-input-${this.colleaguesList.length - 1}`,
          );

          if (element) {
            if (from === 'enter') {
              element.focus();
            }
            element.scrollIntoView({
              behavior: 'smooth',
            });
          }
        }, 100);
      }
    },
    setBackgroundColour() {
      this.inputFocused = true;
      document.getElementById('addCustomPref').style.background = '#f5fcff';
      trackGeneralEvents.fingerPrintTestAddYourOwnClick(this.currentUser, {
        [TD_PLACEMENT]: this.getPlacement(),
        [TD_COMMON_COLUMN_NAME]: TD_FLOW,
        [TD_COMMON_COLUMN_VALUE]: TD_ADD_AUDIENCE_REQUESTED,
      });
    },
    removeBackgroundColour() {
      this.inputFocused = false;
      document.getElementById('addCustomPref').style.background = '#FFFFFF';
    },
    onEnter() {
      trackGeneralEvents.fingerPrintTestAddYourOwnAddClick(this.currentUser, {
        [TD_PLACEMENT]: this.getPlacement(),
        [TD_COMMON_COLUMN_NAME]: TD_FLOW,
        [TD_COMMON_COLUMN_VALUE]: TD_ADD_AUDIENCE_REQUESTED,
      });
      if (this.ownPreference) {
        this.ownPreference.replace(/\s+/g, ' ').trim();
      }
      if (this.questionIndex === 7) {
        this.industries.push({
          name: this.ownPreference,
        });
        this.industrySelected = this.ownPreference;
      }
      if (this.questionIndex === 8) {
        this.functions.push({
          name: this.ownPreference,
        });
        this.functionSelected = this.ownPreference;
      }
      if (this.questionIndex === 9) {
        this.groups.push({
          name: this.ownPreference,
        });
        this.groupSelected.push(this.ownPreference);
      }
      if (this.questionIndex === 6) {
        // this.ownPreference = preference;
        if (!this.isExistingPref)
          this.selections.push({
            value: this.ownPreference.toLowerCase(),
            title: this.ownPreference,
          });
        if (!this.isAlreadySelectedPref)
          this.handleQuestion('question6', {
            value: this.ownPreference.toLowerCase(),
            title: this.ownPreference,
          });
        // this.ownPreferenceCount++;
      }
      this.ownPreference = '';
    },
    handlePreview() {
      if (this.encourageShareFingerprint && this.questionIndex === 12) {
        this.encourageShareFingerprint = !this.encourageShareFingerprint;
        this.questionIndex--;
        return;
      }
      if (this.currentIndex > 0) {
        if (this.questionIndex === 0) {
          this.currentIndex--;
        } else {
          this.questionIndex--;
        }
      }
    },
    isSelectedCard(item) {
      if (this.questionIndex === 7) return this.industrySelected === item?.name;
      if (this.questionIndex === 8) return this.functionSelected === item?.name;
      if (this.questionIndex === 9)
        return this.groupSelected.includes(item?.name);
      if (this.questionIndex === 10)
        return this.regionSelected.includes(item?.name);
      if (this.questionIndex === 11)
        return this.jobTitleSelected === item?.name;
      return false;
    },
    getFingerPrintData(fingerPrint) {
      return fpTypes.find(
        (fprint) => fprint.title === fingerPrint?.toLowerCase(),
      );
    },
    async openEmailModal() {
      const fingerPrint = await this.setCurrentTestAudienceFingerprint({
        ...this.answers,
        emailPreview: true,
      });
      const fingerprintData = this.getFingerPrintData(fingerPrint);
      this.$emit('subModalOpen', false);
      this.$modal.show(
        EmailPreviewModal,
        {
          emailApiPayload: {
            // type: 'send-fingerprint-request-new',
            type: 'fingerprint_share_dml',
            meta: {
              firstName: this.currentTestAudience.firstName,
              lastName: this.currentTestAudience.lastName,
              fingerPrint,
              fingerPrintDescription: fingerprintData.description,
              visualPreferenceImgURL: fingerprintData.preferences[0].logo,
              visualPreference: fingerprintData.preferences[0].preference,
              visualPreferenceText: fingerprintData.preferences[0].oneLine,
              storyPreferenceImgURL: fingerprintData.preferences[1].logo,
              storyPreference: fingerprintData.preferences[1].preference,
              storyPreferenceText: fingerprintData.preferences[1].oneLine,
              dataPreferenceImgURL: fingerprintData.preferences[2].logo,
              dataPreference: fingerprintData.preferences[2].preference,
              dataPreferenceText: fingerprintData.preferences[2].oneLine,
              fingerPrintAvatarURL: fingerprintData.logo,
            },
          },
          previewType: 'AUDIENCE',
          heading: 'Fingerprint share email preview',
        },
        {
          name: 'EmailPreviewModal',
          classes: 'v-application',
          width: '850px',
          height: '600px',
          styles: {
            borderRadius: '20px',
            padding: '20px',
          },
        },
        {
          'before-close': this.subModalClosed,
        },
      );
    },
    getClassName(selection) {
      return findIndex(this.answers.question6, { title: selection.title }) > -1
        ? 'highlight'
        : '';
    },
    handleQuestion(question, item) {
      if (question === 'question6') {
        const initialValue = this.answers[question];
        if (findIndex(initialValue, { title: item.title }) > -1) {
          const updatedValue = filter(
            initialValue,
            (initial) => initial.title !== item.title,
          );
          this.answers = { ...this.answers, [question]: updatedValue };
        } else {
          initialValue.push(item);
          this.answers = { ...this.answers, [question]: initialValue };
        }
      } else {
        this.answers = { ...this.answers, [question]: item };
        if (question !== 'question5') {
          this.$nextTick(() => {
            this.questionIndex++;
          });
        }
        if (question === 'question5') {
          this.$nextTick(() => {
            this.questionIndex++;
            this.firstSectionCompleted = true;
            setTimeout(() => {
              this.firstSectionCompleted = false;
            }, 3000);
          });
        }
      }
    },
    async handleCurrentQuestionIndex() {
      this.ownPreference = '';
      trackGeneralEvents.fingerPrintTestNextClick(this.currentUser, {
        [TD_PLACEMENT]: this.getPlacement(),
        [TD_COMMON_COLUMN_NAME]: TD_FLOW,
        [TD_COMMON_COLUMN_VALUE]: TD_ADD_AUDIENCE_REQUESTED,
      });
      if (this.questionIndex === 6) {
        this.personalPrefCompleted = true;
        setTimeout(() => {
          this.personalPrefCompleted = false;
        }, 3000);
      }
      if (this.questionIndex === 11) {
        this.basicDetailCompleted = true;
        setTimeout(() => {
          this.basicDetailCompleted = false;
        }, 3000);
      }
      this.questionIndex++;
      if (this.questionIndex < 6) {
        this.questionIndex++;
        if (this.questionIndex === 6) {
          // try {
          //   await this.setCurrentTestAudienceFingerprint(this.answers); // TODO: make sure this event fired properly
          // } catch (e) {
          //   // eslint-disable-next-line no-alert
          //   alert('Error while updating fingerprint');
          // }
          // AnalyticsHandler.audienceCompletedFingerprint(
          //   this.currentTestAudience,
          // );
          this.question_progess += 14;
        } else {
          this.question_progess += 14;
        }
      } else if (this.questionIndex === 12) {
        this.encourageShareFingerprint = true;
      } else if (this.questionIndex === 13) {
        this.inviteColleagureCompleted = true;
        await this.handleShare();
        setTimeout(async () => {
          this.inviteColleagureCompleted = false;
          this.encourageShareFingerprint = true;
        }, 3000);
      }
    },
    async handleFingerprintUpdate(updateShare) {
      this.progressScreenIndex = 0;
      this.loadProgressScreens();
      try {
        await this.setCurrentTestAudienceFingerprint(this.answers);
        const userPrefreneces = frameUserPreferencesResponse(this.answers);
        updateAudienceFingerPrint({
          uuid: this.currentTestAudience.uuid,
          id: this.currentTestAudience.id,
          fingerPrint:
            this.currentTestAudience.fingerPrint.charAt(0).toUpperCase() +
            this.currentTestAudience.fingerPrint.slice(1),
          visualPreference: [this.currentTestAudience.visualPreference],
          storyPreference: [this.currentTestAudience.storyPreference],
          dataPreference: [this.currentTestAudience.dataPreference],
          personalPreference: this.answers.question6.map((item) => item.title),
          industry: this.industrySelected,
          jobFunction: this.functionSelected,
          subDivision: this.groupSelected,
          region: this.regionSelected,
          jobTitle: this.jobTitleSelected,
          fpCompletionDate: new Date().toISOString(),
          // active: true,
          ...userPrefreneces,
        }).then((fpData) => {
          AnalyticsHandler.audienceCompletedFingerprint(
            this.currentTestAudience,
          );
          this.setCurrentTestAudience({
            ...this.currentTestAudience,
            ...fpData?.audience,
          });
          updateFingerprintShareDetails(updateShare).then(() => {
            this.shareFingerprintLoading = false;
            this.currentIndex++;
          });
          trackGeneralEvents.addnewaudienceShowfingerprint(this.currentUser, {
            [TD_AUD_EMAIL]: this.currentTestAudience?.id,
            [TD_AUD_FIRST_NAME]: this.currentTestAudience?.firstName,
            [TD_AUD_LAST_NAME]: this.currentTestAudience?.lastName,
            [TD_AUD_COMPANY_NAME]: this.currentTestAudience?.companyName,
            [TD_AUDIENCE_TYPE]: TD_AUDIENCE_TYPE_INDIVIDUAL,
            [TD_PLACEMENT]: TD_FP_TEST,
            [TD_COMMON_COLUMN_NAME]: TD_FLOW,
            [TD_COMMON_COLUMN_VALUE]: TD_ADD_AUDIENCE_REQUESTED,
            [TD_AUD_ADDITION_TYPE]: TD_AUDIENCE_ADD_TYPE_REQUEST,
            ...this.getMatomoFingerprintData(),
          });
        }); // TODO: make sure this event fired properly
      } catch (e) {
        console.log(e);
      }
    },
    getMatomoFingerprintData() {
      return {
        [TD_FINGERPRINT_INDUSTRY]: this.industrySelected
          ? this.industrySelected
          : NA,
        [TD_FINGERPRINT_GROUP]: this.groupSelected?.length
          ? this.groupSelected.toString()
          : NA,
        [TD_FINGERPRINT_REGION]: this.regionSelected?.length
          ? this.regionSelected.toString()
          : NA,
        [TD_FINGERPRINT_JOBTITLE]: this.jobTitleSelected
          ? this.jobTitleSelected
          : NA,
      };
    },
    handleCurrentIndex() {
      this.currentIndex++;
    },
    gotoRetakeTest() {
      if (this.basePath === '') {
        this.basePath = this.$route.fullPath;
      }
      this.retakeCount++;
      this.currentIndex = 2;
      this.questionIndex = 0;
      this.encourageShareFingerprint = false;
      if (this.$route.query.initialIndex) {
        this.$router.push(`${this.basePath}&retake=${this.retakeCount}`);
      } else {
        this.$router.push(`${this.basePath}?retake=${this.retakeCount}`);
      }
      trackGeneralEvents.addnewaudienceShowfingerprintRetakeTest(
        this.currentUser,
        {
          [TD_AUD_EMAIL]: this.currentTestAudience?.id,
          [TD_AUD_FIRST_NAME]: this.currentTestAudience?.firstName,
          [TD_AUD_LAST_NAME]: this.currentTestAudience?.lastName,
          [TD_AUD_COMPANY_NAME]: this.currentTestAudience?.companyName,
          [TD_AUDIENCE_TYPE]: TD_AUDIENCE_TYPE_INDIVIDUAL,
          [TD_PLACEMENT]: TD_FP_TEST,
          [TD_COMMON_COLUMN_NAME]: TD_FLOW,
          [TD_COMMON_COLUMN_VALUE]: TD_ADD_AUDIENCE_REQUESTED,
          [TD_AUD_ADDITION_TYPE]: TD_AUDIENCE_ADD_TYPE_REQUEST,
          ...this.getMatomoFingerprintData(),
        },
      );
    },
    handleRegisterTrail() {
      this.isLoading = true;
      const domain = this.currentTestAudience.id.split('@')[1];
      const payload = [
        {
          email: this.currentTestAudience.id,
          firstname: this.currentTestAudience.firstName,
          lastname: this.currentTestAudience.lastName,
          requester: this.currentTestAudience.userID,
          source: 'AudienceToTrial',
          companyname: domain,
          type: ENTITY_TYPE,
          requester_fullname: this.currentTestAudience.requesterFullname,
        },
      ];
      console.log('payload', payload);
      TrialApi.methods
        .trialRegistration(payload)
        .then((resp) => {
          console.log('resp', resp);
          this.isLoading = false;
          if (resp?.data[0]?.signUpLink) {
            window.location.replace(resp?.data[0]?.signUpLink);
          } else {
            this.$router.push(`/trialregistration?waitList=true`);
          }
          MatomoAnalyticsHandler.trackFingerprint({
            actionName: FP_RESULT_START_FREE_TRIAL,
            user: this.currentUser,
          });
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    handleTouch() {
      this.currentIndex++;
      audienceContactUs({ uuid: this.currentTestAudience.uuid })
        .then((response) => console.log(response))
        .catch((err) => console.log(err));

      MatomoAnalyticsHandler.trackFingerprint({
        actionName: FP_RESULT_GET_IN_TOUCH,
        user: this.currentUser,
      });
    },
    async populateQuestions() {
      this.initialLoading = true;
      let theme = '';
      try {
        let companyName = '';
        let isExternalCompany = false;
        if (this.currentUser?.company?.name) {
          companyName = this.currentUser.company.name.toLowerCase();
        } else if (this.currentTestAudience.companyName) {
          companyName = this.currentTestAudience.companyName.toLowerCase();
          isExternalCompany = this.currentTestAudience.isExternal;
        }

        // if audeince belongs to external company
        // then go for fixed theme to load fingerprint test slides
        if (isExternalCompany) {
          theme = 'generic_global_network';
        } else {
          const { data } = await getUserCompanyInfo(companyName).catch(() => {
            this.initialLoading = false;
          });
          theme = snakeCase(Object.keys(data)[0]);
        }
      } catch (e) {
        console.log(e);
        this.initialLoading = false;
      }
      const { data: fingerprintSlides } = await getFingerprintSlides(
        theme,
      ).catch(() => {
        this.initialLoading = false;
      });
      try {
        const sortedSlides = chunk(orderBy(fingerprintSlides, 'unique_id'), 2);
        this.questions = await Promise.all(
          this.questions.map(
            async (question, index) =>
              new Promise((resolve) => {
                if (question.leftImage && question.rightImage) {
                  getFileURL(
                    '00000000-0000-0000-0000-000000000000',
                    sortedSlides[index][0].landscape,
                    this.currentUser.userIp,
                  ).then((leftImage) => {
                    getFileURL(
                      '00000000-0000-0000-0000-000000000000',
                      sortedSlides[index][1].landscape,
                      this.currentUser.userIp,
                    ).then((rightImage) => {
                      new Image().src = leftImage;
                      new Image().src = rightImage;
                      resolve({
                        ...question,
                        leftImage,
                        rightImage,
                      });
                    });
                  });
                } else {
                  resolve(question);
                }
              }),
          ),
        );
        this.initialLoading = false;
      } catch (e) {
        console.log(e);
        this.initialLoading = false;
      }
    },
    async openShareMenu() {
      // trackGeneralEvents.sharetrialsIconclickLearnmore(this.currentUser);
      // await getDonationDetails()
      //   .then(async (res) => {
      //     if (res.data.contributors && res.data.contributors.length > 0) {
      //       await res.data.contributors.forEach(async (learner) => {
      //         learner.profileImageUrl = '';
      //         if (learner.profileImage && !learner.profileImageUrl) {
      //           await Storage.get(learner.profileImage).then((s3url) => {
      //             learner.profileImageUrl = s3url;
      //           });
      //         }
      //       });
      //     }
      //     this.contributors = res.data.contributors;
      //     this.totalCount = Number(res.data.totalCount);
      //   })
      //   .catch((err) => console.log(err));
      trackGeneralEvents.addnewaudienceLearnMoreV2(this.currentUser, {
        [TD_AUD_EMAIL]: this.currentTestAudience?.id,
        [TD_AUD_FIRST_NAME]: this.currentTestAudience?.firstName,
        [TD_AUD_LAST_NAME]: this.currentTestAudience?.lastName,
        [TD_AUD_COMPANY_NAME]: this.currentTestAudience?.companyName,
        [TD_AUDIENCE_TYPE]: TD_AUDIENCE_TYPE_INDIVIDUAL,
        [TD_PLACEMENT]: TD_FP_TEST,
        [TD_COMMON_COLUMN_NAME]: TD_FLOW,
        [TD_COMMON_COLUMN_VALUE]: TD_ADD_AUDIENCE_REQUESTED,
        [TD_AUD_ADDITION_TYPE]: TD_AUDIENCE_ADD_TYPE_REQUEST,
      });
      this.$emit('subModalOpen', false);
      this.$modal.show(
        LearnMorePop,
        {
          contributors: [],
          // totalCount: this.totalCount,
          origin: 'FingerprintTest',
          onVisitSite: () => {
            // trackGeneralEvents.sharetrialsIconclickLearnmoreVisitsite(
            //   this.currentUser,
            // );
            trackGeneralEvents.addnewaudienceLearnMoreVisitSiteV2(
              this.currentUser,
              {
                [TD_AUD_EMAIL]: this.currentTestAudience?.id,
                [TD_AUD_FIRST_NAME]: this.currentTestAudience?.firstName,
                [TD_AUD_LAST_NAME]: this.currentTestAudience?.lastName,
                [TD_AUD_COMPANY_NAME]: this.currentTestAudience?.companyName,
                [TD_AUDIENCE_TYPE]: TD_AUDIENCE_TYPE_INDIVIDUAL,
                [TD_PLACEMENT]: TD_FP_TEST,
                [TD_COMMON_COLUMN_NAME]: TD_FLOW,
                [TD_COMMON_COLUMN_VALUE]: TD_ADD_AUDIENCE_REQUESTED,
                [TD_AUD_ADDITION_TYPE]: TD_AUDIENCE_ADD_TYPE_REQUEST,
              },
            );
          },
        },
        {
          name: 'LearnMorePop',
          classes: 'v-application',
          width: '520px',
          height: 'auto',
          styles: {
            borderRadius: '20px',
            padding: '20px',
            'max-height': '800px',
            'min-height': '300px',
            'overflow-y': 'auto',
          },
        },
        {
          'before-close': this.subModalClosed,
        },
      );
    },
    closeShareMenu() {
      this.showShare = false;
    },
    removeColleague(index) {
      if (this.colleaguesList.length >= 1) {
        this.colleaguesList = this.colleaguesList.filter((_, i) => i !== index);
      }
    },
    async handleShare() {
      this.colleaguesList = this.colleaguesList.filter((item) => item.email);
      this.isLoading = true;
      const payload = this.colleaguesList.map((item) => {
        const { email, firstname, lastname } = item;
        return {
          email,
          firstname,
          lastname,
          requester: this.currentTestAudience.id,
          message: this.message,
          source: 'MemberReferral',
          type: ENTITY_TYPE,
          requester_fullname: `${this.currentTestAudience.firstName} ${this.currentTestAudience.lastName}`,
        };
      });
      try {
        const resp = await TrialApi.methods.trialRegistration(payload);
        if (!resp.error) {
          this.resultData = resp.data;
          this.sentOutInvitesUsersData = this.resultData.filter(
            (result) =>
              !result.userAlreadyExist &&
              result.companyRegistered &&
              result.signUpLink,
          );
          this.existingUsersData = this.resultData.filter(
            (result) => result.userAlreadyExist,
          );

          this.nonRegisteredCompanyUsersData = this.resultData.filter(
            (result) => !result.userAlreadyExist && !result.companyRegistered,
          );
          this.step = 2;
        } else {
          // TODO: error handle
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    subModalClosed() {
      this.$emit('subModalOpen', true);
    },
    getMatomoOtherData() {
      if (this.origin && this.origin === TD_ORIGIN_FINGERPRINT) {
        return {
          [TD_PLACEMENT]: TD_SHARE_THE_GIFT_OF_FINGERPRINT,
        };
      }
      return {};
    },
    openEmailPreviewModal() {
      this.shareTrialLink = `&lt;Unique link&gt;`;
      this.$emit('subModalOpen', false);
      this.$modal.show(
        EmailPreviewModal,
        {
          previewType: 'AUDIENCE',
          emailApiPayload: {
            type: 'trial-registration-new',
            meta: {
              toAddress: 'test.trial@prezent.ai',
              signupLink: this.shareTrialLink,
              firstName: this.currentTestAudience.firstName,
              lastName: this.currentTestAudience.lastName,
            },
          },
          heading: 'Trial invite email preview',
        },
        {
          name: 'EmailPreviewModal',
          classes: 'v-application',
          width: '520px',
          height: '600px',
          styles: {
            borderRadius: '20px',
            padding: '20px',
          },
        },
        {
          'before-close': this.subModalClosed,
        },
      );
    },
  },
  async mounted() {
    if (this.$route.query.initialIndex) {
      this.currentIndex = Number(this.$route.query.initialIndex);
    }
    try {
      const fingerPrintAdditionalData = await getFingerPrintAdditionalData();
      this.setFingerPrintAdditionalDetails(fingerPrintAdditionalData);
    } catch (err) {
      console.log(err);
    }
    // initialize questions and selections
    this.questions = [...fpQuestions];
    this.selections = [...fpSelections];
    this.industries = this.fingerPrintAdditionalData?.industries;
    this.functions = this.fingerPrintAdditionalData?.functions;
    this.groups = this.getGeneralGroups();
    this.regions = this.fingerPrintAdditionalData?.regions;
    this.jobTitles = this.fingerPrintAdditionalData?.jobTitle;
    await this.populateQuestions();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
// .v-application {
//   .circle {
//     position: relative;
//     height: 100vh;
//     overflow: hidden;
//   }
// }
.circle-top,
.circle-bottom {
  position: absolute;
  display: flex;
  gap: 25px;
  align-items: center;

  .circle {
    width: 200px;
    height: 200px;
    border: 4px solid #21a7e0;
    border-radius: 50%;
  }

  .dot {
    height: 20px;
    width: 20px;
    background: #21a7e0;
    border-radius: 50%;
  }
}
.circle-top {
  top: 0;
  right: 0;
  transform: translate(40%, -40%) rotate(150deg);
}

.circle-bottom {
  bottom: 0;
  left: 0;
  transform: translate(-35%, 30%) rotate(-40deg);
}
// .fadeScreen-enter-active,
// .fadeScreen-leave-active {
//   transition: transform 1s ease 0.5s;
// }
// .fadeScreen-enter,
// .fadeScreen-leave-to {
//   transform: translateX(-100%);
//   opacity: 1;
// }
// .fadeScreen-leave,
// .fadeScreen-enter-to {
//   transition: transform 1s ease;
//   opacity: 0;
// }

.fadeScreen-leave-active {
  transition: all 0.9s ease 0.4s;
  opacity: 1;
}

.fadeScreen-leave-to {
  transform: translateX(-100%);
  opacity: 1;
}

@import '@/scss/variables.scss', '@/scss/app.scss';
.v-application {
  background: linear-gradient(
    0.61deg,
    rgba(149, 208, 234, 0.6) -22.84%,
    rgba(255, 255, 255, 0.048) 38.59%
  );
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.register-trail-btn {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  text-transform: none;
}
.main-wrapper {
  padding-left: 3%;
  padding-right: 3%;
  text-align: center !important;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 20px;
}
::v-deep .highlight {
  color: #29b6f6;
}

::v-deep .label {
  padding-left: 5px;
  margin-right: -100px;
}

.encourage-share-fingerprint {
  align-items: center;
  display: flex;
  flex-direction: column;

  .encourage-title {
    color: #000000;
    height: 52px;
    margin-bottom: 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 52px;
    text-align: center;
  }

  .encourage-sub-title {
    color: #000000;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    height: 28px;
    margin-bottom: 5px;
    text-align: center;
  }

  .add-heart__title {
    color: #000000;
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 14px;
    .add-heart__title_subtext {
      color: #000000;
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
      height: 28px;
      text-align: center;
      margin-left: 5px;
    }
  }
  .learn_more {
    cursor: pointer;
    color: rgb(0, 149, 255);
  }

  .main-wrapper {
    margin-top: 40px;
    width: 60%;
    ::v-deep .audience-item {
      .text-name {
        text-align: start;
      }
    }
  }
}
.section-wrapper {
  display: flex;
  align-items: center !important;
  justify-content: center;
  text-align: center;
  .fingerprint-title {
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 52px;
    text-align: center;
  }
  .fingerprint-sub-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    .fingerprint-sub-title-content {
      color: rgba(0, 0, 0, 0.8);
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 45px;
      line-height: 52px;
      text-align: center;
    }
  }
  .section-image {
    margin-bottom: 40px;
  }
  .lets-go-button {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    color: #ffffff;
  }
}
.privacy-policy-footer {
  bottom: 0;
  color: gray !important;
  display: flex;
  justify-content: flex-end;
  padding: 20px 60px;
  right: 0;
  .privacy-policy {
    padding-right: 20px;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
.first-screen {
  flex-direction: column;
  justify-content: center;
  .name-wrapper {
    font-size: 36px;
    font-weight: bold;
    color: #21a7e0;
  }
  .highlight-bold {
    font-weight: bold;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 44px;
  }
  .sub-header {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 20px;
  }
  button {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none;
  }
}
.second-screen {
  align-items: center;
  display: flex;

  .left-image-section {
    width: 40%;
  }
  .pa-4 {
    width: 60%;
    .header {
      color: rgba(0, 0, 0, 0.8);
      font-size: 45px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 52px;
      letter-spacing: normal;
      text-align: center;
      margin-bottom: 30px;
    }

    .items {
      align-items: center;
      display: flex;
      gap: 30px;
      justify-content: space-between;
      margin: 24px 0;

      .item {
        align-items: center;
        background: #ffffff;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
          -1px -1px 4px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        height: 162px;
        justify-content: center;
        width: 240px;

        .title {
          color: #7b7b7b;
          font-family: 'Lato' !important;
          font-style: normal;
          font-weight: 700;
          font-size: 20px !important;
          line-height: 24px;
          margin-bottom: 15px;
        }
        img {
          width: 86px;
          height: 60px;
        }
      }
    }
  }
  .description {
    color: rgba(0, 0, 0, 0.8);
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 24px;
    text-align: center;
  }
  .action-detail {
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    margin-bottom: 24px;
  }
  button {
    margin-bottom: 9px;
  }
  .final-description {
    color: #000000;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }
}
.question-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  margin: auto;
  text-align: center !important;
  .preview-email {
    color: #21a7e0;
    cursor: pointer;
    margin: 10px 0px 10px 15px;
    font-size: 1rem;
    .preview-email-text {
      margin-right: 9.7px;
    }
  }
  .buttonAndHeading {
    display: flex;
    position: relative;
    margin-bottom: 15px;
    align-items: center;

    .back-button {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      position: absolute;
      right: calc(100% + 150px);
      width: 50px;
      cursor: pointer;
      color: #29b6f6;
      display: flex;
      align-items: center;
    }
    .question-header {
      color: #000000;
      font-family: 'Lato';
      line-height: 29px;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      text-align: center;
    }
  }
  .question-title {
    color: #29b6f6;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .progress-wrapper {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  .question-index {
    font-size: 12px;
    margin-bottom: 40px;
  }
  .question-content {
    width: 100%;
    .question-content-header {
      margin-bottom: 40px;
      font-size: 20px;
      .header {
        color: rgba(0, 0, 0, 0.8);
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 45px;
        line-height: 52px;
        text-align: center;
        margin-bottom: 15px;
      }
      .sub-header {
        color: rgba(0, 0, 0, 0.8);
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        text-align: center;
        &.underline {
          display: flex;
          color: #29b6f6;
          justify-content: center;
          p {
            cursor: pointer;
            margin-bottom: 0px !important;
            width: 150px;
          }
        }
      }
    }
    .images-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-bottom: 50px;
      img.question-image {
        background: #ffffff;
        border-radius: 28px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
          -1px -1px 4px rgba(0, 0, 0, 0.1);
        width: 40%;
        height: 20%;
        &.border {
          border: 5px solid #21a7e0;
          box-shadow: 3px -2px 15px 3px #b6b6b6, -3px 2px 15px 3px #b6b6b6;
        }
        &:hover {
          box-shadow: 3px -2px 15px 3px #b6b6b6, -3px 2px 15px 3px #b6b6b6;
        }
        &.blur {
          opacity: 0.35;
        }
      }
    }
    .selections-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      .header {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        margin-bottom: 20px;
      }
      .selections {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        .selection {
          align-items: center;
          display: flex;
          justify-content: flex-start;
          border-radius: 12px;
          box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
            -1px -1px 4px rgba(0, 0, 0, 0.1);
          padding: 8px 15px;
          margin-right: 25px;
          margin-bottom: 25px;
          color: rgba(33, 33, 33, 1);
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          cursor: pointer;
          height: 70px;
          width: 25%;
          .selection-title {
            padding-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          &.highlight {
            border: 3px solid #29b6f6;
          }
        }
        .group-items {
          padding: 8px 25px;
          width: 20%;
        }
      }
      .write-own-btn {
        background: #ffffff;
        border-radius: 12px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
          -1px -1px 4px rgba(0, 0, 0, 0.1);
        width: 30%;
        padding: 8px 25px;
        font-size: 20px;
        font-weight: 700;
        margin: auto;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 60px;
        .write-own-header {
          display: flex;
          margin-top: 10px;
        }
        .new-pref-text {
          background: #ffffff;
          border-radius: 4px;
          font-weight: 400;
          margin: 10px 0px;
          text-align: left;
          width: 100%;
          ::v-deep .v-input__slot {
            margin-bottom: 0px !important;
          }
        }
        .add-pref {
          color: #29b6f6;
          margin-bottom: 10px;
          text-align: center;
          width: 100%;
          .v-icon.v-icon {
            color: #29b6f6;
          }
        }
      }
      .create-own {
        margin-bottom: 60px;
      }
    }
  }
  .question-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .next-button {
      width: 100px;
      height: 40px;
      background: #21a7e0;
      border-radius: 25px;
      ::v-deep .v-btn__content {
        color: #ffffff;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600 !important;
        font-size: 18px !important;
        line-height: 19px !important;
      }
    }
    button {
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-transform: none;
    }
    .skip-button {
      color: #21a7e0;
      cursor: pointer;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin: 10px 0;
    }
  }
}
.why-we-ask-content {
  margin: 0 auto;
  position: relative;
  width: 320px;
  z-index: 5;
  div {
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    left: 0;
    padding: 10px;
    position: absolute;
    right: 0;
  }
}

.invite-form {
  width: 100%;
}
.result-wrapper {
  margin: auto;
  text-align: center !important;

  .footer-description {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: normal;
  }
  .get-in-touch {
    font-size: 24px;
    font-weight: bold;
    text-transform: none;
  }
}
.v-input--radio-group--row {
  width: 100%;
}
.get-in-touch-wrapper {
  text-align: center !important;
  .get-in-touch-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center !important;
    margin-top: 35px;
    margin-bottom: 25px;
  }
}
.fingerprint-center-item {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $zen-blue-default;
  margin-top: 10px;
  margin-bottom: 7px;
  width: 100%;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
.job-function-wrapper {
  margin-bottom: 200px;
  width: 400px;
}
.cards-wrapper {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  margin-bottom: 30px;
  width: 840px;
  &.industry {
    width: 100%;
    .cards {
      max-width: 20% !important;
    }
  }
}
.cards {
  background: #ffffff;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 10%), -1px -1px 4px rgb(0 0 0 / 10%);
  width: 240px;
  height: 162px;
  border-radius: 12px;
  height: 180px;
  max-width: 260px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex: calc(33.33% - 20px);
  .item-name {
    color: #212121;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
  }
  > div {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
  }

  > img {
    height: 100px;
    width: 120px;
  }

  &.selected-card {
    border: 3px solid #21a7e0;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
  }
}
.loading-header {
  color: rgba(0, 0, 0, 0.8);
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 52px;
  text-align: center;
  margin-bottom: 30px;
}
.loading-sub-header {
  color: rgba(0, 0, 0, 0.8);
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 30px;
}

.add-more {
  align-items: center;
  margin-bottom: 58px;
  .add-btn {
    font-size: 14px;
    text-transform: none;
    letter-spacing: normal;
    text-align: center;
  }
}

.email-list-container {
  max-height: 300px;
  overflow-y: scroll;
  margin-top: 50px;
  margin-bottom: 20px;

  .name-col {
    margin-right: 20px;
    padding: 0;
    .custom-text-field .v-label {
      color: #191c1e;
    }

    .message-label {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .email-col {
    margin-right: 20px;
    //min-width: 30%;
    padding: 0px;

    .message-label {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .close-col {
    min-width: 2%;
  }
}
</style>
