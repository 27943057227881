import featureReleaseApi from "../../API/feature-release-api";

const state = {
  featureReleaseInfo: {},
};

const mutations = {
  LOAD_CONTENT(state, content) {
      state.featureReleaseInfo = {
        ...content,
      };
  },
};

const actions = {
  loadFeatureReleaseInfo: async ({ commit }) => {
    const data = await featureReleaseApi.methods.getAllFeatureReleaseInfo();
    commit("LOAD_CONTENT", data);
  },
};

const getters = {
    getFeatureReleaseInfo: (state) => (state ? state.featureReleaseInfo : {}),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
