<template>
  <div class="redirect">
    <v-img
      src="/assets/img/account-already-exists.svg"
      alt
      max-width="300px"
      max-height="300px"
    />
    <p class="heading">Account already exists</p>
    <p class="subHeading">Redirecting you to login...</p>
    <clip-loader
      :color="`#21a7e0`"
      :width="'60'"
      :height="'60'"
      :size="'50px'"
      class="cliploader"
    />
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

export default {
  name: 'Redirect',
  components: {
    ClipLoader,
  },
};
</script>

<style lang="scss" scoped>
.redirect {
  align-items: center;
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
