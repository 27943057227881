import DialogService from "../../services/DialogService";

const state = {
  content: {},
};

const mutations = {
  SET_CONTENT(state, content) {
    state.content = content;
  },
  SET_SLIDES_CONTENT(state, content) {
    state.content = {
      ...state.content,
      getSlides: {
        ...content,
      },
    };
  },
  SET_MEETING_CONTENT(state, content) {
    state.content = {
      ...state.content,
      getMeeting: {
        ...content,
      },
    };
  },
};

const actions = {
  getDialogContent: async ({ commit }) => {
    const data = await DialogService.methods.getDialogContent();
    commit("SET_CONTENT", data);
  },
  setSlidesContent: async ({ commit }, content) => {
    commit("SET_SLIDES_CONTENT", content);
  },
  setMeetingContent: async ({ commit }, content) => {
    commit("SET_MEETING_CONTENT", content);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
