import createPersistedState from 'vuex-persistedstate';

export const opInitialState = {
  opSnapshot: {},
};

export const overnightPrezentations = {
  namespaced: true,
  state: {
    ...opInitialState,
  },
  getters: {
    getOpSnapshot: (state) => (state.opSnapshot ? state.opSnapshot : {}),
  },
  mutations: {
    SAVE_OP_STATE_SNAPSHOT(state, snapshot) {
      state.opSnapshot = snapshot;
    },
  },
  actions: {
    saveOpStateSnapshot({ commit }, snapshot) {
      commit('SAVE_OP_STATE_SNAPSHOT', snapshot);
    },
  },
  persist: createPersistedState({
    key: 'vuex_overnightPrezentations',
    paths: [],
  }),
};
