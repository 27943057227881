<template>
  <v-app>
    <v-main>
      <div class="signin-wrapper">
        <div v-if="isLoading || isFailed" class="loading-wrapper">
          <div class="logo-loading">
            <img src="/assets/img/prezent_stack.svg" alt width="248px" />
          </div>
          <p v-if="isFailed" class="expired-link">
            Redirecting you to teams.prezent.ai...
          </p>
          <clip-loader
            v-if="isLoading || isFailed"
            :loading="loading"
            :color="`#21a7e0`"
            :width="'60'"
            :height="'60'"
            :size="'50px'"
            class="cliploader"
          />
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { Auth } from 'aws-amplify';
import { mapActions } from 'vuex';
import { getAudience } from '../utils/api-helper';
import AudienceAPI from '../API/audience-api';

export default {
  name: 'FingerprintSignin',
  props: {
    msg: String,
  },
  components: {
    ClipLoader,
  },
  data() {
    return {
      loading: true,
      isLoading: true,
      isFailed: false,
      isDisabled: true,
      valid: true,
      addCollegueFlow: null,
      error: '',
      email: '',
      show1: false,
      password: '',
      passwordRules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => "The email and password you entered don't match",
      },
    };
  },
  methods: {
    ...mapActions('users', ['setCurrentTestAudience']),
  },
  async mounted() {
    if (this.$route.query.inviteId) {
      this.inviteId = this.$route.query.inviteId;
      console.log('withInviteId', this.inviteId);
      const inviteLinkValidationResponse = await AudienceAPI.methods
        .validateAudience(this.inviteId)
        .catch((error) => {
          // TODO : Show error on the page
          this.error = 'Invite link is no longer valid.';
          this.isFailed = true;
          this.isLoading = false;
          setTimeout(() => {
            this.$router.push('/signin', () => {});
          }, 3000);
          console.log(
            'Validation failed for fingerprint test invitation',
            error,
          );
        });

      if (inviteLinkValidationResponse) {
        if (
          inviteLinkValidationResponse.message &&
          inviteLinkValidationResponse.message.trim() !==
            'Audience is authorized'
        ) {
          this.error = 'Fingerprint invite link is no longer valid..';
          this.isFailed = true;
          this.isLoading = false;
          setTimeout(() => {
            this.$router.push('/signin', () => {});
          }, 3000);
        } else {
          // Validate inviteId succeeded
          const responseData = inviteLinkValidationResponse.data;
          this.setCurrentTestAudience(responseData);
          if (responseData.fingerPrint) {
            // this.$router.push(
            //   `/audience-fingerprint?audience=${responseData.uuid}`,
            // );
            this.$router.push('/fingerprinttest?initialIndex=3');
          } else {
            this.$router.push('/fingerprinttest');
          }
        }
      }

      return;
    }
    if (this.$route.query.email) {
      this.email = this.$route.query.email.toLowerCase();
    }
    if (this.$route.query.name) {
      this.name = this.$route.query.name;
    }
    if (this.$route.query.requester) {
      this.requester = this.$route.query.requester;
    }
    if (this.$route.query.addCollegueFlow) {
      this.addCollegueFlow = this.$route.query.addCollegueFlow;
    }
    Auth.signIn(this.email).then((session) => {
      Auth.sendCustomChallengeAnswer(session, '6674')
        .then((user) => {
          console.log(user);
          this.error = '';
          getAudience(this.email).then((existingAud) => {
            if (existingAud && existingAud.data) {
              const audienceInfo = {
                id: this.email,
                lastName: existingAud.data.lastName,
                firstName: this.name,
                email: this.email,
                requester: this.requester,
              };
              this.setCurrentTestAudience(audienceInfo);
              // AmplifyEventBus.$emit("authState", "signedIn");
              this.$router.push('/fingerprinttest');
            }
          });
        })
        .catch((err) => {
          this.error = err.message;
          this.isFailed = true;
          this.isLoading = false;
          setTimeout(() => {
            this.$router.push('/signin', () => {});
          }, 3000);
          console.log('errrrr', err);
        });
    });
  },
  async beforeMount() {
    if (this.$route.query.inviteId) {
      return;
    }
    if (this.$route.query.email) {
      this.email = this.$route.query.email.toLowerCase();
    }
    // try {
    //   const userObject = await checkSSOUser(this.email);
    //   this.ssoUser = userObject.data.ssoUser;
    //   // If sso user and (domain not sso or domain not lcalhost enabled) redirect
    //   if (userObject.data.ssoUser) {
    //     const currentLocation = location.href;
    //     const { origin } = location;
    //     if (
    //       origin !== userObject.data.ssoMeta.prezentDomain &&
    //       !(SSOConfig.isSSOApp && location.hostname === 'localhost')
    //     ) {
    //       location.href = currentLocation.replace(
    //         origin,
    //         userObject.data.ssoMeta.prezentDomain,
    //       );
    //     }
    //   }
    // } catch (err) {
    //   this.isFailed = true;
    //   this.isLoading = false;
    //   console.log('err', err);
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.expired-link {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.logo-loading {
  display: flex;
  justify-content: center;
  .v-image {
    margin: 0 auto;
  }
}
.v-spinner {
  margin-top: 1.5rem;
}
::v-deep .v-main__wrap {
  margin: auto;
}
.signin-custom-card {
  padding: 30px;

  .label {
    text-align: left;
    font-size: 20px;
    font-weight: normal;
    color: black;
  }

  .v-card__title,
  .v-card__subtitle {
    text-align: left;
  }

  .headline {
    font-size: 24px;
    font-weight: bold;
    margin-left: -15px;
  }

  .sub-title {
    font-size: 20px;
    font-weight: normal;
  }
  .v-card__actions {
    justify-content: center;

    button {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-transform: none;
    }

    a {
      text-decoration: none;
    }
  }
}
.signin-wrapper {
  width: 452px;
  height: fit-content;
  margin: auto;

  .logo {
    margin-bottom: 60px;
  }

  .signin-form {
    .label {
      text-align: left;
    }
    button {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-transform: none;
    }
    .reset-password {
      font-size: 16px;
      font-weight: normal;

      a {
        text-decoration: none;
      }
    }
  }
}
</style>
