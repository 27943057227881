export const PrezentationViewTypes = {
  MY_PREZENTATIONS: 101,
  PREZENTATIONS_TO_REVIEW: 102,
  TEAM_PREZENTATIONS: 103,
};

export const defaultFetchCount = {
  LIST_USERS: 1500,
  TEAM_USERS: 500,
  GLOBAL_COUNT: 500,
  ALL_STATS_COUNT: 1000,
  AUDIANCE_LIMIT: 2000,
};

export const getDefaultFetchCount = () => defaultFetchCount;
