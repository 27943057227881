<template>
  <v-main>
    <div class="sso-signin-error">
      <div class="sso-user-disabed-image">
        <img
          src="/assets/img/new-sso-login-illustration.svg"
          alt
          width="350px"
        />
      </div>
      <div class="error-handling-message">
        <div class="error-text">
          <pre>{{ msg }}</pre>
        </div>
        <span class="link-to-main"
          ><a :href="redirectSignOut">Back to main login screen</a></span
        >
      </div>
      <p class="reset-password d-flex justify-center align-center mt-4">
        Trouble logging in?&nbsp;
        <a href="mailto:success@prezent.ai?subject=Trouble logging in">
          Get in touch
        </a>
      </p>
      <div class="try-for-free">
        Don’t have an account?&nbsp;
        <span class="link-to-main">
          <a :href="getTryForFreeLink()" @click="tryForFreeClick()"
            >Try for free</a
          >
        </span>
      </div>
    </div>
  </v-main>
</template>

<script>
import { SSOConfig } from '../runtimeConfig';
import { trackGeneralEvents } from '../components/common/Analytics/GeneralEvents';
import { TD_PLACEMENT } from '../components/common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'SsoUserDisabled',
  data() {
    return {
      redirectSignOut: SSOConfig.redirectSignOut,
    };
  },
  methods: {
    tryForFreeClick() {
      trackGeneralEvents.generalTryForFreeClick(
        {},
        {
          [TD_PLACEMENT]: 'SSO login where user account is disabled',
        },
      );
    },
    getTryForFreeLink() {
      const appDomain = process.env.VUE_APP_DOMAIN;
      return `${appDomain}/trialregistration`;
    },
  },
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss';
::v-deep .v-main__wrap {
  margin: auto;
  margin-top: 2% !important;
}

.sso-signin-error {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

pre {
  font-family: 'Lato', sans-serif;
}

.error-handling-message {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: normal;
  gap: 60px;

  .error-text {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
  }

  .link-to-main {
    color: $dark-blue;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: none;
  }

  a {
    color: $dark-blue;
    text-transform: none;
  }
}
.reset-password {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: -10px;
  color: $gray-dark-login-text;
  width: 100%;

  a {
    text-decoration: none;
    color: $dark-blue;
  }
}
.try-for-free {
  text-align: center;
  color: $gray-dark-login-text;
  display: block;
  margin-bottom: 20px;
  a {
    color: $dark-blue;
    text-transform: none;
  }
}
</style>
