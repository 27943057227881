export const sortedNotifications = (notifications) => {
  const sortedNotifications = notifications.sort(
    (a, b) =>  {
      if ((new Date(b.created_at)) === (new Date(a.created_at))) {
        return (a.notification).localeCompare(b.notification);
      } else {
        return new Date(b.created_at) - new Date(a.created_at);
      }
    }
  );
  return sortedNotifications;
};

const state = {
  tourType: "initial",
  notifications: [],
  loadingNotificationsFlag: false,
};

const mutations = {
  SET_TOUR(state, type) {
    state.tourType = type;
  },

  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  },

  UPDATE_NOTIFICATION_READ_STATUS(state, notifications) {
    state.notifications = notifications;
  },

  UPDATE_NOTIFICATIONS_SEEN_STATUS(state, notifications) {
    state.notifications = notifications;
  },

  SET_LOADING_NOTIFICATIONS_FLAG(state, loadingNotificationsFlag) {
    state.loadingNotificationsFlag = loadingNotificationsFlag;
  },
};

const actions = {
  setTour: async ({ commit }, type) => {
    commit("SET_TOUR", type);
  },

  setNotifications: async ({ commit }, notifications) => {
    commit("SET_NOTIFICATIONS", sortedNotifications(notifications));
  },

  updateNotificationReadStatus({ commit, state }, { notification }) {
    const updatedNotifications = state.notifications.map((notif) => {
      if (notif.id === notification.id) {
        return { ...notification };
      } else {
        return notif;
      }
    })
    commit("UPDATE_NOTIFICATION_READ_STATUS", sortedNotifications(updatedNotifications));
  },

  updateNotificationsSeenStatus({ commit, state }) {
    const seenNotifs = state.notifications.map((notif) => ({
      ...notif,
      seen: true,
    }));
    commit("UPDATE_NOTIFICATIONS_SEEN_STATUS", sortedNotifications(seenNotifs));
  },

  setLoadingNotificationsFlag({ commit }, loadingNotificationsFlag) {
    commit("SET_LOADING_NOTIFICATIONS_FLAG", loadingNotificationsFlag);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
