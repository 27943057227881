const initialState = {
  guides: [],
  guidesLoaded: 0
};

export const pendoStore = {
  namespaced: true,
  state: initialState,
  getters: {
    getGuides: (state) => (state ? state.guides : initialState.guides),
    isGuidesLoaded: (state) => state.guidesLoaded,
  },
  mutations: {
    SET_GUIDES(state, guides) {
      state.guides = guides;
      if (state.guidesLoaded > 0) {
        state.guidesLoaded--;
      } else {
        state.guidesLoaded++;
      }
    },
  },
  actions: {
    setGuides({ commit }, guides) {
      commit('SET_GUIDES', guides);
    }
  },
};
