<template>
  <v-app>
    <v-main>
      <div v-if="!loading">
        <Transition
          v-if="showTransition"
          name="fadeScreen"
          mode="out-in"
          :duration="900"
        >
          <div class="main-wrapper">
            <div class="logo">
              <img
                src="/assets/img/prezent_logo.svg"
                alt
                width="200px"
                height="44px"
              />
            </div>
            <TemplateDesignContainer
              :origin="'Profile'"
              @onTemplateComplete="handleTemplateDone"
              @handleSubmitErr="handleSubmitErr"
            ></TemplateDesignContainer>
            <div
              class="transition-container"
              v-if="templateType === 'transition'"
              :class="templateType === 'transition' ? 'slide-animate' : ''"
            >
              <p class="transition-title">
                {{ $t('templateDesign.uploadTemplate.transtionLoadTitle') }}
              </p>
              <div class="img-container">
                <img :src="'/assets/img/templateDesign/continue-loader.svg'" />
              </div>
            </div>
          </div>
        </Transition>
      </div>
      <div v-else class="loader-wrapper">
        <div class="logo-loading">
          <v-img
            src="/assets/img/prezent_stack.svg"
            alt
            width="248px"
            height="150px"
          />
        </div>
        <clip-loader
          v-if="loading"
          :loading="loading"
          :color="`#21a7e0`"
          :width="'60'"
          :height="'60'"
          :size="'50px'"
          class="cliploader"
        />
      </div>
    </v-main>
    <div v-if="!loading" class="privacy-policy-footer">
      <div class="privacy-policy">
        <a href="https://prezent.ai/privacy-policy" target="_blank">{{
          $t('fingerprint.privacyPolicy')
        }}</a>
      </div>
      <div>&#169; {{ $t('fingerprint.prezent') }}</div>
    </div>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import TemplateDesignContainer from './TemplateDesignContainer.vue';

export default {
  name: 'AddNewTemplateContainer',
  data() {
    return {
      templateType: 'custom',
      loading: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name !== 'profile') {
      next('/home');
    } else {
      next((vm) => {
        if (!vm.isNonBrandedTrialUser) {
          next('/home');
        }
      });
    }
  },
  components: {
    TemplateDesignContainer,
    ClipLoader,
  },
  computed: {
    ...mapState('users', ['currentUser']),
    showTransition() {
      return true;
    },
    isNonBrandedTrialUser() {
      return (
        this.currentUser?.user?.role?.toLowerCase() === 'trialuser' &&
        this.currentUser?.company?.isNonBranded &&
        this.currentUser?.subscriptions?.[0]?.daysLeft >= 0 &&
        this.isTDFeatureEnabled
      );
    },
    isTDFeatureEnabled() {
      const feature = this.currentUser.features.find(
        (f) => f.feature_name === 'template-designer',
      );
      return feature && feature.enabled;
    },
  },
  mounted() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  },
  methods: {
    handleTemplateDone() {
      this.templateType = 'custom';
    },
    handleSubmitErr(err) {
      if (err === 'JOB_ERR') {
        this.templateType = 'standard';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.v-application {
  background: linear-gradient(
    0.61deg,
    rgba(149, 208, 234, 0.6) -22.84%,
    rgba(255, 255, 255, 0.048) 38.59%
  );
}
.main-wrapper {
  padding: 20px 45px 20px 45px;
  text-align: center !important;
}
.loader-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 70px;
  text-align: center;
  width: 50%;
  margin: 10% auto 0 auto;
  .logo-loading {
    .v-image {
      margin: 0 auto;
    }
  }
}
.privacy-policy-footer {
  bottom: 0;
  color: gray !important;
  display: flex;
  justify-content: flex-end;
  padding: 20px 60px;
  right: 0;
  .privacy-policy {
    padding-right: 20px;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
::v-deep .highlight {
  color: #29b6f6;
}
</style>
