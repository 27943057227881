const combo = {
  audience: 0,
  objective: 1,
  type: 2,
};

export const contextSelector = {
  namespaced: true,
  state: {
    select: [
      {
        user: {
          username: 'None',
          fingerPrint: 'director',
          firstName: 'None',
          email: 'None',
        },
      },
      'None',
      'None',
    ],

    componentKey: 0,

    searchQuery: '',
    searchFilter: ['All', 'Slides', 'Videos', 'Tips', 'People'],

    topBarItems: [
      {
        title: 'Audience',
        dropDownItems: [
          {
            user: {
              username: 'None',
              fingerPrint: 'director',
              firstName: 'None',
              email: 'None',
            },
          },
        ],
      },
      {
        title: 'Objective',
        dropDownItems: [
          'None',
          'Align',
          'Decide',
          'Explain',
          'Inform',
          'Inspire',
          'Persuade',
        ],
      },
      {
        title: 'Type',
        dropDownItems: [
          'None',
          'Business Plan',
          'Strategy Plan',
          'Project Update',
          'Market Research Findings',
          'Roadshow',
          'Customer Pitch',
          'Product Review',
        ],
      },
    ],
  },
  getters: {
    selectedAudience: (state) => state.select[combo.audience],
    selectedObjective: (state) => state.select[combo.objective],
    selectedType: (state) => state.selectedType[combo.type],
  },
  mutations: {
    setComponentKey(state, key) {
      state.componentKey = key;
    },
    selectAudience(state, audience) {
      state.select[combo.audience] = audience;
    },
    selectObjective(state, objective) {
      state.select[combo.objective] = objective;
    },
    selectType(state, type) {
      state.select[combo.type] = type;
    },
    setAudienceItems(state, audienceList) {
      state.topBarItems[combo.audience].dropDownItems = audienceList;
    },
  },
  actions: {
    selectAudience(context, audience) {
      this.$store.dispatch('users/setAudience', audience, { root: true });
      context.commit('selectAudience', audience);
    },
    selectObjective(context, objective) {
      context.commit('selectObjective', objective);
    },
    selectType(context, type) {
      context.commit('selectType', type);
    },
    setAudienceItems(context, audienceList) {
      context.commit('setAudienceItems', audienceList);
    },
    setComponentKey(context, key) {
      context.commit('setComponentKey', key);
    },
  },
};
