const almostThere = '/assets/videos/loaders/almostThere.mp4';
const highDemand = '/assets/videos/loaders/highDemand.mp4';
export const prezentBlast = '/assets/videos/loaders/prezentBlast.mp4';

export const LoaderState = {
  IDLE: 'idle',
  LOADING: 'loading',
  BLAST: 'blast',
};

export const prezMapper = {
  reviewCheckLoader: 'tcReviewDetails',
  acceptSuggestionLoader: 'tcAcceptSuggestions',
  synthesizeVideoLoading: 'synthesize',
  complyCheckLoader: 'tcAllSteps',
  makeCompliantLoader: 'tcMake100Compliant',
};

export const AG_LOADER = [
  {
    url: '/assets/videos/loaders/generate.mp4',
    loop: false,
  },
  {
    url: almostThere,
    loop: false,
  },
  {
    url: highDemand,
    loop: true,
  },
  {
    url: prezentBlast,
    loop: false,
  },
];

export const AG_SMART_ACTIONS_LOADER = [
  {
    url: '/assets/videos/loaders/generateSmartTools.mp4',
    loop: true,
  },
];

export const REDESIGN_LOADER = [
  {
    url: '/assets/videos/loaders/redesign.mp4',
    loop: false,
  },
  {
    url: almostThere,
    loop: false,
  },
  {
    url: highDemand,
    loop: true,
  },
  {
    url: prezentBlast,
    loop: false,
  },
];

export const REDESIGN_SMART_ACTIONS_LOADER = [
  {
    url: '/assets/videos/loaders/redesignSmartTools.mp4',
    loop: true,
  },
];

export const SYNTHESIZE_LOADER = [
  {
    url: '/assets/videos/loaders/synthesize.mp4',
    loop: false,
  },
  {
    url: almostThere,
    loop: false,
  },
  {
    url: highDemand,
    loop: true,
  },
  {
    url: prezentBlast,
    loop: false,
  },
];

export const SYNTHESIZE_SMART_ACTIONS_LOADER = [
  {
    url: '/assets/videos/loaders/synthesizeSmartTools.mp4',
    loop: true,
  },
];

export const TC_LOADER = [
  {
    url: '/assets/videos/loaders/TCAllSteps.mp4',
    loop: false,
  },
  {
    url: almostThere,
    loop: false,
  },
  {
    url: highDemand,
    loop: true,
  },
  {
    url: prezentBlast,
    loop: false,
  },
];

export const TC_SMART_ACTIONS_LOADER = [
  {
    url: '/assets/videos/loaders/TCSmartTools.mp4',
    loop: false,
  },
  {
    url: almostThere,
    loop: false,
  },
  {
    url: highDemand,
    loop: true,
  },
  {
    url: prezentBlast,
    loop: false,
  },
];

export const TC_MAKE_100_COMPLIANT_LOADER = [
  {
    url: '/assets/videos/loaders/TCMake100Compliant.mp4',
    loop: false,
  },
  {
    url: almostThere,
    loop: false,
  },
  {
    url: highDemand,
    loop: true,
  },
  {
    url: prezentBlast,
    loop: false,
  },
];

export const TC_ACCEPT_SUGGESTIONS_LOADER = [
  {
    url: '/assets/videos/loaders/TCAcceptSuggestions.mp4',
    loop: false,
  },
  {
    url: almostThere,
    loop: false,
  },
  {
    url: highDemand,
    loop: true,
  },
  {
    url: prezentBlast,
    loop: false,
  },
];

export const TC_REVIEW_DETAILS_LOADER = [
  {
    url: '/assets/videos/loaders/TCExploreDetails.mp4',
    loop: false,
  },
  {
    url: almostThere,
    loop: false,
  },
  {
    url: highDemand,
    loop: true,
  },
  {
    url: prezentBlast,
    loop: false,
  },
];

export const LoaderType = {
  generate: AG_LOADER,
  generateSmartTools: AG_SMART_ACTIONS_LOADER,
  redesign: REDESIGN_LOADER,
  redesignSmartTools: REDESIGN_SMART_ACTIONS_LOADER,
  synthesize: SYNTHESIZE_LOADER,
  synthesizeSmartTools: SYNTHESIZE_SMART_ACTIONS_LOADER,
  tcAllSteps: TC_LOADER,
  tcSmartTools: TC_SMART_ACTIONS_LOADER,
  tcMake100Compliant: TC_MAKE_100_COMPLIANT_LOADER,
  tcAcceptSuggestions: TC_ACCEPT_SUGGESTIONS_LOADER,
  tcReviewDetails: TC_REVIEW_DETAILS_LOADER,
};

export class Loader {
  constructor(type, stateIndex = 0) {
    this.type = type;
    this.assetIndex = 0;
    this.states = [LoaderState.IDLE, LoaderState.LOADING, LoaderState.BLAST];
    this.stateIndex = stateIndex;
  }

  getAssets() {
    return LoaderType[this.type];
  }

  getAsset() {
    return this.getAssets()[this.assetIndex];
  }

  getState() {
    return this.states[this.stateIndex];
  }

  hasBlast() {
    return this.getAssets().find((asset) => asset.url === prezentBlast);
  }

  showingBlast() {
    return this.getAsset().url === prezentBlast;
  }

  isIdle() {
    return this.getState() === LoaderState.IDLE;
  }

  goToBlast() {
    this.assetIndex = this.getAssets().findIndex(
      (asset) => asset.url === prezentBlast,
    );
    this.stateIndex = 2;
  }

  nextAsset() {
    if (this.showingBlast()) {
      this.nextState(0);
    } else {
      this.assetIndex = (this.assetIndex + 1) % this.getAssets().length;
    }
  }

  nextState(stateIndex) {
    this.stateIndex =
      stateIndex !== undefined
        ? stateIndex
        : (this.stateIndex + 1) % this.states.length;
    console.log('nextState', this.stateIndex, 'value sent', stateIndex);
    if (this.getState() === LoaderState.BLAST) {
      if (this.hasBlast()) {
        this.goToBlast();
      } else {
        this.nextState();
      }
    } else if (this.getState() === LoaderState.LOADING) {
      this.assetIndex = 0;
    }
  }
}
