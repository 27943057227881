import { API } from 'aws-amplify';
import {
  listQuizzess,
  itemStatusByUser,
  userLearnIndexByCompany,
} from '@/graphql/queries';

export const initialLearnState = {
  learningLevels: [],
  quizQuestions: [],
  quizzess: [],
  learningModules: [],
  learningTracks: [],
  learningVideoss: [],
  moduleItems: [],
  userModuleItemStatuss: [],
  userModuleStatuss: [],
  userLearnIndex: null,
  organizationLearnIndex: [],
  quizList: [],
  courseInfoForMatotmo: {},
};

export const learndata = {
  namespaced: true,
  state: initialLearnState,
  getters: {},
  mutations: {
    SET_COURSE_INFO_FOR_MATOMO(state, courseInfoForMatotmo) {
      state.courseInfoForMatotmo = {
        ...state.courseInfoForMatotmo,
        ...courseInfoForMatotmo,
      };
    },
    setQuizList(state, list) {
      state.quizList = list;
    },
    setUserLearnIndex(state, index) {
      state.userLearnIndex = index;
    },
    setOrganizationLearnIndex(state, index) {
      state.organizationLearnIndex = index;
    },
    // setLearningUserModuleStatus(state, payload) {
    //   const index = state.userModuleStatuss.findIndex(
    //     (item) => item.id === payload.item.id,
    //   );
    //   if (index > -1) {
    //     state.userModuleStatuss[index].status = payload.status;
    //     API.graphql(
    //       graphqlOperation(updateUserModuleStatus, {
    //         input: {
    //           id: payload.item.id,
    //           status: payload.status,
    //         },
    //       }),
    //     )
    //       .then((value) => console.log(value))
    //       .catch((err) => console.log(err));
    //   }
    // },
    setQuizzess(state, value) {
      state.quizzess = value;
    },
    setModuleItems(state, value) {
      state.moduleItems = value;
    },
    setUserModuleItemStatuss(state, value) {
      state.userModuleItemStatuss = value;
    },
    setUserModuleStatuss(state, value) {
      state.userModuleStatuss = value;
    },
  },
  actions: {
    setCourseInfoForMatomo({ commit }, courseInfoForMatotmo) {
      commit('SET_COURSE_INFO_FOR_MATOMO', courseInfoForMatotmo);
    },

    loadLearningData(context, payload) {
      return new Promise((resolve, reject) => {

        const learnQuiz = new Promise((resolve, reject) => {
          API.graphql({ query: listQuizzess })
            .then((learningdata) => {
              const quizzess = learningdata.data.listQuizzess.items;
              context.commit('setQuizzess', quizzess);
              resolve(quizzess);
            })
            .catch((err) => reject(err));
        });

        const learnStatus = new Promise((resolve, reject) => {
          API.graphql({
            query: itemStatusByUser,
            variables: { userID: payload.userID, limit: 500 },
          })
            .then((learningdata) => {
              let userModuleItemStatuss = [];
              if (
                learningdata.data.itemStatusByUser.items &&
                learningdata.data.itemStatusByUser.items.length > 0
              ) {
                userModuleItemStatuss =
                  learningdata.data.itemStatusByUser.items;
              }
              context.commit('setUserModuleItemStatuss', userModuleItemStatuss);
              resolve(userModuleItemStatuss);
            })
            .catch((err) => reject(err));
        });

        const learnIndex = new Promise((resolve, reject) => {
          API.graphql({
            query: userLearnIndexByCompany,
            variables: { companyID: payload.organizationID, limit: 1000 },
          })
            .then((result) => {
              const userLearnIndexs = result.data.userLearnIndexByCompany.items;
              const learnIndex = userLearnIndexs.find(
                (item) => item.id === payload.userID,
              );
              context.commit('setUserLearnIndex', learnIndex);
              // change this to org id
              const orgLearnIndex = result.data.userLearnIndexByCompany.items;
              context.commit('setOrganizationLearnIndex', orgLearnIndex);
              resolve(learnIndex);
            })
            .catch((err) => reject(err));
        });

        const learnQuizList = new Promise((resolve, reject) => {
          context.commit('setQuizList', quizJson.quizList);
          resolve(quizJson.quizList);
        });

        Promise.all([
          learnQuiz,
          learnStatus,
          learnIndex,
          learnQuizList,
        ])
          .then((values) => {
            resolve(values);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};