<template>
  <v-app>
    <v-main>
      <div class="signin-wrapper">
        <div class="logo">
          <img
            src="/assets/img/prezent_logo.svg"
            alt
            width="350px"
            height="44px"
          />
        </div>
        <v-form
          class="signin-form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent=""
        >
          <v-card class="signin-custom-card">
            <div>
              <div class="label">Login as user's email</div>
              <v-text-field
                v-model="email"
                :rules="nameRules"
                label="Enter your colleague email"
                required
                single-line
                color="#000"
                class="mb-4"
                id="username"
                :placeholder="'Enter your colleague email'"
                v-on:keyup.enter="onContinue"
              />
            </div>
            <v-btn
              class="primary-button"
              @click="onContinue"
              color="primary"
              rounded
              height="50"
              id="continue-superadminlogin-impersonate"
            >
              Continue
            </v-btn>
            <div class="impersonate-error" v-if="error">
              <span>{{ error }}</span>
            </div>
          </v-card>
        </v-form>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import { Auth } from 'aws-amplify';
import { AmplifyEventBus } from 'aws-amplify-vue';
import { isEmojiExist, isEmailValid } from '../components/utils';

export default {
  name: 'SuperAdminLogin',
  data() {
    return {
      error: '',
      isLoading: false,
      email: '',
      valid: true,
      nameRules: [
        (v) => isEmailValid(v) || this.$t('warnings.emailMustBeValid'),
        (v) => !isEmojiExist(v) || 'Emojis are not allowed',
        (v) => !!v || 'Email is required',
      ],
    };
  },
  methods: {
    onContinue() {
      // Below code impersonate's login with another user's email
      this.isLoading = true;
      Auth.signIn(this.email)
        .then((session) => {
          Auth.sendCustomChallengeAnswer(session, '6674')
            .then((user) => {
              console.log('Impersonating user', user);
              this.error = '';
              AmplifyEventBus.$emit('authState', 'signedIn');
              this.$router.push('/home/main');
            })
            .catch((err) => {
              this.error = err.message;
              console.log('Failed to send challenge', err);
            });
        })
        .catch((err) => {
          this.error = err.message;
          console.log('Failed to signin', err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.signin-custom-card {
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  padding: 50px 50px 15px 50px;
  text-align: center;

  .label {
    color: black;
    font-size: 20px;
    font-weight: normal;
    text-align: left;
  }

  .v-card__title,
  .v-card__subtitle {
    text-align: left;
  }

  .v-card__actions {
    justify-content: center;
    text-align: center;
  }
}

.impersonate-error {
  color: red;
  padding: 20px;
}

.signin-wrapper {
  height: fit-content;
  margin: auto;
  margin-top: 60px;
  text-align: center;
  width: 452px;

  .logo {
    margin-bottom: 0px !important;
  }
}
.signin-form {
  .label {
    text-align: left;
  }
}
</style>
