<template>
  <div class="what-to-upload-container">
    <v-btn class="close-btn" icon @click="close">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <h2
      class="heading"
      v-html="$t('templateDesign.whatToUploadModal.heading')"
    ></h2>
    <div class="steps-container">
      <div class="step-container" :key="index" v-for="(step, index) in steps">
        <div class="step-heading">
          <div class="circle" v-html="step.number"></div>
          <div v-html="step.title"></div>
        </div>
        <v-img
          :src="step.image"
          contain
          :aspect-ratio="16 / 9"
          class="step-img"
        />
      </div>
    </div>
    <div class="gs-privacy">
      <div>
        {{ $t('overnightPresentations.confidentiality') }}
        <a
          href="https://www.prezent.ai/security"
          target="_blank"
          class="primary--text"
          >{{ $t('fingerprint.learnMore') }}</a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WhatToUpload',
  props: {},
  data() {
    return {
      steps: {
        step1: {
          number: this.$t('templateDesign.whatToUploadModal.one'),
          title: this.$t('templateDesign.whatToUploadModal.step1'),
          image: '/assets/img/templateDesign/Slide 1.png',
        },
        step2: {
          number: this.$t('templateDesign.whatToUploadModal.two'),
          title: this.$t('templateDesign.whatToUploadModal.step2'),
          image: '/assets/img/templateDesign/Slide 2.png',
        },
        step3: {
          number: this.$t('templateDesign.whatToUploadModal.three'),
          title: this.$t('templateDesign.whatToUploadModal.step3'),
          image: '/assets/img/templateDesign/Slide 3.png',
        },
      },
    };
  },
  methods: {
    close() {
      this.$modal.hide('WhatToUpload');
    },
  },
};
</script>

<style lang="scss" scoped>
.what-to-upload-container {
  padding: 10px;
  background-color: white;

  .close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 10px 5px;
  }

  .steps-container {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    .step-container {
      width: 30%;
      height: 290px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .step-heading {
        display: flex;
        height: 45px;
        .circle {
          width: 25px;
          flex-shrink: 0;
          height: 25px;
          background-color: #1075ab;
          border-radius: 50%;
          color: white;
          font-size: 14px;
          display: flex;
          font-weight: 600;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
        }
      }
      .step-img {
        margin: 10px 0px 0px 10px;
      }
    }
  }
  .gs-privacy {
    margin-top: 8px;
    padding-bottom: 20px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #000000;

    a {
      color: #21a7e0;
      cursor: pointer;
    }
  }
  .gs-privacy-sub {
    margin-bottom: 2px;
  }
}
</style>
