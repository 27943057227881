import axios from 'axios';

// add https://cors-anywhere.herokuapp.com/ to the start of the url if you're having cors erros
class IpifyService {
  // eslint-disable-next-line class-methods-use-this
  async get() {
    try {
      const res = await Promise.any([
        axios.get('https://api64.ipify.org?format=json', {
          timeout: 3000,
        }),
        axios.get('https://api.ipify.org?format=json', {
          timeout: 3000,
        }),
      ]);
      return res.data.ip;
    } catch (e) {
      return '';
    }
  }
}

export default new IpifyService();
