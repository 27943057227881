<template>
  <v-app>
    <v-main>
      <div class="signin-wrapper">
        <div class="logo-wrapper">
          <img
            src="/assets/img/prezent_logo.svg"
            alt
            width="400px"
            height="44px"
          />
          <p class="header">Log in with SSO</p>
        </div>
        <v-form class="signin-form" ref="form" v-model="valid" lazy-validation>
          <v-card class="signin-custom-card">
            <div>
              <div class="label">Work email</div>
              <v-text-field
                v-model="ssoEmail"
                :rules="ssoEmailRules"
                label="Enter work email"
                required
                single-line
                color="#000"
                class="mb-4"
                id="ssoEmail"
              />
            </div>
            <div class="terms">
              <div>By clicking below, you agree to our</div>
              <div>
                <a href="https://prezent.ai/terms-of-service/" target="_blank"
                  >Terms of Service</a
                >
                <pre>&nbsp;and&nbsp;</pre>
                <a href="https://prezent.ai/privacy-policy/" target="_blank"
                  >Privacy Policy</a
                >.
              </div>
            </div>
            <v-btn
              @click="oauthLogin"
              :disabled="!ssoEmail || !ssoEmail.includes('@prezent.ai')"
              color="primary"
              rounded
              width="144"
              height="50"
              id="oauthLogin"
            >
              Log in
            </v-btn>
            <div v-if="error !== ''" class="error">
              {{ error }}
            </div>
          </v-card>
        </v-form>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { Auth } from 'aws-amplify';
import PrivacyPolicyModal from '../components/common/Profile/PrivacyPolicy.vue';
import { isEmailValid } from '../components/utils';

export default {
  name: 'SsoLogin',
  data() {
    return {
      valid: true,
      error: '',
      ssoEmail: '',
      ssoEmailRules: [
        (v) => isEmailValid(v) || this.$t('warnings.emailMustBeValid'),
        (v) => !!v || 'Email is required ',
        (v) => v && v.length >= 5,
        (v) =>
          v.includes('@prezent.ai') ||
          'Oops! Couldn’t find your corporate account ',
      ],
    };
  },
  methods: {
    async oauthLogin() {
      Auth.federatedSignIn();
    },

    showPrivacyModal() {
      this.$modal.show(PrivacyPolicyModal, {
        name: 'PrivacyPolicyModal',
        maxwidth: '300px',
        maxheight: '173px',
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss';

::v-deep .v-main__wrap {
  margin: auto;
  margin-top: 2% !important;
}

.signin-custom-card {
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  padding: 30px;
  text-align: center;

  .label {
    color: black;
    font-size: 20px;
    font-weight: normal;
    text-align: left;
  }
}

.signin-wrapper {
  height: fit-content;
  margin: auto;
  margin-top: 0px !important;
  text-align: center;
  width: 452px;

  .logo-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 50px;

    .header {
      font-size: 24px;
      font-weight: 600;
    }
  }

  .signin-form {
    .label {
      text-align: left;
    }
    button {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      letter-spacing: normal;
      line-height: normal;
      margin: 0 auto;
      margin-top: 38px;
      max-width: 144px;
      text-align: center;
      text-transform: none;
    }

    .error {
      color: red;
      background-color: transparent !important;
      border-color: transparent !important;
      margin-top: 20px;
    }
  }

  .terms {
    align-items: center;
    color: $darker-gray;
    display: block;
    margin-top: 20px;

    p {
      display: inline-flex;
      margin: 0;
      pre {
        font-family: inherit;
      }
    }
  }
}
</style>
