import { v4 as uuidv4 } from 'uuid';
import { addPrezentationDeck } from '../utils/api-helper';

export const UploadService = {
  async processUploadDeck(prezentationFinalData, sharedEntities, params) {
    const {
      selectedAudience,
      templateSelected,
      prezentationType,
      customPrezentationType,
      selectedSanitizedSlideList,
      addToPrezentations,
      addToSlides,
    } = prezentationFinalData;

    const payload = {
      outline_type: prezentationFinalData.type,
      outline_id: prezentationFinalData.outlineId,
      outline_description: prezentationFinalData.description,
      prezentation_name: prezentationFinalData.prezentationName,
      audience_id: prezentationFinalData.audienceID || selectedAudience?.id,
      audience_owner_id:
        prezentationFinalData.audienceOwnerID ||
        (selectedAudience &&
          selectedAudience.audience_type === 'private_audience')
          ? selectedAudience?.userID
          : null,
      audience_type: selectedAudience?.audienceType || 'Me',
      audience_num_id: prezentationFinalData.num_id || selectedAudience?.num_id,
      fingerprint_used:
        prezentationFinalData.fingerprintUsed || selectedAudience?.fingerPrint,
      theme_code: prezentationFinalData.theme || templateSelected?.code,
      sanitization_mode: prezentationFinalData.sanitizeType,
      add_to_prezentations: addToPrezentations,
      add_to_slides: addToSlides,
      shared_entities: sharedEntities,
      slides: selectedSanitizedSlideList.map((slide) => {
        let outline = slide?.outline;
        let category = slide?.category;
        if (!outline && !category && slide.ideas.length) {
          outline = slide.ideas[0]?.display_name;
          category = slide.ideas[0]?.category;
        }
        const slideMatchedBestPractices =
          prezentationFinalData.bestPractices?.slideToolTips.filter(
            (slidetooltip) =>
              slidetooltip.assetId === slide.pdf.s3_path &&
              slidetooltip.tips.length,
          );
        const slideMatchedBestPracticesInFormat =
          slideMatchedBestPractices &&
          slideMatchedBestPractices[0]?.tips.map((tip, index) => ({
            x: parseFloat(tip[`pro_tip${index}_pos`].split(',')[0]),
            y: parseFloat(tip[`pro_tip${index}_pos`].split(',')[1]),
            name: `#${index + 1}`,
            tooltip: tip[`pro_tip${index}`],
          }));
        const result = {
          pptx_file: slide.pptx.s3_path.startsWith('/')
            ? slide.pptx.s3_path.substring(1)
            : slide.pptx.s3_path,
          pdf_file: slide.pdf.s3_path.startsWith('/')
            ? slide.pdf.s3_path.substring(1)
            : slide.pdf.s3_path,
          slide_name: slide.name,
          business_ideas: slide.ideas.map((c) => c.category),
          node_count: slide.nodeCount,
          tags: slide.tags,
          theme_code: slide.theme_code,
          speaker_notes: slide.speaker_notes,
          outline,
          category,
          ml_detected_tags: {
            ...slide.ml_detected_tags,
            node: slide.nodeCount,
          },
          constructs: slide.constructs,
        };
        if (
          slideMatchedBestPracticesInFormat &&
          slideMatchedBestPracticesInFormat.length
        ) {
          result.best_practices = slideMatchedBestPracticesInFormat;
        }
        return result;
      }),
    };

    if (prezentationType?.type) {
      payload.outline_type = prezentationType.type;
      // storyline data indexed in typesense has storylineId intead of uuid
      payload.outline_id = prezentationType.uuid
        ? prezentationType.uuid
        : prezentationType.storylineId;
      payload.outline_description =
        prezentationType.type.toLowerCase() === 'custom'
          ? customPrezentationType
          : prezentationType.description;
      if (prezentationType.type.toLowerCase() === 'other') {
        payload.outline_type = customPrezentationType;
        payload.outline_id = uuidv4();
        payload.outline_description = 'Custom storyline';
        payload.is_outline_custom = true;
      }
    }

    // eslint-disable-next-line no-unreachable
    return await addPrezentationDeck(payload, params);
  },
};
