import createPersistedState from 'vuex-persistedstate';

export const uploadInitialState = {
  placement: '',
};

export const upload = {
  namespaced: true,
  state: {
    ...uploadInitialState,
  },
  getters: {
    getPlacement: (state) => state.placement,
  },
  mutations: {
    SET_PLACEMENT(state, position) {
      state.placement = position;
    },
  },
  actions: {
    setUploadPlacement({ commit }, placement) {
      commit('SET_PLACEMENT', placement);
    },
  },
  persist: createPersistedState({
    key: 'vuex_upload',
    paths: [],
  }),
};
