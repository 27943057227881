var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"application-main"},[_c('v-main',[(!_vm.isExistingUser)?_c('div',{staticClass:"registration-wrapper"},[_c('div',{class:("registration-content  " + (_vm.innerWidth < 500 ? 'mobile-content' : ''))},[(_vm.innerWidth > 500 && !_vm.isUserRegistered)?_c('div',{staticClass:"registration-content-left"},[_c('TrialSignUpAdvantages')],1):_vm._e(),(!_vm.isUserRegistered)?_c('div',{class:("registration-content-right " + (_vm.innerWidth < 500 ? 'mobile-right' : ''))},[_c('div',{staticClass:"registration-heading"},[_vm._v(" Sign up to Prezent and supercharge your presentation"),_c('br'),_vm._v(" productivity while streamlining agency costs ")]),_c('v-form',{ref:"registrationForm",staticClass:"registration-form",attrs:{"lazy-validation":""},model:{value:(_vm.isNotValid),callback:function ($$v) {_vm.isNotValid=$$v},expression:"isNotValid"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"label":"First name","rules":_vm.formRules.firstName,"required":"","color":"#fff","id":"first","loading":_vm.loadingWithExternalFpTestId},scopedSlots:_vm._u([{key:"message",fn:function(ref){
          var message = ref.message;
return [_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"14px"},attrs:{"color":"#fff"}},[_vm._v("mdi-alert-circle")]),_c('span',[_vm._v(" "+_vm._s(message))])]}}],null,false,440903354),model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('v-text-field',{staticClass:"mb-4",attrs:{"label":"Last name","rules":_vm.formRules.lastName,"required":"","id":"last","loading":_vm.loadingWithExternalFpTestId},scopedSlots:_vm._u([{key:"message",fn:function(ref){
          var message = ref.message;
return [_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"14px"},attrs:{"color":"#fff"}},[_vm._v("mdi-alert-circle")]),_c('span',[_vm._v(" "+_vm._s(message))])]}}],null,false,440903354),model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('v-text-field',{staticClass:"mb-4",attrs:{"label":_vm.isFocused || _vm.email
                  ? 'Work email'
                  : 'Work email (Ex. john.doe@company.com)',"rules":_vm.formRules.email,"required":"","id":"email","loading":_vm.loadingWithExternalFpTestId},on:{"focus":function($event){_vm.isFocused = true},"blur":_vm.handleAudienceEmailChange},scopedSlots:_vm._u([{key:"message",fn:function(ref){
                  var message = ref.message;
return [_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"14px"},attrs:{"color":"#fff"}},[_vm._v("mdi-alert-circle")]),_c('span',[_vm._v(" "+_vm._s(message))])]}}],null,false,440903354),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{staticClass:"mb-4",attrs:{"label":"Company name","rules":_vm.formRules.companyName,"required":"","id":"companyName","loading":_vm.loadingWithExternalFpTestId},scopedSlots:_vm._u([{key:"message",fn:function(ref){
                  var message = ref.message;
return [_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"14px"},attrs:{"color":"#fff"}},[_vm._v("mdi-alert-circle")]),_c('span',[_vm._v(" "+_vm._s(message))])]}}],null,false,440903354),model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}}),_c('v-autocomplete',{staticClass:"mb-4",attrs:{"items":_vm.companySizeOptions,"label":"Company size","rules":_vm.formRules.companySize,"required":"","id":"companySize","loading":_vm.loadingWithExternalFpTestId},scopedSlots:_vm._u([{key:"message",fn:function(ref){
                  var message = ref.message;
return [_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"14px"},attrs:{"color":"#fff"}},[_vm._v("mdi-alert-circle")]),_c('span',[_vm._v(" "+_vm._s(message))])]}}],null,false,440903354),model:{value:(_vm.companySize),callback:function ($$v) {_vm.companySize=$$v},expression:"companySize"}}),_c('v-text-field',{staticClass:"mb-4",attrs:{"label":"Phone","rules":_vm.formRules.phone,"required":"","id":"phone","loading":_vm.loadingWithExternalFpTestId},scopedSlots:_vm._u([{key:"message",fn:function(ref){
                  var message = ref.message;
return [_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"14px"},attrs:{"color":"#fff"}},[_vm._v("mdi-alert-circle")]),_c('span',[_vm._v(" "+_vm._s(message))])]}}],null,false,440903354),model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('v-autocomplete',{staticClass:"mb-4",attrs:{"label":"Country","rules":_vm.formRules.country,"required":"","id":"country","item-text":"name","items":_vm.countries,"return-object":"","loading":_vm.loadingWithExternalFpTestId},scopedSlots:_vm._u([{key:"message",fn:function(ref){
                  var message = ref.message;
return [_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"14px"},attrs:{"color":"#fff"}},[_vm._v("mdi-alert-circle")]),_c('span',[_vm._v(" "+_vm._s(message))])]}}],null,false,440903354),model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('div',{staticClass:"terms"},[_c('p',[_vm._v(" By clicking below, you agree to our "),_c('a',{attrs:{"href":"https://prezent.ai/terms-of-service/","target":"_blank"}},[_vm._v("Terms of Service")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://prezent.ai/privacy-policy/","target":"_blank"}},[_vm._v("Privacy Policy")]),_vm._v(". ")])]),_c('div',{staticStyle:{"text-align":"left"}},[_c('v-btn',{attrs:{"disabled":!_vm.isNotValid ||
                  !_vm.firstName ||
                  !_vm.lastName ||
                  !_vm.email ||
                  !_vm.companyName ||
                  !_vm.companySize ||
                  !_vm.phone ||
                  !_vm.country ||
                  _vm.country === '',"color":"primary","rounded":"","min-width":"144","height":"50","id":"submit","loading":_vm.isLoading},on:{"click":_vm.submit}},[_vm._v(" Continue ")]),_c('p',[_vm._v("No credit card required")]),(_vm.error !== '')?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()],1)],1)],1):_c('div',{staticClass:"confirmation-wrapper"},[_c('div',{staticClass:"confirmation"},[_c('v-img',{attrs:{"src":"/assets/img/feather_check-circle.svg","max-width":"60px","max-height":"60px","alt":""}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.getMessage)}}),(_vm.isWaitListCompany)?[(_vm.loginLoading)?_c('clip-loader',{staticClass:"cliploader",attrs:{"loading":_vm.loginLoading,"color":"#21a7e0","width":'60',"height":'60',"size":'50px'}}):_vm._e()]:_vm._e()],2)])])]):_vm._e(),(_vm.isExistingUser)?_c('div',{staticClass:"existing-user"},[_c('div',[_c('v-img',{attrs:{"src":"/assets/img/account-already-exists.svg","alt":"","max-width":"300px","max-height":"300px"}}),_c('p',[_vm._v("Account already exists")]),_c('p',[_vm._v("Redirecting you to login...")]),(_vm.loginLoading)?_c('clip-loader',{staticClass:"cliploader",attrs:{"loading":_vm.loginLoading,"color":"#21a7e0","width":'60',"height":'60',"size":'50px'}}):_vm._e()],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }