import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { getFileURL } from '@/utils/calyrex';
import { v4 as uuidv4 } from 'uuid';
import { checkGSStatus, generateSlideCompleteLog, generateSlideRequest, generateSlideRequestLog, getSlideDataForDetailView, getSlideDetail } from '../../utils/api-helper';


Vue.use(Vuex);

const generateSlidesInitialState = () => {
    return {
        isSmartToolsOpen : false,
        rightPanelComponent: null,
        showRightPanel: false,
        promptText: '',
        promptLoadIndex: 1,
        filename: '',
        title: '',
        isTemplateDetected: false,
        mlDetectedTemplate: '',
        isTemplateDefault: true,
        addToSlideLibraryResponse: {},
        promptSubIndex: null,
        deckContext: {
          texts: {text: ''},
          links: [],
          files: [],
          linkAccess: false,
          extract_graph_data: false,
        }
    }
}

export const generateSlides = {
    namespaced: true,
    state: {
        ...generateSlidesInitialState(),
    },
    getters: {
        isPrezentation(state){
          return state.slides.length >= 2
        },
        getDeckContext: (state) => state.deckContext ? state.deckContext : { text: '', links: '', files: [] },
        // disableActions(state){
        //     return state.generationInProgress || !state.isSlideGenerated;
        // },
        // generateLoading(state){
        //     return state.isGeneratingSlide;
        // },
        // generateLandscape(state){
        //     return state.magicFillEnabled ? state.magicFillLandscape : state.slideLandscape;
        // },
        // generateThumbnail(state){
        //     return state.magicFillEnabled ? state.magicFillThumbnail : state.thumbnail;
        // },
        // generatedSlide(state) {
        //     return state.slideGenerated;
        // },
        // getRelatedSlides(state){
        //     return state.relatedSlides;
        // },
        // getFilePath(state){
        //     return state.magicFillEnabled ? state.magicFillPath : state.slidePath;
        // },
        // getFileBucket(state){
        //     return state.magicFillEnabled ? state.magicFillBucket : state.slideBucket;
        // },
        // isReplacedVisuals(state){
        //   return state.replaceVisuals || state.is_image_replaced
        // },
        // getContextualPrompt(state) {
        //   return  state.slideGenerated ? 
        //   (['slides','prezentation','deck','presentation'].find(i => {
        //     return state.promptText.toLowerCase().includes(i)
        //   }) ? 'NO_MULTIPLE_SLIDES' : null) ||
        //   (state.audience && state.audience.audience_contextual_prompt && state.audience.audience_contextual_prompt.context) ||
        //   state.node_contextual_prompt ||
        //   state.template_contextual_prompt
        //   : ''
        // },
        // getGenerateLog(state){
        //     return {
        //         type: state.magicFillEnabled ? 'MagicFill' : 'Generate',
        //         translatedPrompt: state.promptText,
        //         audienceID: state.audience.id,
        //         audienceType: state.audience.audienceType,
        //         isAudienceDetected: state.audience.model_detected,
        //         isAudienceDefault: state.audience.is_audience_default,
        //         mlDetectedAudience: state.audience.ml_detected_audience,
        //         audienceRemarks: state.audience.audience_remarks,
        //         templateCode: state.template,
        //         isTemplateteDetected: state.isTemplateDetected,
        //         mlDetectedTemplate: state.mlDetectedTemplate,
        //         isTemplateDefault: state.isTemplateDefault,
        //         category: state.slideGenerated.categories[0],
        //         construct: state.slideGenerated.constructs[0],
        //         assetID: state.slideGenerated.unique_id,
        //         s3: state.magicFillEnabled ? state.magicFillBucket : state.slideBucket,
        //         fileName: state.magicFillEnabled ? state.magicFillPath : state.slidePath,
        //         thumbnail: state.magicFillEnabled ? state.magicFillThumbnail : state.thumbnail,
        //         node: state.nodeCount,
        //         secondaryPrompt: undefined,
        //         image: state.image_key,
        //         title: state.title,
        //         slideName: state.filename,
        //       }
        // },
        // getImageDetected: (state) => {
        //   if(state.is_image_replaced && state.image_key){
        //     return `${state.image_key.charAt(0).toUpperCase()}${state.image_key.substring(1)}`
        //   } else {
        //     return ''
        //   }
        // },
        // getAddToSlideLibraryResponse: (state) => state.addToSlideLibraryResponse ? state.addToSlideLibraryResponse : {},
    },
    mutations: {
        SET_DECK_CONTEXT(state, deckContext) {
          state.deckContext = deckContext;
        },
        SET_ADD_TO_SLIDE_LIBRARY_RESPONSE(state, addToSlideLibraryResponse) {
            state.addToSlideLibraryResponse = addToSlideLibraryResponse;
        },
        RESET_STATE(state){
            Object.assign(state, generateSlidesInitialState())
            console.log(state, 'resetting');
        },
        SET_SMART_TOOLS_OPEN(state, value){
            state.isSmartToolsOpen = value;
            if(!value){
                state.showRightPanel = false;
                state.rightPanelComponent = null
            }
        },
        SET_RIGHT_PANEL_COMPONENT(state, component){
            state.rightPanelComponent = component;
            state.showRightPanel = component ? true : false;
        },
        // SET_IS_SLIDE_GENERATED(state, val){
        //     state.isSlideGenerated = val;
        //     if(!val){
        //       state.replaceVisuals = false;
        //     }
        // },
        // SET_THUMBNAIL(state,val){
        //     if(state.magicFillEnabled){
        //         state.magicFillThumbnail = val.thumbnail;
        //         state.magicFillLandscape = val.landscape;
        //     } else {
        //         state.magicFillThumbnail = '';
        //         state.magicFillLandscape = '';
        //         state.thumbnail = val.thumbnail;
        //         state.slideLandscape = val.landscape
        //     }
        // },
        SET_PROMPT_LOAD_INDEX(state, val){
            if(val === 1){
                state.isGeneratingSlide = true;
            } else if(val === 3){
                setTimeout(() => {
                    state.isGeneratingSlide = false;
                }, 3500);
            }
            state.promptLoadIndex = val;
        },
        // SET_FILE_PATH(state, val){
        //     if(state.magicFillEnabled){
        //         state.magicFillBucket = val.bucket;
        //         state.magicFillPath = val.path;
        //     } else {
        //         state.magicFillBucket ='';
        //         state.magicFillPath= '';
        //         state.slideBucket = val.bucket;
        //         state.slidePath = val.path;
        //     }
        // },
        SET_FILE_CONTENT(state, val){
            state.filename = val.filename;
            state.title = val.title;
            state.sec_heading_content = val.sec_heading_content;
        },
        SET_GENERATED_SLIDE(state, val){
            state.slideGenerated = val
        },
        SET_RELATED_SLIDES(state, val){
            state.relatedSlides = val;
        },
        SET_SIBLING_NODES(state, val){
            state.siblings = val.sort((a,b) => {
                return parseInt(a.asset.prefs.node, 10) - parseInt(b.asset.prefs.node, 10)
            });
        },
        // SET_MAGIC_FILL(state, val){
        //     state.magicFillEnabled = val
        // },
        SET_PROMPT_TEXT(state, val){
            state.promptText= val || state.promptText;
        },
        SET_TEMPLATE(state, val){
          if(val){
            state.template = val.template;
            state.isTemplateDetected = val.isTemplateDetected || state.isTemplateDetected
            state.mlDetectedTemplate = val.mlDetectedTemplate || state.mlDetectedTemplate
            state.isTemplateDefault = val.isTemplateDefault || state.isTemplateDefault
            state.template_contextual_prompt = val.template_contextual_prompt;
          } else { 
            state.template = null;
            state.isTemplateDetected = null;
            state.mlDetectedTemplate = null;
            state.isTemplateDefault = null;
            state.template_contextual_prompt = null;
          }
        },
        SET_AUDIENCE(state, val){
          if(val){
            if(val.type === 'audienceChange'){
              state.audience = {
                ...state.audience,
                ...val,
                audience_contextual_prompt: null,
              }
            } else {
              state.audience = {
                ...val
              }
            }
          } else {
            state.audience= null
          }
        },
        // SET_GENERATE_RESPONSE(state, val){
        //     if(state.magicFillEnabled){
        //         state.magicFillResponse = val;
        //     } else {
        //         state.magicFillResponse = null;
        //         state.generateResponse = val;
        //     }
        // },
        // SET_NODE_COUNT(state, val){
        //     state.nodeCount =  val.nodeCount;
        //     state.node_contextual_prompt = val.node_contextual_prompt
        // },
        // SET_GENERATE_LOADING(state,val){
        //   state.isGeneratingSlide = val;
        // },
        // SET_IMAGE_DETECTION(state, val){
        //   state.is_image_replaced = val.is_image_replaced
        //   state.image_key = val.image_key
        // },
        // SET_REPLACE_VISUALS(state, val){
        //   state.replaceVisuals = val
        // },
        SET_PROMPT_SUB_DIVISION(state, val){
          state.promptSubIndex = val;
        }
    },
    actions: {
      setDeckContext({ commit }, val) {
        commit('SET_DECK_CONTEXT', val);
      },
      setReplaceVisuals({commit}, val){
        commit('SET_REPLACE_VISUALS', val);
      },
      setImageDetection({commit}, val){
        commit('SET_IMAGE_DETECTION', val);
      },
        setAddToSlideLibraryResponse({ commit }, addToSlideLibraryResponse) {
            commit('SET_ADD_TO_SLIDE_LIBRARY_RESPONSE', addToSlideLibraryResponse);
        },
        setGenerateLoading({commit},val){
          commit('SET_GENERATE_LOADING', val);
        },
        setTemplate({commit}, val){
            commit('SET_TEMPLATE', val);
        },
        setFilePath({commit}, val){
            commit('SET_FILE_PATH', val);
        },
        setThumbnail({commit}, val){
            commit('SET_THUMBNAIL', val);
        },
        setNodeCount({commit}, val){
            commit('SET_NODE_COUNT', val);
        },
        setFileContent({commit}, val){
            commit('SET_FILE_CONTENT', val)
        },
        setSmartToolsOpen({ commit }, value){
            commit('SET_SMART_TOOLS_OPEN', value)
        },
        setGenerateSlide({commit}, val){
            commit('SET_GENERATED_SLIDE', val);
        },
        setRightPanelComponent({commit}, component){
            commit('SET_RIGHT_PANEL_COMPONENT', component)
        },
        setIsSlideGenerated({commit}, val){
            commit('SET_IS_SLIDE_GENERATED', val)
        },
        setPromptText({commit}, val){
            commit('SET_PROMPT_TEXT', val);
        },
        setGenerateResponse({commit}, val){
            commit('SET_GENERATE_RESPONSE', val);
        },
        setPromptLoadIndex({commit}, val){
            commit('SET_PROMPT_LOAD_INDEX', val);
        },
        toggleMagicFill({commit}, val){
            commit('SET_MAGIC_FILL', val);
        },
        showLoader({commit, state}, val) {
            commit('SET_PROMPT_SUB_DIVISION', val);
            commit('SET_PROMPT_LOAD_INDEX', 1)
            setTimeout(() => {
              if (state.magicFillEnabled && state.magicFillLandscape || !state.magicFillEnabled && state.slideLandscape) {
                commit('SET_PROMPT_LOAD_INDEX', 3)
              } else {
                commit('SET_PROMPT_LOAD_INDEX', 2)
              }
            }, 16000);
          },
        setAudience({commit}, audience){
          commit('SET_AUDIENCE', audience);
        },
        resetState({commit}){
            commit('RESET_STATE')
        },
        setRelatedSlides({commit}, val){
            commit('SET_RELATED_SLIDES', val)
        },
        setSiblingNodes({commit}, val){
            commit('SET_SIBLING_NODES',val)
        }
    },
    persist: createPersistedState({
        key: 'vuex_generateSlides',
        paths: [],
      }),
}