<template>
  <v-app>
    <v-main class="main-wrapper">
      <div class="forgot-password-wrapper">
        <div class="logo">
          <img
            src="assets/img/prezent_stack.svg"
            alt
            width="350px"
            height="44px"
          />
        </div>
        <v-form ref="forgotPasswordForm" @submit.prevent="onSubmitUsername">
          <v-card class="forgot-password-card elevation-0">
            <v-card-title class="mb-3">
              <span class="headline">Password Reset</span>
            </v-card-title>
            <v-card-subtitle>
              <span class="sub-title"
                >You will receive a verification code.</span
              >
            </v-card-subtitle>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12" class="px-0">
                    <div class="label">Username</div>
                    <v-text-field
                      placeholder="Enter your username"
                      :rules="usernameRules"
                      v-model="username"
                      id="username"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <div class="mb-2 actions">
                <v-btn
                  rounded
                  type="submit"
                  color="primary"
                  min-width="333"
                  height="50"
                  :loading="isSendingResetLink"
                  :disabled="!username"
                  class="mb-4"
                >
                  Email Verification Code
                </v-btn>
                <div class="mr-4">
                  <router-link to="/signin"> Back to Login </router-link>
                </div>
              </div>
            </v-card-actions>
            <div class="try-for-free">
              <div>
                <p>Don’t have an account?&nbsp;</p>
                <a :href="getTryForFreeLink()" @click="tryForFreeClick()"
                  >Try for free</a
                >
              </div>
            </div>
          </v-card>
          <div v-if="error" class="error">
            {{ error }}
          </div>
        </v-form>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { Auth } from 'aws-amplify';
import { SSOConfig } from '../runtimeConfig';
import { checkSSOUser } from '../utils/api-helper';
import { isEmojiExist, isEmailValid } from '../components/utils';
import { trackGeneralEvents } from '../components/common/Analytics/GeneralEvents';
import {
  TD_PLACEMENT,
  TD_USER,
  NA,
} from '../components/common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'ForgotPassword',
  props: {
    msg: String,
  },
  data() {
    return {
      valid: true,
      error: '',
      username: '',
      usernameRules: [
        (v) => isEmailValid(v) || this.$t('warnings.emailMustBeValid'),
        (v) => !isEmojiExist(v) || 'Emojis are not allowed',
        (v) => !!v || 'username is required',
      ],
      isSent: false,
      isSendingResetLink: false,
    };
  },
  methods: {
    tryForFreeClick() {
      trackGeneralEvents.generalTryForFreeClick(
        {},
        {
          [TD_USER]: this.username.length ? this.username : NA,
          [TD_PLACEMENT]: 'Reset password screen',
        },
      );
    },
    async onSubmitUsername() {
      if (!this.$refs.forgotPasswordForm.validate()) return;
      this.isSendingResetLink = true;

      this.username = `${this.username.trim().toLowerCase()}`;
      // Check if the user is sso user and display error, else continue
      const user = await checkSSOUser(this.username);
      if (user.data.ssoUser) {
        this.error =
          'Congratulations! Your Prezent login is already SSO-enabled. \nPlease update your corporate password in order to move ahead.';
        this.isSendingResetLink = false;
      } else {
        Auth.forgotPassword(this.username)

          .then((data) => {
            this.isSendingResetLink = false;
            console.log(data);
            this.error = '';
            this.$router.push(`/reset-password?username=${this.username}`);
          })
          .catch((err) => {
            this.isSendingResetLink = false;
            console.error(err);
            this.error = err.message;
          });
      }
    },
    getTryForFreeLink() {
      const appDomain = process.env.VUE_APP_DOMAIN;
      return `${appDomain}/trialregistration`;
    },
  },
  beforeMount() {
    if (SSOConfig.isSSOApp) {
      this.$router.push('/signin', () => {});
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
::v-deep .v-main__wrap {
  margin-top: 5% !important;
}
.forgot-password-wrapper {
  height: fit-content;
  margin: auto;
  width: 28.25rem;

  .logo {
    align-items: center;
    display: flex;
    justify-content: center;
    // margin-left: 3.125rem;
  }

  .forgot-password-card {
    padding: 1.875rem 2.1875rem;
    text-align: center;
    .label {
      color: black;
      font-size: 1.25rem;
      font-weight: normal;
      text-align: left;
    }

    .v-card__title,
    .v-card__subtitle {
      text-align: left;
    }

    .headline {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .sub-title {
      font-size: 1.25rem;
      font-weight: normal;
    }
    .v-card__actions {
      justify-content: center;

      button {
        font-size: 1.5rem;
        font-stretch: normal;
        font-style: normal;
        font-weight: bold;
        letter-spacing: normal;
        line-height: normal;
        text-transform: none;
      }

      a {
        font-size: 1rem;
        text-decoration: none;
      }
    }
  }
  .error {
    background-color: transparent !important;
    border-color: transparent !important;
    color: red;
    text-align: center;
    white-space: pre;
  }
  .try-for-free {
    align-items: center;
    color: $gray-dark-login-text;
    display: block;

    p {
      display: inline-flex;
      margin: 0;
      pre {
        font-family: inherit;
      }
    }
  }
}
</style>
