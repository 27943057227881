import Cry from 'crypto';

const APP_CALYREX_SECRET_KEY = 'Xdz8JYFkkjMhg2AJmSlCfw==';
const APP_CALYREX_DOMAIN = 'prez-magik-images.prezent.ai';
const DOMAIN = `https://${APP_CALYREX_DOMAIN}`;

const generateAccessKey = (ts, user, uri, ip) => {
  const plainText = [ts, user, uri, ip].join('|');
  return new Promise((resolve) => {
    const iv = Cry.randomBytes(16).toString('hex').substr(0, 16);
    const cipher = Cry.createCipheriv(
      'AES-128-CBC',
      Buffer.from(APP_CALYREX_SECRET_KEY, 'base64'),
      iv,
    );
    const encrypted =
      iv +
      Buffer.concat([cipher.update(plainText), cipher.final()]).toString(
        'base64',
      );
    resolve(encodeURIComponent(encrypted));
  });
};

export const getFileURLDownload = async (user, uri, ip) => {
  const ts = Math.floor(Date.now() / 1000).toString();
  const accessKey = await generateAccessKey(ts, user, uri, ip);
  return `${DOMAIN}${uri}?accesskey=${accessKey}`;
};
