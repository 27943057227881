import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);
export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#21a7e0',
        secondary: '#90a4ae',
        accent: '#b3e5fc',
        error: '#f70000',
        info: '#01579b',
        sucess: '#80d8ff',
        warning: '#cfd8dc',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});
