export const utf8ToIso = (input) => {
  let output = '';
  for (let i = 0; i < input.length; i++) {
    const charCode = input.charCodeAt(i);
    if (charCode <= 255) {
      output += String.fromCharCode(charCode);
    }
  }
  return output;
};

export const getAccessLevelFormatedStrings = ({ company, team, user }) => {
  const edit = `c__${company.num_id}__e t__${team.num_id}__e u__${user.num_id}__e`;
  const reviewer = `c__${company.num_id}__r t__${team.num_id}__r u__${user.num_id}__r`;
  const viewer = `c__${company.num_id}__v t__${team.num_id}__v u__${user.num_id}__v`;
  const restricted = `c__${company.num_id}__s t__${team.num_id}__s u__${user.num_id}__s`;
  return [restricted, viewer, reviewer, edit];
};
