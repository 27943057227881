<template>
  <v-app class="vApp">
    <template v-if="!isFailed">
      <div class="customRegistration">
        <div class="header">
          <a href="https://prezent.ai">
            <v-img
              src="/assets/img/prezent_logo.svg"
              alt
              width="200px"
              height="44px"
            />
          </a>
        </div>

        <div class="content">
          <SignUp v-if="currentPage === 'signup'" />
          <SubmitPayment
            v-if="currentPage === 'payment'"
            :customRegistrationId="customRegistrationId"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="loading-wrapper">
        <div class="logo-loading">
          <v-img src="/assets/img/prezent_stack.svg" alt width="248px" />
        </div>
        <p class="expired-link">Redirecting you to teams.prezent.ai...</p>
        <clip-loader
          :loading="true"
          :color="`#21a7e0`"
          :width="'60'"
          :height="'60'"
          :size="'50px'"
          class="cliploader"
        />
      </div>
    </template>
  </v-app>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import SignUp from './components/CustomRegistration/SignUp.vue';
import SubmitPayment from './components/CustomRegistration/SubmitPayment.vue';

export default {
  name: 'CustomRegistration',
  props: {},
  components: {
    SignUp,
    SubmitPayment,
    ClipLoader,
  },
  data() {
    return {
      isFailed: false,
    };
  },
  computed: {
    currentPage() {
      return this.$route.query.id ? 'payment' : 'signup';
    },
    customRegistrationId() {
      return this.$route.query.id || '';
    },
  },
  methods: {
    // registrationFailed() {
    //   this.isFailed = true;
    //   setTimeout(() => {
    //     this.$router.push('/signin', () => {});
    //   }, 3000);
    // },
  },
};
</script>

<style lang="scss" scoped>
.customRegistration {
  overflow: auto;
  padding: 1rem 2rem;
}
.loading-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
}

.expired-link {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.logo-loading {
  padding-bottom: 20px;
}
</style>
