import { Storage } from 'aws-amplify';

export const categories = {
  namespaced: true,
  state: {
    categoryList: null,
  },
  getters: {
    getCategoryList: (state) => state.categoryList,
  },
  mutations: {
    setCategoryList(state, list) {
      state.categoryList = list;
    },
  },
  actions: {
    // not using this function any where in the project.hence commenting it out
    // loadCategories(context) {
    //   const categoryKey = 'category.txt';
    //   Storage.get(categoryKey, { download: true }).then(async (data) => {
    //     const blob = await data.Body.text();
    //     // let list = blob.toString()
    //     context.commit('setCategoryList', JSON.parse(blob).category);
    //   });
    // },
  },
};
