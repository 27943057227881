/* eslint-disable camelcase */
const aws_project_region = process.env.VUE_APP_AWS_PROJECT_REGION;
const aws_cognito_region = process.env.VUE_APP_AWS_COGNITO_REGION;
const aws_cognito_endpoint = process.env.VUE_APP_AWS_COGNITO_ENDPOINT;
const aws_user_pools_id = process.env.VUE_APP_AWS_USER_POOLS_ID;
const aws_user_pools_web_client_id =
  process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID;
const aws_appsync_graphqlEndpoint =
  process.env.VUE_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT;
const aws_appsync_region = process.env.VUE_APP_AWS_APPSYNC_REGION;
const aws_appsync_authenticationType =
  process.env.VUE_APP_AWS_APPSYNC_AUTHENTICATION_TYPE;
const aws_user_files_s3_bucket = process.env.VUE_APP_AWS_USER_FILES_S3_BUCKET;
const aws_user_files_s3_bucket_region =
  process.env.VUE_APP_AWS_USER_FILES_S3_BUCKET_REGION;
const aws_prezent_api = process.env.VUE_APP_AWS_PREZENT_API;
const aws_assets_distribution = process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
const aws_prezentation_distribution =
  process.env.VUE_APP_AWS_PREZENTAION_DISTRIBUTION;
const image_replacemt_source =
  process.env.VUE_APP_IMAGE_REPLACEMENT_SOURCE || 'Adobe';

/*
  Config for freepik proxy url.
  Use default freepik domain for images when config is not present.
*/
const freepik_images_proxy_url =
  process.env.VUE_APP_FREEPIK_IMAGES_PROXY_URL || 'img.b2bpic.net';

const isSSOApp = process.env.VUE_APP_IS_SSO_APP_DEPLOYMENT;
const ssoDomain = process.env.VUE_APP_SSO_COGNITO_DOMAIN;
const aapDomain = process.env.VUE_APP_DOMAIN;

const calyrex_bucket_region = process.env.VUE_APP_CALYREX_S3_BUCKET_REGION;
const calyrex_bucket_name = process.env.VUE_APP_CALYREX_S3_BUCKET;
const slides_bucket_name = process.env.VUE_APP_SLIDES_S3_BUCKET;
const typesense_host = process.env.VUE_APP_TYPESENSE_HOST;
const typesense_port = process.env.VUE_APP_TYPESENSE_PORT;
const typesense_protocol = process.env.VUE_APP_TYPESENSE_PROTOCOL;
const external_service_bucket = process.env.VUE_APP_EXTERNAL_SERVICE_BUCKET;
const mvp_assets_bucket = process.env.VUE_APP_MVP_ASSETS_BUCKET;
const merge_slides_service_url = process.env.VUE_APP_MERGE_SLIDE_SERVICE_URL;

const SSOConfig = {
  isSSOApp: isSSOApp === 'true', // required since env is string
  redirectSignIn: `${location.origin}/redirect`,
  // Setting domain specific logout only for redirecting at time of login to main page
  redirectSignOut: `${
    location.hostname === 'localhost' ? location.origin : aapDomain
  }/signin`,
};

const calyrexConfig = {
  calyrex_bucket_region,
  calyrex_bucket_name,
  slides_bucket_name,
};

let oauth = {};
if (isSSOApp) {
  oauth = {
    domain: ssoDomain,
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: SSOConfig.redirectSignIn,
    redirectSignOut: SSOConfig.redirectSignOut,
    clientId: aws_user_pools_web_client_id,
    responseType: 'token',
  };
}

const awsConfig = {
  aws_project_region,
  aws_cognito_region,
  aws_user_pools_id,
  aws_user_pools_web_client_id,
  aws_appsync_graphqlEndpoint,
  aws_appsync_region,
  aws_appsync_authenticationType,
  aws_user_files_s3_bucket,
  aws_user_files_s3_bucket_region,
  endpoint: aws_cognito_endpoint,
  oauth,
  // Auth: {
  //   identityPoolId: aws_cognito_identity_pool_id,
  //   region: aws_project_region, // REQUIRED - Amazon Cognito Region
  //   userPoolId: aws_user_pools_id, //OPTIONAL - Amazon Cognito User Pool ID
  //   userPoolWebClientId: aws_user_pools_web_client_id, //OPTIONAL - Amazon Cognito Web Client ID,
  //   oauth,
  // },
  API: {
    endpoints: [
      {
        name: 'PREZENT_API',
        endpoint: aws_prezent_api,
        region: aws_project_region,
        paths: ['/'],
      },
    ],
  },
  Analytics: {
    disabled: true,
  },
};

const miscConfig = {
  aws_assets_distribution,
  aws_prezentation_distribution,
  image_replacemt_source,
  freepik_images_proxy_url,
};

const typeSenseConfig = {
  typesense_host,
  typesense_port,
  typesense_protocol,
};

const mergeSlideConfig = {
  merge_slides_service_url,
  external_service_bucket,
  mvp_assets_bucket,
  calyrex_bucket_name,
  slides_bucket_name,
};

export {
  awsConfig,
  SSOConfig,
  miscConfig,
  calyrexConfig,
  typeSenseConfig,
  mergeSlideConfig,
};
