/* eslint-disable */
import { API, graphqlOperation, Storage } from 'aws-amplify';
import API2, {
  API_PRODUCT_SERVICES,
  TC_COMPLY_API_SERVICES,
  getLoggedInUserToken,
} from './api';
import { v4 as uuidv4 } from 'uuid';
import {
  prezentationByOwner,
  prezentationByCompany,
  mergeslides,
  listThemess,
  listAudiences,
  userByCompany,
  performSearch,
  getRelatedSlides,
} from '../graphql/queries';
import axios from 'axios';
import {
  deletePrezentation as doDelete,
  createPrezentation,
  updatePrezentation,
} from '../graphql/mutations';
import { miscConfig } from '../runtimeConfig';
import { toNumber } from 'lodash';
import { GraphQLService } from '../services/GraphQLService';
import { utf8ToIso } from './utility';
import { getFileURL } from '@/utils/calyrex';
import { getTypeSenseAPIKey } from '../pages/components/TypeSenseInit';

// importing store in this utils file to access vuex state variables
import store from '@/store';

const UPDATE_USER_ATTRIBUTE_ACTIONS = {
  increment: 'increment',
};
const UPDATE_USER_ATTRIBUTE_FIELDS = {
  downloadUsed: 'downloadUsed',
  countSearches: 'countSearches',
};

const ADOBE_API_KEY = 'f3963f99ad644828a2b82c21cb927d2e';

/**
 *
 * @param {string} action
 * @param {string} field
 * @param {number} modifier
 * @returns
 */
export const updateUserAttributes = async function (action, field, modifier) {
  const path = '/users/attribute';
  return await API2.put(path, { action, field, modifier });
};

/**
 * Reusable polling function with abort controller
 * @param {Function} fn - Function that returns a promise
 * @param {Function} validate - Function to validate the response
 * @param {number} interval - Interval between requests (in milliseconds)
 * @param {number} retries - Maximum retries to keep polling (number of retries)
 * @param {AbortSignal} signal - Signal to cancel the polling
 * @returns {Promise} - Resolves with the successful response or rejects with an error
 */
export function poll(fn, validate, interval, retries, signal, callback) {
  let attempt = 0;

  const checkCondition = (resolve, reject) => {
    if (signal?.aborted) {
      reject(new Error('Polling aborted'));
      return;
    }

    fn()
      .then(async (response) => {
        if (callback) {
          await callback(response);
        }
        if (validate(response)) {
          resolve(response);
        } else if (attempt <= retries) {
          setTimeout(() => checkCondition(resolve, reject), interval);
          attempt++;
        } else {
          reject(new Error('Polling timed out'));
        }
      })
      .catch(reject);
  };

  return new Promise(checkCondition);
}

export const PrezentAnalyticsAPI = (function () {
  return {
    /**
     * Increment download count metrics
     * @param {*} countToIncrement
     * @returns Promise
     */
    incrementSlideDownloadCount(countToIncrement) {
      return updateUserAttributes(
        UPDATE_USER_ATTRIBUTE_ACTIONS.increment,
        UPDATE_USER_ATTRIBUTE_FIELDS.downloadUsed,
        countToIncrement,
      );
    },
    incrementSearchCount() {
      return updateUserAttributes(
        UPDATE_USER_ATTRIBUTE_ACTIONS.increment,
        UPDATE_USER_ATTRIBUTE_FIELDS.countSearches,
        1,
      );
    },
  };
})();

export const getUserAssociatedCompanies = async function (companyId) {
  const path = '/association/getall';
  return API2.post(path, { company_id: `${companyId}` });
};

export const getPrezentationsByOwner = async function (owner) {
  return API.graphql(
    graphqlOperation(prezentationByOwner, {
      ownerID: owner,
      limit: 1000,
    }),
  ).then((prezentationsData) => {
    if (
      prezentationsData &&
      prezentationsData.data &&
      prezentationsData.data.prezentationByOwner &&
      prezentationsData.data.prezentationByOwner.items
    ) {
      return {
        prezentations: prezentationsData.data.prezentationByOwner.items,
        nextToken: prezentationsData.data.prezentationByOwner.nextToken,
      };
    }
    return { prezentations: [] };
  });
};

export const getPrezentations = async function (
  query_type,
  size = 200,
  includeOP = true,
) {
  let path = `/prezentation?size=${size}&includeOP=${includeOP}&sort_column=createdAt&sort_order=desc&includeUploaded=true`;
  return API2.get(path).then((prezentationsData) => {
    if (
      prezentationsData &&
      prezentationsData.data &&
      prezentationsData.data.items
    ) {
      return {
        prezentations: [...prezentationsData.data.items].map((itm) => ({
          ...itm,
          prezType: query_type,
        })),
        nextToken: prezentationsData.data.nextToken,
      };
    }
    return { prezentations: [], nextToken: null };
  });
};

export const getPrezentationsForListView = async (
  last_sort_value = false,
  last_num_id = false,
  includeOP = true,
) => {
  let path = `/prezentation?includeOP=${includeOP}&size=50&sort_column=createdAt&sort_order=desc&includeUploaded=true`;
  // size=5&sort_column=name&last_sort_value=prezent_C&sort_order=desc&last_num_id=6&
  if (last_sort_value && last_num_id)
    path += `&last_sort_value=${last_sort_value}&last_num_id=${last_num_id}`;
  const prezentationsData = await API2.get(path);
  console.log(prezentationsData);
  if (
    prezentationsData &&
    prezentationsData.data &&
    prezentationsData.data.items
  ) {
    return prezentationsData;
  }
  return [];
};

export const updatePrezentationView = async function (prezentationId) {
  return API2.post('/api2/prezentations/view', { prezentationId });
};

export const updatePrezentationFunction = (prezentationData) => {
  return API2.put('/prezentation', prezentationData);
};
export const getPrezentationByID = async function (id) {
  const path = `/prezentation?id=${id}&ideaGallery=true`;
  return API2.get(path)
    .then((prezentationsData) => {
      if (
        prezentationsData &&
        prezentationsData.data &&
        prezentationsData.data.items &&
        prezentationsData.data.items[0]
      ) {
        return prezentationsData.data.items[0];
      }
      return {};
    })
    .catch((errorData) => {
      let prezData = {
        isError: true,
      };
      const { data: errData, error: reason } = errorData?.response?.data;
      if (reason === 'Forbidden') {
        const { prezentationName, ownerId } = errData;
        prezData.isForbidden = true;
        prezData.prezentationName = prezentationName;
        prezData.ownerId = ownerId;
      }
      if (reason === 'Prezentation not found') {
        prezData.doesntExist = true;
      }

      return prezData;
    });
};

export const saveReplacedSlideForPrezentation = async function (payload) {
  const path = '/prezentation/newslide';
  return API2.post(path, payload);
};

export const updatePrezentationReviewers = async function (
  prezentationId,
  reviewers,
) {
  const path = `/prezentation/share/reviewers`;
  return API2.put(path, {
    prezentation_id: prezentationId,
    user_ids: reviewers,
  });
};

export const getPrezentationCommentsByID = function (prezentationID) {
  return API2.get(`/prezentationcomment?id=${prezentationID}`);
};

export const addPrezentationComment = function (commentData) {
  const path = `/prezentationcomment`;
  return API2.post(path, commentData);
};

export const updatePrezentationComment = function (commentData) {
  const path = `/prezentationcomment`;
  return API2.put(path, commentData);
};

export const deletePrezentationComment = function (toDeleteId) {
  const path = `/prezentationcomment/${toDeleteId}`;
  return API2.delete(path);
};

export const getUserCompanyInfo = (company) =>
  API2.get(`/themes/info?company=${company}`);

export const getBestPractices = (sid, category) =>
  API2.get(`/api2/know/slide/bestpractices?sid=${sid}&category=${category}`);

export const getFingerprintSlides = (theme) =>
  API2.post('/public/fingerprint/slides', { theme });

export const postPhaedrus = (data) => API2.post('/api/phaedrus', data);

export const fetchRelatedSlides = async function (
  data,
  sessionkey,
  currentTheme,
) {
  const payload = {
    ...data,
  };
  return new Promise((resolve, reject) => {
    if (!payload.andprefs) {
      payload.andprefs = {};
    }
    payload.andprefs.theme = currentTheme.code ? currentTheme.code : 'default';
    const params = {
      similar: payload.similar,
      username: payload.username,
      category: payload.category,
      orderby: 'CHOICE_DESC',
      choice: payload.choice || {},
      morprefs: payload.morprefs ? payload.morprefs : [],
      prefs: payload.prefs ? payload.prefs : [],
      andprefs: payload.andprefs ? payload.andprefs : [],
      // notprefs: payload.notprefs ? payload.notprefs : [],
      // company: this.currentUser.companyName.toLowerCase(),
      limit: payload.limit,
      skip: payload.skip >= 0 ? payload.skip : 1,
    };
    API2.post('/search/slides/similar', JSON.stringify(params))
      .then((resp) => resolve(resp))
      .catch((e) => reject(e));
  });
};

export const fetchSimilarSlides = (params) => {
  return API2.post('/search/slides/similar', JSON.stringify(params));
};

export const handleSearch = async function ({
  query_type,
  theme_id,
  search,
  limit = null,
  skip = null,
}) {
  let path = `/slides?query_type=${query_type}&theme_id=${theme_id}&search=${
    search ? search : ''
  }`;
  if (limit) path += `&limit=${limit}`;
  if (skip) path += `&skip=${skip}`;
  return API2.get(path);
};

export const handleKnowSearchBySort = async function ({
  query_type,
  theme_id,
}) {
  const path = `/api2/know/slide?query_type=${query_type}&theme_id=${theme_id}`;
  return API2.get(path);
};

export const getPrezentationsToReview = async function (userId, companyId) {
  if (!companyId) {
    // eslint-disable-next-line new-cap
    return new Promise.resolve({ prezentations: [] });
  }

  return API.graphql(
    graphqlOperation(prezentationByCompany, {
      companyID: companyId,
    }),
  ).then((prezentationsData) => {
    if (
      prezentationsData &&
      prezentationsData.data &&
      prezentationsData.data.prezentationByCompany &&
      prezentationsData.data.prezentationByCompany.items
    ) {
      return {
        prezentations:
          prezentationsData.data.prezentationByCompany.items.filter(
            (item) =>
              item.reviewersIDs &&
              item.reviewersIDs.length > 0 &&
              item.reviewersIDs.find((id) => id === userId),
          ),
        nextToken: prezentationsData.data.prezentationByCompany.nextToken,
      };
    }
    return { prezentations: [] };
  });
};

export const getTeamPrezentations = async function (companyId, teamId) {
  return API.graphql(
    graphqlOperation(prezentationByCompany, {
      companyID: companyId,
      limit: 1000,
    }),
  ).then((prezentationsData) => {
    if (
      prezentationsData &&
      prezentationsData.data &&
      prezentationsData.data.prezentationByCompany &&
      prezentationsData.data.prezentationByCompany.items
    ) {
      return {
        prezentations:
          prezentationsData.data.prezentationByCompany.items.filter(
            (item) => item.visibleTeam && item.teamID && item.teamID === teamId,
          ),
        nextToken: prezentationsData.data.prezentationByCompany.nextToken,
      };
    }
    return { prezentations: [] };
  });
};

export const clonePrezentation = async function (prezentationToClone) {
  const clone = { ...prezentationToClone };
  delete clone.owner;
  delete clone.audience;
  delete clone.comments;
  delete clone.slides;
  delete clone.thumbnail;
  clone.id = uuidv4();
  let n = 1;
  clone.name = `${prezentationToClone.name}--copy`;
  return API.graphql(
    graphqlOperation(createPrezentation, { input: clone }),
  ).then((value) => value.data.createPrezentation);
};

export const editPrezentationName = async function (
  newName,
  prezentationToUpdate,
) {
  const prezentationData = {
    id: prezentationToUpdate.id,
    name: newName,
  };
  return API.graphql(
    graphqlOperation(updatePrezentation, { input: prezentationData }),
  );
};

export const deletePrezentation = async function (prezentationToDelete) {
  return API.graphql(
    graphqlOperation(doDelete, { input: { id: prezentationToDelete.id } }),
  );
};

export const editPrezentation = (data) => {
  const prezentationData = {
    ...data,
  };
  return API.graphql(
    graphqlOperation(updatePrezentation, { input: prezentationData }),
  );
};

export const mergeSlides = async (slides) => {
  let s = '';
  for (let i = 0; i < slides.length; i++) {
    s += `&slides=${slides[i].substring(1)}`;
  }
  return API.graphql(
    graphqlOperation(mergeslides, {
      parameters: s,
    }),
  ).then((res) => ({
    ...JSON.parse(res.data.mergeslides),
    url: `${miscConfig.aws_prezentation_distribution}/${
      JSON.parse(res.data.mergeslides).body
    }`,
  }));
};

export const sendFPReminder = (currentUser, currentAudience, isUser) => {
  const requester = `${currentUser.user.firstName} ${currentUser.user.lastName}`;
  const subject = `Request from ${requester}`;
  let fpTestLink;
  if (!isUser) {
    fpTestLink = `${window.location.origin}/fingerprintsignin?inviteId=${currentAudience.uuid}`;
  } else {
    fpTestLink = `${window.location.origin}`;
  }
  const message = `${requester} (${currentUser.user.id}) has requested fingerprint test for ${currentAudience.firstName} (${currentAudience.id})`;
  return sendEmail({
    type: 'send-fingerprint-request-new',
    meta: {
      audience: currentAudience.firstName,
      requester,
      toAddress: currentAudience.id,
      subject,
      fpLink: fpTestLink,
      message,
      isUser: isUser,
    },
  });
};

/**
 * Use in login.
 * Check if user is SSO and redirect accordingly
 */
export const checkSSOUser = async (email, shouldCheckDomain) => {
  let path = '/users/sso-user-check';
  if (email) path += `?email=${email}`;
  if (shouldCheckDomain) path += `?domain=${location.origin}`;
  return await API2.get(path);
};

/**
 * Sharing prezentations GET
 * GET /prezentation/share (Get shared entities)
 */
export const getPrezentationShareDetails = async function (prezentationId) {
  const path = `/prezentation/share?prezentation_id=${prezentationId}`;
  return await API2.get(path);
};

/**
 * Sharing prezentations PUT
 * PUT /prezentation/share (Update permission for entities)
 */

export const updatePresentationShareDetails = async function (
  prezentationId,
  sharedEntities,
) {
  const path = '/prezentation/share';
  return await API2.put(path, {
    prezentation_id: prezentationId,
    shared_entities: sharedEntities,
  });
};

/**
 * Get shared fingerprint's details
 * GET /fingerprint/share (Get shared entities)
 * params:
 *  entityID: user's UUID of selected fingerprint
 */
export const getFingerprintShareDetails = async function (entityID) {
  const path = `/fingerprint/share?fingerprint_id=${entityID}`;
  return await API2.get(path);
};

/**
 * Update fingerprint's sharing details
 * POST /fingerprint/share (Update permission for entities)
 */

export const updateFingerprintShareDetails = async function (body) {
  const path =
    body.entityType === 'audience'
      ? '/fingerprint/audiences'
      : '/fingerprint/share';
  return await API2.post(path, body);
};

export const getFingerPrintShareACL = function (audienceID) {
  const path = audienceID
    ? `/fingerprint/audience/share?audience=${audienceID}`
    : '/fingerprint/share?type=user';
  return API2.get(path).then((resp) => {
    if (resp.data && resp.data.length) {
      return {
        type: 'data',
        data: resp.data,
      };
    } else if (resp.sharedDefaultValue) {
      return {
        type: 'default',
        data: resp.sharedDefaultValue,
      };
    } else {
      return null;
    }
  });
};

export const getCompanies = async function (all = false) {
  let path = '/companies';
  if (all) path += '?all=true';
  return await API2.get(path);
};

export const getRealtimeAudience = async function () {
  return await API2.get('api2/realtimeaudience/get').then((resp) => {
    return [...resp.data, ...resp.standardAudiences];
  });
};

export const getFingerPrintAdditionalData = async function () {
  return await API2.get('api2/fingerprint-additional-data').then((resp) => {
    return resp.data;
  });
};

export const getOpPssEntity = async function (id) {
  return await API2.get(`/op/pss/entity?entity=company&entity_id=${id}`);
};

export const setRecentAudience = async function (audience) {
  const body = {
    audienceID: audience.id,
    audienceType: audience.audienceType,
  };
  if (audience.fingerprintType) {
    body.audienceSubType = audience.fingerprintType;
  }
  if (audience.ownerID) {
    body.audienceOwnerID = audience.ownerID;
  }
  if (audience.uuid) {
    body.uuid = audience.uuid;
  }
  return await API2.post('api2/realtimeaudience/add', body).then((resp) => {
    return [...resp.data, ...resp.standardAudiences];
  });
};

export const createCompanies = async function (companyData) {
  const path = '/companies';
  return await API2.post(path, companyData);
};

export const trialValidate = async function (id) {
  const path = '/trial/validate';
  return API2.post(path, { trialRegID: `${id}` });
};

export const trialInValidate = async function (params) {
  const path = '/trial/invalidate';
  return API2.post(path, params);
};

export const trialSignUp = async function (params) {
  const path = '/trial/signup';
  return API2.post(path, params);
};

export const getThemesList = async (nextToken, themes = []) => {
  const queryObject = nextToken
    ? { query: listThemess, variables: { nextToken } }
    : { query: listThemess };
  const themesListResponse = await API.graphql(queryObject);
  if (themesListResponse) {
    themes.push(...themesListResponse.data.listThemess.items);

    if (themesListResponse.data.listThemess.nextToken !== null) {
      await getThemesList(
        themesListResponse.data.listThemess.nextToken,
        themes,
      );
    }
  }

  return themes;
};

export const getUsersList = async (nextToken, users = [], companyID) => {
  let variables = { companyID, limit: 1000 };
  if (nextToken) {
    variables = { ...variables, nextToken };
  }
  const usersData = await API.graphql({ query: userByCompany, variables });
  if (usersData.data.userByCompany) {
    users.push(...usersData.data.userByCompany.items);

    if (usersData.data.userByCompany.nextToken !== null) {
      await getUsersList(
        usersData.data.userByCompany.nextToken,
        users,
        companyID,
      );
    }
  }
  return users;
};

export const updateCompany = async function (id, domains) {
  const path = '/companies';
  return await API2.put(path, {
    id: id,
    domains: domains,
  });
};

export const subscriptions = async function () {
  const path = '/subscriptions';
  return await API2.get(path);
};

export const imageStockSearch = async (searchKey, limit, offset) => {
  // const isFreepik = miscConfig?.image_replacemt_source === 'Freepik';

  /*
    Relying on a feature flag 'adobe_stock_library', rather than config
    to determine which image library
    to use during image replacements throughout product
  */
  const isUsingAdobe = store.getters['users/getAdobeStockLibFullAccess'];

  // when using Freepik
  if (!isUsingAdobe) {
    let images = [];
    await API2.get(
      `/api3/replaceimages/getlibraryimages?term=${searchKey}&page=${
        offset + 1
      }&limit=30&order=relevance&filters[content_type][photo]=1&filters[author]=23&filters[ai-generated][excluded]=1&filters[content_type][vector]=0`,
    )
      .then((resp) => {
        images = {
          data: {
            files: resp.resp.data.map((img) => {
              const freepikImgDomain = img?.image?.source?.url.split('/')[2];
              // console.log('---- freepik domain with image from freepik ----', freepikImgDomain);
              return {
                ...img,

                // Customising the thumbnail urls according to the proxy for freepik images
                thumbnail_url: `${img?.image?.source?.url.replace(
                  new RegExp(freepikImgDomain, 'g'),
                  miscConfig.freepik_images_proxy_url,
                )}?site=${freepikImgDomain}`,

                // customising thumbnail height to adjust in masonry image containers
                thumbnail_height: Number(
                  Number(img?.image?.source?.size.split('x')[1]) / 2,
                ).toFixed(0),
              };
            }),
          },
        };
      })
      .catch((err) => {
        console.log(err);
        images = [];
      });
    return images;
  }

  // when using adobe
  const headersConfig = {
    headers: {
      'x-api-key': process.env.VUE_APP_ADOBE_API_KEY || ADOBE_API_KEY,
      'x-product': 'prezentAI',
    },
  };
  // &search_parameters[filters][orientation]=vertical
  const searchParams = `&search_parameters[thumbnail_size]=160&search_parameters[limit]=${limit}&search_parameters[offset]=${offset}&search_parameters[filters][content_type:photo]=1&search_parameters[filters][premium]=false&search_parameters[filters][offensive:2]=1`;
  const resultColumns = `&result_columns[]=id&result_columns[]=title&result_columns[]=thumbnail_url&result_columns[]=is_licensed&result_columns[]=premium_level_id&result_columns[]=thumbnail_width&result_columns[]=thumbnail_height`;
  const adobeStockSearchUrl = `https://stock.adobe.io/Rest/Media/1/Search/Files?search_parameters[words]=${searchKey}${searchParams}${resultColumns}`;
  const imagesData = await axios.get(adobeStockSearchUrl, headersConfig);
  return imagesData;
};

// export const adobeStockSearch = async (searchKey, limit, offset) => {
//   const headersConfig = {
//     headers: {
//       'x-api-key': process.env.VUE_APP_ADOBE_API_KEY || ADOBE_API_KEY,
//       'x-product': 'prezentAI',
//     },
//   };
//   // &search_parameters[filters][orientation]=vertical
//   const searchParams = `&search_parameters[thumbnail_size]=160&search_parameters[limit]=${limit}&search_parameters[offset]=${offset}&search_parameters[filters][content_type:photo]=1&search_parameters[filters][premium]=false&search_parameters[filters][offensive:2]=1`;
//   const resultColumns = `&result_columns[]=id&result_columns[]=title&result_columns[]=thumbnail_url&result_columns[]=is_licensed&result_columns[]=premium_level_id&result_columns[]=thumbnail_width&result_columns[]=thumbnail_height`;
//   const adobeStockSearchUrl = `https://stock.adobe.io/Rest/Media/1/Search/Files?search_parameters[words]=${searchKey}${searchParams}${resultColumns}`;

//   const imagesData = await axios.get(adobeStockSearchUrl, headersConfig);
//   return imagesData;
// };

export const sendEmail = async function (params) {
  const path = '/util/email';
  return await API2.post(path, params);
};
export const sendEmailAud = async function (params) {
  const path = '/fingerprint/preview';
  return await API2.post(path, params);
};

export const createTeams = async function (teamData) {
  const path = '/teams';
  return await API2.post(path, teamData);
};

export const getTeams = async function (companyID) {
  const path = `/teams?companyID=${companyID}`;
  return await API2.get(path);
};

export const updateSubscription = async function (subscriptionData) {
  const path = `/subscriptions/update`;
  return await API2.put(path, subscriptionData);
};

// Audeince APIS - START
export const createAudience = async function (audienceData) {
  const path = `/audiences`;
  return await API2.post(path, audienceData);
};

export const updateAudience = async function (audienceData) {
  const path = `/audiences`;
  return await API2.put(path, audienceData);
};

export const updateAudienceFingerPrint = async function (audienceData) {
  const path = `/audiences/update`;
  return await API2.put(path, audienceData);
};

export const getAudience = async function (email) {
  const path = `/audience?email=${email}`;
  return await API2.get(path);
};

export const getTeamCount = function () {
  const path = `/users/myteam/count`;
  return API2.get(path);
};

export const getTeamUsers = function (minNumId, maxNumId) {
  let path = `/users/myteam/list?minNumId=${minNumId}`;
  if (maxNumId) {
    path += `&maxNumId=${maxNumId}`;
  }
  return API2.get(path).then((resp) => resp.data);
};

export const getAudiences = function (minNumId, maxNumId) {
  let path = `/audiences?minNumId=${minNumId}`;
  if (maxNumId) {
    path += `&maxNumId=${maxNumId}`;
  }
  return API2.get(path);
};

export const audienceContactUs = function (audienceData) {
  const path = `/audience/contact-us`;
  return API2.post(path, audienceData);
};

// Audeince APIS - END

// Private Audeince APIS - START

export const createPrivateAudience = function (privateAudienceData) {
  const path = `/privateaudiences`;
  return API2.post(path, privateAudienceData);
};

export const updatePrivateAudience = async function (privateAudienceData) {
  const path = `/privateaudiences`;
  return await API2.put(path, privateAudienceData);
};

export const getPrivateAudience = async function (email) {
  const path = `/privateaudience?email=${email}`;
  return await API2.get(path);
};

export const getZenSenseAudience = async function (body) {
  const path = '/privateaudience/zensense';
  return await API2.put(path, body).then((resp) => {
    return { data: resp.privateAudience };
  });
};

export const getPrivateAudiences = function (minNumId, maxNumId) {
  let path = `/privateaudiences?minNumId=${minNumId}`;
  if (maxNumId) {
    path += `&maxNumId=${maxNumId}`;
  }
  return API2.get(path);
};

// Private Audeince APIS - END

export const getFingerprint = async (email, type) => {
  const path = `/fingerprint?username=${email}&type=${type}`;
  const { data } = await API2.get(path);
  return data;
};

export const getSlideDetail = async function (value) {
  const path = `/slides/meta`;
  return await API2.post(path, value);
};

export const getKnowSlideMetaData = async function (value) {
  const path = `/api2/know/meta`;
  return await API2.post(path, value);
};

export const sendNotification = async function (params) {
  return await API_PRODUCT_SERVICES.post(
    '/services/general/notifications',
    params,
  );
};

export const getNotificationsForUser = async function (params) {
  return await API_PRODUCT_SERVICES.get('/services/general/notifications');
};

export const updateNotificationsForUser = async function (payload) {
  return await API_PRODUCT_SERVICES.put(
    '/services/general/notifications',
    payload,
  );
};

export const markNotificationsSeen = async function () {
  return await API_PRODUCT_SERVICES.put(
    '/services/general/notifications/seen',
    {},
  );
};

export const getUserSearchTerms = async function () {
  return await API2.get('/searchterm');
};

export const addSearchTerms = async function (payload) {
  return await API2.post('/searchterm', payload);
};

export const updateSearchTerms = async function (payload) {
  return await API2.put('/searchterm', payload);
};

export const logSearchQuery = async function (payload) {
  return await API2.post('/api2/search-query-log', payload);
};

export const getKnowUserSearchTerms = async function () {
  return await API2.get('/api2/know/searchterms');
};

export const addKnowSearchTerms = async function (payload) {
  return await API2.post('/api2/know/searchterms', payload);
};

export const updateKnowSearchTerms = async function (payload) {
  return await API2.put('/api2/know/searchterms', payload);
};

export const createFavorite = async function (payload) {
  return await API2.post('/favorite', payload);
};

export const deleteFavorite = async function (id) {
  return await API2.delete(`/favorite/${id}`);
};

export const createKnowFavorite = async function (payload) {
  return await API2.post('/api2/know/favorite', payload);
};

export const deleteKnowFavorite = async function (id) {
  return await API2.delete(`/api2/know/favorite?assetID=${id}`);
};

export const createKnowDownload = async function (payload) {
  return await API2.post('/api2/know/download', payload);
};

export const createDownload = async function (payload) {
  return await API2.post('/download', payload);
};

export const generateDownload = async function (payload) {
  return await API2.post('/api3/generate/download', payload);
};

export const createCollectionDownload = async function (body) {
  return await API2.post(`/download/set`, body);
};

export const createWishes = async function (payload) {
  return await API2.post('/wish', payload);
};

export const getStorylines = async function (parrams) {
  let url = '/api2/outlines/all';
  if (parrams) {
    url += '?';
    for (const key in parrams) {
      if (Object.hasOwnProperty.call(parrams, key)) {
        const value = parrams[key];
        url += `${key}=${value}&`;
      }
    }
  }

  return await API2.get(url);
};

export const uploadRequest = async function (data) {
  return await API2.post(`/api2/uploadrequest/prezentation`, data);
};

export const requestStorylineAccess = async function (id) {
  return await API2.post('/api2/outlines/accessrequest', {
    outline_id: id,
  });
};

export const getSectionGalery = async function (text) {
  return await API2.get(`/api2/outlines/sections?query=${text}`);
};

export const getStorylineByID = async function (id) {
  return await API2.get(`/api2/outlines?outline_id=${id}`);
};

export const createStorylines = async function (data) {
  return await API2.post(`/api2/outlines`, data);
};

export const updateStorylines = async function (data) {
  return await API2.put(`/api2/outlines`, data);
};

export const deleteStorylines = async function (id) {
  return await API2.delete(`/api2/outlines?outline_id=${id}`);
};

export const getShareStorylines = async function (id) {
  return await API2.get(`/api2/outlines/acl?outline_id=${id}`);
};

export const shareStorylines = async function (data) {
  return await API2.post(`/api2/outlines/share`, data);
};

export const createUserVote = async function (payload) {
  return await API2.post('/vote', payload);
};

export const getKnowCategories = async function () {
  return await API2.get('/api2/know/slide/category');
};

export const addSurvey = async function (payload) {
  return await API2.post('/api2/ratings/add', payload);
};

export const addStandSurvey = async function (payload) {
  return await API2.post('/api2/ratings/standalone/survey/add', payload);
};

export const updateSurvey = async function (payload) {
  return await API2.put('/api2/ratings/update', payload);
};

export const updateStandSurvey = async function (payload) {
  return await API2.put('/api2/ratings/standalone/survey/update', payload);
};

export const surveySkip = async function (payload) {
  return await API2.post('/api2/ratings/skip', payload);
};

export const surveyStandSkip = async function (payload) {
  return await API2.post('/api2/ratings/standalone/survey/skip', payload);
};

export const getCopyDetails = async function (payload) {
  return await API2.post('api3/asset/copy/slides', payload);
};

export const getMyTeamStats = async function (from, to) {
  if (from && to) {
    return await API2.get(
      `/api2/valuemetrics/metrics/myteamstats?from=${encodeURI(
        from,
      )}&to=${encodeURI(to)}`,
    );
  }
  return await API2.get('/api2/valuemetrics/metrics/myteamstats');
};
export const getMyTeamActiveUser = (year) => {
  return API2.get(`/api2/valuemetrics/metrics/active-users`, {
    params: { year },
  });
};

export const getUnSubscriptionDetails = function () {
  return API2.get('/api2/emailunsubscribe/get');
};

export const postUnSubscriptionDetails = function () {
  return API2.post('/api2/emailunsubscribe/add');
};

export const deleteUnSubscription = function () {
  return API2.delete('/api2/emailunsubscribe/remove');
};

export const getThemesAclById = async function (themeId) {
  return await API2.get(`/theme/acl/${themeId}`);
};

export const updateTemplateShareDetails = async function (payload) {
  return await API2.put('/theme/share', payload);
};

export const getDonationDetails = async function () {
  return await API2.get('/api2/donation/stats');
};

export const getVerifyDetails = async function (link) {
  return await API2.get(`/api2/standalone/survey/verifylink?linkId=${link}`);
};

export const getUpdateLink = async function (payload) {
  return await API2.put('/api2/standalone/survey/updatelink', payload);
};

export const getCommunicationStandAlone = async function (email, type) {
  return await API2.get(
    `/api2/external_user_byemail?email=${email}&type=${type}`,
  );
};

export const updateEmailNotification = async function (payload) {
  return await API2.post('/api2/external_users', payload);
};

export const updateCollection = async function (payload) {
  return await API2.post('/api2/slide/collection', payload);
};

export const getSuggestionsTotalCount = function (suggestions) {
  if (!Array.isArray(suggestions)) {
    return 0;
  }
  let subtract = 0;
  return (
    suggestions.reduce((acc, suggestion) => {
      if (suggestion.mapping) {
        suggestion.mapping.forEach((item) => {
          if (item.status === 'accepted' || item.status === 'rejected') {
            subtract += 1;
          }
        });
        return acc + suggestion.mapping.length;
      }
      return acc;
    }, 0) - subtract
  );
};

export const deleteCollection = async function ({
  type,
  slideID,
  templateID,
  slideFileName,
}) {
  let path = `/api2/slide/collection?type=${type}`;
  if (slideID) {
    path += `&slideID=${slideID}`;
  }
  if (templateID) {
    path += `&templateID=${templateID}`;
  }
  if (slideFileName) {
    path += `&slideFileName=${slideFileName}`;
  }
  return await API2.delete(path);
};

export const getCollection = async function () {
  return await API2.get('/api2/slide/collection');
};

export const getFingerprintDistribution = async function (scope) {
  return await API2.get(
    `/api2/fpinsights/fingerprints_distribution?scope=${scope}`,
  );
};

export const getFingerprintInfo = async function (scope, fp) {
  return await API2.get(
    `/api2/fpinsights/fingerprint_insight?scope=${scope}&fingerPrint=${fp}`,
  );
};

export const getFingerprintUsersInfo = async function (scope, fp) {
  return await API2.get(
    `/api2/fpinsights/users_insights_data?scope=${scope}&fingerPrint=${fp.toLowerCase()}`,
  );
};

export const calculateGroupInsights = async function (users) {
  return await API2.post('/api2/fpinsights/generate_group_insight', users);
};

export const getGroupInsights = async function (uuid) {
  return await API2.get(`/api2/fpinsights/get_group_fingerprint?uuid=${uuid}`);
};

export const updateCompareFingerprintCount = async function () {
  return await API2.get('/api2/fpinsights/increment_compare_count');
};

export const createGroupInsightFingerprint = async function (body) {
  if (body.uuid) {
    return await API2.put('/api2/fpinsights/create_group_fingerprint', body);
  }
  return await API2.post('/api2/fpinsights/create_group_fingerprint', body);
};

export const addPrezentationAsFavorite = async function (payload) {
  return await API2.post('/api2/prezentations/addfavorite', payload);
};

export const removePrezentationAsFavorite = async function (payload) {
  return await API2.delete(
    `/api2/prezentations/removefavorite?prezentationId=${payload}`,
  );
};

export const getFrequentlyUserOutlinesByUser = async function () {
  return await API2.get('/api2/outlines/frequentbyuser');
};

export const addPrezentationAsDownloaded = async function (payload) {
  return await API2.post('/api2/prezentations/adddownload', payload);
};

export const updatePrezentationAsDownloaded = async function (payload) {
  return await API2.put('/api2/prezentations/updatedownload', payload);
};

export const submitOPRequest = async function (payload) {
  return await API2.post('/api2/ordermanager/ticket', payload);
};

export const getOPTicketsMessage = async function () {
  return await API2.get('/api2/op/message');
};

export const updateOPTicketsMessageStatus = async function (payload) {
  return await API2.put('/api2/ordermanager/comment', payload);
};

export const getOPTickets = async function (params) {
  const {
    offset,
    limit,
    searchQuery,
    templateIds,
    sortColumn,
    sortOrder,
    isDownloaded,
    startDate,
    endDate,
    status,
  } = params;
  let path = `/api2/ordermanager/ticket?offset=${
    offset * limit
  }&limit=${limit}&sortColumn=${sortColumn || 'createdAt'}&sortOrder=${
    sortOrder ? 'desc' : 'asc'
  }`;
  if (searchQuery) {
    path = path + `&searchQuery=${searchQuery}`;
  }
  if (templateIds.length) {
    path = path + `&templateIds=${templateIds}`;
  }
  if (isDownloaded) {
    path = path + `&isDownloaded=true`;
  }
  if (startDate) {
    path = path + `&startDate=${startDate}`;
  }
  if (endDate) {
    path = path + `&endDate=${endDate}`;
  }
  if (status.length) {
    path = path + `&status=${status}`;
  }
  return await API2.get(path);
};
export const getOPConversationHistory = async function (id) {
  return await API2.get(`/api2/ordermanager/comment?id=${id}`);
};
export const getOPManageFiles = async function (id) {
  return await API2.get(`/api2/ordermanager/attachment?id=${id}`);
};
export const updateRating = async function (payload) {
  return await API2.put('/api2/ordermanager/like', payload);
};

export const getOPTicket = async function (ticketID) {
  return await API2.get(`/api2/ordermanager/ticket?id=${ticketID}`);
};
export const getLowFundFlag = async function () {
  return await API2.get(`/api2/ordermanager/low-fund-check`);
};
export const requestAddFunds = async function (body = {}) {
  return await API2.post(`/api2/ordermanager/request-add-funds`, body);
};
export const updateShareCertificateCount = async function () {
  return await API2.post('/api2/learn/certificateshared');
};

export const downloadFinalOPPPT = async function (id) {
  return await API2.get(
    `/api2/ordermanager/attachment?id=${id}&isFinalPpt=true`,
  ).then((resp) => resp.data[0]);
};

export const logOPDownload = async function (ticketId) {
  return await API2.post('/api2/overnight/download', { ticketId });
};
export const addToPrezentation = async function (payload) {
  return await API2.post('/api2/op/add_to_prezentation', payload);
};

export const convertPptxToPdf = async function (payload) {
  const path = '/api2/converpptxtopdf';
  return API2.post(path, payload);
};

export const createTemplateRequest = async function (payload) {
  return await API2.post('/theme/request', payload);
};

export const updateOpRequestWithAttachmentAndLinks = async function (payload) {
  return await API2.post('/api2/ordermanager/attachment', payload);
};

export const deleteOpRequestAttachmentsAndLinks = async function (
  ticketId,
  attachmentId,
) {
  return await API2.delete(
    `/api2/ordermanager/attachment?ticketId=${ticketId}&attachmentId=${attachmentId}`,
  );
};
export const updateOPTicket = async function (payload) {
  return await API2.put('/api2/ordermanager/ticket', payload);
};
export const updateOPAccess = async function (body) {
  return await API2.put(`/feature`, body);
};

export const updateBulkAction = async function (body) {
  return await API2.put('/bulk/user', body);
};

export const getTeamUsageStatistics = async function (from, to) {
  if (from && to) {
    return await API2.get(
      `/users/myteamusagestats?from=${encodeURI(from)}&to=${encodeURI(to)}`,
    );
  }
  return await API2.get('/users/myteamusagestats');
};
export const getTeamOpStats = async function (
  fromDate,
  toDate,
  lastNumId,
  lastSortValue,
) {
  if (fromDate && toDate) {
    return await API2.get(
      `/users/myteamopstats?from_date=${fromDate}&to_date=${toDate}&last_num_id=${lastNumId}&last_sort_value=${lastSortValue}`,
    );
  }
  return await API2.get(
    `/users/myteamopstats?last_num_id=${lastNumId}&last_sort_value=${lastSortValue}`,
  );
};

export const sendAccessRequest = async function (type) {
  return await API2.put('/api2/overnight/enablement/request', { type });
};

export const generateUtilizationReport = async function (payload) {
  return await API2.post('/api2/utilization-report/generate-async', payload);
};

export const getFundsUtilizationMetrics = async function () {
  return await API2.get('/api2/utilization-report/funds-utilization-metrics');
};

const wait = (ms) => new Promise((r) => setTimeout(r, ms));

export const retryOperation = async function (operation, delay, retries) {
  return new Promise((resolve, reject) => {
    return operation()
      .then(resolve)
      .catch((reason) => {
        if (retries > 0) {
          return wait(delay)
            .then(retryOperation.bind(null, operation, delay, retries - 1))
            .then(resolve)
            .catch(reject);
        }
        return reject(reason);
      });
  });
};

export const uploadOPFileChunk = async function (chunk, totalChunks, xSource) {
  return API2.put('/api2/att/preprocess', chunk.chunk, {
    headers: {
      'x-request-identifier': chunk.requestIdentifier,
      'x-file-name': utf8ToIso(chunk.filename),
      'x-source': 'op',
      'x-amz-chunk-index': chunk.chunkIndex,
      'x-amz-total-chunks': totalChunks,
      'x-source': xSource,
      'Content-Type': 'text/plain',
    },
  });
};

export const uploadGenerateMultiContentFileChunk = async function (
  chunk,
  totalChunks,
  xSource,
) {
  return API2.put('/api2/att/preprocess', chunk.chunk, {
    headers: {
      'x-request-identifier': chunk.requestIdentifier,
      'x-file-name': utf8ToIso(chunk.filename),
      'x-source': 'op',
      'x-amz-chunk-index': chunk.chunkIndex,
      'x-amz-total-chunks': totalChunks,
      'x-source': xSource,
      'Content-Type': 'text/plain',
    },
  });
};

export const uploadOPFileBatch = async function (batch, totalChunks, xSource) {
  return Promise.all(
    batch.map((chunk) => {
      return retryOperation(
        () => uploadOPFileChunk(chunk, totalChunks, xSource),
        1000,
        3,
      );
    }),
  );
};

export const uploadGenerateMultiContentFileBatch = async function (
  batch,
  totalChunks,
  xSource,
) {
  return Promise.all(
    batch.map((chunk) => {
      return retryOperation(
        uploadGenerateMultiContentFileChunk(chunk, totalChunks, xSource),
        1000,
        3,
      );
    }),
  );
};

export const uploadOPFileBatchChunk = async function (
  batchChunks,
  totalChunks,
  progress_cb,
  xSource = 'op',
) {
  return new Promise(async (resolve, reject) => {
    let d = 0;
    progress_cb(0);
    for (let batch of batchChunks) {
      try {
        let r = await uploadOPFileBatch(batch, totalChunks, xSource);
        d++;
        progress_cb((d * 100) / batchChunks.length);
      } catch (err) {
        reject(err);
        return;
      }
    }
    resolve();
  });
};

export const uploadOPFile = async function (
  requestIdentifier,
  filename,
  xSource = 'op',
  isDesigner = false,
) {
  return API2.put(
    '/api2/att/finalprocess',
    {},
    {
      headers: {
        'x-request-identifier': requestIdentifier,
        'x-file-name': utf8ToIso(filename),
        'x-source': xSource,
        'x-is-designer': isDesigner,
      },
    },
  );
};

export const uploadChunks = async function (file, type) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = async () => {
      const chunkSize = 3 * 1024 ** 2;
      const chunks = [];
      let chunkIndex = 0;
      for (let start = 0; start < reader.result.length; start += chunkSize) {
        const chunk = reader.result.slice(start, start + chunkSize);
        chunks.push({
          chunk,
          chunkIndex,
          requestIdentifier: file.requestIdentifier,
          filename: file.name,
        });
        chunkIndex++;
      }
      const chunkBatch = chunks.reduce((resultArray, item, index) => {
        const chunkIdx = Math.floor(index / 5);

        if (!resultArray[chunkIdx]) {
          resultArray[chunkIdx] = []; // start a new chunk
        }

        resultArray[chunkIdx].push(item);

        return resultArray;
      }, []);
      try {
        await uploadOPFileBatchChunk(
          chunkBatch,
          chunkIndex,
          (prog) => {
            console.log(prog);
            file.progress = prog;
          },
          type,
        );
        resolve('success');
      } catch (err) {
        reject(err);
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file.file);
  });
};

export const getSlidesZipgen = async (payload) => {
  return await API2.post('/api3/zipgen', payload);
};

export const sanitizeSlides = async (payload) => {
  return await API2.post('/api3/sanitize', payload);
};

export const getSlideTags = async (payload) => {
  return await API2.post('/api3/tags', payload);
};

/**
 * addPrezentationDeck
 * @param {*} payload
 * @param {*} params
 * @returns {Promise<{ data: { prezentation: { id: string } } }>}
 */
export const addPrezentationDeck = async (payload, params) => {
  return await API2.post('/api3/add-uploaded-deck', payload, { params });
};

export const updateBestPractices = async (payload) => {
  return await API2.put('/prezentation/bestpractices', payload);
};

export const getFirstSignin = async (email) => {
  const localStorageKey = `pendo_first_signin_${email}`;
  const timestamp = localStorage.getItem(localStorageKey);
  if (timestamp) {
    return toNumber(timestamp);
  }
  try {
    const data = await API2.get(`/pendo-visitor`, { params: { email } });
    const firstSignInTimeStamp = data?.data?.metadata?.auto?.firstvisit;
    if (firstSignInTimeStamp) {
      localStorage.setItem(localStorageKey, firstSignInTimeStamp);
      return firstSignInTimeStamp;
    } else {
      return null;
    }
  } catch {
    return null;
  }
};

export const getSlideDataForDetailView = async function (id) {
  return await API2.get(`/api2/slide-data?assetID=${id}`);
};

export const getSlideMetaData = async function (id, isKnow) {
  let url = `/api2/slide-data?assetID=${id}`;
  if (isKnow) {
    url += `&is_know=1`;
  }
  return await API2.get(url);
};

export const updateCountForSlideView = async function (payload) {
  return await API2.post(`/api2/slide-views-count`, payload);
};

export const generateSlideRequestLog = function (body) {
  return API2.post('/api3/generate/request', body);
};

export const generateSlideCompleteLog = function (body) {
  return API2.put('/api3/generate/result', body);
};

export const generateSlideUpdateLog = function (body) {
  return API2.put('/api3/generate/slide/update', body);
};

export const addComplyLog = function (body) {
  return API2.post('/api3/comply/siem', body);
};

export const complyDislike = function (body) {
  return API2.post('/api3/comply/dislike', body);
};

export const addUploadLog = function (body) {
  return API2.post('/api3/upload/siem', body);
};

export const generateSlidePostCompleteLog = function (body) {
  return API2.put('/api3/generate/postresult', body);
};

export const errorRequestLog = async function (body) {
  return await API2.put('/api3/generate/failed', body);
};

export const generateSlideRequest = async function (body) {
  return await retryOperation(
    () => API_PRODUCT_SERVICES.post('/sb/pipeline/start', body),
    1000,
    3,
  );
};

export const uploadPPTXStartRequest = async function (body) {
  return await API_PRODUCT_SERVICES.post('/sb/pipeline/start', body);
};

export const getPipeineStatus = async function (callback) {
  return await API_PRODUCT_SERVICES.get(
    `/sb/pipeline/status?callback_id=${callback}`,
  );
};

export const cancelGSRequest = async function (callback) {
  return await API_PRODUCT_SERVICES.get(
    `/sb/pipeline/cancel?callback_id=${callback}`,
  );
};

export const getSiblings = async function (
  payload,
  sid,
  currentUser,
  theme,
  isBPESlide = false,
) {
  const getSlides = (resolve, reject) => {
    const related = [];
    related.push({
      unique_id: payload,
      keytype: 'K_SLIDEDATA',
      sid,
      is_know: isBPESlide ? 'yes' : undefined,
      theme,
    });
    const params = {
      username: currentUser.user.cognitoID,
      related,
    };
    const serialized = JSON.stringify(params);

    GraphQLService.requestWithToken(
      graphqlOperation(getRelatedSlides, {
        parameters: serialized,
      }),
    )
      .then(async (res) => {
        const result = JSON.parse(res.data.getRelatedSlides);
        if (result.statusCode === 200) {
          const assets = [];
          const slides = result.body.payloads;
          if (!slides || slides.length < 1) {
            reject(assets);
          } else {
            for (const slide of slides) {
              const obj = {};
              obj.asset = slide;
              obj.asset.name = obj.asset.title;
              obj.asset.file = obj.asset.filename;
              obj.asset.pptFile = obj.asset.file;
              obj.landscape = await getFileURL(
                currentUser.user.cognitoID,
                obj.asset.landscape,
                currentUser.userIp,
              );
              obj.poster = await getFileURL(
                currentUser.user.cognitoID,
                obj.asset.poster,
                currentUser.userIp,
              );
              assets.push(obj);
            }

            resolve(
              assets.sort(
                (a, b) =>
                  parseInt(a.asset.prefs.node, 10) -
                  parseInt(b.asset.prefs.node, 10),
              ),
            );
          }
        } else {
          reject(result);
        }
      })
      .catch((err) => reject(err));
  };
  return new Promise(getSlides);
};

export const getRecommendedSlides = async function (slideIds, currentUser, theme) {
  const recommendSlides = (resolve, reject) => {
    getSlideDetail({
      assetIds: slideIds,
      getMeta: true,
      themeID: theme,
    })
      .then((resp) => {
        const { data } = resp;
        const recSlides = [];
        Object.keys(data).forEach(async (key) => {
          const { meta } = data[key];
          if (meta) {
            meta.landscapePath = await getFileURL(
              currentUser.user.cognitoID,
              meta.landscape,
              currentUser.userIp,
            );
            meta.thumbnailPath = await getFileURL(
              currentUser.user.cognitoID,
              meta.thumbnail,
              currentUser.userIp,
            );
            recSlides.push(meta);
          }
        });
        resolve(recSlides);
      })
      .catch((err) => {
        reject(err);
      });
  };
  return new Promise(recommendSlides);
};

export const getRecommendedSlidesV2 = async function (
  slides,
  currentUser,
  signal,
) {
  let ids = slides.map((i) => i.thumbnail_generator_callbak_id);
  const recommendSlides = (resolve, reject) => {
    try {
      const reccos = new Array(ids.length);
      const operation = () => checkBulkStatus(ids);
      const validateResponse = () => ids.length === 0;
      const callback = async (response) => {
        for (let index = 0; index < slides.length; index++) {
          const slide = slides[index];
          if (
            response[slide.thumbnail_generator_callbak_id] &&
            response[slide.thumbnail_generator_callbak_id].workflow_status ===
              'success'
          ) {
            ids.splice(ids.indexOf(slide.thumbnail_generator_callbak_id), 1);
            const thumbnail =
              '/' +
              response[slide.thumbnail_generator_callbak_id].outputs[
                'U-Thumbnails'
              ].data[0].thumbnail_png.s3_path;
            const landscape = await getFileURL(
              currentUser.user.cognitoID,
              thumbnail,
              currentUser.userIp,
            );
            reccos[index] = {
              ...slide,
              title: slide.image_keyword || slide.category || '',
              id: uuidv4(),
              thumbnail,
              landscapePath: landscape,
              thumbnailPath: landscape,
            };
          } else if (
            response[slide.thumbnail_generator_callbak_id] &&
            response[slide.thumbnail_generator_callbak_id].workflow_status ===
              'failed'
          ) {
            ids.splice(ids.indexOf(slide.thumbnail_generator_callbak_id), 1);
          }
        }
        // slides.forEach(async (slide, index) => {
        // });
      };

      poll(operation, validateResponse, 3000, 150, signal, callback)
        .then((res) => {
          console.log(reccos);
          resolve(reccos.filter((i) => !!i));
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    } catch (err) {
      console.log(err);
      reject(err);
    }
  };
  return new Promise(recommendSlides);
};

export const checkGSStatus = async function (
  callback,
  intervalCallback,
  slidesCallback,
) {
  return new Promise((resolve, reject) => {
    let pollInterval;
    let responseArrived = false;
    function getStatus() {
      retryOperation(
        () =>
          API_PRODUCT_SERVICES.get(
            `/sb/pipeline/status?callback_id=${callback}&debug=t`,
          ),
        2000,
        50,
      )
        .then((resp) => {
          responseArrived = true;
          if (resp.workflow_status === 'success') {
            if (slidesCallback) {
              slidesCallback(resp);
            }
            resolve(resp);
            clearInterval(pollInterval);
          } else if (resp.workflow_status === 'failed') {
            if (resp.log === 'sb-suggestions') {
              reject({
                id: callback,
                meesage: resp.message,
                log: resp.log,
                liveSuggestions:
                  resp.outputs.SanityCheck.output.live_suggestions,
                promptCategory: resp.outputs.SanityCheck.output.prompt_category,
              });
            } else {
              reject({ id: callback, meesage: resp.message, log: resp.log });
            }
            clearInterval(pollInterval);
          } else if (slidesCallback) {
            slidesCallback(resp);
          }
        })
        .catch((err) => {
          responseArrived = true;
          clearInterval(pollInterval);
          reject(err);
        });
    }
    getStatus();
    pollInterval = setInterval(() => {
      if (responseArrived) {
        getStatus();
        responseArrived = false;
      }
    }, 2000);
    intervalCallback(pollInterval);
  });
};

export const checkTaskStatus = async function (
  callback,
  intervalCallback,
  taskName,
  slidesCallback,
) {
  return new Promise((resolve, reject) => {
    let pollInterval;
    function getStatus() {
      API_PRODUCT_SERVICES.get(`/sb/pipeline/status?callback_id=${callback}`)
        .then((resp) => {
          if (resp.outputs[taskName].status === 'success') {
            if (slidesCallback) {
              slidesCallback(resp, pollInterval);
            }
            resolve(resp.outputs[taskName].data);
            clearInterval(pollInterval);
          } else if (
            resp.outputs[taskName].status === 'failed' ||
            resp.workflow_status === 'failed'
          ) {
            reject({ id: callback, meesage: resp.message });
            clearInterval(pollInterval);
          } else if (slidesCallback) {
            slidesCallback(resp, pollInterval);
          }
        })
        .catch((err) => {
          clearInterval(pollInterval);
          reject(err);
        });
    }
    getStatus();
    pollInterval = setInterval(() => {
      getStatus();
    }, 2000);
    intervalCallback(pollInterval);
  });
};

export const checkBulkStatus = async function (callback_ids) {
  return retryOperation(
    () =>
      API_PRODUCT_SERVICES.post('/sb/pipeline/status-bulk', { callback_ids }),
    2000,
    50,
  );
};

export const getSlideByID = async function ({
  username,
  theme,
  sid,
  category,
}) {
  return new Promise((resolve, reject) => {
    const params = {
      username: username,
      orderby: 'CHOICE_DESC',
      category: category,
      andprefs: {
        theme: theme,
        sid: sid,
      },
      limit: 10,
      skip: 0,
    };
    const serialized = JSON.stringify(params);
    GraphQLService.requestWithToken(
      graphqlOperation(performSearch, {
        parameters: serialized,
      }),
    )
      .then((res) => {
        const result = JSON.parse(res.data.performSearch);
        if (result.statusCode === 200) {
          resolve(result.body.payloads);
          console.log(result.body.payloads);
          console.log(result);
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};
export const deleteSlide = async function (id) {
  return await API2.delete(`/api3/slides-data?assetID=${id}`);
};

export const updateSlide = async function (payload) {
  return await API2.put(`/api3/slides-data`, payload);
};

export const getSharePermissionsForSlide = async function (id) {
  return await API2.get(`/api3/slide/share?id=${id}`);
};

export const updateSharePermissionsForSlide = async function (payload) {
  return await API2.put('/api3/slide/share', payload);
};

export const getGeneratedUploadedSlides = async function ({
  limit,
  offset,
  createdFromString,
  slideTypeString,
  authorString,
  accessString,
  createdOnFromDate,
  createdOnToDate,
  categories,
  templateCode,
  ids,
  origin,
  search,
}) {
  return await API2.get(
    `/api3/slides-data?limit=${limit}&offset=${offset}&slideType=${slideTypeString}&createdFrom=${createdFromString}&author=${authorString}&access=${accessString}&createdOnFrom=${createdOnFromDate}&createdOnTo=${createdOnToDate}&categories=${categories}&templateCode=${templateCode}${
      origin === 'uploaded' || origin === 'synthesis' ? '&ids=' + ids : ''
    }&search=${search ? search : ''}`,
  );
};

export const createSlide = async function (payload) {
  return await API2.post(`/api3/slides-data`, payload);
};

export const addToLibrary = async function (payload) {
  return await API2.post('api3/generate/addtoprezentation', payload);
};

export const removeFromLibrary = async function (id) {
  return await API2.delete(`/prezentation/${id}`);
};

export const getSuperhawkCategories = async () => {
  const res = await API2.get('/superhawk/infoname');
  return res.data.category;
};

export const getPromptDropdownHistoryOnLoad = async function () {
  return await API2.get(`api3/generate/prompt`);
};

export const getAdminSettingsTeamUsers = async function (payload) {
  const { size, page, sortOrder, sortColumn, searchText } = payload;
  let sortByOrder = '';
  if (sortOrder.length <= 0) {
    sortByOrder = null;
  } else {
    sortByOrder = sortOrder[0] === true ? 'DESC' : 'ASC';
  }
  return await API2.get(
    `/users/myteam/admin?size=${size}&page=${page}${
      sortByOrder ? `&sort_order=${sortByOrder}` : ''
    }${
      sortColumn && sortColumn.length > 0 ? `&sort_column=${sortColumn[0]}` : ''
    }${searchText && searchText.length > 0 ? `&search=${searchText}` : ''}`,
  );
};

export const getExsitingAdminTeamUser = async function (payload) {
  return await API2.get(`/user/exist?id=${payload}`);
};

export const getExternalFingerprintResultByTestId = async function (payload) {
  return await API2.get(`/api3/external/get-external-fp-test?id=${payload}`);
};

/**
 * Comply -> APIs start
 */

export const fetchSlidesDataByUploadId = async (uploadId) => {
  return await retryOperation(
    () =>
      TC_COMPLY_API_SERVICES.get(
        `/comply/user/fetch-split-slides?upload_id=${uploadId}`,
      ),
    1000,
    3,
  );
};

export const fetchSlidesListByUploadId = async (uploadId) => {
  return await API_PRODUCT_SERVICES.get(
    `/sb/pipeline/status?callback_id=${uploadId}`,
  );
};

export const submitComplyRatings = async (body) => {
  return await TC_COMPLY_API_SERVICES.put('/comply/user/update-feedback', body);
};

export const fetchSplitSlides = async (uploadId) => {
  return await TC_COMPLY_API_SERVICES.get(
    `/comply/user/fetch-split-slides?upload_id=${uploadId}`,
  );
};

export const applyCompliance = async (applySlidePayload) => {
  return await TC_COMPLY_API_SERVICES.post(
    `/comply/user/slide-full-compliance`,
    applySlidePayload,
  );
};

export const updateSlideSuggestions = async (body) => {
  return await TC_COMPLY_API_SERVICES.post(
    'comply/user/update-suggestions',
    body,
  );
};

export const deckFullCompliance = async (applyDeckPayload) => {
  return await TC_COMPLY_API_SERVICES.post(
    `comply/user/deck-full-compliance`,
    applyDeckPayload,
  );
};

export const downloadComplyDeck = async (uploadId, outputPptx = '') => {
  if (outputPptx)
    return TC_COMPLY_API_SERVICES.get(
      `comply/user/download-deck?upload_id=${uploadId}&output_pptx=${outputPptx}`,
    );
  return await TC_COMPLY_API_SERVICES.get(
    `comply/user/download-deck?upload_id=${uploadId}`,
  );
};

export const downloadConvertTemplateDeck = async (
  uploadId,
  outputPptx = '',
) => {
  if (outputPptx) {
    return await TC_COMPLY_API_SERVICES.get(
      `comply/user/update-prez-convert-metrics?upload_id=${uploadId}&output_pptx=${outputPptx}`,
    );
  } else {
    return await TC_COMPLY_API_SERVICES.get(
      `comply/user/update-prez-convert-metrics?upload_id=${uploadId}`,
    );
  }
};

export const fetchSlideImagesFromS3 = (slidesResp) => {
  return new Promise(async (resolve, reject) => {
    try {
      let slidesData = [];
      const token = await getLoggedInUserToken();
      const awsAssetsDistribution = process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
      const uuid = uuidv4();

      const { slide_images } = slidesResp;
      // fetching the images and pptx files and re-structuring the API response as list of objects;
      for (const key in slide_images) {
        if (Object.hasOwnProperty.call(slide_images, key)) {
          let slideInfo = {};
          const pngImageInfo = slide_images[key].png;
          slide_images[key].png = {
            ...pngImageInfo,
            thumbnail: `${awsAssetsDistribution}/${pngImageInfo.s3_path}?accesskey=${token}&uuid=${uuid}`,
          };
          const pptxImageInfo = slide_images[key].pptx;
          slide_images[key].pptx = {
            ...pptxImageInfo,
            thumbnail: `${awsAssetsDistribution}/${pptxImageInfo.s3_path}?accesskey=${token}&uuid=${uuid}`,
          };
          slideInfo = { key, ...slide_images[key] };
          slidesData.push(slideInfo);
        }
      }
      resolve(slidesData);
    } catch (error) {
      console.log(error);
      reject('error while fetching slide images');
    }
  });
};

export const fetchRecomendedTemplateImages = (templates, themes, feature) => {
  return new Promise((resolve, reject) => {
    try {
      const recommendedTemplates = [];
      let disabledTemplates = [];
      const otherTemplates = [];
      const recommendedTemplateCodes = [];
      templates.recommended_templates.forEach((theme) => {
        recommendedTemplateCodes.push(theme.code);
        theme.thumbnailURL = `/assets/img/themes/${theme.code}.jpg`;
        theme.sampleSlideURL = `/assets/img/themes/${theme.code}.jpg`;
        if (theme.thumbnail) {
          getFileURL(null, `public/${theme.thumbnail}`, null, 'storage').then(
            (s3url) => {
              theme.thumbnailURL = s3url;
            },
          );
          getFileURL(null, `public/${theme.sampleSlide}`, null, 'storage').then(
            (s3url) => {
              theme.sampleSlideURL = s3url;
            },
          );
        }
        if (theme.enabled_feature_list.includes(feature)) {
          recommendedTemplates.push(theme);
        } else {
          disabledTemplates.push(theme);
        }
      });
      if (themes && themes.length) {
        templates.other_templates = [];
        themes.forEach((theme) => {
          if (recommendedTemplateCodes.indexOf(theme.code) < 0) {
            templates.other_templates.push({
              ...theme,
              template_internal_name: theme.code,
            });
          }
        });
      }
      templates.other_templates.forEach((theme) => {
        theme.thumbnailURL = `/assets/img/themes/${theme.code}.jpg`;
        theme.sampleSlideURL = `/assets/img/themes/${theme.code}.jpg`;
        if (theme.thumbnail) {
          getFileURL(null, `public/${theme.thumbnail}`, null, 'storage').then(
            (s3url) => {
              theme.thumbnailURL = s3url;
            },
          );
          getFileURL(null, `public/${theme.sampleSlide}`, null, 'storage').then(
            (s3url) => {
              theme.sampleSlideURL = s3url;
            },
          );
        }
        if (theme.enabled_feature_list.includes(feature)) {
          otherTemplates.push(theme);
        } else {
          disabledTemplates.push(theme);
        }
      });
      resolve({
        recommended_templates: recommendedTemplates,
        other_templates: otherTemplates,
        disabled_templates: disabledTemplates.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (b.name > a.name) return -1;
          return 0;
        }),
      });
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Comply -> APIs end
 */

export const getBestPracticesFrequentOutlines = async function () {
  return await API2.get(
    `/api2/outlines/frequentbyuser?isBestPracticesExample=true`,
  );
};

export const getBestPracticesExamplesDecks = async function (payload) {
  let params = {
    size: 2000,
    includeOP: false,
    sort_column: 'createdAt',
    sort_order: 'desc',
    includeUploaded: true,
    pageType: 'bestPractices',
  };

  if (payload) {
    params = { ...params, ...payload };
  }
  return await API2.get('/prezentation', { params });
};

export const getRecentsearches = async function () {
  return await API2.get(`/api3/search/recent`);
};

export const addRecentSearches = async function (payload) {
  return await API2.post(`/api3/search/recent`, payload);
};

export const deleteRecentSeaches = async function () {
  return await API2.delete(`/api3/search/recent`);
};

export const updateKnowSlideViewCount = async function (payload) {
  return await API2.post('/api3/know-slide-views-count', payload);
};

export const getBestPracticeSlideDetail = async function (id) {
  return await API2.get(`/api2/slide-data?assetID=${id}&is_know=1 `);
};

export const getSearchBrandImagesSolr = async function ({ query, skip }) {
  return await API2.post(
    `/api3/collections/brand_images/search?query=${query}&limit=10&skip=${skip}`,
  );
};

export const getSearchBrandIconsSolr = async function ({
  query,
  skip,
  templates,
  skipFetchAll = false,
}) {
  if (skipFetchAll) {
    return await API2.post(
      `/api3/collections/brand_icons/search?query=${query}&limit=12&skip=${skip}&skipFetchAll=${skipFetchAll}&templates=${templates}`,
    );
  }
  return await API2.post(
    `/api3/collections/brand_icons/search?query=${query}&limit=12&skip=${skip}&templates=${templates}`,
  );
};

export const getHiddenIdeas = async function ({
  companyID,
  themeID,
  isBPLSlides,
}) {
  return await API2.get(
    `/api3/theme/ideas/get?companyID=${companyID}&themeID=${themeID}&isBPLSlides=${isBPLSlides}`,
  );
};

export const getFeatureUsage = async function () {
  return await API2.get('/api3/feature/usage');
};

export const postFeatureUsage = async function (payload) {
  return await API2.post('/api3/feature/usage', payload);
};

export const postUpdateActions = async function (payload) {
  return await API2.put('/api3/premium-features/updateaction', payload);
};

export const flatIconsStockSearch = async function (term, limit, page) {
  let icons = [];
  await API2.get(
    `/api3/replaceicons/getlibraryicons?term=${term}&page=${page}&filters[color]=black&filters[shape]=outline`,
  )
    .then((response) => {
      icons = {
        ...response,
        resp: {
          ...response.resp,
          data: [...response.resp.data].map((item) => {
            const freepikIconDomain = item.thumbnails[0].url.split('/')[2];
            // console.log('---- freepik domain with icon from freepik ----', freepikIconDomain);

            return {
              ...item,
              thumbnails: [
                {
                  ...item.thumbnails[0],
                  // Customising the thumbnail urls according to the proxy for freepik icons
                  url: `${item.thumbnails[0].url.replace(
                    new RegExp(freepikIconDomain, 'g'),
                    miscConfig.freepik_images_proxy_url,
                  )}?site=${freepikIconDomain}`,
                },
                ...item.thumbnails.slice(1),
              ],
            };
          }),
        },
      };
    })
    .catch((err) => {
      console.log(err);
      icons = [];
    });
  return icons;
};

export const anonymizeSlides = async function (body) {
  return await API_PRODUCT_SERVICES.post('/sb/pipeline/start', body);
};

export const getAnonymizeSlidesStatus = async function (callback) {
  return await API_PRODUCT_SERVICES.get(
    `/sb/pipeline/status?callback_id=${callback}`,
  );
};

export const updateInternalFingerprint = async function (payload) {
  return await API2.put('/api3/internal/update-internal-fp', payload);
};

export const updateEditSuggestionMetric = async function (uploadId) {
  return await TC_COMPLY_API_SERVICES.post('comply/user/mark-edit-status', {
    id: uploadId,
  });
};

export const getPrezentationSearch = async function (payload, tsKey, signal) {
  return API2.post('/prezentation/tp/search', payload, {
    headers: {
      'x-typesense-api-key': tsKey.apiKey,
    },
    signal
  });
};

export const updateStockLibraryHitsForImages = async function (payload) {
  return await API2.put('/api3/replaceimages/updatedownloadcountimages', payload);
};
