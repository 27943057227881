import Vue from "vue";
import Vuex from "vuex";
import { Storage } from "aws-amplify";

Vue.use(Vuex);

export const synonyms = {
  namespaced: true,
  state: {
    synonymsList: [],
  },
  getters: {
    getSynonymsList: (state) => state.synonymsList,
  },
  mutations: {
    setSynonymsList(state, list) {
      state.synonymsList = list;
    },
  },
  actions: {
    // not using this function any where in the project.hence commenting it out
    // loadSynonyms(context) {
    //   const synonymKey = "prezentium-synonym.txt";
    //   const listSynonyms = [];
    //   Storage.get(synonymKey, { download: true }).then(async (data) => {
    //     const blob = await data.Body.text();
    //     const list = blob.toString().split("\n");
    //     for (let i = 0; i < list.length; i++) {
    //       const item = list[i].split(",");
    //       listSynonyms.push(item);
    //     }
    //     context.commit("setSynonymsList", listSynonyms);
    //   });
    // },
  },
};
