import axios from 'axios';

/**
 * getCountries
 * @returns {Promise<{ name: string; Iso2: string; Iso3: string; }[]>}
 */
const getCountries = async () => {
  const url = 'https://countriesnow.space/api/v0.1/countries/iso';
  const response = await axios.get(url);
  return response.data.data;
};

/**
 * getStates
 * @param {string} iso2
 * @returns {Promise<{ name: string; state_code: string; }[]>}
 */
const getStates = async (iso2) => {
  const url = 'https://countriesnow.space/api/v0.1/countries/states/q';
  const response = await axios.get(url, { params: { iso2 } });
  return response.data.data.states;
};

export const CountriesNowService = { getCountries, getStates };
