/* eslint-disable class-methods-use-this */

import API, { TC_COMPLY_API_SERVICES } from '../../../utils/api';
import { retryOperation } from '../../../utils/api-helper';

export class TemplateDesignerService {
  intervals = [];

  constructor() {
    console.log('Template Designer Service initiated');
  }

  async getUploadId(payload) {
    return new Promise((resolve, reject) => {
      retryOperation(
        () => TC_COMPLY_API_SERVICES.post('/template-designer/upload', payload),
        1000,
        3,
      )
        .then((resp) => {
          if (resp?.status === 'success') {
            resolve(resp);
          } else if (resp?.status === 'error') {
            reject(new Error('Failed'));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async fetchBrandPref(uploadId, intervalCallback) {
    return new Promise((resolve, reject) => {
      let pollInterval;
      function getStatus() {
        retryOperation(
          () =>
            TC_COMPLY_API_SERVICES.get(
              `/template-designer/extract-brand-preferences?upload_id=${uploadId}`,
            ),
          2000,
          3,
        )
          .then((resp) => {
            if (resp?.status === 'success') {
              resolve(resp?.data);
              clearInterval(pollInterval);
            } else if (resp?.status === 'error') {
              reject(new Error('Failed'));
              clearInterval(pollInterval);
            }
          })
          .catch((err) => {
            clearInterval(pollInterval);
            reject(err);
          });
      }
      getStatus();
      pollInterval = setInterval(() => {
        getStatus();
      }, 3000);
      intervalCallback(pollInterval);
    });
  }

  async extractTargetFilters(uploadId, intervalCallback, filter) {
    return new Promise((resolve, reject) => {
      let pollInterval;
      function getStatus() {
        retryOperation(
          () =>
            TC_COMPLY_API_SERVICES.get(
              `/template-designer/extract-target-filters?upload_id=${uploadId}&filter=${filter}`,
            ),
          1000,
          3,
        )
          .then((resp) => {
            if (resp?.status === 'success') {
              clearInterval(pollInterval);
              resolve(resp);
            } else if (resp?.status === 'error') {
              clearInterval(pollInterval);
              reject(new Error('Failed'));
            }
          })
          .catch((err) => {
            clearInterval(pollInterval);
            reject(err);
          });
      }
      getStatus();
      pollInterval = setInterval(() => {
        getStatus();
      }, 3000);
      intervalCallback(pollInterval);
    });
  }

  async submitTemplateConversion(payload) {
    return new Promise((resolve, reject) => {
      retryOperation(
        () =>
          TC_COMPLY_API_SERVICES.post(
            'template-designer/customize-template',
            payload,
          ),
        1000,
        1,
      )
        .then((resp) => {
          if (resp?.status === 'success') {
            resolve(resp);
          } else if (resp?.status === 'error') {
            reject(new Error('Failed'));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async fetchDesignerTemplates() {
    return new Promise((resolve, reject) => {
      TC_COMPLY_API_SERVICES.get(`/template-designer/fetch-templates`)
        .then((resp) => {
          if (resp?.status === 'success') {
            resolve(resp.data);
          } else if (resp?.status === 'error') {
            reject(new Error('Failed'));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async fetchPreviewDeck(uploadId, intervalCallback, filter = 'preview_deck') {
    return new Promise((resolve, reject) => {
      let pollInterval;
      function getStatus() {
        retryOperation(
          () =>
            TC_COMPLY_API_SERVICES.get(
              `template-designer/extract-preview-deck?upload_id=${uploadId}&filter=${filter}`,
            ),
          1000,
          1,
        )
          .then((resp) => {
            if (resp?.status === 'success') {
              clearInterval(pollInterval);
              resolve(resp);
            } else if (resp?.status === 'error') {
              clearInterval(pollInterval);
              reject(new Error('Failed'));
            }
          })
          .catch((err) => {
            clearInterval(pollInterval);
            reject(err);
          });
      }
      getStatus();
      pollInterval = setInterval(() => {
        getStatus();
      }, 3000);
      intervalCallback(pollInterval);
    });
  }

  async cancel() {
    this.intervals.forEach((interval) => clearInterval(interval));
    this.intervals = [];
  }

  async updateBanner(body) {
    try {
      const resp = await API.post('/api3/designer/banners', body);
      return resp;
    } catch (err) {
      return err;
    }
  }

  async updateDesignerStats(body) {
    try {
      const resp = await API.put('/api3/designer/stats', body);
      return resp;
    } catch (err) {
      return err;
    }
  }
}
