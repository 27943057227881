export const fpLearnFingerprints = {
  title: 'fingerprints',
  videos: {
    en: '/20230206/121027/videos/KYF_00_Overview.m3u8',
    ja: '/20231012/112715/videos/KYF_00_Overview_ja.m3u8',
  },
  videoDuration: 2,
};

export const fpTypes = [
  {
    title: 'director',
    logo_transparent: '/assets/img/lion_transparent.svg',
    logo: '/assets/img/lion.png',
    logo_hover: '/assets/img/lion_blue.svg',
    videos: {
      en: '/20230202/55843/videos/KYF_02_Director.m3u8',
      ja: '/20231012/112835/videos/KYF_02_Director_ja.m3u8',
    },
    videoDuration: 4,
    videoThumbnailURL: '/assets/img/director-illustration.png',
    public: true,
    description:
      'Directors are pragmatic leaders with a bias for action. They process information by analyzing the data and translating into concrete actionable steps. They appreciate the rich tapestry of bold communication.',
    preferences: [
      {
        title: 'fingerprint.visualPreference',
        preference: 'Vibrant',
        oneLine: 'Open to bold imagery and colors',
        logo: '/assets/img/welcome-item-vibrant.svg',
      },
      {
        title: 'fingerprint.storyChoice',
        preference: 'Inductive',
        oneLine: 'Punchline early, followed by facts',
        logo: '/assets/img/welcome-item-inductive.svg',
      },
      {
        title: 'fingerprint.dataStyle',
        preference: 'Objective',
        oneLine: 'Lead with the data',
        logo: '/assets/img/welcome-item-objective.svg',
      },
    ],
    dos: [
      'Start with executive summary to grab attention',
      'Be prepared to dive into detail and answer questions',
      'Use vibrant layouts and graphics',
    ],
    donts: [
      'Be long-winded in getting to the answer',
      'Be rigid in the flow of the deck',
      'Skip critical data and facts',
    ],
  },
  {
    title: 'navigator',
    logo_transparent: '/assets/img/Wolf_transparent.svg',
    logo: '/assets/img/Wolf.png',
    logo_hover: '/assets/img/Wolf_blue.svg',
    videos: {
      en: '/20230202/4245/videos/KYF_03_Navigator.m3u8',
      ja: '/20231012/112830/videos/KYF_03_Navigator_ja.m3u8',
    },
    videoDuration: 3,
    videoThumbnailURL: '/assets/img/navigator-illustration.png',
    public: true,
    description:
      'Navigators are confident and bold self-starters. They focus on the key takeaways and appreciate powerful storytelling using vivid images.',
    preferences: [
      {
        title: 'fingerprint.visualPreference',
        preference: 'Vibrant',
        oneLine: 'Open to bold imagery and colors',
        logo: '/assets/img/welcome-item-vibrant.svg',
      },
      {
        title: 'fingerprint.storyChoice',
        preference: 'Inductive',
        oneLine: 'Punchline early, followed by facts',
        logo: '/assets/img/welcome-item-inductive.svg',
      },
      {
        title: 'fingerprint.dataStyle',
        preference: 'Narrative',
        oneLine: 'Support argument with data',
        logo: '/assets/img/welcome-item-narrative.svg',
      },
    ],
    dos: [
      'Start with executive summary to grab attention',
      'Use vibrant layouts and graphics',
      'Utilize simple graphs to visualize data',
    ],
    donts: [
      'Be long-winded in getting to the answer',
      'Be rigid in the flow of the deck',
      'Clutter with data-heavy tables',
    ],
  },
  {
    title: 'scholar',
    logo_transparent: '/assets/img/Owl_transparent.svg',
    logo: '/assets/img/Owl.png',
    logo_hover: '/assets/img/Owl_blue.svg',
    videos: {
      en: '/20230202/424/videos/KYF_06_Scholar.m3u8',
      ja: '/20231012/112923/videos/KYF_06_Scholar_ja.m3u8',
    },
    videoDuration: 4,
    videoThumbnailURL: '/assets/img/scholar-illustration.png',
    public: true,
    description:
      'Scholars are intellectuals seeking to crack the toughest problems. They are highly comfortable with data and appreciate exhaustive analysis to reach any conclusion. They are intrigued by striking visuals.',
    preferences: [
      {
        title: 'fingerprint.visualPreference',
        preference: 'Vibrant',
        oneLine: 'Open to bold imagery and colors',
        logo: '/assets/img/welcome-item-vibrant.svg',
      },
      {
        title: 'fingerprint.storyChoice',
        preference: 'Deductive',
        oneLine: 'Logic and facts first, punchline later',
        logo: '/assets/img/welcome-item-deductive.svg',
      },
      {
        title: 'fingerprint.dataStyle',
        preference: 'Objective',
        oneLine: 'Lead with the data',
        logo: '/assets/img/welcome-item-objective.svg',
      },
    ],
    dos: [
      'Share the analysis or approach used to reach conclusion',
      'Be prepared to dive into detail and answer questions',
      'Use vibrant layouts and graphics',
    ],
    donts: [
      'Start with the answer, unless asked to',
      'Skip critical data and facts',
      'Use dull colors',
    ],
  },
  {
    title: 'performer',
    logo_transparent: '/assets/img/Peacock_transparent.svg',
    logo: '/assets/img/Peacock.png',
    logo_hover: '/assets/img/Peacock_blue.svg',
    videos: {
      en: '/20230202/4214/videos/KYF_04_Performer.m3u8',
      ja: '/20231012/11298/videos/KYF_04_Performer_ja.m3u8',
    },
    videoDuration: 3,
    videoThumbnailURL: '/assets/img/performer-illustration.png',
    public: true,
    description:
      'Performers are charismatic presenters who can deliver any message with panache. They like to reach conclusions driven by facts and intuition. They revel in bold visualization of ideas.',
    preferences: [
      {
        title: 'fingerprint.visualPreference',
        preference: 'Vibrant',
        oneLine: 'Open to bold imagery and colors',
        logo: '/assets/img/welcome-item-vibrant.svg',
      },
      {
        title: 'fingerprint.storyChoice',
        preference: 'Deductive',
        oneLine: 'Logic and facts first, punchline later',
        logo: '/assets/img/welcome-item-deductive.svg',
      },
      {
        title: 'fingerprint.dataStyle',
        preference: 'Narrative',
        oneLine: 'Support argument with data',
        logo: '/assets/img/welcome-item-narrative.svg',
      },
    ],
    dos: [
      'Share the analysis or approach used to reach conclusion',
      'Utilize simple graphs to visualize relevant data',
      'Use vibrant layouts and graphics',
    ],
    donts: [
      'Clutter with data and tables',
      'Start with the answer, unless asked to',
      'Use dull colors',
    ],
  },
  {
    title: 'architect',
    logo_transparent: '/assets/img/Horse_transparent.svg',
    logo: '/assets/img/Horse.svg',
    logo_hover: '/assets/img/Horse_blue.svg',
    videos: {
      en: '/20230202/41412/videos/KYF_01_Architect.m3u8',
      ja: '/20231012/112828/videos/KYF_01_Architect_ja.m3u8',
    },
    videoDuration: 3,
    videoThumbnailURL: '/assets/img/architect-illustration.png',
    public: true,
    description:
      'Architects are innovators energized by the next big thing. They believe in expressing complex ideas with minimalist perfection. They strive for results and are fueled by data.',
    preferences: [
      {
        title: 'fingerprint.visualPreference',
        preference: 'Minimal',
        oneLine: 'Simplicity in design',
        logo: '/assets/img/welcome-item-minimal.svg',
      },
      {
        title: 'fingerprint.storyChoice',
        preference: 'Inductive',
        oneLine: 'Punchline early, followed by facts',
        logo: '/assets/img/welcome-item-inductive.svg',
      },
      {
        title: 'fingerprint.dataStyle',
        preference: 'Objective',
        oneLine: 'Lead with the data',
        logo: '/assets/img/welcome-item-objective.svg',
      },
    ],
    dos: [
      'Start with executive summary to grab attention',
      'Be prepared to dive into detail and answer questions',
      'Use simple layouts that are easy on the eye ',
    ],
    donts: [
      'Be long-winded in getting to the answer',
      'Skip critical data and facts',
      'Use loud colors or distracting images',
    ],
  },
  {
    title: 'surgeon',
    logo_transparent: '/assets/img/Eagle_transparent.svg',
    logo: '/assets/img/Eagle.png',
    logo_hover: '/assets/img/Eagle_blue.svg',
    videos: {
      en: '/20230202/4234/videos/KYF_08_Surgeon.m3u8',
      ja: '/20231012/112810/videos/KYF_08_Surgeon_ja.m3u8',
    },
    videoDuration: 3,
    videoThumbnailURL: '/assets/img/surgeon-illustration.png',
    public: true,
    description:
      'Surgeons are driven by strong purpose. They strive for logical outcomes backed by wisdom. They believe in succinct communication.',
    preferences: [
      {
        title: 'fingerprint.visualPreference',
        preference: 'Minimal',
        oneLine: 'Simplicity in design',
        logo: '/assets/img/welcome-item-minimal.svg',
      },
      {
        title: 'fingerprint.storyChoice',
        preference: 'Inductive',
        oneLine: 'Punchline early, followed by facts',
        logo: '/assets/img/welcome-item-inductive.svg',
      },
      {
        title: 'fingerprint.dataStyle',
        preference: 'Narrative',
        oneLine: 'Support argument with data',
        logo: '/assets/img/welcome-item-narrative.svg',
      },
    ],
    dos: [
      'Start with executive summary to grab attention',
      'Utilize simple graphs to visualize data',
      'Use simple layouts that are easy on the eye ',
    ],
    donts: [
      'Be long-winded in getting to the answer',
      'Clutter with data-heavy tables',
      'Use loud colors or distracting images',
    ],
  },
  {
    title: 'scientist',
    logo_transparent: '/assets/img/Dolphin_transparent.svg',
    logo: '/assets/img/Dolphin.png',
    logo_hover: '/assets/img/Dolphin_blue.svg',
    videos: {
      en: '/20230202/4237/videos/KYF_07_Scientist.m3u8',
      ja: '/20231012/112849/videos/KYF_07_Scientist_ja.m3u8',
    },
    videoDuration: 3,
    videoThumbnailURL: '/assets/img/scientist-illustration.png',
    public: true,
    description:
      'Scientists are curious learners with an inclination for in-depth problem-solving. They are highly comfortable with data. Simplicity in design helps them keep focus.',
    preferences: [
      {
        title: 'fingerprint.visualPreference',
        preference: 'Minimal',
        oneLine: 'Simplicity in design',
        logo: '/assets/img/welcome-item-minimal.svg',
      },
      {
        title: 'fingerprint.storyChoice',
        preference: 'Deductive',
        oneLine: 'Logic and facts first, punchline later',
        logo: '/assets/img/welcome-item-deductive.svg',
      },
      {
        title: 'fingerprint.dataStyle',
        preference: 'Objective',
        oneLine: 'Lead with the data',
        logo: '/assets/img/welcome-item-objective.svg',
      },
    ],
    dos: [
      'Share the analysis or approach used to reach conclusion',
      'Be prepared to dive into detail and answer questions',
      'Use simple layouts that are easy on the eye',
    ],
    donts: [
      'Start with the answer, unless asked to',
      'Skip critical data and facts',
      'Use loud colors or distracting images',
    ],
  },
  {
    title: 'producer',
    logo_transparent: '/assets/img/Bear_transparent.svg',
    logo: '/assets/img/Bear.png',
    logo_hover: '/assets/img/Bear_blue.svg',
    videos: {
      en: '/20230202/4941/videos/KYF_05_Producer.m3u8',
      ja: '/20231012/112819/videos/KYF_05_Producer_ja.m3u8',
    },
    videoDuration: 3,
    videoThumbnailURL: '/assets/img/producer-illustration.png',
    public: true,
    description:
      'Producers are risk-takers with clear vision. They believe in the power of good storytelling backed by logic. They avoid anything that distracts them from the main goal.',
    preferences: [
      {
        title: 'fingerprint.visualPreference',
        preference: 'Minimal',
        oneLine: 'Simplicity in design',
        logo: '/assets/img/welcome-item-minimal.svg',
      },
      {
        title: 'fingerprint.storyChoice',
        preference: 'Deductive',
        oneLine: 'Logic and facts first, punchline later',
        logo: '/assets/img/welcome-item-deductive.svg',
      },
      {
        title: 'fingerprint.dataStyle',
        preference: 'Narrative',
        oneLine: 'Support argument with data',
        logo: '/assets/img/welcome-item-narrative.svg',
      },
    ],
    dos: [
      'Share the analysis or approach used to reach conclusion',
      'Utilize simple graphs to visualize data',
      'Use simple layouts that are easy on the eye',
    ],
    donts: [
      'Clutter with data-heavy tables',
      'Start with the answer, unless asked to',
      'Use loud colors or distracting images',
    ],
  },
  {
    title: 'locked',
    logo: '/assets/img/lock-icon@100px.svg',
    public: false,
    description: '',
    preferences: [
      {
        title: 'fingerprint.visualPreference',
        preference: 'Minimal',
        oneLine: 'Simplicity in design',
        logo: '/assets/img/lock-icon@100px.svg',
      },
      {
        title: 'fingerprint.storyChoice',
        preference: 'Deductive',
        oneLine: 'Logic and facts first, punchline later',
        logo: '/assets/img/lock-icon@100px.svg',
      },
      {
        title: 'fingerprint.dataStyle',
        preference: 'Narrative',
        oneLine: 'Support argument with data',
        logo: '/assets/img/lock-icon@100px.svg',
      },
    ],
  },
];

export const fpQuestions = [
  {
    header: 'fpTest.header1',
    subHeader: 'fpTest.subHeader1',
    headeraud: 'fpTest.headerAudience1',
    subHeaderaud: 'fpTest.subHeaderAudience1',
    leftImage: '/assets/img/question1_1.png',
    rightImage: '/assets/img/question1_2.png',
  },
  {
    header: 'fpTest.header1',
    subHeader: 'fpTest.subHeader2',
    headeraud: 'fpTest.headerAudience1',
    subHeaderaud: 'fpTest.subHeaderAudience2',
    leftImage: '/assets/img/question2_1.png',
    rightImage: '/assets/img/question2_2.png',
  },
  {
    header: 'fpTest.header1',
    subHeader: 'fpTest.subHeader3',
    headeraud: 'fpTest.headerAudience1',
    subHeaderaud: 'fpTest.subHeaderAudience3',
    leftImage: '/assets/img/question3_1.png',
    rightImage: '/assets/img/question3_2.png',
  },
  {
    header: 'fpTest.headerArgument',
    subHeader: 'fpTest.subHeaderArgument',
    headeraud: 'fpTest.headerAudienceArgument',
    subHeaderaud: 'fpTest.subHeaderAudienceArgument',
    leftImage: '/assets/img/question4_1.png',
    rightImage: '/assets/img/question4_2.png',
  },
  {
    header: 'fpTest.headerConvincing',
    subHeader: 'fpTest.subHeaderConvincing',
    headeraud: 'fpTest.headerAudienceConvincing',
    subHeaderaud: 'fpTest.subHeaderAudienceConvincing',
    leftImage: '/assets/img/question5_1.png',
    rightImage: '/assets/img/question5_2.png',
  },
  {
    header: 'fpTest.headerDataPresentation',
    subHeader: 'fpTest.subHeaderDataPresentation',
    headeraud: 'fpTest.headerAudienceDataPresentation',
    subHeaderaud: 'fpTest.subHeaderAudienceDataPresentation',
    leftImage: '/assets/img/question6_1.png',
    rightImage: '/assets/img/question6_2.png',
  },
  {
    header: 'fpTest.headerPreferences',
    subHeader: 'fpTest.subHeaderPreferences',
    headeraud: 'fpTest.headerAudiencePreferences',
    subHeaderaud: 'fpTest.subHeaderAudiencePreferences',
  },
  {
    header: 'fpTest.headerIndustry',
    subHeader: 'fingerprint.whyWeAsk',
    headeraud: 'fpTest.headerAudienceIndustry',
    subHeaderaud: 'fingerprint.whyWeAsk',
    tooltip:
      'This makes it easier for your colleagues to find you on Prezent while also helping us personalize your Prezent experience.',
  },
  {
    header: 'fpTest.headerFunction',
    subHeader: 'fingerprint.whyWeAsk',
    headeraud: 'fpTest.headerAudienceFunction',
    subHeaderaud: 'fingerprint.whyWeAsk',
  },
  {
    header: 'fpTest.headerGroup',
    subHeader: 'fingerprint.whyWeAsk',
    headeraud: 'fpTest.headerAudienceGroup',
    subHeaderaud: 'fingerprint.whyWeAsk',
  },
  {
    header: 'fpTest.headerRegionFocus',
    subHeader: 'fingerprint.whyWeAsk',
    headeraud: 'fpTest.headerAudienceRegionFocus',
    subHeaderaud: 'fingerprint.whyWeAsk',
  },
  {
    header: 'fpTest.headerJobTitle',
    subHeader: 'fingerprint.whyWeAsk',
    headeraud: 'fpTest.headerAudienceJobTitle',
    subHeaderaud: 'fingerprint.whyWeAsk',
  },
];

export const fpSelections = [
  {
    value: 'startWithSummary',
    title: 'Start with an Executive Summary',
    image: 'assets/img/preferences-Start_with_an_executive_summary.svg',
  },
  {
    value: 'labelAllCharts',
    title: 'Label all charts',
    image: 'assets/img/preferences-Label_all_charts.svg',
  },
  {
    value: 'MinsteadOfMM',
    title: '$M instead of $mm',
    image: 'assets/img/preferences-$M_instead_of_$mm.svg',
  },
  {
    value: 'stateObjectiveUpfront',
    title: 'State objective upfront',
    image: 'assets/img/preferences-State_objective_upfront.svg',
  },
  {
    value: 'allText14Font',
    title: 'All text > 14 pt font',
    image: 'assets/img/preferences-All_text_14pt_font.svg',
  },
  {
    value: 'sendDeckADay',
    title: 'Send deck a day in advance',
    image: 'assets/img/preferences-Send_deck_a_day_in_advance.svg',
  },
  {
    value: 'actionOrientedTitles',
    title: 'Action-oriented titles',
    image: 'assets/img/preferences-Action_oriented_titles.svg',
  },
  {
    value: 'threeMinutes',
    title: '3 minutes per slide',
    image: 'assets/img/preferences-3_minutes_per_slide.svg',
  },
  {
    value: 'pauseForQuestions',
    title: 'Pause for questions',
    image: 'assets/img/preferences-Pause_for_questions.svg',
  },
  {
    value: 'leave50pForQuestons',
    title: 'Leave 50% time for questions',
    image: 'assets/img/preferences-Leave_50_time_for_questions.svg',
  },
  {
    value: 'footnotesMatter',
    title: 'Footnotes matter',
    image: 'assets/img/preferences-Footnotes_matter.svg',
  },
  {
    value: 'noAnimations',
    title: 'No animations, please',
    image: 'assets/img/preferences-No_animations_please.svg',
  },
];
