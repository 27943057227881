import { uploadAsyncDeck } from '../../components/Main/ComplianceChecker/utils/comply-helper.utils';
import {uploadOPFileBatchChunk, uploadOPFile} from '../../utils/api-helper';
export const compliance = {
    namespaced: true,
    state: {
        fileDetails: null,
    },
    getters: {
        getFileS3Details: (state) => { return {"s3": state.fileDetails.s3Bucket, "filepath": state.fileDetails.s3Prefix, "filename": state.fileDetails.name, "sizeKb": state.fileDetails.sizeKb}},
        getFileDetails: (state) => state.fileDetails,
    },
    mutations: {
        SET_FILE(state, file) {
            state.fileDetails = file;
        },
        RESET_FILE(state) {
            state.fileDetails = null;
        }
    },
    actions: {
        async getFileUploadId({ commit , getters }) {
            try {
                const payload = getters.getFileS3Details;
                const resp = await uploadAsyncDeck(payload)
                const fileDetails = getters.getFileDetails;
                fileDetails.uploadId = resp.data.upload_id;
                console.log(fileDetails);
                commit('SET_FILE', fileDetails);
                return resp
            } catch (error) {
                console.log(error)
                throw error
            }
        },
        async resetFileData({ commit }) {
            commit('RESET_FILE')
        },
        async uploadComplyFile({ commit, dispatch }, file) {
            commit('RESET_FILE');
            try {
              const preprocessUploadResponse = await dispatch('uploadChunks', file);
              if (preprocessUploadResponse) {
                const postProcessUploadResponse = await dispatch('uploadFilePostProcess', file);
                if (postProcessUploadResponse) {
                    commit('SET_FILE', postProcessUploadResponse)
                    return postProcessUploadResponse;
                }
              } else {
                return { error: 'File upload failed!' };
              }
            } catch (error) {
              console.log(error);
              return { error: 'File upload failed!' };
            }
        },
        uploadChunks({commit}, file) {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = async () => {
                const chunkSize = 3 * 1024 ** 2;
                const chunks = [];
                let chunkIndex = 0;
                for (
                  let start = 0;
                  start < reader.result.length;
                  start += chunkSize
                ) {
                  const chunk = reader.result.slice(start, start + chunkSize);
                  chunks.push({
                    chunk,
                    chunkIndex,
                    requestIdentifier: file.requestIdentifier,
                    filename: file.name,
                  });
                  chunkIndex++;
                }
                const chunkBatch = chunks.reduce((resultArray, item, index) => {
                  const chunkIdx = Math.floor(index / 5);
      
                  if (!resultArray[chunkIdx]) {
                    resultArray[chunkIdx] = []; // start a new chunk
                  }
      
                  resultArray[chunkIdx].push(item);
      
                  return resultArray;
                }, []);
                try {
                  await uploadOPFileBatchChunk(
                    chunkBatch,
                    chunkIndex,
                    (prog) => {
                      console.log(prog);
                      file.progress = prog;
                    },
                    'comply',
                  );
                  resolve('success');
                } catch (err) {
                  reject(err);
                }
              };
              reader.onerror = (error) => {
                reject(error);
              };
              reader.readAsDataURL(file.file);
            });
        },
        uploadFilePostProcess({ commit }, file) {
            return new Promise(async (resolve, reject) => {
                try {
                    const postProcessUpload = await uploadOPFile(file.requestIdentifier, file.name, 'comply');
                    if (postProcessUpload) {
                        file = { ...file, ...postProcessUpload.data, processingCompleted: true }; 
                        return resolve(file)
                    } else {
                        return reject('error while uploading')
                    }
                } catch(error) {
                    file.processingCompleted = true;
                    file.error = true;
                    file.progress = 'failed';
                    return reject('error while uploading');
                }
            })
        }
    }
}