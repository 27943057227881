<template>
  <div class="view-dt-container">
    <div class="view-dt-wrap">
      <div class="view-dt-header">
        Select an existing brand template or create your own
      </div>
      <div class="view-dt-cards-wrap">
        <v-row>
          <v-col
            class="col-xs-12 col-sm-6 col-md-4 col-xs-12 col-lg-4 col-xl-4"
            :key="index"
            v-for="(card, index) in designerTemplates"
            @click="handleSelectTD(card, index)"
          >
            <TemplateDesignerCard
              :templateDesignerCard="card"
              :selected="selectedCardIndex === index"
              @onPreviewClick="handlePreviewClick"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="cta-view-dt-wrap">
      <div class="add-template-wrap">
        Can’t find your brand template?
        <a @click="handleAddNow">Add it now</a>
      </div>
      <div class="btn-help-wrap">
        <div class="button-container">
          <v-btn class="next" :disabled="isNextDisabled" @click="handleNext">
            Next</v-btn
          >
        </div>
        <div class="help-wrap">
          <p>
            {{ $t('templateDesign.needHelp') }}
            <a @click="getInTouch">{{ $t('templateDesign.getInTouch') }}</a>
            {{ $t('templateDesign.designTeamNow') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import TemplateDesignerCard from './TemplateDesignerCard.vue';
import ContactSoonModal from '../../../components/common/ContactSoonModal.vue';
import { sendEmail } from '../../../utils/api-helper';
import TemplatePreviewPopup from './TemplatePreviewPopup';
import { TemplateDesignerService } from './TemplateDesignerService';
import { designerTemplateType } from './constants.js';

export default {
  name: 'ViewDesignerTemplates',
  components: {
    TemplateDesignerCard,
  },
  computed: {
    ...mapState('users', ['currentUser']),
    isNextDisabled() {
      return !Object.keys(this.selectedDT).length;
    },
    templateDesignerService() {
      return new TemplateDesignerService();
    },
  },
  data() {
    return {
      selectedDT: {},
      selectedCardIndex: null,
    };
  },
  mounted() {},
  props: {
    designerTemplates: {
      type: Array,
    },
  },
  methods: {
    getInTouch() {
      try {
        const params = {
          type: 'template-designer-request',
          meta: {
            firstName: `${this.currentUser?.user?.firstName}`,
            trialDate: `${moment(new Date()).format('MM/DD/YYYY')}`,
          },
        };
        sendEmail(params)
          .then((response) => console.log(response))
          .catch((err) => console.log(err));
      } catch (err) {
        console.log(err);
      }
      this.$modal.show(
        ContactSoonModal,
        {},
        {
          name: 'ContactSoonModal',
          width: '449px',
          height: '503px',
          styles: {
            borderRadius: '12px',
          },
        },
      );
    },
    handleNext() {
      this.updateDesignerStats('existing');
      this.$emit('onNext', this.selectedDT);
    },
    async updateDesignerStats(type) {
      let statsPayload = {};
      if (type === 'existing') {
        statsPayload = {
          templateType: designerTemplateType.existing,
          templateName: this.selectedDT?.name,
        };
      } else if (type === 'custom') {
        statsPayload = {
          templateType: designerTemplateType.designer,
        };
      }
      await this.templateDesignerService.updateDesignerStats(statsPayload);
    },
    handleAddNow() {
      this.updateDesignerStats('custom');
      this.$emit('onAddNow');
    },
    async handlePreviewClick(selectedCard) {
      selectedCard.e.preventDefault();
      selectedCard.e.stopPropagation();

      this.$modal.show(
        TemplatePreviewPopup,
        { selectedCard },
        {
          name: 'TemplatePreviewPopup',
          width: '780px',
          height: '700px',
          styles: {
            borderRadius: '12px',
          },
        },
      );
    },
    handleSelectTD(data, index) {
      this.selectedDT = data;
      this.selectedCardIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.view-dt-container {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  margin: 10px;
  .view-dt-wrap {
    display: flex;
    flex-direction: column;
    .view-dt-header {
      font-family: Lato;
      font-size: 45px;
      font-weight: 400;
      line-height: 52px;
      text-align: center;
      margin-bottom: 20px;
    }
    .view-dt-cards-wrap {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      min-height: 0;
      flex: 1;
      padding: 20px;
      .row {
        overflow-y: auto;
        height: 430px;
      }
    }
  }

  .cta-view-dt-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    padding-top: 20px;
    .add-template-wrap {
      font-family: Lato;
      font-size: 22px;
      font-weight: 400;
      line-height: 28px;
      text-align: center;
      a {
        font-weight: 700;
      }
    }
    .btn-help-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      .button-container {
        padding: 20px;
        margin: 10px;
        padding-bottom: 0;
        .next {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 6px 22px;
          gap: 8px;

          width: auto;
          height: 32px;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          text-transform: capitalize;
          letter-spacing: normal;
          line-height: 19px;
          color: #ffffff;
          background: #21a7e0;
          border-radius: 25px;
        }
      }
      .help-wrap {
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
        text-align: center;
      }
    }
  }
}
</style>
