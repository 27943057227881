var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-preview-popup-wrapper"},[_c('v-btn',{staticClass:"close-btn",attrs:{"icon":""},on:{"click":_vm.handleClose}},[_c('v-icon',{attrs:{"dark":""},domProps:{"textContent":_vm._s("mdi-close")}})],1),(_vm.loading)?_c('div',{staticClass:"loader-container"},[_c('clip-loader',{staticClass:"cliploader",attrs:{"loading":_vm.loading,"color":"#21a7e0","width":'60',"height":'60',"size":'50px'}})],1):_c('div',{staticClass:"template-preview-popup-wrapper-content"},[_c('div',{staticClass:"popup-carousel-wrapper"},[_c('v-carousel',{staticClass:"slide-img",attrs:{"height":"auto","hide-delimiters":"","light":"","prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right"},model:{value:(_vm.currentImage),callback:function ($$v) {_vm.currentImage=$$v},expression:"currentImage"}},_vm._l((_vm.themeImages),function(tImage,index){return _c('v-carousel-item',{key:index},[_c('v-img',{staticClass:"v-slide-img",attrs:{"src":tImage,"width":"624px","lazy-src":"/assets/img/slides/placeholder-slide.svg"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0 align-placeholder",attrs:{"align":"center","justify":"center"}},[_c('clip-loader',{attrs:{"color":"#21a7e0","width":20,"height":20}})],1)]},proxy:true}],null,true)})],1)}),1),_c('div',{staticClass:"carousel-nav-section"},_vm._l((_vm.themeImages),function(img,j){return _c('img',{key:j,attrs:{"src":_vm.currentImage === j
              ? "/assets/img/trial/dot-indicator-active.svg"
              : "/assets/img/trial/dot-indicator-inactive.svg","alt":""},on:{"click":function($event){return _vm.setCurrentImage(j)}}})}),0)],1),_c('div',{staticClass:"popup-data-wrapper"},[_c('div',{staticClass:"data-title"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(_vm.selectedCard.name))]),_c('p',{staticClass:"createdBy"},[_vm._v("Created by "+_vm._s(_vm.selectedCard.uploadedByName))])]),(
          _vm.selectedCard.theme_color_palette &&
          _vm.selectedCard.theme_color_palette.length
        )?_c('div',{staticClass:"palette-wrapper"},[_c('p',[_vm._v("Shapes Color Palette")]),_c('div',{staticClass:"color-wrap"},_vm._l((_vm.selectedCard.theme_color_palette),function(color,index){return _c('div',{key:("" + (color + index)),staticClass:"color-fill",style:({ background: ("#" + color) })})}),0)]):_vm._e(),(
          _vm.selectedCard.chart_color_palette &&
          _vm.selectedCard.chart_color_palette.length
        )?_c('div',{staticClass:"palette-wrapper"},[_c('p',[_vm._v("Charts Color Palette")]),_c('div',{staticClass:"color-wrap"},_vm._l((_vm.selectedCard.chart_color_palette),function(color,index){return _c('div',{key:("" + (color + index)),staticClass:"color-fill",style:({ background: ("#" + color) })})}),0)]):_vm._e(),(_vm.selectedCard.header_font)?_c('div',{staticClass:"font-wrapper"},[_c('p',{staticClass:"font-title"},[_vm._v("Font Style")]),_c('p',{staticClass:"font-body"},[_c('span',{style:({ 'font-family': _vm.selectedCard.header_font })},[_vm._v(" "+_vm._s(_vm.selectedCard.header_font)+" (Header),")]),_c('span',{style:({ 'font-family': _vm.selectedCard.body_font })},[_vm._v(" "+_vm._s(_vm.selectedCard.body_font)+" (Body) ")])])]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }