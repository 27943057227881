<template>
  <div v-if="!isNotValidUser" class="paymentPage">
    <div class="content">
      <clip-loader
        v-if="userInfoLoading"
        :loading="userInfoLoading"
        :color="`#21a7e0`"
        :width="'60'"
        :height="'60'"
        :size="'50px'"
        class="cliploader"
      />
      <div v-else class="paymentOrderWrapper">
        <div class="orderInformation">
          <div class="header">
            <div class="heading">Submit payment</div>
            <div class="subHeading">
              Select the number of licenses and submit payment information
            </div>
          </div>
          <div class="personal">
            <div class="yourInfo">Your information:</div>
            <v-form class="vForm">
              <div class="userInfo">
                <div class="label">First name</div>
                <v-text-field
                  v-model="firstName"
                  :disabled="true"
                  required
                  single-line
                  color="#000"
                  id="first"
                  :loading="userInfoLoading"
                />
              </div>
              <div class="userInfo">
                <div class="label">Last name</div>
                <v-text-field
                  v-model="lastName"
                  :disabled="true"
                  required
                  single-line
                  color="#000"
                  id="last"
                  :loading="userInfoLoading"
                />
              </div>
              <div class="userInfo">
                <div class="label">Email</div>
                <v-text-field
                  type="email"
                  v-model="email"
                  :disabled="true"
                  required
                  single-line
                  color="#000"
                  id="email"
                  :loading="userInfoLoading"
                />
              </div>
              <div class="userInfo">
                <div class="label">Company name</div>
                <v-text-field
                  type="companyName"
                  v-model="companyName"
                  :disabled="true"
                  required
                  single-line
                  color="#000"
                  id="companyName"
                  :loading="userInfoLoading"
                />
              </div>
            </v-form>
          </div>
          <div class="licenses">
            <div class="licensesTitle">Licenses:</div>
            <div class="subTitle">
              Select the number of licenses to include with your subscription
            </div>
            <div class="quantityWrapper">
              <div>
                <number-input
                  :min="1"
                  :max="9999"
                  :value="licences"
                  inline
                  center
                  controls
                  size="small"
                  :step="1"
                  @change="onLicencesCountUpdate"
                  @click="licencesCountUpdated = true"
                ></number-input>
              </div>
              <div class="licenseInfo">
                You can allocate 1 license to 1 user
              </div>
            </div>
          </div>
          <div class="paymentDetails">
            <div class="paymentDetailsTitle">Payment details:</div>
            <div class="cardDetails">
              <div class="nameTitle">Credit or debit card number</div>
              <stripe-element-card
                v-if="stripeElementLoading"
                @element-change="stripeElementCardChanged($event)"
                ref="stripeElementCard"
                :pk="striprPublicKey"
              />
            </div>
            <v-form v-model="isFormValid">
              <div class="fullName">
                <div class="nameWrapper">
                  <div class="nameTitle">{{ 'Card holder name' }}</div>
                  <v-text-field
                    class="card__input"
                    solo
                    dense
                    :rules="specialCharacterRules"
                    v-model="cardHolderName"
                    placeholder="Enter card holder's name"
                  />
                  <div class="nameError" v-if="cardHolderName.length > 30">
                    {{ 'Name cannot exceed 30 characters' }}
                  </div>
                </div>
              </div>
              <div class="billingDetails">
                <div class="nameWrapper">
                  <div class="nameTitle">Billing address</div>
                  <v-text-field
                    class="card__input--width"
                    solo
                    dense
                    :rules="specialCharacterRules"
                    v-model="billingAddress"
                    placeholder="Enter your billing address"
                  />
                </div>
              </div>
              <div class="countryStateZip">
                <div class="nameWrapper countryWrapper">
                  <div class="nameTitle">Country</div>
                  <Country
                    :countryChanged="countryChanged"
                    :countrySelected="country"
                  />
                </div>
                <div class="nameWrapper stateWrapper">
                  <div class="nameTitle">{{ stateLabel }}</div>
                  <State
                    :stateChanged="stateChanged"
                    :country="country"
                    :disabled="country === ''"
                    :selectedState="state_code"
                  />
                  <div
                    class="stateError"
                    v-if="(country === 'US' || country === 'CA') && stateError"
                  >
                    {{ 'Please select a state' }}
                  </div>
                </div>
                <div class="nameWrapper">
                  <div class="nameTitle">Zip code</div>
                  <input
                    v-model="zipCode"
                    placeholder="12345"
                    :disabled="country === ''"
                    :maxlength="getZipCodeMaxLength()"
                    @input="isZipValidAsPerCountry"
                    @click="isZipValidAsPerCountry"
                  />
                  <div class="zipError" v-if="zipCodeError">
                    {{ 'Please enter a valid zip code' }}
                  </div>
                </div>
              </div>
            </v-form>
            <PoweredByStripe />
          </div>

          <div class="saveAndAutoRenewCheckbox">
            <!--
            <v-checkbox
              v-model="saveCardAndAutoRenew"
              label="Save card details and enable auto-renew"
              color="#20a7e0"
              hide-details
            />
            !-->
          </div>

          <div class="terms">
            <p>
              By clicking below, you agree that you will be charged US ${{
                `${formatAmountWithCommas(getFinalPrice(getTotalPrice))}`
              }}
              (including taxes) once. At the end of your one-year term, your
              subscription will expire until you renew. You also agree to our
              <a href="https://prezent.ai/terms-of-service/" target="_blank">
                Terms of Service </a
              >and
              <a href="https://prezent.ai/privacy-policy/" target="_blank"
                >Privacy Policy</a
              >.
            </p>
          </div>

          <!--
          <div class="terms" v-else>
            <p>
              By clicking below, you agree that you will be charged US ${{
                `${formatAmountWithCommas(getFinalPrice(getTotalPrice))}`
              }}
              (including taxes) once. At the end of your one-year term, your
              subscription will automatically renew for another one-year term
              until you cancel (price subject to change). Your card details will
              be saved for any future charges. Reach us at success@prezent.ai to
              cancel anytime. You also agree to our
              <a href="https://prezent.ai/terms-of-service/" target="_blank">
                Terms of Service </a
              >and
              <a href="https://prezent.ai/privacy-policy/" target="_blank"
                >Privacy Policy</a
              >.
            </p>
          </div>
          !-->
          <div>
            <v-btn
              color="primary"
              rounded
              class="signUpButton"
              min-width="144"
              height="50"
              @click="handleSignUp"
              :loading="isSignupLoading"
              :disabled="
                !isFormValid ||
                !cardHolderName ||
                !billingAddress ||
                !zipCode ||
                !(licences > 0) ||
                !country ||
                !getTotalPrice ||
                isCardElementError ||
                taxJarError ||
                zipCodeError ||
                stateError ||
                stateMissingButRequired ||
                fetchingTaxRates ||
                cardHolderName.length > 30
              "
            >
              Sign up
            </v-btn>
          </div>
        </div>
        <div class="orderSummary">
          <div class="yourOrder">Your order:</div>
          <div class="orderInfo">
            <div class="itemTitle">Enterprise</div>
            <div class="infoImage">
              <img src="/assets/img/profile/subscription-enterprise.svg" alt />
            </div>
            <div class="pricePerUser">
              <div class="heading">${{ getBasePrice() }} (plus taxes)</div>
              <div class="subHeading">per user per year</div>
            </div>
            <div class="subscription-wrapper-header">
              {{ 'Everything in Prezent Premium plus:' }}
            </div>
            <div class="details">
              <div
                class="subscription-wrapper-detail"
                v-for="(item, index) in enterprisePlanDetails"
                :key="index"
              >
                <div class="detailItem">
                  <div class="tickContainer">
                    <img
                      src="/assets/img/profile/check-mark.svg"
                      alt="Tick Mark"
                      class="tick-mark"
                    />
                  </div>
                  <div class="detailText">{{ item }}</div>
                </div>
              </div>
            </div>
            <div class="subscription">
              <div class="subscriptionTitle">Subscription term</div>
              <div class="subTitle">{{ subscriptionTerm }}</div>
            </div>
          </div>
          <div class="price" v-if="!taxJarError">
            <li class="subTotal">
              {{ `Subtotal: US $${formatAmountWithCommas(getTotalPrice)}` }}
            </li>
            <li class="taxes">{{ `Taxes: ${taxToBeApplied}%` }}</li>
            <li class="total">
              {{
                `Total: US $${formatAmountWithCommas(
                  getFinalPrice(getTotalPrice),
                )}`
              }}
            </li>
          </div>
          <div class="taxJarErrors" v-else>
            <div class="taxCalculationFailure">
              {{ 'Could not calculate tax' }}
            </div>
            <div class="validZipCodeRequest">
              {{ 'Please enter a valid zip code' }}
            </div>
          </div>
          <div class="getInTouch">
            <div class="getInTouchTitle">Have questions for us?</div>
            <div class="getInTouchLink">
              <a href="#">
                <div @click="getInTouch()">Get in touch</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="linkExpiredScenario" v-else>
    <div class="thanksContainerImageWrapper">
      <img src="/assets/img/survey/expired-link.svg" alt />
    </div>
    <div class="thanksContainerHeading">
      <p class="response">
        {{ failureMsg }}
      </p>
      <p class="redirectText">
        {{ `Redirecting you to the login page in ${counter}...` }}
      </p>
      <clip-loader
        :color="`#21a7e0`"
        :width="'60'"
        :height="'60'"
        :size="'50px'"
        class="cliploader"
      />
    </div>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import moment from 'moment-timezone';
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import NumberInput from '@chenfengyuan/vue-number-input';
import { API, graphqlOperation } from 'aws-amplify';
import { getTaxRate } from '../../../API/payment-api';
import SuccessPaymentModal from './SuccessPaymentModal.vue';
import PoweredByStripe from '../../../components/common/PoweredByStripe.vue';
import FailedPaymentDetail from '../../../components/common/FailedPaymentDetail.vue';
import ContactSoonModal from '../../../components/common/ContactSoonModal.vue';
import CustomRegistrationApi from '../../../API/custom-registration-api';
import { emailNotification } from '../../../graphql/queries';
import Country from '../../../components/common/Country.vue';
import State from '../../../components/common/State.vue';
import ProcessingPaymentDetail from '../../../components/common/ProcessingPaymentDetail.vue';
import { isEmojiExist } from '../../../components/utils';

export default {
  name: 'SubmitPayment',
  props: {
    customRegistrationId: {
      type: String,
      required: true,
    },
  },
  emits: ['onFailedRegistration'],
  data() {
    return {
      stripeElementLoading: false,
      firstName: '',
      lastName: '',
      email: '',
      failureMsg: '',
      companyName: '',
      userInfoLoading: false,
      pricePerUser: '',
      licences: 1,
      subscriptionPricing: [],
      licencesCountUpdated: false,
      numberRules: [
        (v) => String(v).length > 0 || 'This field may not be empty',
        (v) => Number.isInteger(v) || 'The value must be an integer number',
        (v) => v > 0 || 'The value must be greater than zero',
      ],
      specialCharacterRules: [
        (v) => !v || !isEmojiExist(v) || 'Emojis are not allowed',
      ],
      minLicences: 1,
      isNotValidUser: false,
      striprPublicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      cardHolderName: '',
      billingAddress: '',
      zipCode: '',
      country: 'US',
      state: '',
      state_code: '',
      isSignupLoading: false,
      isPaymentProcessing: false,
      isPaymentError: false,
      paymentErrorMessage: '',
      isCardElementError: true,
      // saveCardAndAutoRenew: true,
      isFormValid: false,
      taxToBeApplied: 0,
      taxForCalculation: 0,
      taxJarError: false,
      stateError: false,
      zipCodeError: false,
      fetchingTaxRates: false,
      enterprisePlanDetails: [
        'Unlimited brand templates',
        'Dedicated success manager',
      ],
      counter: 5,
    };
  },
  computed: {
    stateLabel() {
      return this.country === 'US' || this.country === 'CA'
        ? 'State'
        : 'State (optional)';
    },
    subscriptionTerm() {
      return `${this.getStartDate()} to ${this.getEndDate()}`;
    },
    getTotalPrice() {
      if (this.licences) {
        const perUserPrice = this.getBasePrice();
        return this.getYearlyEnterprisePrice(
          this.licences,
          this.licencesCountUpdated ? perUserPrice : this.pricePerUser,
        );
      }
      return 0;
    },
    stateMissingButRequired() {
      return (
        (this.country === 'US' || this.country === 'CA') &&
        this.state_code === ''
      );
    },
  },
  methods: {
    getBasePrice() {
      if (!this.subscriptionPricing.length || this.licences === 0) return '';
      const priceSlab = this.subscriptionPricing.find(
        (price) => this.licences >= price.min && this.licences <= price.max,
      );
      if (!priceSlab) return '';
      return priceSlab?.enterprisePrice;
    },
    stripeElementCardChanged(event) {
      if (event && (event.error || event.empty || !event.complete)) {
        this.isCardElementError = true;
      } else {
        this.isCardElementError = false;
      }
    },
    getStartDate() {
      const today = new Date();
      return moment(today).format('MMM Do, YYYY');
    },
    getEndDate() {
      const today = new Date();
      const endDate = new Date(today.getTime() + 365 * 24 * 60 * 60 * 1000);
      return moment(endDate).format('MMM Do, YYYY');
    },
    handleSignUp() {
      const { stripe } = this.$refs.stripeElementCard;
      const billingDetails = {
        name: this.cardHolderName,
        email: this.email,
      };
      this.isSignupLoading = true;
      this.isPaymentProcessing = true;
      stripe
        .createPaymentMethod({
          type: 'card',
          card: this.$refs.stripeElementCard.element,
          billing_details: billingDetails,
        })
        .then((paymentMethodRes) => {
          this.isSignupLoading = true;
          const { paymentMethod } = paymentMethodRes;
          if (paymentMethod.id) {
            const payload = {
              customRegID: this.customRegistrationId,
              paymentMethodID: paymentMethod.id,
              quantity: this.licences,
              saveAndRenew: false,
              postal_code: this.zipCode,
              country: this.country,
              address_line1: this.billingAddress,
              state_code: this.state_code ? this.state_code : undefined,
              state: this.state ? this.state : undefined,
              planType: 'custom',
            };
            CustomRegistrationApi.methods
              .signUp(payload)
              .then((resp) => {
                console.log(resp);
                this.isPaymentProcessing = false;
                this.$modal.show(
                  SuccessPaymentModal,
                  {},
                  {
                    name: 'SuccessPaymentModal',
                    width: '683px',
                    height: '565px',
                    clickToClose: false,
                  },
                );
              })
              .catch((err) => {
                this.isPaymentError = true;
                this.paymentErrorMessage = err?.response?.data?.log;
              })
              .finally(() => {
                this.isSignupLoading = false;
                this.isPaymentProcessing = false;
              });
          }
        })
        .catch((paymentMethodCallError) => {
          console.log(paymentMethodCallError, 'paymentMethodCallError');
          this.isPaymentError = true;
        })
        .finally(() => {
          // this.isSignupLoading = false;
        });
    },
    getInTouch() {
      try {
        const sub =
          `${this.firstName} (${this.email})` +
          ` has expressed interest in buying enterprise licenses`;
        API.graphql(
          graphqlOperation(emailNotification, {
            subject: sub,
            audience: 'Success Team',
            requester: 'prezent.ai Team',
            toAddress: 'sfdc@prezent.ai',
            message: sub,
          }),
        )
          .then((response) => console.log(response))
          .catch((err) => console.log(err));
      } catch (err) {
        console.log(err);
      }

      this.$modal.show(
        ContactSoonModal,
        {},
        {
          name: 'ContactSoonModal',
          width: '449px',
          height: '503px',
        },
      );
    },
    onLicencesCountUpdate(newValue) {
      if (!isNaN(newValue)) {
        this.licences = Math.ceil(newValue);
      }
    },
    isCardEmpty() {
      const { stripe } = this.$refs.stripeElementCard;
      console.log(stripe);
      return true;
    },
    specialCCheck(inputVal) {
      return !/[^A-Za-z0-9]/.test(inputVal);
    },
    round(num) {
      const m = Number((Math.abs(num) * 100).toPrecision(15));
      return (Math.round(m) / 100) * Math.sign(num);
    },
    getFinalPrice(totalPrice) {
      return Number(totalPrice + totalPrice * this.taxForCalculation);
    },
    getYearlyEnterprisePrice(numSeats, pricePerSeat) {
      let totalPrice = 0;
      if (numSeats <= 0) {
        return totalPrice;
      }
      const price = pricePerSeat;
      const total = price * numSeats;
      totalPrice = this.round(total);
      return totalPrice;
    },

    getZipCodeMaxLength() {
      if (this.country === 'US') {
        return 5;
      }
      if (this.country === 'CA') {
        return 7;
      }
      return 10;
    },

    async isZipValidAsPerCountry() {
      // this.zipCodeError = false;
      // this.taxJarError = false;
      /* eslint-disable prefer-regex-literals */
      const usCodes = new RegExp('^\\d{5}(-{0,1}\\d{4})?$');
      const caCodes = new RegExp(
        /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
      );
      if (this.country === 'US') {
        if (usCodes.test(this.zipCode.toString())) {
          this.zipCodeError = false;
          if (this.state_code) {
            await this.calculateTax(
              this.country,
              this.zipCode,
              this.state_code,
            );
          }
        } else {
          this.taxToBeApplied = 0;
          this.taxForCalculation = 0;
          this.zipCodeError = true;
        }
      } else if (this.country === 'CA') {
        if (caCodes.test(this.zipCode.toString())) {
          this.zipCodeError = false;
          if (this.state_code) {
            await this.calculateTax(
              this.country,
              this.zipCode,
              this.state_code,
            );
          }
        } else {
          this.taxToBeApplied = 0;
          this.taxForCalculation = 0;
          this.zipCodeError = true;
        }
      } else if (this.zipCode.toString()) {
        this.zipCodeError = false;
      } else {
        this.taxToBeApplied = 0;
        this.taxForCalculation = 0;
        this.zipCodeError = true;
      }
    },

    async calculateTax(country, zip, stateCode) {
      this.fetchingTaxRates = true;
      await getTaxRate(country, stateCode, zip)
        .then((resp) => {
          const rate = resp?.data?.rate;
          this.taxToBeApplied = Number(Number(rate * 100).toFixed(3));
          this.taxForCalculation = rate;
          this.zipCodeError = false;
          this.stateError = false;
          this.taxJarError = false;
        })
        .catch((err) => {
          // this.zipCodeError = true;
          if (err?.response?.status === 500 || err?.response?.status === 503) {
            this.taxToBeApplied = 0;
            this.taxForCalculation = 0;
            this.taxJarError = false;
            this.zipCodeError = false;
            this.stateError = false;
          } else {
            this.taxJarError = true;
          }
        });
      this.fetchingTaxRates = false;
    },

    async countryChanged(newCountry) {
      // resetting all tax flags
      this.taxToBeApplied = 0;
      this.taxForCalculation = 0;
      this.taxJarError = false;
      this.zipCodeError = false;
      this.stateError = false;
      this.state = '';
      this.state_code = '';
      this.zipCode = '';
      this.country = newCountry;
      if (this.country !== 'US' && this.country !== 'CA') {
        // calculate tax for other countries
        this.zipCode = '';
        await this.calculateTax(newCountry);
      }
    },

    stateChanged(newState, state) {
      // resetting tax flags
      this.taxJarError = false;
      this.zipCodeError = false;
      this.zipCode = '';
      this.state = state.name;
      this.state_code = newState;
      this.stateError = false;
      if (this.country === 'US' || this.country === 'CA') {
        this.taxToBeApplied = 0;
        this.taxForCalculation = 0;
        if (newState === '') {
          this.stateError = true;
        }
      }
    },

    formatAmountWithCommas(num) {
      if (num % 1 === 0)
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return num
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    startCounter() {
      const countDownTimer = setInterval(() => {
        this.counter -= 1;
        if (this.counter < 1) {
          clearInterval(countDownTimer);
          this.$router.push('/signin', () => {});
        }
      }, 1000);
    },
  },
  watch: {
    isPaymentProcessing(val) {
      if (val) {
        this.$modal.show(
          ProcessingPaymentDetail,
          {
            processing: this.isPaymentProcessing,
          },
          {
            name: 'ProcessingPaymentModal',
            width: '680px',
            height: '280px',
            clickToClose: false,
          },
        );
      } else {
        this.$modal.hide('ProcessingPaymentModal');
        if (this.isPaymentError) {
          this.$modal.show(
            FailedPaymentDetail,
            { errorMessage: this.paymentErrorMessage },
            {
              name: 'FailedPaymentDetail',
              width: '683px',
              height: '555px',
            },
          );
          this.isPaymentError = false;
        }
      }
    },
  },
  components: {
    StripeElementCard,
    PoweredByStripe,
    NumberInput,
    ClipLoader,
    Country,
    State,
  },
  mounted() {
    this.stripeElementLoading = false;
    const payload = {
      customRegID: this.customRegistrationId,
    };
    this.userInfoLoading = true;
    CustomRegistrationApi.methods
      .validateRegistration(payload)
      .then((resp) => {
        if (!resp.error) {
          this.isNotValidUser = false;
          const { customRegistrationUserData } = resp.message;
          this.firstName = customRegistrationUserData.firstname;
          this.lastName = customRegistrationUserData.lastname;
          this.email = customRegistrationUserData.email;
          this.companyName = customRegistrationUserData.companyDisplayName;
          this.licences = customRegistrationUserData.nooflicense
            ? customRegistrationUserData.nooflicense
            : 1;
          this.subscriptionPricing =
            customRegistrationUserData.subscriptionPricing;
          this.userInfoLoading = false;
          const userBasePrice = this.getBasePrice();
          this.pricePerUser = userBasePrice;
        }
      })
      .catch((error) => {
        console.log(error?.response?.data?.log);
        this.failureMsg = 'Oops! This link has expired or is invalid.';
        this.isNotValidUser = true;
        this.userInfoLoading = false;
        this.startCounter();
      })
      .finally(() => {
        this.userInfoLoading = false;
        this.stripeElementLoading = true;
      });
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.paymentPage {
  .content {
    .paymentOrderWrapper {
      display: flex;
      justify-content: space-evenly;
      margin-top: 1rem;
    }

    .orderInformation {
      padding-right: 40px;
      width: 915px;

      & > div {
        margin-bottom: 10px;
        margin-top: 10px;
      }

      .countryWrapper {
        select {
          width: 220px;
          height: 40px;
          position: relative;
          padding: 10px 12px;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          background-color: #fff;
          border: 1px solid transparent;
          border-radius: 24px;
          box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
            0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        }
      }

      .stateWrapper {
        select {
          width: 220px;
          height: 40px;
          position: relative;
          padding: 10px 12px;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          background-color: #fff;
          border: 1px solid transparent;
          border-radius: 24px;
          box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
            0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        }
      }

      .header {
        margin-bottom: 36px;

        .heading {
          color: #000000;
          font-size: 32px;
          font-weight: 700;
          letter-spacing: -0.02em;
          line-height: 38px;
          margin-bottom: 16px;
        }

        .subHeading {
          color: #000000;
          font-size: 24px;
          font-weight: 400;
          letter-spacing: -0.01em;
          line-height: 29px;
        }
      }

      .licenses {
        margin-bottom: 20px;
        .quantityWrapper {
          align-items: center;
          display: flex;
          padding-top: 10px;

          .licenseInfo {
            color: $gray-dark-login-text;
            margin-left: 10px;
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
          }

          .number-input {
            width: 130px;
          }
        }

        .licensesTitle {
          color: #000000;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 16px;
        }

        .subTitle {
          color: #000000;
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
        }
      }

      .nameWrapper {
        height: 90px;

        .v-input {
          width: 500px !important;
          height: 40px;
          background-color: #fff;
          border: 1px solid transparent;
          border-radius: 24px;
        }
        .nameTitle {
          color: #000000;
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          margin-bottom: 10px;
        }
        .nameError {
          color: red;
        }
      }

      .paymentDetails {
        .billingDetails {
          display: flex;
          justify-content: space-between;

          .nameWrapper {
            .zipError {
              color: red;
            }
            input {
              width: 200px;
              height: 40px;
              background-color: #fff;
              border: 1px solid transparent;
              border-radius: 24px;
              padding: 10px 12px;
              box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
                0px 2px 2px 0px rgb(0 0 0 / 14%),
                0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
            }
          }

          .nameWrapper:first-child {
            width: 500px;
            input {
              width: 100%;
            }
          }
        }

        .countryStateZip {
          display: flex;
          justify-content: space-between;

          .nameWrapper {
            .zipError {
              color: red;
            }
            .stateError {
              color: red;
            }
            input {
              width: 100%;
              height: 40px;
              background-color: #fff;
              border: 1px solid transparent;
              border-radius: 24px;
              padding: 10px 12px;
              box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
                0px 2px 2px 0px rgb(0 0 0 / 14%),
                0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
            }
          }

          .nameWrapper * {
            width: 280px;
            input {
              width: 100%;
            }
            select {
              width: 100%;
            }
          }
        }

        .cardDetails {
          height: 90px;
          .nameTitle {
            color: #000000;
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;

            margin-bottom: 10px;
          }

          ::v-deep #stripe-element-form {
            width: 500px;

            .StripeElement {
              padding: 10px 12px;
              background-color: #fff;
              border: 1px solid transparent;
              border-radius: 24px;
              box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
                0px 2px 2px 0px rgb(0 0 0 / 14%),
                0px 1px 5px 0px rgb(0 0 0 / 12%);
            }
            #stripe-element-errors {
              color: #fa755a;
            }
          }
        }

        .fullName {
          display: flex;
          justify-content: space-between;
          margin: 8px 0px 10px 0px;
          width: 500px;
        }

        .paymentDetailsTitle {
          color: #000000;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 16px;
        }
      }

      .personal {
        .yourInfo {
          color: #000000;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 16px;
        }
        .vForm {
          display: flex;
          justify-content: space-between;

          .userInfo {
            width: 24%;

            .label {
              color: #000000;
              font-size: 16px;
              font-weight: 600;
              line-height: 19px;
            }
          }
        }
      }

      .signUpButton {
        font-size: 24px;
        font-stretch: normal;
        font-style: normal;
        font-weight: bold;
        letter-spacing: normal;
        line-height: normal;
        text-transform: none;
      }

      .terms {
        color: $gray-dark-login-text;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.02em;
        line-height: 14px;
        margin-bottom: 16px;
        margin-top: 32px;
      }
    }

    .orderSummary {
      margin-top: 83px;
      width: 332px;
      & > div {
        margin-bottom: 10px;
      }

      .getInTouch {
        align-items: flex-end;
        color: $gray-dark-login-text;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.02em;
        line-height: 14px;
        margin-top: 75px;
      }
      .orderInfo {
        & > div {
          margin-bottom: 10px;
        }

        align-items: center;
        border-radius: 12px;
        box-shadow: 1px 1px 4px rgb(0 0 0 / 10%), -1px -1px 4px rgb(0 0 0 / 10%);
        display: flex;
        flex-direction: column;
        padding: 28px 39px 8px;

        .details {
          .subscription-wrapper-detail {
            // align-items: flex-start;
            // display: flex;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            // justify-content: flex-start;
            line-height: 26px;
            letter-spacing: normal;
            text-align: left;
            // height: 100px;
            // white-space: pre-wrap;
            // margin-top: 10px;
            .detailItem {
              display: flex;
              .tickContainer {
                min-width: 16px;
              }
              .detailText {
                margin: 0px 0px 0px 2px;
              }
            }
          }
        }

        .itemTitle {
          font-size: 24px;
          font-stretch: normal;
          font-style: normal;
          font-weight: bold;
          height: 58px;
          letter-spacing: normal;
          line-height: normal;
          text-align: center;
        }

        .subscription {
          align-items: center;
          display: flex;
          flex-direction: column;

          .subscriptionTitle {
            color: $gray-dark-login-text;
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            margin-bottom: 6px;
          }

          .subTitle {
            color: $gray-dark-login-text;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.01em;
            line-height: 17px;
            text-align: center;
          }
        }
      }

      .price {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        li {
          color: $gray-dark-login-text;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          list-style-type: none;
        }

        .total {
          color: #212121;
        }
      }

      .pricePerUser {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        text-align: center;

        .heading {
          color: $gray-dark-login-text;
          font-size: 20px;
          font-weight: 400;
          line-height: 130%;
        }

        .subHeading {
          color: $gray-dark-login-text;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
        }
      }

      .subscription-wrapper-header {
        text-align: center !important;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        height: 8px;
        margin-bottom: 16px !important;
      }

      .yourOrder {
        color: #000000;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 26px;
      }

      .taxJarErrors {
        text-align: right;
        margin: 24px 0px 0px 0px;
        color: red;
      }
    }
  }
}

.linkExpiredScenario {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  margin: 100px 0px 0px 0px;
  .response,
  .redirectText {
    text-align: center;
    font-size: 16px;
  }
  .response {
    margin: 0px 0px 10px 0px;
  }
  .cliploader {
    margin: 32px 0px 0px 0px;
  }
  .successMaill {
    color: #21a7e0;
  }
}

.expiredLink {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

::v-deep .v-text-field.card__input {
  width: 220px;
  height: 40px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 24px;
}
::v-deep .v-text-field.card__input--width {
  height: 40px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 24px;
  width: 366px !important;
}
</style>
