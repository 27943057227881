import { getFileURL } from '@/utils/calyrex';
import { getFileURLDownload } from '@/utils/calyrex-test';
import { convertPdfToImage } from '../../utils/pdf-to-png';
import { getLoggedInUserToken } from '../../utils/api';

const getThumbnailsForCarousel = async function (presentation, currentUser) {
  let ids = [];
  let slidesObj = {};

  if (presentation.sections) {
    ids = [];
    presentation.sections?.list.forEach((section) => {
      section.slides.forEach((item) => {
        if (item.assetId) {
          ids.push(item.assetId);
          slidesObj[item.assetId] = item;
        }
      });
    });
  }

  const res = [];
  const getThumnails = ids.map(async (id) => {
    if (
      (id.indexOf('.pdf') !== -1 &&
        !id?.startsWith('newImageReplaceOutput/') &&
        !id?.startsWith('/newImageReplaceOutput/') &&
        !id?.startsWith('/output/pptx/')) ||
      id?.indexOf('/comply/') !== -1
    ) {
      const awsAssetsDistribution = process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
      const token = await getLoggedInUserToken();
      let thumbnail = await convertPdfToImage(
        `${awsAssetsDistribution}${
          id.startsWith('/') ? '' : '/'
        }${id}?accesskey=${token}`,
      );
      res.push(thumbnail);
    } else {
      let newId = id?.startsWith('/') ? id : `/${id}`;
      let type = 'Product';
      if (slidesObj[id]?.isOPUploaded) {
        type = 'OP';
      } else if (
        slidesObj[id]?.isGenerated ||
        slidesObj[id]?.source === 'generated' ||
        slidesObj[id]?.isIOCUploaded ||
        slidesObj[id]?.isRedesign ||
        slidesObj[id]?.isUserUploaded ||
        slidesObj[id]?.isSynthesis ||
        slidesObj[id]?.isComply
      ) {
        type = 'IOCUpload';
      }

      if (type === 'OP') {
        res.push(slidesObj[id].thumbnail);
      } else {
        await getThumbnail(newId, currentUser.id, currentUser.userIp, type)
          .then(async (resp) => {
            res.push(resp);
          })
          .catch((err) => console.log(err));
      }
    }
  });
  await Promise.all(getThumnails);
  return res;
};

const getPrezentationThumbnailUrl = async function (presentation, currentUser) {
  if (presentation.sections && presentation.sections.list) {
    let assetId = null;
    let slide = {};
    presentation.sections?.list.forEach((section) => {
      if (section.slides[0] && !assetId) {
        assetId = section.slides[0].assetId;
        slide = section.slides[0];
      }
    });

    if (
      assetId &&
      assetId.indexOf('.pdf') !== -1 &&
      !assetId?.startsWith('newImageReplaceOutput/') &&
      !assetId?.startsWith('/newImageReplaceOutput/') &&
      !assetId?.startsWith('/output/pptx/')
    ) {
      const awsAssetsDistribution = process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
      const token = await getLoggedInUserToken();
      return await convertPdfToImage(
        `${awsAssetsDistribution}${
          assetId.startsWith('/') ? '' : '/'
        }${assetId}?accesskey=${token}`,
      );
    } else {
      assetId = assetId?.startsWith('/') ? assetId : `/${assetId}`;
      let type = 'Product';
      if (slide.isOPUploaded) {
        type = 'OP';
      } else if (
        slide.isGenerated ||
        slide.source === 'generated' ||
        slide.isIOCUploaded ||
        slide.isRedesign ||
        slide.isUserUploaded ||
        slide.isSynthesis ||
        slide.isComply ||
        (slide.isPremiumSlide && assetId.indexOf('private/') !== -1)
      ) {
        type = 'IOCUpload';
      } else if (
        slide.source === 'comply' ||
        assetId?.indexOf('/comply/') !== -1
      ) {
        const token = await getLoggedInUserToken();
        const awsAssetsDistribution =
          process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
        // const uuid = uuidv4();

        return `${awsAssetsDistribution}${
          assetId.startsWith('/') ? '' : '/'
        }${assetId}?accesskey=${token}`;
      }
      if (type === 'OP') {
        return slide.thumbnail;
      }
      return getThumbnail(
        assetId
          ? presentation.prezentationSource === 'Product'
            ? assetId.replace('1280x720', '640x360')
            : assetId
          : '',
        currentUser.id,
        currentUser.userIp,
        type,
      );
    }
  }
};

const getPrezentationThumbnaiAssetId = (presentation) => {
  let slide = null;
  presentation.sections =
    typeof presentation?.sections === 'string'
      ? JSON.parse(presentation.sections)
      : presentation.sections;
  presentation?.sections?.list.forEach((section) => {
    if (!slide) slide = section.slides.find((slide) => slide.assetId);
  });

  return slide?.assetId;
};

const getThumbnail = async function getThumbnail(
  url,
  userId,
  userIp,
  type = 'Product',
) {
  return new Promise((resolve) => {
    if (!url || url.includes('null') || url.includes('undefined')) {
      return resolve('/assets/img/404.png');
    }
    if (
      url.startsWith('/newImageReplaceOutput/output/') ||
      url.startsWith('/output/pptx/')
    ) {
      getFileURLDownload(userId, url, userIp)
        .then(async (r) => {
          if (url.indexOf('.pdf') !== -1) {
            const pdfToPngUrl = await convertPdfToImage(r);

            resolve(pdfToPngUrl);
          } else {
            resolve(r);
          }
        })
        .catch((err) => {
          console.log('Thumbnail fetch failed', err);
          resolve('/assets/img/404.png');
        });
    } else {
      getFileURL(userId, url, userIp, type)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          console.log('Thumbnail fetch failed', err);
          resolve('/assets/img/404.png');
        });
    }
  });
};
export {
  getThumbnail,
  getPrezentationThumbnailUrl,
  getThumbnailsForCarousel,
  getPrezentationThumbnaiAssetId,
};
