import { find } from 'lodash';
import { DialogContents } from '../mock';

export default {
  methods: {
    getDialogContent() {
      return new Promise((resolve) => {
        resolve(DialogContents);
      });
    },
    getLearnContent() {
      return new Promise((resolve) => {
        resolve(DialogContents.learnData);
      });
    },
    getProfileContent() {
      return new Promise((resolve) => {
        resolve(DialogContents.profileContents);
      });
    },
    getRelatedImageProducts(payload) {
      return new Promise((resolve) => {
        const originImageProducts = DialogContents.imageProducts;
        const { relatedProductIds } = payload;
        const relatedProducts = originImageProducts.filter((item) =>
          relatedProductIds.includes(item.id),
        );
        resolve(relatedProducts);
      });
    },
    getSelectedImage(payload) {
      return new Promise((resolve) => {
        const originImageProducts = DialogContents.imageProducts;
        const { currentProductId } = payload;
        const currentProduct = find(originImageProducts, [
          'id',
          currentProductId,
        ]);
        resolve(currentProduct);
      });
    },
    getMyPresentations() {
      return new Promise((resolve) => {
        resolve(DialogContents.my_presentations);
      });
    },
    getReviewPresentations() {
      return new Promise((resolve) => {
        resolve(DialogContents.review_presentations);
      });
    },
    getSlides() {
      return new Promise((resolve) => {
        resolve(DialogContents.slides);
      });
    },
    getSelectedSlide(payload) {
      return new Promise((resolve) => {
        const originalSlides = [
          ...DialogContents.slides.beginning,
          ...DialogContents.slides.middle,
          ...DialogContents.slides.end,
          ...DialogContents.slides.idea,
        ];
        const { currentSlideId } = payload;
        const currentProduct = find(originalSlides, ['id', currentSlideId]);
        resolve(currentProduct);
      });
    },
    getRelatedSlides(payload) {
      return new Promise((resolve) => {
        const originalSlides = [
          ...DialogContents.slides.beginning,
          ...DialogContents.slides.middle,
          ...DialogContents.slides.end,
          ...DialogContents.slides.idea,
        ];
        const { relatedSlideIds } = payload;
        const relatedSlides = originalSlides.filter((item) =>
          relatedSlideIds.includes(item.id),
        );
        resolve(relatedSlides);
      });
    },
    getRelatedVideoProducts(payload) {
      return new Promise((resolve) => {
        const originVideoProducts = DialogContents.videoProducts;
        const { relatedProductIds } = payload;
        const relatedProducts = originVideoProducts.filter((item) =>
          relatedProductIds.includes(item.id),
        );
        resolve(relatedProducts);
      });
    },
    getSelectedVideo(payload) {
      return new Promise((resolve) => {
        const originVideoProducts = DialogContents.videoProducts;
        const { currentProductId } = payload;
        const currentProduct = find(originVideoProducts, [
          'id',
          currentProductId,
        ]);
        resolve(currentProduct);
      });
    },
  },
};
