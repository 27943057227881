<template>
  <div class="successPaymentWrapper">
    <div class="successPayment">
      <div class="successImg">
        <img src="/assets/img/profile/payment-success.svg" alt="" />
      </div>
      <div class="successTitle">Account created successfully</div>
      <div class="successDesc">
        You will receive your login credentials over email.
      </div>
      <div class="successDesc">
        You will be able to add your team members after logging in.
      </div>
      <div class="successDesc">
        Redirecting you to login in {{ counter }} ...
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessEnterpriseSignupModal',

  data() {
    return {
      counter: 5,
    };
  },
  methods: {},

  mounted() {
    const countDownTimer = setInterval(() => {
      this.counter -= 1;
      if (this.counter < 1) {
        clearInterval(countDownTimer);
        this.$modal.hide('SuccessEnterpriseSignupModal');
        this.$router.push('/signin', () => {});
      }
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.successPaymentWrapper {
  height: 100%;
  width: 100%;

  .successPayment {
    height: 100%;
    text-align: center;
    width: 100%;

    .successImg {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 65px auto 45px auto;
    }

    .successTitle {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      letter-spacing: normal;
      line-height: normal;
      margin-bottom: 20px;
      text-align: center;
    }

    .successDesc {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: center;
    }
  }
}
</style>
