<template>
  <v-main>
    <div class="redirect-wrapper">
      <div class="logo">
        <img src="/assets/img/prezent_stack.svg" alt width="248px" />
      </div>
      <clip-loader
        v-if="!isSigninFailure"
        :loading="loading"
        :color="`#21a7e0`"
        :width="'60'"
        :height="'60'"
        :size="'50px'"
        class="cliploader"
      />
      <div class="sso-signin-error" v-if="isSigninFailure">
        <SsoUserDisabled :msg="signinFailureMessage" />
      </div>
    </div>
  </v-main>
</template>
<script>
import { Auth, Hub } from 'aws-amplify';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import SsoUserDisabled from './SsoUserDisabled.vue';
import { trySSOLoginAndRedirect, cacheUser } from '../utils/common';

export default {
  name: 'Redirect',
  props: {},
  components: {
    SsoUserDisabled,
    ClipLoader,
  },
  data() {
    return {
      isSigninFailure: false,
      signinFailureMessage: '',
      loading: true,
    };
  },
  methods: {
    // returns empty map if input is null
    decodeHash(hashString) {
      return !hashString
        ? {}
        : hashString.split('&').reduce((params, param) => {
            const [key, value] = param.split('=');
            params[key] = value
              ? decodeURIComponent(value.replace(/\+/g, ' '))
              : '';
            return params;
          }, {});
    },
    parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join(''),
      );

      return JSON.parse(jsonPayload);
    },
  },
  beforeMount() {
    const queryparam = this.decodeHash(this.$route.hash.substring(1));
    // Following code should be handled properly
    if (queryparam.error_description) {
      // Error pattern for user not existing in cognito
      // eslint-disable-next-line prefer-regex-literals
      const userDoesNotExistPattern = new RegExp(
        '(.*user_does_not_exist:)(.*)(. $)',
      );
      // Error pattern for user existing but first time login
      // eslint-disable-next-line prefer-regex-literals
      const userAlreadyExistPattern = new RegExp('(.*user_exist:)(.*)(. $)');

      if (userDoesNotExistPattern.test(queryparam.error_description)) {
        const email = queryparam.error_description
          .match(userDoesNotExistPattern)[2]
          .trim();
        this.signinFailureMessage = `User (${email}) does not exist.`;
        this.isSigninFailure = true;
      } else if (userAlreadyExistPattern.test(queryparam.error_description)) {
        const email = queryparam.error_description
          .match(userAlreadyExistPattern)[2]
          .trim();
        // Redirect user again to cognito login to route to application
        // This will happen once per user on first login
        // This error is from signup logic on cognito, since its a new user
        this.isSigninFailure = true;
        this.signinFailureMessage = `Logging you in...`;
        trySSOLoginAndRedirect(email);
      } else {
        this.signinFailureMessage = `Oops! User is disabled.
Please contact your team admin.`;
        this.isSigninFailure = true;
      }
    }

    if (queryparam.id_token) {
      Hub.listen('auth', () => {
        Auth.currentAuthenticatedUser().then((user) => {
          cacheUser(user.attributes.email);
        });
      });
    }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss';

::v-deep .v-main__wrap {
  margin: auto;
  margin-top: 2% !important;
}

.redirect-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 70px;
  height: fit-content;
  margin: 10% auto 0 auto;
  text-align: center;
  width: 452px;
  margin-top: 20px;

  .logo {
    margin-bottom: 0px !important;
  }

  .sso-signin-error {
    display: flex;
    flex-direction: column;
    gap: 30px;
    text-align: center;
  }
}
</style>
