import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

export const tdInitialState = {
  templateDesignerData: [],
  tdOverallStatus: '',
};
export const templateDesigner = {
  namespaced: true,
  state: {
    ...tdInitialState,
  },
  getters: {
    getTDData: (state) =>
      state.templateDesignerData.length ? state.templateDesignerData : [],
    areAllJobsFailed: (state) =>
      state.templateDesignerData.length
        ? !!state.templateDesignerData.every(
            (item) => item?.status === 'failed',
          )
        : null,
    areAllJobsCompleted: (state) =>
      state.templateDesignerData.length
        ? !!state.templateDesignerData.every(
            (item) => item?.status === 'completed',
          )
        : null,
    areAllJobsSubmitted: (state) =>
      state.templateDesignerData.length
        ? !!state.templateDesignerData.every((item) => item?.jobSubmitted)
        : null,
    getTDStatuses: (state) =>
      state.templateDesignerData.length
        ? state.templateDesignerData.map((item) => item.status)
        : [],
    getTDPercentages: (state) =>
      state.templateDesignerData.length
        ? state.templateDesignerData.map((item) =>
            parseInt(item.percentage, 10),
          )
        : [],
    getFirstTDData: (state) => {
      const data = [...state.templateDesignerData];
      return data.length
        ? data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))[0]
        : {};
    },
    getTDDataAsc: (state) => {
      const data = [...state.templateDesignerData];
      return data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    },
  },
  mutations: {
    SET_TD_DATA(state, value) {
      state.templateDesignerData = value;
    },
    SET_TD_OVERALL_STATUS(state, value) {
      state.tdOverallStatus = value;
    },
  },
  actions: {
    setTDData({ commit }, data) {
      commit('SET_TD_DATA', data);
    },
    setTDOverallStatus({ commit }, data) {
      commit('SET_TD_OVERALL_STATUS', data);
    },
  },
  persist: createPersistedState({
    key: 'vuex_templateDesigner',
    paths: [],
  }),
};
