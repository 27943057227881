<template>
  <div class="td-card-wrap" :class="`${selected ? 'selected' : ''} `">
    <div class="td-title-wrap">
      <div class="title-data">
        {{ tdCardData.name }}
      </div>
      <div class="preview">
        <v-img
          src="/assets/img/templateDesign/preview.svg"
          @click="handlePreviewClick($event)"
        />
      </div>
    </div>
    <div class="title-created-by">
      <p class="createdBy">Created by {{ tdCardData.uploadedByName }}</p>
    </div>
    <div class="td-sample-wrap">
      <v-img
        contain
        :src="thumbnailURL"
        class="partition"
        lazy-src="/assets/img/slides/placeholder-slide.svg"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <clip-loader :color="`#21a7e0`" :width="20" :height="20" />
          </v-row>
        </template>
      </v-img>
      <v-img
        contain
        :src="sampleURL"
        class="partition"
        lazy-src="/assets/img/slides/placeholder-slide.svg"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <clip-loader :color="`#21a7e0`" :width="20" :height="20" />
          </v-row>
        </template>
      </v-img>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { getFileURL } from '@/utils/calyrex';

export default {
  name: 'TemplateDesignerCard',
  components: {
    ClipLoader,
  },
  props: {
    templateDesignerCard: { default: {} },
    selected: { default: false },
  },
  data() {
    return {
      tdCardData: {},
      sampleURL: '',
      thumbnailURL: '',
    };
  },
  async mounted() {
    this.tdCardData = this.templateDesignerCard;
    this.thumbnailURL = await getFileURL(
      null,
      `public/${this.templateDesignerCard.thumbnail}`,
      null,
      'storage',
    );
    this.sampleURL = await getFileURL(
      null,
      `public/${this.templateDesignerCard?.sampleSlide}`,
      null,
      'storage',
    );
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    handlePreviewClick(event) {
      this.$emit('onPreviewClick', { ...this.tdCardData, e: event });
    },
  },
};
</script>

<style lang="scss" scoped>
.td-card-wrap {
  background: white;
  border: 2px solid transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  box-shadow: 1.34px 1.34px 5.35px 0px #0000001a;
  padding: 10px;
  border-radius: 12px;
  .td-title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-data {
      display: flex;
      align-items: center;
      gap: 10px;
      letter-spacing: 0.01em;
      text-align: left;
      font-family: Lato;
      font-size: 18.73px;
      font-weight: 700;
      line-height: 22.47px;
      margin-bottom: 0;
    }
    .preview {
      width: 24px;
      height: 24px;
    }
  }
  .title-created-by {
    padding-top: 5px;
    display: flex;
    .createdBy {
      font-size: 16.05px;
      font-weight: 400;
      line-height: 19.26px;
      margin-bottom: 0;
      color: #343a3f;
    }
  }
  .td-sample-wrap {
    display: flex;
    column-gap: 15px;
    padding-top: 12px;
    .partition {
      aspect-ratio: 16/9;
      flex-basis: calc(50% - 7.5px);
      box-shadow: -0.56px -0.56px 2.25px 0px #0000001a,
        0.56px 0.56px 2.25px 0px #0000001a;
    }
  }
}
.selected {
  border: 2px solid #21a7e0;
}
</style>
