import * as pdfJS from 'pdfjs-dist/build/pdf.min.js';

export const convertPdfToImage = async (pdfSrc) => {
  try {
    if (!pdfSrc) {
      console.log('Pdf source url is missin');
      return pdfSrc;
    }
    if (pdfSrc.startsWith('data:image/') || pdfSrc.indexOf('.pdf') === -1) {
      return pdfSrc;
    }
    pdfJS.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfJS.version}/pdf.worker.min.js`;
    const canvas = document.createElement('canvas');
    const pdfDoc = pdfJS.getDocument({
      url: pdfSrc,
      verbosity: 0,
      isEvalSupported: false,
    });
    if (pdfDoc) {
      const pdf = await pdfDoc.promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1.5 });
      const ctx = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const renderContext = {
        canvasContext: ctx,
        viewport,
      };
      await page.render(renderContext).promise;
      return canvas.toDataURL();
    }
    return pdfSrc;
  } catch (e) {
    console.log('failed to convert pdf to image', e);
    return pdfSrc;
  }
};
