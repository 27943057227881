<template>
  <section class="type-wrap">
    <div class="title-container">
      {{ $t('templateDesign.templateType.title') }}
    </div>
    <div class="images-wrapper">
      <TemplateCard
        :src="'/assets/img/templateDesign/standard.svg'"
        :selected="firstCardSelected"
        :cardData="'standard'"
        @selectedCardData="selectTemplateType"
        :title="$t('templateDesign.templateType.standardTitle')"
        :subtext="$t('templateDesign.templateType.standardSubtext')"
        :estimateTime="$t('templateDesign.templateType.estimatedTimeStandard')"
      />

      <TemplateCard
        :src="'/assets/img/templateDesign/custom.svg'"
        :selected="secondCardSelected"
        :cardData="'custom'"
        @selectedCardData="selectTemplateType"
        :title="customTitle"
        :subtext="$t('templateDesign.templateType.customSubtext')"
        :estimateTime="$t('templateDesign.templateType.estimatedTimeCustom')"
      />
    </div>
    <div class="button-container">
      <v-btn
        :disabled="selectedTemplate === ''"
        @click="handleNext"
        class="next"
      >
        {{ $t('templateDesign.nextBtn') }}
      </v-btn>
    </div>
    <p>
      {{ $t('templateDesign.needHelp') }}
      <a @click="getInTouch">{{ $t('templateDesign.getInTouch') }}</a>
      {{ $t('templateDesign.designTeamNow') }}
    </p>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import TemplateCard from './TemplateCard.vue';
import ContactSoonModal from '../../../components/common/ContactSoonModal.vue';
import { sendEmail } from '../../../utils/api-helper';

export default {
  name: 'TemplateType',
  data() {
    return {
      selectedTemplate: '',
      firstCardSelected: null,
      secondCardSelected: null,
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    customTitle() {
      return `${this.$t('templateDesign.templateType.customTitle')} ${
        this.currentUser?.company?.displayName
      }'s brand`;
    },
  },
  props: {
    isTemplateSelected: { default: null },
  },
  methods: {
    selectTemplateType(type) {
      this.selectedTemplate = type;
      this.selectedTemplateFlag();
    },
    selectedTemplateFlag() {
      this.firstCardSelected = this.selectedTemplate === 'standard';
      this.secondCardSelected = this.selectedTemplate === 'custom';
    },
    handleNext() {
      this.$emit('selectTemplateType', this.selectedTemplate);
    },
    getInTouch() {
      try {
        const params = {
          type: 'template-designer-request',
          meta: {
            firstName: `${this.currentUser?.user?.firstName}`,
            trialDate: `${moment(new Date()).format('MM/DD/YYYY')}`,
          },
        };
        sendEmail(params)
          .then((response) => console.log(response))
          .catch((err) => console.log(err));
      } catch (err) {
        console.log(err);
      }
      this.$modal.show(
        ContactSoonModal,
        {},
        {
          name: 'ContactSoonModal',
          width: '449px',
          height: '503px',
          styles: {
            borderRadius: '12px',
          },
        },
      );
    },
  },
  mounted() {
    this.secondCardSelected = this.isTemplateSelected === true || null;
    if (this.secondCardSelected === true) {
      this.selectedTemplate = 'custom';
      this.firstCardSelected = false;
    }
  },
  components: { TemplateCard },
};
</script>

<style lang="scss" scoped>
.type-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  margin: auto;
  text-align: center !important;

  p {
    font-size: 14px;
  }
  .title-container {
    margin-bottom: 55px;
    font-size: 20px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 52px;
    padding-bottom: 20px;
  }

  .images-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    gap: 3.5rem;
  }

  .button-container {
    padding: 20px;
    margin: 10px;
    .next {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 22px;
      gap: 8px;

      width: auto;
      height: 32px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-transform: capitalize;
      letter-spacing: normal;
      line-height: 19px;
      color: #ffffff;
      background: #21a7e0;
      border-radius: 25px;
    }
  }
}
</style>
