import axios from 'axios';
import { calyrexConfig } from '../runtimeConfig';
import API from './api';
import { getFileURLDownload } from '@/utils/calyrex-test';
import { convertPPTToPng } from './merge-slide-helper';

// importing store in this helper file to access vuex state variables
import store from '@/store';

// const DECK_DETAILS_PATH = '/api/v1/mergequery/deckdetails';
const CONVERT_DECK_PATH = '/api/v1/mergequery/convertdeck';

/**
 * Get Slide Deck details
 * POST /magikarp/create
 */
async function magiKarpWrapperAPI(payload, url) {
  const path = '/magikarp/create';
  return await API.post(path, {
    url,
    ...payload,
  });
}

function getS3Bucket(slide) {
  const slidePath = slide.slide_path;
  if (slidePath?.startsWith('/newImageReplaceOutput/output/')) {
    return calyrexConfig.calyrex_bucket_name;
  }
  return process.env.VUE_APP_MVP_ASSETS_BUCKET;
}

/**
 * Get Slide Deck details
 * POST /magikarp/create
 */
async function getDeckDetails(payload) {
  // return await magiKarpWrapperAPI(payload, DECK_DETAILS_PATH);
  const path = '/api2/image-replacement/extract_images';
  return await API.post(path, payload);
}

export const extractImage = async (payload) => {
  try {
    return await API.post('/api2/image-replacement/extract_images', payload);
  } catch (e) {
    return null;
  }
};

export const extractIcons = async (payload) => {
  try {
    return await API.post('/api2/image-replacement/extract_icons', payload);
  } catch (e) {
    return null;
  }
};

export const getSvgOfIcon = async (payload) => {
  let url = '';
  await axios
    .get(payload)
    .then((resp) => {
      url = `data:image/svg+xml;utf8,${encodeURIComponent(resp.data)}`;
    })
    .catch(() => {
      url = '';
    });
  return url;
};

export const replaceImage = async (payload) => {
  try {
    // Passing logged in user email in replace_image api payload
    const userEmail = store.getters['users/getUser']?.user?.id || '';
    return await API.post('/api2/image-replacement/replace_image', {
      ...payload,
      email: userEmail,
    });
  } catch (e) {
    return null;
  }
};

export const replaceIcon = async (payload) => {
  try {
    // Passing logged in user email in replace_icon api payload
    const userEmail = store.getters['users/getUser']?.user?.id || '';
    return await API.post('/api2/image-replacement/replace_icon', {
      ...payload,
      email: userEmail,
    });
  } catch (e) {
    return null;
  }
};

export const convertToPDF = async (payload) => {
  try {
    return await API.post('/api2/image-replacement/convert-to-pdf', payload);
  } catch (e) {
    return null;
  }
};

export const convertToPDFForIcons = async (payload) => {
  try {
    return await API.post(
      '/api2/image-replacement/replace_icon_convert_to_pdf',
      payload,
    );
  } catch (e) {
    return null;
  }
};

/**
 * Get Slide Deck details
 * POST /magikarp/create
 */
async function convertDeck(payload) {
  return await magiKarpWrapperAPI(payload, CONVERT_DECK_PATH);
}

const uploadAdobeImagesToS3 = async (
  adobeContentId,
  resizeImageData,
  user,
  ip,
) => {
  const path = '/upload-adobe-images';
  return await API.post(path, {
    contentId: adobeContentId,
    resizeImageData,
    user,
    ip,
  });
};

const uploadLocalImagesToS3 = async (payload) => {
  const path = '/upload-local-images';
  try {
    return await API.post(path, payload);
  } catch (e) {
    console.log(e);
    return null;
  }
};

const getBrandImages = async () => {
  const path = '/api2/brandimages';
  return API.get(path);
};

const uploadBrandImages = async (payload) => {
  const path = '/api2/moveToMagikarpBucket';
  try {
    return await API.post(path, payload);
  } catch (e) {
    console.log(e);
    return null;
  }
};

async function triggerReplaceImage(
  imageToReplace,
  currentSelectedSlideImageData,
  slide,
  currentUser,
) {
  const { meta, shapeType, imageIndex, originFeature } =
    currentSelectedSlideImageData;

  let newImage = {
    imageIndex,
    s3_path: imageToReplace.s3_path,
    source: imageToReplace.source,
  };

  if (
    imageToReplace.source === 'adobe' ||
    imageToReplace.source === 'freepik'
  ) {
    newImage = {
      imageIndex,
      source: imageToReplace.source,
      id: imageToReplace.id,
    };
  } else if (imageToReplace.source === 'upload') {
    newImage = {
      imageIndex,
      source: imageToReplace.source,
      image: imageToReplace.image,
      extension: imageToReplace.extension,
    };
  } else if (imageToReplace.source === 'extracted') {
    newImage = {
      imageIndex,
      source: 's3',
      s3_bucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
      s3_path: imageToReplace.s3_path,
    };
  }

  const s3Path = slide.slide_path;
  let s3Bucket = getS3Bucket(slide);

  if (s3Path.indexOf('newImageReplaceOutput/') !== -1) {
    s3Bucket = calyrexConfig.calyrex_bucket_name;
  }

  const body = {
    s3_bucket: s3Bucket,
    s3_path: s3Path,
    outputFormat: 'pptx',
    newImage,
    oldImage: { meta, shapeType },
    /*
      passing feature in replace_image payload
      which triggered the replace image in product
    */
    feature: originFeature,
  };

  try {
    const response = await replaceImage(body);

    if (response?.s3_path) {
      console.log('Deck successfuly converted', response);
      console.log(response.s3_path, 'response s3 path replace visual');

      const path = await convertPPTToPng(response.s3_bucket, response.s3_path);

      const convertedImage = await getFileURLDownload(
        currentUser.user.cognitoID,
        path.startsWith('/') ? path : `/${path}`,
        currentUser.userIp,
      );

      return {
        path: response.s3_path,
        bucket: response.s3_bucket,
        thumbnail: path,
        landscape: convertedImage,
        error: false,
      };
    }
    console.error(response);
    return { error: true };
  } catch (err) {
    console.error(err);
    return { error: true };
  }
}
export {
  getDeckDetails,
  convertDeck,
  uploadAdobeImagesToS3,
  uploadLocalImagesToS3,
  getBrandImages,
  uploadBrandImages,
  triggerReplaceImage,
};
