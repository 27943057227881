<template>
  <v-app>
    <template v-if="!isAddToWaitList">
      <v-main v-if="!isSuccessIntermediateLoader">
        <!-- template section handling failure screen renders !-->
        <div
          v-if="isLoading || isFailed || isFailedWhileValidation"
          class="loading-wrapper"
        >
          <!-- prezent logo in header for trial validate exceptions only !-->
          <div class="header" v-if="isFailedWhileValidation">
            <a href="https://prezent.ai">
              <v-img
                src="/assets/img/prezent_logo.svg"
                alt
                width="200px"
                height="44px"
              />
            </a>
          </div>
          <div class="logo-loading">
            <img
              v-if="isFailed"
              src="/assets/img/prezent_stack.svg"
              alt
              width="248px"
            />

            <!-- error svg for trial validate exceptions only !-->
            <img
              v-if="isFailedWhileValidation"
              src="/assets/img/survey/expired-link.svg"
              alt
            />
          </div>
          <p v-if="isFailed" class="expired-link">
            {{ `Redirecting you to teams.prezent.ai...` }}
          </p>

          <!-- messages for trial validate exceptions only !-->
          <p v-if="isFailedWhileValidation" class="expired-link">
            {{ 'Oops! This link has expired or is invalid.' }}
          </p>
          <p v-if="isFailedWhileValidation" class="expired-link">
            {{ `Redirecting you to login page in ${counterForValidation}...` }}
          </p>
          <clip-loader
            v-if="isLoading || isFailed || isFailedWhileValidation"
            :loading="loading"
            :color="`#21a7e0`"
            :width="'60'"
            :height="'60'"
            :size="'50px'"
            class="cliploader"
          />
        </div>
        <!-- non failure screen renders !-->
        <div
          v-if="!isLoading && !isFailed && !isFailedWhileValidation"
          class="signup-wrapper"
        >
          <div class="signup-content">
            <template>
              <div class="signup-content-left">
                <TrialSignUpAdvantages></TrialSignUpAdvantages>
              </div>
              <div class="signup-content-right">
                <div class="signup-heading">Sign up to Prezent</div>
                <v-form
                  class="signup-form"
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  v-on:submit.prevent="submit"
                >
                  <template v-if="!continueToSignUp">
                    <v-text-field
                      v-model="firstName"
                      label="First name"
                      :rules="nameRules.firstName"
                      :disabled="isReadonlyfirstName"
                      required
                      color="#000"
                      class="mb-4"
                      id="first"
                    />
                    <v-text-field
                      v-model="lastName"
                      label="Last name"
                      :rules="nameRules.lastName"
                      :disabled="isReadonlylastName"
                      required
                      color="#000"
                      class="mb-4"
                      id="last"
                    />
                    <v-text-field
                      v-model="email"
                      label="Work email"
                      :disabled="true"
                      required
                      color="#000"
                      class="mb-4"
                      id="email"
                    />
                    <v-text-field
                      v-model="companyName"
                      label="Company name"
                      :rules="nameRules.companyName"
                      :disabled="true"
                      required
                      color="#000"
                      class="mb-4"
                      id="first"
                    />
                  </template>
                  <template v-if="goToIntermediateRegistration">
                    <v-autocomplete
                      v-model="companySize"
                      :items="companySizeOptions"
                      label="Company size"
                      :rules="nameRules.companySize"
                      required
                      class="mb-4"
                      id="companySize"
                    >
                      <template v-slot:message="{ message }">
                        <v-icon
                          style="font-size: 14px"
                          color="#fff"
                          class="mr-1"
                          >mdi-alert-circle</v-icon
                        ><span> {{ message }}</span>
                      </template></v-autocomplete
                    >
                    <v-text-field
                      v-model="phone"
                      label="Phone"
                      :rules="nameRules.phone"
                      required
                      class="mb-4"
                      id="phone"
                    >
                      <template v-slot:message="{ message }">
                        <v-icon
                          style="font-size: 14px"
                          color="#fff"
                          class="mr-1"
                          >mdi-alert-circle</v-icon
                        ><span> {{ message }}</span>
                      </template></v-text-field
                    >
                    <v-autocomplete
                      v-model="country"
                      label="Country"
                      :rules="nameRules.country"
                      required
                      class="mb-4"
                      id="country"
                      item-text="name"
                      :items="countries"
                      return-object
                    >
                      <template v-slot:message="{ message }">
                        <v-icon
                          style="font-size: 14px"
                          color="#fff"
                          class="mr-1"
                          >mdi-alert-circle</v-icon
                        ><span> {{ message }}</span>
                      </template></v-autocomplete
                    >
                  </template>
                  <div
                    v-if="
                      !ssoUser &&
                      !goToIntermediateRegistration &&
                      !continueToSignUp
                    "
                    class="create-password"
                  >
                    <v-form
                      ref="passwordControl"
                      v-on:submit.prevent="submit"
                      class="create-password-form"
                    >
                      <v-text-field
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Create password"
                        id="password"
                        class="mb-4"
                        @click:append="show1 = !show1"
                        :rules="passwordRules"
                      >
                        <template v-slot:message="{ message }">
                          <v-icon
                            style="font-size: 14px"
                            color="#fff"
                            class="mr-1"
                            >mdi-alert-circle</v-icon
                          ><span> {{ message }}</span>
                        </template></v-text-field
                      >
                      <div
                        v-if="password && passwordRequirements.length"
                        class="password-errors-box"
                      >
                        <div
                          v-for="(value, index) in passwordErrors"
                          :key="index"
                          class="password-wrapper"
                        >
                          <span :class="checkError(value) ? 'visibility' : ''"
                            >&#10003;</span
                          >
                          <p
                            :class="
                              checkError(value) ? 'password-error' : 'no-error'
                            "
                          >
                            {{ value }}
                          </p>
                        </div>
                      </div>
                    </v-form>
                    <v-form
                      ref="confirmPasswordControl"
                      v-on:submit.prevent="submit"
                    >
                      <v-text-field
                        v-model="rePassword"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="confirmPasswordRules"
                        :type="show2 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Confirm password"
                        id="rePassword"
                        @click:append="show2 = !show2"
                      >
                        <template v-slot:message="{ message }">
                          <v-icon
                            style="font-size: 14px"
                            color="#fff"
                            class="mr-1"
                            >mdi-alert-circle</v-icon
                          ><span> {{ message }}</span>
                        </template></v-text-field
                      >
                    </v-form>
                  </div>
                  <div v-if="continueToSignUp" class="create-password">
                    <v-form
                      ref="passwordControl"
                      v-on:submit.prevent="submit"
                      class="create-password-form"
                    >
                      <v-text-field
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Create password"
                        id="password"
                        class="mb-4"
                        @click:append="show1 = !show1"
                        :rules="passwordRules"
                      >
                        <template v-slot:message="{ message }">
                          <v-icon
                            style="font-size: 14px"
                            color="#fff"
                            class="mr-1"
                            >mdi-alert-circle</v-icon
                          ><span> {{ message }}</span>
                        </template></v-text-field
                      >
                      <div
                        v-if="password && passwordRequirements.length"
                        class="password-errors-box"
                      >
                        <div
                          v-for="(value, index) in passwordErrors"
                          :key="index"
                          class="password-wrapper"
                        >
                          <span :class="checkError(value) ? 'visibility' : ''"
                            >&#10003;</span
                          >
                          <p
                            :class="
                              checkError(value) ? 'password-error' : 'no-error'
                            "
                          >
                            {{ value }}
                          </p>
                        </div>
                      </div>
                    </v-form>
                    <v-form
                      ref="confirmPasswordControl"
                      v-on:submit.prevent="submit"
                    >
                      <v-text-field
                        v-model="rePassword"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="confirmPasswordRules"
                        :type="show2 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Confirm password"
                        id="rePassword"
                        @click:append="show2 = !show2"
                      >
                        <template v-slot:message="{ message }">
                          <v-icon
                            style="font-size: 14px"
                            color="#fff"
                            class="mr-1"
                            >mdi-alert-circle</v-icon
                          ><span> {{ message }}</span>
                        </template></v-text-field
                      >
                    </v-form>
                  </div>
                  <div v-if="ssoUser">
                    <p>
                      Once registered, please use your corporate email id and
                      password to log in to Prezent.
                    </p>
                  </div>
                  <div class="terms">
                    <p>
                      By clicking below, you agree to our
                      <a
                        href="https://prezent.ai/terms-of-service/"
                        target="_blank"
                        >Terms of Service</a
                      >
                      and
                      <a
                        href="https://prezent.ai/privacy-policy/"
                        target="_blank"
                        >Privacy Policy</a
                      >.
                    </p>
                  </div>
                  <div style="text-align: left">
                    <v-btn
                      v-if="!goToIntermediateRegistration"
                      @click="submit"
                      :disabled="
                        !firstName ||
                        !lastName ||
                        !email ||
                        !!passwordRequirements.length ||
                        ((!password ||
                          !rePassword ||
                          !(password && password.length >= 8) ||
                          !(rePassword === password)) &&
                          !ssoUser)
                      "
                      color="primary"
                      rounded
                      min-width="144"
                      height="50"
                      id="submit"
                      :loading="isSignupLoading"
                    >
                      Sign up
                    </v-btn>
                    <v-btn
                      v-else
                      @click="intermediateRegistrationSubmit"
                      :disabled="
                        !firstName ||
                        !lastName ||
                        !email ||
                        !companySize ||
                        !phone ||
                        !country
                      "
                      color="primary"
                      rounded
                      min-width="144"
                      height="50"
                      id="submit"
                      :loading="isSignupLoading"
                    >
                      Continue
                    </v-btn>
                    <p class="card-details">No credit card required</p>
                    <div v-if="error !== ''" class="error">
                      {{ error }}
                    </div>
                  </div>
                </v-form>
              </div>
            </template>
          </div>
        </div>
      </v-main>
      <v-main v-else>
        <div class="logo">
          <img
            src="/assets/img/prezent_logo.svg"
            alt
            width="200px"
            height="44px"
          />
        </div>
        <div class="redirect">
          <v-img
            src="/assets/img/account-creation-success.svg"
            alt
            max-width="358px"
            max-height="281px"
            style="margin-bottom: 24px"
          />
          <p class="heading">
            Yay! Your Prezent account was created successfully!
          </p>
          <p class="subHeading">Redirecting you to login {{ counter }} ...</p>
        </div>
      </v-main>
      <div v-if="isSuccessIntermediateLoader" class="privacy-policy-footer">
        <div class="privacy-policy">
          <a href="https://prezent.ai/privacy-policy" target="_blank"
            >Privacy Policy</a
          >
        </div>
        <div>&#169; Prezent Inc. 2021</div>
      </div>
    </template>
    <div class="waitListContent" v-if="isAddToWaitList">
      <div class="confirmation-wrapper">
        <div class="confirmation">
          <v-img
            src="/assets/img/feather_check-circle.svg"
            max-width="60px"
            max-height="60px"
            alt
          />
          <div v-html="getMessage"></div>
          <template>
            <clip-loader
              v-if="loginLoading"
              :loading="loginLoading"
              :color="`#21a7e0`"
              :width="'60'"
              :height="'60'"
              :size="'50px'"
              class="cliploader"
            />
          </template>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import {
  trialValidate,
  trialSignUp,
  trialInValidate,
} from '../utils/api-helper';
import { clearCachedUser } from '../utils/common';
import { isEmojiExist } from '../components/utils';
import TrialSignUpAdvantages from '../components/common/TrialSignUpAdvantages.vue';
import { CountriesNowService } from '../services/CountriesNowService';

export default {
  name: 'TrialSignUpNew',
  components: {
    ClipLoader,
    TrialSignUpAdvantages,
  },
  data() {
    return {
      id: '',
      isAudienceFlow: false,
      ssoUser: false,
      valid: true,
      isReadonlyfirstName: false,
      isReadonlylastName: false,
      isLoading: true,
      error: '',
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      nameRules: {
        firstName: [
          (v) => !isEmojiExist(v) || 'Emojis are not allowed',
          (v) => !!v || 'First Name is required',
        ],
        lastName: [
          (v) => !isEmojiExist(v) || 'Emojis are not allowed',
          (v) => !!v || 'Last Name is required',
        ],
        companySize: [(v) => !!v || this.$t('warnings.companySizeRequired')],
        phone: [
          (v) => !!v || this.$t('warnings.phoneNumberRequired'),
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
          (v) => /^[0-9]+$/.test(v) || this.$t('warnings.phoneMustBeValid'),
        ],
        country: [(v) => !!v || this.$t('warnings.countryRequired')],
      },
      passwordRules: [(v) => !isEmojiExist(v) || 'Emojis are not allowed'],
      confirmPasswordRules: [
        (v) => !isEmojiExist(v) || 'Emojis are not allowed',
        () =>
          this.isPasswordMatched('confirmPassword') || 'Password must match',
      ],
      show1: false,
      show2: false,
      password: '',
      rePassword: '',
      passwordErrors: [
        '8 characters',
        '1 letter',
        '1 number',
        '1 special character',
      ],
      isSignupLoading: false,
      isFailed: false,

      // flag for trial validate exceptions only
      isFailedWhileValidation: false,

      loading: true,
      isSuccessIntermediateLoader: false,

      // counter for trial validate exceptions only
      counterForValidation: 5,

      counter: 3,
      goToIntermediateRegistration: false, // for adding user company size, phone and country.
      continueToSignUp: false,
      countDown: 0,
      isAddToWaitList: false,
      loginLoading: true,
      companySizeOptions: [
        'Less than 50',
        'Between 50 - 1000',
        'More than 1000',
      ],
      companySize: '',
      phone: '',
      country: '',
      countries: [],
    };
  },
  computed: {
    passwordRequirements() {
      const errors = [];
      if (this.password && this.password.length < 8)
        errors.push('8 characters');
      if (this.password && !/[A-Za-z]{1}/.test(this.password))
        errors.push('1 letter');
      if (this.password && !/\d/.test(this.password)) errors.push('1 number');
      if (this.password && !/[^A-Za-z0-9]/.test(this.password))
        errors.push('1 special character');
      return errors;
    },
    getMessage() {
      return `
            <p>Thank you for your interest in Prezent!</p><p>We have added you to our waitlist<br />and will get back to you soon.</p>
            <p>Redirecting you to our website in ${this.countDown}s...</p>
          `;
    },
  },
  methods: {
    loadCountries() {
      CountriesNowService.getCountries().then((countries) => {
        this.countries = countries;
      });
    },
    checkError(err) {
      return this.passwordRequirements.includes(err);
    },
    isPasswordMatched(control) {
      if (
        (control === 'password' && this.rePassword) ||
        (control === 'confirmPassword' && this.password)
      ) {
        return this.password === this.rePassword;
      }
      return true;
    },
    async submit() {
      if (
        !this.$refs.form.validate() ||
        !this.isPasswordMatched('password') ||
        !this.isPasswordMatched('confirmPassword')
      )
        return;
      try {
        this.isSignupLoading = true;
        const user = {
          email: this.email,
          firstname: this.firstName,
          lastname: this.lastName,
          password: this.password,
          trialRegID: this.id,
        };
        if (this.continueToSignUp) {
          user.companySize = this.companySize;
          user.phone = this.phone;
          user.country = this.country?.name;
        }
        await trialSignUp(user);
        this.isSignupLoading = false;
        this.isSuccessIntermediateLoader = true;
        const countDownTimer = setInterval(() => {
          this.counter -= 1;
          if (this.counter < 1) {
            clearInterval(countDownTimer);
            clearCachedUser();
            this.isSuccessIntermediateLoader = false;
            this.$router.push('/signin', () => {});
          }
        }, 1000);
      } catch (err) {
        console.log(err);
        this.isFailed = true;
        this.isSignupLoading = false;
        setTimeout(() => {
          this.$router.push('/signin', () => {});
        }, 3000);
      }
    },
    async intermediateRegistrationSubmit() {
      /* if the company size is Less than 50 add to wait list
       else allow user to set password and allow signup */
      if (this.companySize === 'Less than 50') {
        this.isSignupLoading = true;
        const payload = {
          email: this.email,
          firstname: this.firstName,
          lastname: this.lastName,
          companyname: this.companyName,
          companySize: this.companySize,
          phone: this.phone,
          country: this.country?.name,
          trialRegID: this.id,
        };
        console.log('payload---->>', payload);
        await trialInValidate(payload);
        /* add the user to waitlist and redirect to Prezent website */
        this.isAddToWaitList = true;
        this.startWebsiteRedirectCounter();
      } else {
        /* allow user to set password and continue to signup */
        this.goToIntermediateRegistration = false;
        this.continueToSignUp = true;
      }
      this.isSignupLoading = false;
    },
    startWebsiteRedirectCounter() {
      this.countDown = 5;
      const countDownTimer = setInterval(() => {
        if (this.countDown <= 1) {
          clearInterval(countDownTimer);
          window.location.href = 'https://www.prezent.ai/thank-you';
        } else {
          this.countDown -= 1;
        }
      }, 1000);
    },
  },
  async mounted() {
    if (this.$route?.query?.id) {
      const queryString = this.$route.query.id;
      this.loadCountries();
      try {
        this.id = queryString;
        const userData = await trialValidate(this.id);
        this.ssoUser = userData?.message?.sso_enabled;
        const trialRegistrationUserData =
          userData?.message?.trialRegistrationUserData;
        this.firstName = trialRegistrationUserData?.firstname
          ? trialRegistrationUserData?.firstname
          : '';
        if (this.firstName) {
          this.isReadonlyfirstName = true;
        }
        this.lastName = trialRegistrationUserData?.lastname
          ? trialRegistrationUserData?.lastname
          : '';
        if (this.lastName) {
          this.isReadonlylastName = true;
        }
        this.email = trialRegistrationUserData?.email;
        this.companyName = trialRegistrationUserData?.companyDisplayName;
        /* if 'checkForCompanySize' size is true from response add an intermediate
         screen to upload the compnay size phone and country of the user (sharibg a free trial from product) */
        if (
          trialRegistrationUserData?.checkForCompanySize &&
          !trialRegistrationUserData?.companySize
        ) {
          this.goToIntermediateRegistration = true;
        }
        this.isLoading = false;
      } catch (err) {
        // Timer exclusively for trial validate exceptions
        this.isFailedWhileValidation = true;
        this.isLoading = false;
        const countDownTimer = setInterval(() => {
          this.counterForValidation -= 1;
          if (this.counterForValidation < 1) {
            clearInterval(countDownTimer);
            this.$router.push('/signin', () => {});
          }
        }, 1000);
      }
    } else {
      this.isLoading = false;
      this.isFailed = true;
      setTimeout(() => {
        this.$router.push('/signin', () => {});
      }, 3000);
      console.log('No access token');
    }
  },
  watch: {
    rePassword(value) {
      if (value) {
        this.$refs.passwordControl.resetValidation();
        this.$refs.passwordControl.validate();
      }
    },
    password(value) {
      if (value) {
        this.$refs.confirmPasswordControl.resetValidation();
        this.$refs.confirmPasswordControl.validate();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
// .v-application {
//   background: linear-gradient(
//     0.61deg,
//     rgba(149, 208, 234, 0.6) -22.84%,
//     rgba(255, 255, 255, 0.048) 38.59%
//   );
// }

.logo {
  padding: 30px 30px 0px 40px;
  width: fit-content;
}
.header {
  padding: 20px 0px 40px 40px;
  display: flex;
  width: 100%;
  justify-content: left;
}
.logo-loading {
  padding-bottom: 20px;
}
.expired-link {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.loading-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
}
.signup-wrapper {
  height: fit-content;
  margin: auto;
  width: 100%;
  .signup-content {
    align-items: flex-start;
    display: flex;
    min-height: 100vh;
    .signup-content-right {
      width: 50%;
      color: #fff !important;
      background-color: #1075ab;
      min-height: 100vh;
      .signup-heading {
        font-weight: 400px;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 25px;
        padding-top: 50px;
        padding-left: 100px;
      }
      .signup-form {
        // position: relative;
        width: 60%;
        padding: 10px 30px 0px 100px;
        ::v-deep .v-input input {
          color: #fff !important;
        }
        ::v-deep .v-input.v-input--is-disabled input {
          color: rgba(255, 255, 255, 0.58) !important;
        }
        ::v-deep .v-input .v-label {
          color: #fff !important;
        }
        ::v-deep .v-input__slot:before {
          border-color: #fff !important;
        }
        ::v-deep .v-input__slot::after {
          border-color: #fff !important;
        }
        ::v-deep .v-input input {
          color: #fff !important;
        }
        ::v-deep .v-input__slot {
          margin-bottom: 0px !important;
        }
        ::v-deep .v-input .v-label {
          color: #fff !important;
        }
        ::v-deep .v-input__slot:before {
          border-color: #fff !important;
        }
        ::v-deep .v-input__slot::after {
          border-color: #fff !important;
        }
        ::v-deep.error--text .v-input__slot:before {
          border-color: #f70000 !important;
        }
        ::v-deep.error--text .v-input__slot::after {
          border-color: #f70000 !important;
        }
        ::v-deep.v-messages__wrapper {
          height: 25px;
        }
        ::v-deep.v-messages__message {
          position: absolute;
          color: #fff; /* Change the text color */
          font-size: 12px; /* Adjust the font size */
          font-weight: 400; /* Adjust the font size */
          background-color: #f70000; /* Add a background color */
          padding: 4px; /* Add some padding */
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        .v-messages__message::before {
          content: '\f059'; /* Font Awesome "info-circle" icon or similar */
          font-family: 'Font Awesome 5 Free'; /* Make sure Font Awesome is available */
          font-weight: 900; /* Needed for solid Font Awesome icons */
          color: #fff; /* Icon color */
          margin-right: 8px; /* Space between the icon and the text */
          cursor: pointer; /* Show pointer on hover for the icon */
          display: inline-block;
          vertical-align: middle;
          position: relative;
        }
        .v-messages__message:hover::before::after {
          content: 'This field is required'; /* Tooltip text */
          position: absolute;
          top: -24px;
          left: 0;
          background-color: black;
          color: white;
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 12px;
          white-space: nowrap;
          z-index: 9999;
        }
        .create-password {
          /* Triangle for tooltip */
          .create-password-form {
            position: relative;
          }
          .v-messages__message:hover::before::before {
            content: '';
            position: absolute;
            top: -8px;
            left: 10px;
            border-width: 4px;
            border-style: solid;
            border-color: transparent transparent black transparent;
          }
        }
        .password-errors-box {
          box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
          font-size: 13px;
          left: -33%;
          padding: 5px;
          position: absolute;
          top: -30%;
          background-color: #fff;
          .password-wrapper {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            .visibility {
              visibility: hidden;
            }
            span {
              font-size: 18px;
              font-weight: 500;
            }
            .password-error {
              color: red;
            }
            .no-error {
              color: green;
            }
            p {
              margin-bottom: 0px;
            }
          }
        }
        .card-details {
          font-size: 14px;
          margin-top: 20px;
        }
        .label {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
        }
        button {
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          text-transform: none;
          margin-top: 38px;
        }
        .error {
          background-color: transparent !important;
          border-color: transparent !important;
          color: red;
        }
      }
    }
    .signup-content-left {
      padding: 50px 80px 0px 80px;
      width: 50%;
      // .logo {
      //   padding: 0px 0 10px 0 !important;
      //   width: fit-content;
      // }
      // .registration-heading-left {
      //   font-family: Lato;
      //   font-weight: 400;
      //   font-size: 28px;
      //   line-height: 36px;
      //   color: #121619;
      //   padding-bottom: 20px;
      // }
      // .registration-subheading {
      //   font-family: Lato;
      //   font-weight: 400;
      //   font-size: 24px;
      //   line-height: 29px;
      //   color: #1075ab;
      //   padding-bottom: 15px;
      // }
      // .services-items {
      //   grid-row-gap: 25px;
      //   flex-direction: column;
      //   align-items: flex-start;
      //   width: 100%;
      //   margin-top: 20px;
      //   margin-bottom: 40px;
      //   display: flex;
      //   .services-item {
      //     grid-column-gap: 25px;
      //     align-items: flex-start;
      //     display: flex;
      //     .service-icon-wrapper {
      //       border: 0.8px solid rgba(216, 230, 241, 1);
      //       background-color: #fff;
      //       border-radius: 12px;
      //       flex: none;
      //       justify-content: center;
      //       align-items: center;
      //       width: 75px;
      //       height: 75px;
      //       margin-bottom: 0;
      //       padding: 14px;
      //       display: flex;
      //       // box-shadow: rgba(179, 228, 245, 0.2);
      //     }
      //     .service-description-wrapper {
      //       grid-row-gap: 8px;
      //       flex-direction: column;
      //       display: flex;
      //       .service-description-title {
      //         font-size: 18px;
      //         font-weight: 500;
      //         color: #1075ab;
      //         margin: 0 !important;
      //       }
      //       .service-description-subtitle {
      //         font-size: 16px;
      //         font-weight: 400;
      //         color: #000;
      //         margin: 0 !important;
      //         line-height: 17px;
      //       }
      //     }
      //   }
      // }
    }
  }
}
.terms {
  align-items: center;
  display: block;
  margin-top: 20px;
  p {
    margin-bottom: 0px;
  }
  a {
    text-decoration: underline;
    color: #fff !important;
  }
}
.password-errors-box::after {
  border: 10px solid transparent;
  border-left: 10px solid white;
  clear: both;
  content: '';
  position: absolute;
  right: -20px;
  visibility: visible;
  top: 40px;
}

.redirect {
  align-items: center;
  display: flex;
  flex-direction: column;

  p {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 22px;
  }
}

.privacy-policy-footer {
  bottom: 0;
  color: gray !important;
  display: flex;
  justify-content: flex-end;
  padding: 20px 60px;
  right: 0;
  margin-top: auto;
  .privacy-policy {
    padding-right: 20px;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
.waitListContent {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  .confirmation-wrapper {
    align-items: center;
    display: flex;
    font-size: 24px;
    justify-content: center;
    margin-bottom: 50px;
    text-align: center;
    width: 40%;
    .confirmation {
      .v-image {
        margin: 0 auto;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
