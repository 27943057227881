export const templateDesignerWorker = () => {
  const fetchTemplateDesignerData = ({ url, token }) => {
    fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          self.postMessage('worker unauthorized');
        }
        return response.json();
      })
      .then((jsonObj) => {
        self.postMessage(jsonObj.data);
        setTimeout(() => fetchTemplateDesignerData({ url, token }), 60000);
      })
      .catch((err) => {
        console.error(err);
        setTimeout(() => fetchTemplateDesignerData({ url, token }), 60000);
      });
  };

  self.addEventListener('message', (e) => {
    const highestTimeoutId = setTimeout(() => {}, 0);
    for (let i = 0; i < highestTimeoutId; i++) {
      clearTimeout(i);
    }
    fetchTemplateDesignerData({
      url: `${process.env.VUE_APP_AWS_PREZENT_API}/api3/designer/theme`,
      token: e.data.token,
    });
  });
};
