/* eslint-disable arrow-body-style */
import { API } from 'aws-amplify';
import { getLoggedInUserToken } from '../utils/api';

export class GraphQLService {
  /** @type {typeof import('aws-amplify')['API']['graphql']} */
  static async requestWithToken(options, additionalHeaders) {
    const token = await getLoggedInUserToken();

    if (!token) {
      window.location = '/signin/10001';
    }

    return API.graphql(options, {
      'X-Authorization': token,
      ...(additionalHeaders || {}),
    });
  }
}
