<template>
  <v-app>
    <v-main>
      <div class="home-wrapper">
        <div class="main-wrapper">
          <div class="logo">
            <img
              width="200px"
              height="44px"
              src="/assets/img/prezent_logo.svg"
              alt
            />
          </div>
          <div class="result-wrapper" v-if="!loading">
            <FingerPrint
              :fingerprint="audience.fingerPrint"
              :audience="audience"
              :personalPrefs="audience.personalPreference"
              :isPrivateAudience="false"
              :shareable="true"
            />
          </div>
          <div v-if="noresult"></div>
        </div>
      </div>
    </v-main>
    <div class="privacy-policy-footer">
      <div class="privacy-policy">
        <a href="https://prezent.ai/privacy-policy" target="_blank"
          >Privacy Policy</a
        >
      </div>
      <div>&#169; Prezent Inc. 2021</div>
    </div>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import audienceApi from '../API/audience-api';
import FingerPrint from '../components/Main/FingerPrints/FingerPrint.vue';
import ShareFingerPrintVue from '../components/Main/FingerPrints/ShareFingerPrint.vue';

export default {
  name: 'FingerPrintTest',
  components: { FingerPrint },
  data() {
    return {
      audience: null,
      loading: true,
      noresult: false,
    };
  },
  computed: {},
  methods: {
    ...mapActions('users', ['setCurrentTestAudience']),
  },
  async mounted() {
    audienceApi.methods
      .getAudienceFingerprint(this.$route.query.audience)
      .then((audience) => {
        if (audience.fingerPrint) {
          this.audience = audience;
          this.setCurrentTestAudience({
            ...audience,
            uuid: this.$route.query.audience,
          });
          this.loading = false;
          if (this.$route.query.requester) {
            this.$modal.show(
              ShareFingerPrintVue,
              {
                type: 'modal',
                isAudience: true,
                requester: this.$route.query.requester,
              },
              {
                name: 'ShareFingerPrint',
                width: 700,
                height: 'auto',
                minHeight: 300,
                styles: { borderRadius: '20px' },
              },
            );
          }
        } else {
          this.noresult = true;
          this.$router.push('/signin', () => {});
        }
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main-wrapper {
  padding-left: 3%;
  padding-right: 3%;
  text-align: center !important;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 20px;
}
::v-deep .highlight {
  color: #29b6f6;
}

.encourage-share-fingerprint {
  align-items: center;
  display: flex;
  flex-direction: column;

  .encourage-title {
    color: #000000;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 38px;
    text-align: center;
  }

  .encourage-sub-title {
    color: #000000;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-top: 15px;
    text-align: center;
  }

  .main-wrapper {
    margin-top: 40px;
    width: 90%;
    ::v-deep .audience-item {
      .text-name {
        text-align: start;
      }
    }
  }
}
.section-wrapper {
  display: flex;
  align-items: center !important;
  justify-content: center;
  text-align: center;
  .fingerprint-title {
    font-size: 36px;
    font-weight: bold;
    color: #21a7e0;
  }
  .fingerprint-sub-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 45px;
    .fingerprint-sub-title-content {
      font-size: 36px;
      margin-right: 10px;
    }
  }
  .section-image {
    margin-right: 45px;
  }
}
.privacy-policy-footer {
  bottom: 0;
  color: gray !important;
  display: flex;
  justify-content: flex-end;
  padding: 20px 60px;
  right: 0;
  .privacy-policy {
    padding-right: 20px;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
.first-screen {
  justify-content: center;
  .name-wrapper {
    font-size: 36px;
    font-weight: bold;
    color: #21a7e0;
  }
  .highlight-bold {
    font-weight: bold;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 44px;
  }
  .sub-header {
    font-size: 24px;
    margin-bottom: 60px;
  }
  button {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none;
  }
}
.second-screen {
  align-items: center;
  display: flex;

  .left-image-section {
    width: 40%;
  }
  .pa-4 {
    width: 60%;
    .header {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      letter-spacing: normal;
      text-align: center;
      margin-bottom: 16px;
    }

    .items {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 24px 0;

      .item {
        align-items: center;
        border-radius: 12px;
        box-shadow: 1px 1px 8px 4px rgba(0, 0, 0, 0.02),
          -1px -1px 8px 4px rgba(0, 0, 0, 0.02);
        display: flex;
        flex-direction: column;
        height: 162px;
        justify-content: center;
        width: 240px;

        .title {
          font-size: 20px;
          font-weight: normal;
          margin-bottom: 20px;
        }
        img {
          width: 86px;
          height: 60px;
        }
      }
    }
  }
  .description {
    font-size: 16px;
    font-weight: normal;
    line-height: 19px;
    margin-bottom: 24px;
    text-align: center;
  }
  .action-detail {
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    margin-bottom: 24px;
  }
  button {
    margin-bottom: 9px;
  }
  .final-description {
    font-size: 20px;
    font-weight: normal;
  }
}
.question-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  margin: auto;
  text-align: center !important;
  .buttonAndHeading {
    display: flex;
    width: 30%;
    margin: 0 auto;
    .back-button {
      // margin-right: 40px;
      cursor: pointer;
      color: #29b6f6;
      display: flex;
      align-items: center;
      width: 18%;
    }
    .question-header {
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 15px;
      width: 63%;
      text-align: center;
    }
  }
  .question-title {
    color: #29b6f6;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .progress-wrapper {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 47px;
    margin-left: -24px;
  }
  .question-index {
    font-size: 12px;
    margin-bottom: 40px;
  }
  .question-content {
    width: 100%;
    .question-content-header {
      margin-bottom: 40px;
      font-size: 20px;
      .header {
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 15px;
      }
      .sub-header {
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }
    .images-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 50px;
      img.question-image {
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
        &.border {
          border: 5px solid #21a7e0;
          box-shadow: 3px -2px 15px 3px #b6b6b6, -3px 2px 15px 3px #b6b6b6;
        }
        &:hover {
          box-shadow: 3px -2px 15px 3px #b6b6b6, -3px 2px 15px 3px #b6b6b6;
        }
        &.blur {
          opacity: 0.35;
        }
      }
    }
    .selections-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      .header {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        margin-bottom: 20px;
      }
      .selections {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .selection {
          align-items: center;
          display: flex;
          justify-content: flex-start;
          border-radius: 12px;
          box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
            -1px -1px 4px rgba(0, 0, 0, 0.1);
          padding: 8px 25px;
          margin-right: 25px;
          margin-bottom: 25px;
          color: rgba(33, 33, 33, 1);
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          cursor: pointer;
          height: 70px;
          width: 40%;
          .selection-title {
            padding-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          &.highlight {
            border: 1px solid #29b6f6;
          }
        }
      }
      .write-own-btn {
        background: #f5fcff;
        border-radius: 12px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
          -1px -1px 4px rgba(0, 0, 0, 0.1);
        width: 40%;
        padding: 8px 25px;
        font-size: 20px;
        font-weight: 700;
        margin: auto;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 60px;
        .write-own-header {
          display: flex;
          margin-top: 10px;
        }
        .new-pref-text {
          background: #ffffff;
          border-radius: 4px;
          font-weight: 400;
          margin: 10px 0px;
          text-align: left;
          width: 100%;
          ::v-deep .v-input__slot {
            margin-bottom: 0px !important;
          }
        }
        .add-pref {
          color: #29b6f6;
          margin-bottom: 10px;
          text-align: right;
          width: 100%;
          .v-icon.v-icon {
            color: #29b6f6;
          }
        }
      }
      .create-own {
        margin-bottom: 60px;
      }
    }
  }
  .question-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    button {
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-transform: none;
    }
  }
}
.result-wrapper {
  margin: auto;
  text-align: center !important;

  .footer-description {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: normal;
  }
  .get-in-touch {
    font-size: 24px;
    font-weight: bold;
    text-transform: none;
  }
}
::v-deep .v-text-field__details {
  display: none;
}
.v-input--radio-group--row {
  width: 100%;
}
.get-in-touch-wrapper {
  text-align: center !important;
  .get-in-touch-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center !important;
    margin-top: 35px;
    margin-bottom: 25px;
  }
}
.fingerprint-center-item {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $zen-blue-default;
  margin-top: 10px;
  margin-bottom: 7px;
  width: 100%;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
