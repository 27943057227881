export const initialState = {
  slidesList: [],
  slideDetail: {},
  slidesMeta: {},
  slideMetaData: {},
  // selectedSource: ['All'],
  selectedTrendingIdeas: ['All'],
  // selectedSortBy: 'Recommended',
  sourceDisplayNameRef: {},
};

const mutations = {
  SLIDES_LIST(state, content) {
    state.slidesList = content;
  },
  SLIDE_DETAIL(state, content) {
    state.slideDetail = content;
  },
  SLIDES_META_DATA(state, content) {
    state.slidesMeta = content;
  },
  SLIDE_META(state, content) {
    state.slideMetaData = content;
  },
  KNOW_CATEGORIES(state, content) {
    state.knowCategories = content;
  },
  SELECTED_TRENDING_IDEAS(state, content) {
    state.selectedTrendingIdeas = content;
  },
  // SELECTED_SOURCE(state, content) {
  //   state.selectedSource = content;
  // },
  // SELECTED_SORT_BY(state, content) {
  //   state.selectedSortBy = content;
  // },

  SOURCE_REF_OBJ(state, content) {
    state.sourceDisplayNameRef = content;
  },
  RESET_STATE(state) {
    state = Object.assign(state,initialState);
  },
};

const actions = {
  setSlidesList: ({ commit }, data) => {
    commit('SLIDES_LIST', data);
  },
  setSlideDetail: ({ commit }, data) => {
    commit('SLIDE_DETAIL', data);
  },
  setSlidesMeta: ({ commit }, data) => {
    commit('SLIDES_META_DATA', data);
  },
  setSlideMetaData: ({ commit }, data) => {
    commit('SLIDE_META', data);
  },
  setKnowCategories: ({ commit }, data) => {
    commit('KNOW_CATEGORIES', data);
  },
  setSelectedTrendingIdeas: ({ commit }, data) => {
    commit('SELECTED_TRENDING_IDEAS', data);
  },
  // setSelectedSource: ({ commit }, data) => {
  //   commit('SELECTED_SOURCE', data);
  // },
  // setSelectedSortBy: ({ commit }, data) => {
  //   commit('SELECTED_SORT_BY', data);
  // },
  resetKnow: ({ commit }) => {
    commit('RESET_STATE');
  },
  setSourceDisplayNameRef: ({ commit }, data) => {
    commit('SOURCE_REF_OBJ', data);
  },
};

const getters = {};

export default {
  namespaced: true,
  state: {...initialState,knowCategories: []},
  mutations,
  actions,
  getters,
};
