import moment from 'moment';
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const advancedFilters = [
  {
    items: [
      { title: 'Submitted', active: true },
      { title: 'Accepted', active: true },
      { title: 'In Progress', active: true },
      { title: 'Completed', active: true },
    ],
    checkAll: true,
    title: 'Status',
    active: true,
    includeAllOption: true,
    icon: 'status',
  },
  {
    items: [{ title: 'My Downloads', active: false }],
    checkAll: true,
    title: 'Downloads',
    active: true,
    includeAllOption: false,
    icon: 'downloads',
  },
  {
    checkAll: true,
    title: 'Submitted on',
    active: true,
    includeAllOption: false,
    icon: 'created-on',
    isCustomGroup: true,
  },
];

const knowAdvancedItemsOnLoad = [
  {
    items: [
      {
        title: 'My Favorites',
        display_title: 'filters.myFavorites',
        active: false,
      },
    ],
    checkAll: true,
    title: 'Favorites',
    display_title: 'slides.favourites',
    active: true,
    includeAllOption: false,
    icon: 'favorites',
  },
  {
    items: [
      {
        title: 'My Downloads',
        display_title: 'filters.myDownloads',
        active: false,
      },
    ],
    checkAll: true,
    title: 'Downloads',
    display_title: 'slides.downloads',
    active: true,
    includeAllOption: false,
    icon: 'downloads',
  },
];

const advancedFilterState = () => {
  return {
    slides: {
      filterOptionCategory: 'brand_slides',
      searchProgress: true,
      advancedItems: [
        {
          items: [
            {
              title: 'Prezent',
              name: 'prezent',
              display_title: 'Prezent',
              active: true,
              disabled: false,
            },
            {
              title: 'Company',
              name: 'company',
              display_title: 'Company',
              active: true,
              disabled: false,
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Source',
          display_title: 'userUploadPrezentation.source',
          active: false,
          icon: 'filter-source',
          disabled: false,
        },
        {
          items: [
            { title: '1', display_title: '1', active: true, disabled: false },
            { title: '2', display_title: '2', active: true, disabled: false },
            { title: '3', display_title: '3', active: true, disabled: false },
            { title: '4', display_title: '4', active: true, disabled: false },
            { title: '5', display_title: '5', active: true, disabled: false },
            { title: '6', display_title: '6', active: true, disabled: false },
            { title: '7', display_title: '7', active: true, disabled: false },
            { title: '8', display_title: '8', active: true, disabled: false },
            { title: '9', display_title: '9', active: true, disabled: false },
            {
              title: '10 or more',
              display_title: 'filters.more',
              active: true,
              disabled: false,
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Node Count',
          display_title: 'tagSlides.nodeCountLabel',
          active: false,
          icon: 'node-count-slides',
          disabled: false,
        },
        {
          items: [
            {
              title: 'Cartoons',
              display_title: 'filters.cartoons',
              active: true,
              disabled: false,
            },
            {
              title: 'Icons',
              display_title: 'filters.icons',
              active: true,
              disabled: false,
            },
            {
              title: 'Images',
              display_title: 'filters.images',
              active: true,
              disabled: false,
            },
            {
              title: '3-D',
              display_title: '3-D',
              active: true,
              disabled: false,
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Graphics',
          display_title: 'tagSlides.graphics',
          active: false,
          icon: 'graphics-slides',
          disabled: false,
        },
        {
          active: false,
          checkAll: true,
          disableCheckAll: false,
          showIcon: true,
          items: [
            {
              title: 'Area Graph',
              display_title: 'filters.areaGraph',
              icon: 'area-graph',
              name: 'area_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Assessment',
              display_title: 'filters.assessment',
              icon: 'assessment',
              name: 'assessment',
              active: true,
              disabled: false,
            },
            {
              title: 'Bar/Column Graph',
              display_title: 'filters.barGraph',
              icon: 'bar-column',
              name: 'bar_column_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Box and Whisker',
              display_title: 'filters.boxAndWhisker',
              icon: 'box-and-whisker',
              name: 'box_and_whisker',
              active: true,
              disabled: false,
            },
            {
              title: 'Bubble Graph',
              display_title: 'filters.bubbleGraph',
              icon: 'bubblish-graph',
              name: 'bubble_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Bullet Graph',
              display_title: 'filters.bulletGraph',
              icon: 'bullet-graph',
              name: 'bullet_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Category',
              display_title: 'filters.category',
              icon: 'category',
              name: 'category',
              active: true,
              disabled: false,
            },
            {
              title: 'Causality',
              display_title: 'filters.casuality',
              icon: 'causality',
              name: 'causality',
              active: true,
              disabled: false,
            },
            {
              title: 'Calendar',
              display_title: 'filters.calendar',
              icon: 'calendar',
              name: 'calendar',
              active: true,
              disabled: false,
            },
            {
              title: 'Circular',
              display_title: 'filters.circular',
              icon: 'circular',
              name: 'circular',
              active: true,
              disabled: false,
            },
            {
              title: 'Convergent',
              display_title: 'filters.convergent',
              icon: 'convergent',
              name: 'convergent',
              active: true,
              disabled: false,
            },

            {
              title: 'Decision Tree',
              display_title: 'filters.decisionTree',
              icon: 'decision-tree',
              name: 'decision_tree',
              active: true,
              disabled: false,
            },
            {
              title: 'Divergent',
              display_title: 'filters.divergent',
              icon: 'divergent',
              name: 'divergent',
              active: true,
              disabled: false,
            },
            {
              title: 'Donut/Pie Chart',
              display_title: 'filters.pieChart',
              icon: 'donut-pie-graph',
              name: 'donut_pie_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Emphasis',
              display_title: 'filters.emphasis',
              icon: 'emphasis',
              name: 'emphasis',
              active: true,
              disabled: false,
            },
            {
              title: 'Enclosed',
              display_title: 'filters.enclosed',
              icon: 'enclosed',
              name: 'enclosed',
              active: true,
              disabled: false,
            },
            {
              title: 'Filter',
              display_title: 'filters.filter',
              icon: 'filter',
              name: 'filter',
              active: true,
              disabled: false,
            },
            {
              title: 'Histogram',
              display_title: 'filters.histogram',
              icon: 'histogram',
              name: 'histogram',
              active: true,
              disabled: false,
            },
            {
              title: 'Linked',
              display_title: 'filters.linked',
              icon: 'linked',
              name: 'linked',
              active: true,
              disabled: false,
            },
            {
              title: 'List',
              display_title: 'filters.list',
              icon: 'list',
              name: 'list',
              active: true,
              disabled: false,
            },
            {
              title: 'Line Graph',
              display_title: 'filters.lineGraph',
              icon: 'line-graph',
              name: 'line_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Matrix',
              display_title: 'filters.matrix',
              icon: 'matrix',
              name: 'matrix',
              active: true,
              disabled: false,
            },
            {
              title: 'Multi Bar',
              display_title: 'filters.multiBar',
              icon: 'multi-graph',
              name: 'multi_bar',
              active: true,
              disabled: false,
            },
            {
              title: 'One Pager',
              display_title: 'filters.onePager',
              icon: 'one-pager',
              name: 'one_pager',
              active: true,
              disabled: false,
            },
            {
              title: 'Pictogram Chart',
              display_title: 'filters.pictogram',
              icon: 'pictogram-chart',
              name: 'pictogram_chart',
              active: true,
              disabled: false,
            },
            {
              title: 'Process',
              display_title: 'filters.process',
              icon: 'process',
              name: 'process',
              active: true,
              disabled: false,
            },
            {
              title: 'Pyramid',
              display_title: 'filters.pyramid',
              icon: 'pyramid',
              name: 'pyramid',
              active: true,
              disabled: false,
            },
            {
              title: 'Radar Chart',
              display_title: 'filters.radarChart',
              icon: 'radar-chart',
              name: 'radar_chart',
              active: true,
              disabled: false,
            },
            {
              title: 'Ring',
              display_title: 'filters.ring',
              icon: 'ring',
              name: 'ring',
              active: true,
              disabled: false,
            },
            {
              title: 'Spoke',
              display_title: 'filters.spoke',
              icon: 'spoke',
              name: 'spoke',
              active: true,
              disabled: false,
            },
            {
              title: 'Stacked Bar Graph',
              display_title: 'filters.stackedBarGraph',
              icon: 'stacked-bar-graph',
              name: 'stacked_bar_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Stairs',
              display_title: 'filters.stairs',
              icon: 'stairs',
              name: 'stairs',
              active: true,
              disabled: false,
            },
            {
              title: 'Table',
              display_title: 'filters.table',
              icon: 'table',
              name: 'table',
              active: true,
              disabled: false,
            },
            {
              title: 'Timeline',
              display_title: 'filters.timeLine',
              icon: 'timeline',
              name: 'timeline',
              active: true,
              disabled: false,
            },
            {
              title: 'Tree',
              display_title: 'filters.tree',
              icon: 'tree',
              name: 'tree',
              active: true,
              disabled: false,
            },
            {
              title: 'Tree map',
              display_title: 'filters.treeMap',
              icon: 'tree-map',
              name: 'tree_map',
              active: true,
              disabled: false,
            },
            {
              title: 'Venn',
              display_title: 'filters.venn',
              icon: 'venn',
              name: 'venn',
              active: true,
              disabled: false,
            },
          ],
          title: 'Diagrams',
          display_title: 'tagSlides.diagrams',
          icon: 'diagrams-slides',
          disabled: false,
        },
      ],
      advancedItemsOnLoad: [
        {
          items: [
            {
              title: 'Prezent',
              name: 'prezent',
              display_title: 'Prezent',
              active: true,
              disabled: false,
            },
            {
              title: 'Company',
              name: 'company',
              display_title: 'Company',
              active: true,
              disabled: false,
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Source',
          display_title: 'userUploadPrezentation.source',
          active: false,
          icon: 'filter-source',
          disabled: false,
        },
        {
          items: [
            { title: '1', display_title: '1', active: true, disabled: false },
            { title: '2', display_title: '2', active: true, disabled: false },
            { title: '3', display_title: '3', active: true, disabled: false },
            { title: '4', display_title: '4', active: true, disabled: false },
            { title: '5', display_title: '5', active: true, disabled: false },
            { title: '6', display_title: '6', active: true, disabled: false },
            { title: '7', display_title: '7', active: true, disabled: false },
            { title: '8', display_title: '8', active: true, disabled: false },
            { title: '9', display_title: '9', active: true, disabled: false },
            {
              title: '10 or more',
              display_title: 'filters.more',
              active: true,
              disabled: false,
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Node Count',
          display_title: 'tagSlides.nodeCountLabel',
          active: false,
          icon: 'node-count-slides',
          disabled: false,
        },
        {
          items: [
            {
              title: 'Cartoons',
              display_title: 'filters.cartoons',
              active: true,
              disabled: false,
            },
            {
              title: 'Icons',
              display_title: 'filters.icons',
              active: true,
              disabled: false,
            },
            {
              title: 'Images',
              display_title: 'filters.images',
              active: true,
              disabled: false,
            },
            {
              title: '3-D',
              display_title: '3-D',
              active: true,
              disabled: false,
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Graphics',
          display_title: 'tagSlides.graphics',
          active: false,
          icon: 'graphics-slides',
          disabled: false,
        },
        {
          active: false,
          checkAll: true,
          disableCheckAll: false,
          showIcon: true,
          items: [
            {
              title: 'Area Graph',
              display_title: 'filters.areaGraph',
              icon: 'area-graph',
              name: 'area_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Assessment',
              display_title: 'filters.assessment',
              icon: 'assessment',
              name: 'assessment',
              active: true,
              disabled: false,
            },
            {
              title: 'Bar/Column Graph',
              display_title: 'filters.barGraph',
              icon: 'bar-column',
              name: 'bar_column_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Box and Whisker',
              display_title: 'filters.boxAndWhisker',
              icon: 'box-and-whisker',
              name: 'box_and_whisker',
              active: true,
              disabled: false,
            },
            {
              title: 'Bubble Graph',
              display_title: 'filters.bubbleGraph',
              icon: 'bubblish-graph',
              name: 'bubble_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Bullet Graph',
              display_title: 'filters.bulletGraph',
              icon: 'bullet-graph',
              name: 'bullet_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Category',
              display_title: 'filters.category',
              icon: 'category',
              name: 'category',
              active: true,
              disabled: false,
            },
            {
              title: 'Causality',
              display_title: 'filters.casuality',
              icon: 'causality',
              name: 'causality',
              active: true,
              disabled: false,
            },
            {
              title: 'Calendar',
              display_title: 'filters.calendar',
              icon: 'calendar',
              name: 'calendar',
              active: true,
              disabled: false,
            },
            {
              title: 'Circular',
              display_title: 'filters.circular',
              icon: 'circular',
              name: 'circular',
              active: true,
              disabled: false,
            },
            {
              title: 'Convergent',
              display_title: 'filters.convergent',
              icon: 'convergent',
              name: 'convergent',
              active: true,
              disabled: false,
            },

            {
              title: 'Decision Tree',
              display_title: 'filters.decisionTree',
              icon: 'decision-tree',
              name: 'decision_tree',
              active: true,
              disabled: false,
            },
            {
              title: 'Divergent',
              display_title: 'filters.divergent',
              icon: 'divergent',
              name: 'divergent',
              active: true,
              disabled: false,
            },
            {
              title: 'Donut/Pie Chart',
              display_title: 'filters.pieChart',
              icon: 'donut-pie-graph',
              name: 'donut_pie_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Emphasis',
              display_title: 'filters.emphasis',
              icon: 'emphasis',
              name: 'emphasis',
              active: true,
              disabled: false,
            },
            {
              title: 'Enclosed',
              display_title: 'filters.enclosed',
              icon: 'enclosed',
              name: 'enclosed',
              active: true,
              disabled: false,
            },
            {
              title: 'Filter',
              display_title: 'filters.filter',
              icon: 'filter',
              name: 'filter',
              active: true,
              disabled: false,
            },
            {
              title: 'Histogram',
              display_title: 'filters.histogram',
              icon: 'histogram',
              name: 'histogram',
              active: true,
              disabled: false,
            },
            {
              title: 'Linked',
              display_title: 'filters.linked',
              icon: 'linked',
              name: 'linked',
              active: true,
              disabled: false,
            },
            {
              title: 'List',
              display_title: 'filters.list',
              icon: 'list',
              name: 'list',
              active: true,
              disabled: false,
            },
            {
              title: 'Line Graph',
              display_title: 'filters.lineGraph',
              icon: 'line-graph',
              name: 'line_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Matrix',
              display_title: 'filters.matrix',
              icon: 'matrix',
              name: 'matrix',
              active: true,
              disabled: false,
            },
            {
              title: 'Multi Bar',
              display_title: 'filters.multiBar',
              icon: 'multi-graph',
              name: 'multi_bar',
              active: true,
              disabled: false,
            },
            {
              title: 'One Pager',
              display_title: 'filters.onePager',
              icon: 'one-pager',
              name: 'one_pager',
              active: true,
              disabled: false,
            },
            {
              title: 'Pictogram Chart',
              display_title: 'filters.pictogram',
              icon: 'pictogram-chart',
              name: 'pictogram_chart',
              active: true,
              disabled: false,
            },
            {
              title: 'Process',
              display_title: 'filters.process',
              icon: 'process',
              name: 'process',
              active: true,
              disabled: false,
            },
            {
              title: 'Pyramid',
              display_title: 'filters.pyramid',
              icon: 'pyramid',
              name: 'pyramid',
              active: true,
              disabled: false,
            },
            {
              title: 'Radar Chart',
              display_title: 'filters.radarChart',
              icon: 'radar-chart',
              name: 'radar_chart',
              active: true,
              disabled: false,
            },
            {
              title: 'Ring',
              display_title: 'filters.ring',
              icon: 'ring',
              name: 'ring',
              active: true,
              disabled: false,
            },
            {
              title: 'Spoke',
              display_title: 'filters.spoke',
              icon: 'spoke',
              name: 'spoke',
              active: true,
              disabled: false,
            },
            {
              title: 'Stacked Bar Graph',
              display_title: 'filters.stackedBarGraph',
              icon: 'stacked-bar-graph',
              name: 'stacked_bar_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Stairs',
              display_title: 'filters.stairs',
              icon: 'stairs',
              name: 'stairs',
              active: true,
              disabled: false,
            },
            {
              title: 'Table',
              display_title: 'filters.table',
              icon: 'table',
              name: 'table',
              active: true,
              disabled: false,
            },
            {
              title: 'Timeline',
              display_title: 'filters.timeLine',
              icon: 'timeline',
              name: 'timeline',
              active: true,
              disabled: false,
            },
            {
              title: 'Tree',
              display_title: 'filters.tree',
              icon: 'tree',
              name: 'tree',
              active: true,
              disabled: false,
            },
            {
              title: 'Tree map',
              display_title: 'filters.treeMap',
              icon: 'tree-map',
              name: 'tree_map',
              active: true,
              disabled: false,
            },
            {
              title: 'Venn',
              display_title: 'filters.venn',
              icon: 'venn',
              name: 'venn',
              active: true,
              disabled: false,
            },
          ],
          title: 'Diagrams',
          display_title: 'tagSlides.diagrams',
          icon: 'diagrams-slides',
          disabled: false,
        },
      ],
      advancedItemsUpload: [
        {
          items: [
            {
              title: 'Slide Shells',
              display_title: 'filters.slideShells',
              active: true,
              disabled: false,
              value: 'Slide Shells',
            },
            {
              title: 'Partially Anonymized',
              display_title: 'filters.partiallyAnonymized',
              active: true,
              disabled: false,
              value: 'Best Practice Slides',
            },
            {
              title: 'Original',
              display_title: 'upload.original',
              active: true,
              disabled: false,
              value: 'Original Slides',
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Slide Type',
          display_title: 'filters.slideType',
          active: false,
          icon: 'slide-type-slides',
          disabled: false,
        },
        {
          items: [
            {
              title: 'Me',
              display_title: 'fingerprint.compareFingAud.me',
              active: true,
              disabled: false,
              value: 'me',
            },
            {
              title: 'My Colleagues',
              display_title: 'filters.myColleagues',
              active: true,
              disabled: false,
              value: 'team,company',
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Author',
          display_title: 'build.step3.author',
          active: false,
          icon: 'author-slides',
          disabled: false,
        },
        {
          items: [
            {
              title: 'Viewer',
              display_title: 'prezentationShareStatus.viewer',
              active: true,
              disabled: false,
              value: 'viewer',
            },
            {
              title: 'Restricted',
              display_title: 'prezentationShareStatus.restricted',
              active: true,
              disabled: false,
              value: 'restricted',
            },
            {
              title: 'Editor',
              display_title: 'prezentationShareStatus.editor',
              active: true,
              disabled: false,
              value: 'editor',
            },
            {
              title: 'Shared by me',
              display_title: 'filters.sharedByMe',
              active: true,
              disabled: false,
              value: 'shared',
            },
            {
              title: 'Private',
              display_title: 'prezentationShareStatus.private',
              active: true,
              disabled: false,
              value: 'private',
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Access',
          display_title: 'filters.access',
          active: false,
          icon: 'access-slides',
          disabled: false,
        },
        {
          checkAll: false,
          title: 'Uploaded on',
          display_title: 'prezentationsList.updatedOn',
          active: false,
          includeAllOption: false,
          icon: 'created-uploaded-on-slides',
          isCustomGroup: true,
        },
      ],
      advancedItemsOnLoadGraphs: [
        {
          items: [
            {
              title: 'Prezent',
              name: 'prezent',
              display_title: 'Prezent',
              active: true,
              disabled: false,
            },
            {
              title: 'Company',
              name: 'company',
              display_title: 'Company',
              active: true,
              disabled: false,
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Source',
          display_title: 'userUploadPrezentation.source',
          active: false,
          icon: 'filter-source',
          disabled: false,
        },
        {
          items: [
            { title: '1', display_title: '1', active: true, disabled: false },
            { title: '2', display_title: '2', active: true, disabled: false },
            { title: '3', display_title: '3', active: true, disabled: false },
            { title: '4', display_title: '4', active: true, disabled: false },
            { title: '5', display_title: '5', active: true, disabled: false },
            { title: '6', display_title: '6', active: true, disabled: false },
            { title: '7', display_title: '7', active: true, disabled: false },
            { title: '8', display_title: '8', active: true, disabled: false },
            { title: '9', display_title: '9', active: true, disabled: false },
            {
              title: '10 or more',
              display_title: 'filters.more',
              active: true,
              disabled: false,
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Node Count',
          display_title: 'tagSlides.nodeCountLabel',
          active: false,
          icon: 'node-count-slides',
          disabled: false,
        },
        {
          items: [
            {
              title: 'Cartoons',
              display_title: 'filters.cartoons',
              active: true,
              disabled: false,
            },
            {
              title: 'Icons',
              display_title: 'filters.icons',
              active: true,
              disabled: false,
            },
            {
              title: 'Images',
              display_title: 'filters.images',
              active: true,
              disabled: false,
            },
            {
              title: '3-D',
              display_title: '3-D',
              active: true,
              disabled: false,
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Graphics',
          display_title: 'tagSlides.graphics',
          active: false,
          icon: 'graphics-slides',
          disabled: false,
        },
        {
          active: false,
          checkAll: false,
          disableCheckAll: false,
          showIcon: true,
          items: [
            {
              title: 'Area Graph',
              display_title: 'filters.areaGraph',
              icon: 'area-graph',
              name: 'area_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Assessment',
              display_title: 'filters.assessment',
              icon: 'assessment',
              name: 'assessment',
              active: false,
              disabled: false,
            },
            {
              title: 'Bar/Column Graph',
              display_title: 'filters.barGraph',
              icon: 'bar-column',
              name: 'bar_column_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Box and Whisker',
              display_title: 'filters.boxAndWhisker',
              icon: 'box-and-whisker',
              name: 'box_and_whisker',
              active: false,
              disabled: false,
            },
            {
              title: 'Bubble Graph',
              display_title: 'filters.bubbleGraph',
              icon: 'bubblish-graph',
              name: 'bubble_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Bullet Graph',
              display_title: 'filters.bulletGraph',
              icon: 'bullet-graph',
              name: 'bullet_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Category',
              display_title: 'filters.category',
              icon: 'category',
              name: 'category',
              active: false,
              disabled: false,
            },
            {
              title: 'Causality',
              display_title: 'filters.casuality',
              icon: 'causality',
              name: 'causality',
              active: false,
              disabled: false,
            },
            {
              title: 'Calendar',
              display_title: 'filters.calendar',
              icon: 'calendar',
              name: 'calendar',
              active: false,
              disabled: false,
            },
            {
              title: 'Circular',
              display_title: 'filters.circular',
              icon: 'circular',
              name: 'circular',
              active: false,
              disabled: false,
            },
            {
              title: 'Convergent',
              display_title: 'filters.convergent',
              icon: 'convergent',
              name: 'convergent',
              active: false,
              disabled: false,
            },

            {
              title: 'Decision Tree',
              display_title: 'filters.decisionTree',
              icon: 'decision-tree',
              name: 'decision_tree',
              active: false,
              disabled: false,
            },
            {
              title: 'Divergent',
              display_title: 'filters.divergent',
              icon: 'divergent',
              name: 'divergent',
              active: false,
              disabled: false,
            },
            {
              title: 'Donut/Pie Chart',
              display_title: 'filters.pieChart',
              icon: 'donut-pie-graph',
              name: 'donut_pie_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Emphasis',
              display_title: 'filters.emphasis',
              icon: 'emphasis',
              name: 'emphasis',
              active: false,
              disabled: false,
            },
            {
              title: 'Enclosed',
              display_title: 'filters.enclosed',
              icon: 'enclosed',
              name: 'enclosed',
              active: false,
              disabled: false,
            },
            {
              title: 'Filter',
              display_title: 'filters.filter',
              icon: 'filter',
              name: 'filter',
              active: false,
              disabled: false,
            },
            {
              title: 'Histogram',
              display_title: 'filters.histogram',
              icon: 'histogram',
              name: 'histogram',
              active: true,
              disabled: false,
            },
            {
              title: 'Linked',
              display_title: 'filters.linked',
              icon: 'linked',
              name: 'linked',
              active: false,
              disabled: false,
            },
            {
              title: 'List',
              display_title: 'filters.list',
              icon: 'list',
              name: 'list',
              active: false,
              disabled: false,
            },
            {
              title: 'Line Graph',
              display_title: 'filters.lineGraph',
              icon: 'line-graph',
              name: 'line_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Matrix',
              display_title: 'filters.matrix',
              icon: 'matrix',
              name: 'matrix',
              active: false,
              disabled: false,
            },
            {
              title: 'Multi Bar',
              display_title: 'filters.multiBar',
              icon: 'multi-graph',
              name: 'multi_bar',
              active: true,
              disabled: false,
            },
            {
              title: 'One Pager',
              display_title: 'filters.onePager',
              icon: 'one-pager',
              name: 'one_pager',
              active: false,
              disabled: false,
            },
            {
              title: 'Pictogram Chart',
              display_title: 'filters.pictogram',
              icon: 'pictogram-chart',
              name: 'pictogram_chart',
              active: true,
              disabled: false,
            },
            {
              title: 'Process',
              display_title: 'filters.process',
              icon: 'process',
              name: 'process',
              active: false,
              disabled: false,
            },
            {
              title: 'Pyramid',
              display_title: 'filters.pyramid',
              icon: 'pyramid',
              name: 'pyramid',
              active: false,
              disabled: false,
            },
            {
              title: 'Radar Chart',
              display_title: 'filters.radarChart',
              icon: 'radar-chart',
              name: 'radar_chart',
              active: true,
              disabled: false,
            },
            {
              title: 'Ring',
              display_title: 'filters.ring',
              icon: 'ring',
              name: 'ring',
              active: false,
              disabled: false,
            },
            {
              title: 'Spoke',
              display_title: 'filters.spoke',
              icon: 'spoke',
              name: 'spoke',
              active: false,
              disabled: false,
            },
            {
              title: 'Stacked Bar Graph',
              display_title: 'filters.stackedBarGraph',
              icon: 'stacked-bar-graph',
              name: 'stacked_bar_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Stairs',
              display_title: 'filters.stairs',
              icon: 'stairs',
              name: 'stairs',
              active: false,
              disabled: false,
            },
            {
              title: 'Table',
              display_title: 'filters.table',
              icon: 'table',
              name: 'table',
              active: false,
              disabled: false,
            },
            {
              title: 'Timeline',
              display_title: 'filters.timeLine',
              icon: 'timeline',
              name: 'timeline',
              active: false,
              disabled: false,
            },
            {
              title: 'Tree',
              display_title: 'filters.tree',
              icon: 'tree',
              name: 'tree',
              active: false,
              disabled: false,
            },
            {
              title: 'Tree map',
              display_title: 'filters.treeMap',
              icon: 'tree-map',
              name: 'tree_map',
              active: false,
              disabled: false,
            },
            {
              title: 'Venn',
              display_title: 'filters.venn',
              icon: 'venn',
              name: 'venn',
              active: false,
              disabled: false,
            },
          ],
          title: 'Diagrams',
          display_title: 'tagSlides.diagrams',
          icon: 'diagrams-slides',
          disabled: false,
        },
      ],
      fromDateMenu: false,
      toDateMenu: false,
      fromDate: '',
      toDate: '',
      fromDateStr: '',
      toDateStr: '',
      pickerDate: '',
      filterItems: ['Recommended for selected audience', 'Popular'],
      filterBy: 'Recommended for selected audience',
    },
    prezentations: {
      activePanels: [0, 1, 2, 3, 4, 5, 6, 7],
      advancedFilters: [],
      advancedItems: [],
      pickerDate: '',
      fromDateMenu: false,
      toDateMenu: false,
      fromDate: '',
      toDate: '',
      fromDateStr: '',
      toDateStr: '',
      filterItems: [
        {
          display_title: 'prezentation.mostRecent',
          title: 'Most recent',
          field: 'createdAt',
          comparator: 'lt',
        },
        {
          display_title: 'prezentation.oldestFirst',
          title: 'Oldest first',
          field: 'createdAt',
          comparator: 'gt',
        },
        {
          display_title: 'prezentation.aToZ',
          title: 'A-Z',
          field: 'name',
          comparator: 'gt',
        },
        {
          display_title: 'prezentation.zToA',
          title: 'Z-A',
          field: 'name',
          comparator: 'lt',
        },
      ],
      selectedFilter: {
        display_title: 'prezentation.mostRecent',
        title: 'Most recent',
        field: 'createdAt',
        comparator: 'lt',
      },
    },
    know: {
      isSearching: true,
      advancedItemsOnLoad: [
        {
          items: [
            {
              title: 'My Favorites',
              display_title: 'filters.myFavorites',
              active: false,
            },
          ],
          checkAll: true,
          title: 'Favorites',
          display_title: 'slides.favourites',
          active: true,
          includeAllOption: false,
          icon: 'favorites',
        },
        {
          items: [
            {
              title: 'My Downloads',
              display_title: 'filters.myDownloads',
              active: false,
            },
          ],
          checkAll: true,
          title: 'Downloads',
          display_title: 'slides.downloads',
          active: true,
          includeAllOption: false,
          icon: 'downloads',
        },
      ],
      advancedItems: JSON.parse(JSON.stringify(knowAdvancedItemsOnLoad)),
      sortByValue: 'Recommended',
      sortByList: ['Recommended', 'Most Popular', 'A-Z', 'Z-A'],
      resetFilter: false,
    },
    knowDeck: {
      isSearching: true,
      advancedItemsOnLoad: [],
      advancedItems: [],
      sortByValue: 'Recommended',
      sortByList: ['Recommended', 'Most Popular', 'A-Z', 'Z-A'],
      resetFilter: false,
    },
    op: {
      advancedFilterActivePanels: [0, 1, 2],
      advancedItems: JSON.parse(JSON.stringify(advancedFilters)),
      fromDate: '',
      toDate: '',
      fromDateMenu: false,
      toDateMenu: false,
      toDateStr: '',
      fromDateStr: '',
      pickerDate: '',
      advancedFilterOpened: false,
      filterItems: [
        { title: 'Most recent', field: 'createdAt', comparator: true },
        { title: 'Oldest first', field: 'createdAt', comparator: false },
        { title: 'Sort by A-Z', field: 'name', comparator: false },
        { title: 'Sort by Z-A', field: 'name', comparator: true },
      ],
      selectedFilter: {
        title: 'Most recent',
        field: 'createdAt',
        comparator: true,
      },
      backCta: false,
    },
  };
};

export const advancedFilterStore = {
  namespaced: true,
  state: {
    ...advancedFilterState(),
  },
  getters: {
    getSlidesAdvanceItemsOnload: (state) => {
      return state.slides.advancedItemsOnLoad;
    },
    getFromDateStr: (state) => {
      return state.op.fromDateStr;
    },
    getToDateStr: (state) => {
      return state.op.toDateStr;
    },
  },
  mutations: {
    SET_KNOW_ADVANCED_FILTER_DECKS(state, val) {
      state.knowDeck.advancedItems = val;
    },
    SET_KNOW_ADVANCED_FILTER_SLIDES(state, val) {
      state.know.advancedItems = val;
    },
    SET_FILTER_OPTION_CATEGORY(state, value) {
      state.slides.filterOptionCategory = value;
    },
    SET_SLIDES_SEARCH_PROGRESS(state, value) {
      state.slides.searchProgress = value;
    },
    SET_SLIDES_ADVANCED_ITEMS(state, value) {
      state.slides.advancedItems = value;
    },
    SET_SLIDES_ADVANCED_ITEMS_ON_LOAD(state, value) {
      state.slides.advancedItemsOnLoad = value;
    },
    SET_SLIDES_FROM_DATE_MENU(state, value) {
      state.slides.fromDateMenu = value;
    },
    SET_SLIDES_TO_DATE_MENU(state, value) {
      state.slides.toDateMenu = value;
    },
    SET_SLIDES_TO_DATE(state, value) {
      state.slides.toDate = value;
    },
    SET_SLIDES_FROM_DATE_STR(state, value) {
      state.slides.fromDateStr = value;
    },
    SET_SLIDES_FROM_DATE(state, value) {
      state.slides.fromDate = value;
    },
    SET_SLIDES_TO_DATE_STR(state, value) {
      state.slides.toDateStr = value;
    },
    SET_SLIDES_PICKER_DATE(state, value) {
      state.slides.pickerDate = value;
    },
    SET_SLIDES_FILTER_BY(state, value) {
      state.slides.filterBy = value;
    },
    SET_SLIDES_FILTER_ITEMS(state, value) {
      state.slides.filterItems = value;
    },
    SET_SLIDES_ADVANCED_ITEMS_UPLOAD(state, value) {
      state.slides.advancedItemsUpload = value;
    },
    SLIDES_DATE_PICKER_CHANGE(state, value) {
      if (value.type === 'from') {
        state.slides.fromDate = value.date;
        state.slides.fromDateStr = moment(value.date).format('MM/DD/YYYY');

        // Make the to date blank if less than fromDateStr
        if (
          state.slides.toDate &&
          moment(state.slides.toDate).isBefore(fromDate)
        ) {
          state.slides.toDate = '';
          state.slides.toDateStr = '';
        }
      } else if (value.type === 'to') {
        state.slides.toDate = value.date;
        state.slides.toDateStr = moment(value.date).format('MM/DD/YYYY');
      }
    },
    SET_PREZ_ADVANCED_FILTERS(state, value) {
      state.prezentations.advancedFilters = value;
    },
    SET_PREZ_ADVANCED_ITEMS(state, value) {
      state.prezentations.advancedItems = value;
    },
    SET_PREZ_PICKER_DATE(state, value) {
      state.prezentations.pickerDate = value;
    },
    SET_PREZ_FROM_DATE_MENU(state, value) {
      state.prezentations.fromDateMenu = value;
    },
    SET_PREZ_TO_DATE_MENU(state, value) {
      state.prezentations.toDateMenu = value;
    },
    SET_PREZ_FROM_DATE(state, value) {
      state.prezentations.fromDate = value;
    },
    SET_PREZ_FROM_DATE_STR(state, value) {
      state.prezentations.fromDateStr = value;
    },
    SET_PREZ_TO_DATE(state, value) {
      state.prezentations.toDate = value;
    },
    SET_PREZ_TO_DATE_STR(state, value) {
      state.prezentations.toDateStr = value;
    },
    SET_PREZ_RESET_ALL_FILTERS(state) {
      state.prezentations.advancedItems = JSON.parse(
        JSON.stringify(state.prezentations.advancedFilters),
      );
      state.prezentations.activePanels = [0, 1, 2, 3, 4, 5, 6, 7];
      state.prezentations.fromDate = '';
      state.prezentations.fromDateStr = '';
      state.prezentations.fromDateMenu = false;
      state.prezentations.toDate = '';
      state.prezentations.toDateStr = '';
      state.prezentations.toDateMenu = false;
    },
    SET_PREZ_SELECTED_FILTER(state, value) {
      state.prezentations.selectedFilter = value;
    },
    SET_PREZ_FILTER_ITEMS(state, value) {
      state.prezentations.filterItems = value;
    },
    SET_BACK_CTA(state, value) {
      state.op.backCta = value;
    },
    SET_ADVANCED_FILTER_TOGGLE(state) {
      state.op.advancedFilterOpened = !state.op.advancedFilterOpened;
    },
    SET_OP_ADVANCED_ITEMS(state, value) {
      state.op.advancedItems = value;
    },
    RESET_ADVANCED_FILTERS(state) {
      state.op.advancedItems = JSON.parse(JSON.stringify(advancedFilters));
      state.op.fromDate = '';
      state.op.toDate = '';
      state.op.fromDateMenu = false;
      state.op.toDateMenu = false;
      state.op.toDateStr = '';
      state.op.fromDateStr = '';
      state.op.pickerDate = '';
    },
    HANDLE_CLEAR_DATE(state, value) {
      if (value === 'from') {
        state.op.fromDate = '';
        state.op.fromDateStr = '';
      } else if (value === 'to') {
        state.op.toDate = '';
        state.op.toDateStr = '';
      }
    },
    HANDLE_DATE_PICKER_TEXT_CHANGE(state, value) {
      if (value.type === 'from') {
        state.op.fromDate = value.date;
        state.op.fromDateStr = moment(value.date).format('MM/DD/YYYY');

        // Make the to date blank if less than fromDateStr
        if (state.op.toDate && moment(state.op.toDate).isBefore(fromDate)) {
          state.op.toDate = '';
          state.op.toDateStr = '';
        }
      } else if (value.type === 'to') {
        state.op.toDate = value.date;
        state.op.toDateStr = moment(value.date).format('MM/DD/YYYY');
      }
    },
    PREZ_DATE_PICKER_TEXT_CHANGE(state, value) {
      if (value.type === 'from') {
        state.prezentations.fromDate = value.date;
        state.prezentations.fromDateStr = moment(value.date).format(
          'MM/DD/YYYY',
        );
        if (
          state.prezentations.toDate &&
          moment(state.prezentations.toDate).isBefore(fromDate)
        ) {
          state.prezentations.toDate = '';
          state.prezentations.toDateStr = '';
        }
      } else if (value.type === 'to') {
        state.prezentations.toDate = value.date;
        state.prezentations.toDateStr = moment(
          state.prezentations.toDate,
        ).format('MM/DD/YYYY');
      }
    },
    SET_PICKER_DATE(state, value) {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');

      state.op.pickerDate = `${year}-${month}-${day}`;
      if (value === 'from' && state.op.fromDate)
        state.op.pickerDate = state.op.fromDate;
      if (value === 'to' && state.op.toDate)
        state.op.pickerDate = state.op.toDate;
    },
    SET_FROM_DATE_MENU(state, value) {
      state.op.fromDateMenu = value;
    },
    SET_TO_DATE_MENU(state, value) {
      state.op.toDateMenu = value;
    },
    SET_TO_DATE(state, value) {
      state.op.toDate = value;
    },
    SET_FILTER_ITEMS(state, value) {
      state.op.filterItems = value;
    },
    SET_SELECTED_FILTER(state, value) {
      state.op.selectedFilter = value;
    },
    SET_KNOW_SORT_BY_VALUE(state, value) {
      state.know.sortByValue = value;
    },
    SET_KNOW_DECK_SORT_BY_VALUE(state, value) {
      state.knowDeck.sortByValue = value;
    },
    SET_KNOW_IS_SEARCHING(state, value) {
      state.know.isSearching = value;
    },
    SET_KNOW_DECK_IS_SEARCHING(state, value) {
      state.knowDeck.isSearching = value;
    },
    RESET_KNOW_ADVANCED_ITEMS(state) {
      state.know.advancedItems = JSON.parse(
        JSON.stringify(state.know.advancedItemsOnLoad),
      );
    },
    SET_RESET_FILTER(state, value) {
      state.know.resetFilter = value;
    },
    UPDATE_BPE_DECK_FILTER(state, value) {
      const defaultItems = [
        {
          items: [
            {
              title: 'My Favorites',
              display_title: 'filters.myFavorites',
              active: false,
            },
          ],
          checkAll: true,
          title: 'Favorites',
          display_title: 'slides.favourites',
          active: true,
          includeAllOption: false,
          icon: 'favorites',
        },
        {
          items: [
            {
              title: 'My Downloads',
              display_title: 'filters.myDownloads',
              active: false,
            },
          ],
          checkAll: true,
          title: 'Downloads',
          display_title: 'slides.downloads',
          active: true,
          includeAllOption: false,
          icon: 'downloads',
        },
      ];
      let arrayObject = {};
      let final = [];
      if (value && value.length > 0) {
        const excludeArray = [];
        const includeArray = value.filter(
          (item) => !excludeArray.includes(item.name),
        );
        includeArray.forEach((item) => {
          item,
            (item.active = true),
            (item.isFunction = true),
            (item.display_title = item.name),
            (item.title = item.name);
        });
        const includeAllOption = [
          {
            active: true,
            isFunction: true,
            display_title: 'All',
            title: 'All',
          },
          ...includeArray,
        ];
        arrayObject = {
          active: true,
          checkAll: true,
          display_title: 'Function',
          icon: 'function-icon',
          includeAllOption: true,
          title: 'Function',
          items: includeAllOption,
          isFunction: true,
        };
        final = [...defaultItems, arrayObject];
      } else {
        final = [...defaultItems];
      }
      state.knowDeck.advancedItems = [...final];
    },
    SET_BPE_DECK_FILTER_ONLOAD(state, value) {
      let arrayObject = {};
      let final = [];
      if (value && value.length > 0) {
        const excludeArray = [];
        const includeArray = value.filter(
          (item) => !excludeArray.includes(item.name),
        );
        includeArray.forEach((item) => {
          item,
            (item.active = true),
            (item.isFunction = true),
            (item.display_title = item.name),
            (item.title = item.name);
        });
        const includeAllOption = [
          {
            active: true,
            isFunction: true,
            display_title: 'All',
            title: 'All',
          },
          ...includeArray,
        ];
        arrayObject = {
          active: true,
          checkAll: true,
          display_title: 'Function',
          icon: 'function-icon',
          includeAllOption: true,
          title: 'Function',
          items: includeAllOption,
          isFunction: true,
        };
        final = [...knowAdvancedItemsOnLoad, arrayObject];
      } else {
        final = [...knowAdvancedItemsOnLoad];
      }
      // final = [...knowAdvancedItemsOnLoad, arrayObject];
      state.knowDeck.advancedItemsOnLoad = [...final];
    },
  },
  actions: {
    setKnowAdvancedFilterDecks({ commit }, val) {
      commit('SET_KNOW_ADVANCED_FILTER_DECKS', val);
    },
    setKnowAdvancedFilterSlides({ commit }, val) {
      commit('SET_KNOW_ADVANCED_FILTER_SLIDES', val);
    },
    setSlidesFilterOptionCategory({ commit }, value) {
      commit('SET_FILTER_OPTION_CATEGORY', value);
    },
    setSlidesSearchProgress({ commit }, value) {
      commit('SET_SLIDES_SEARCH_PROGRESS', value);
    },
    setSlidesAdvancedItems({ commit }, value) {
      commit('SET_SLIDES_ADVANCED_ITEMS', value);
    },
    setSlidesAdvancedItemsOnLoad({ commit }, value) {
      commit('SET_SLIDES_ADVANCED_ITEMS_ON_LOAD', value);
    },
    setSlidesFromDateMenu({ commit }, value) {
      commit('SET_SLIDES_FROM_DATE_MENU', value);
    },
    setSlidesToDateMenu({ commit }, value) {
      commit('SET_SLIDES_TO_DATE_MENU', value);
    },
    setSlidesFromDateStr({ commit }, value) {
      commit('SET_SLIDES_FROM_DATE_STR', value);
    },
    setSlidesFromDate({ commit }, value) {
      commit('SET_SLIDES_FROM_DATE', value);
    },
    setSlidesToDate({ commit }, value) {
      commit('SET_SLIDES_TO_DATE', value);
    },
    setSlidesToDateStr({ commit }, value) {
      commit('SET_SLIDES_TO_DATE_STR', value);
    },
    setSlidesPickerDate({ commit }, value) {
      commit('SET_SLIDES_PICKER_DATE', value);
    },
    setSlidesFilterBy({ commit }, value) {
      commit('SET_SLIDES_FILTER_BY', value);
    },
    setSlidesFilterItems({ commit }, value) {
      commit('SET_SLIDES_FILTER_ITEMS', value);
    },
    setSlidesAdvancedItemsUpload({ commit }, value) {
      commit('SET_SLIDES_ADVANCED_ITEMS_UPLOAD', value);
    },
    setSlidesDatePickerTextChange({ commit }, value) {
      commit('SLIDES_DATE_PICKER_CHANGE', value);
    },
    setPrezAdvancedFilters({ commit }, value) {
      commit('SET_PREZ_ADVANCED_FILTERS', value);
    },
    setPrezFromDateMenu({ commit }, value) {
      commit('SET_PREZ_FROM_DATE_MENU', value);
    },
    setPrezToDateMenu({ commit }, value) {
      commit('SET_PREZ_TO_DATE_MENU', value);
    },
    setPrezFromDate({ commit }, value) {
      commit('SET_PREZ_FROM_DATE', value);
    },
    setPrezFromDateStr({ commit }, value) {
      commit('SET_PREZ_FROM_DATE_STR', value);
    },
    setPrezToDate({ commit }, value) {
      commit('SET_PREZ_TO_DATE', value);
    },
    setPrezToDateStr({ commit }, value) {
      commit('SET_PREZ_TO_DATE_STR', value);
    },
    setPrezAdvancedItems({ commit }, value) {
      commit('SET_PREZ_ADVANCED_ITEMS', value);
    },
    setPrezPickerDate({ commit }, value) {
      commit('SET_PREZ_PICKER_DATE', value);
    },
    setPrezDatePickerTextChange({ commit }, value) {
      commit('PREZ_DATE_PICKER_TEXT_CHANGE', value);
    },
    setPrezResetAllFilters({ commit }) {
      commit('SET_PREZ_RESET_ALL_FILTERS');
    },
    setPrezSelectedFilter({ commit }, value) {
      commit('SET_PREZ_SELECTED_FILTER', value);
    },
    setPrezFilterItems({ commit }, value) {
      commit('SET_PREZ_FILTER_ITEMS', value);
    },
    setAdvancedFilterToggle({ commit }) {
      commit('SET_ADVANCED_FILTER_TOGGLE');
    },
    setBackCta({ commit }, value) {
      commit('SET_BACK_CTA', value);
    },
    setOpAdvancedItems({ commit }, value) {
      commit('SET_OP_ADVANCED_ITEMS', value);
    },
    resetFilters({ commit }) {
      commit('RESET_ADVANCED_FILTERS');
    },
    resetDate({ commit }, value) {
      commit('HANDLE_CLEAR_DATE', value);
    },
    handleDatePickerTextChange({ commit }, value) {
      commit('HANDLE_DATE_PICKER_TEXT_CHANGE', value);
    },
    setCurrentDateFormatted({ commit }, value) {
      commit('SET_PICKER_DATE', value);
    },
    setFromDateMenu({ commit }, value) {
      commit('SET_FROM_DATE_MENU', value);
    },
    setToDateMenu({ commit }, value) {
      commit('SET_TO_DATE_MENU', value);
    },
    setToDate({ commit }, value) {
      commit('SET_TO_DATE', value);
    },
    setFilterItems({ commit }, value) {
      commit('SET_FILTER_ITEMS', value);
    },
    setSelectedFilter({ commit }, value) {
      commit('SET_SELECTED_FILTER', value);
    },
    setKnowSortByValue({ commit }, value) {
      commit('SET_KNOW_SORT_BY_VALUE', value);
    },
    setKnowDeckSortByValue({ commit }, value) {
      commit('SET_KNOW_DECK_SORT_BY_VALUE', value);
    },
    setKnowIsSearching({ commit }, value) {
      commit('SET_KNOW_IS_SEARCHING', value);
    },
    setKnowDeckIsSearching({ commit }, value) {
      commit('SET_KNOW_DECK_IS_SEARCHING', value);
    },
    resetKnowAdvancedItems({ commit }) {
      commit('RESET_KNOW_ADVANCED_ITEMS');
    },
    resetKnowDeckAdvancedItems({ commit }, data) {
      commit('UPDATE_BPE_DECK_FILTER', data?.functions);
    },
    setResetFilter({ commit }, value) {
      commit('SET_RESET_FILTER', value);
    },
    setFingerPrintAdditionalDetailsBPEDeck({ commit, state }, value) {
      commit('UPDATE_BPE_DECK_FILTER', value?.functions);
    },
    setFingerPrintAdditionalDetailsBPEDeckOnload({ commit, state }, value) {
      commit('SET_BPE_DECK_FILTER_ONLOAD', value?.functions);
    },
  },
  persist: createPersistedState({
    key: 'vuex_advancedFilterStore',
    paths: [],
  }),
};
