const initialState = {
  paymentMethods: [
    {
      title: 'Add new payment method',
    }
  ],
  snackbar: false,
  snackbarText: '',
  snackbarAction: null,
};

export const paymentMethods = {
  namespaced: true,
  state: initialState,
  getters: {
    getPaymentMethods: (state) => (state ? state.paymentMethods : [{ title: 'Add new payment method'}]),
    getSnackbar: (state) => state.snackbar,
    getSnackbarText: (state) => state.snackbarText,
    getSnackbarAction: (state) => state.snackbarAction,
  },
  mutations: {
    SET_PAYMENT_METHODS(state, methods) {
      state.paymentMethods = [...methods, { title: 'Add new payment method'}];
    },
    SET_SNACKBAR(state, info) {
      state.snackbar = info.status;
      state.snackbarText = info.text;
      state.snackbarAction = info.action;
    },
    ADD_NEW_PAYMENT_METHOD(state, method) {
      const existingMethods = [...state.paymentMethods.slice(0, state.paymentMethods.length - 1)];
      state.paymentMethods = [method, ...existingMethods, { title: 'Add new payment method'}];
    },
    REMOVE_PAYMENT_METHOD(state, method) {
      const existingMethods = [...state.paymentMethods.slice(0, state.paymentMethods.length - 1)];
      const filteredMethods = [...existingMethods].filter((itm) => itm.id !== method);
      state.paymentMethods = [...filteredMethods, { title: 'Add new payment method' }];
    },
    SET_PAYMENT_METHOD_AS_DEFAULT(state, method) {
      const existingMethods = [...state.paymentMethods.slice(0, state.paymentMethods.length - 1)];
      const updatedMethods = [...existingMethods].map((itm) => {
        if (itm.id === method) {
          return {
            ...itm,
            is_default: true,
          };
        }
        return {
          ...itm,
          is_default: false,
        }
      });
      state.paymentMethods = [...updatedMethods, { title: 'Add new payment method' }];
    },
    UPDATE_PAYMENT_METHOD_DETAILS_IN_STORE(state, method) {
      const existingMethods = [...state.paymentMethods.slice(0, state.paymentMethods.length - 1)];
      const updatedMethods = [...existingMethods].map((item) => {
        if (item.id === method.id) {
          return {
            ...item,
            name: method.name,
            exp_month: method.exp_month,
            exp_year: method.exp_year,
          };
        }
        return {
          ...item,
        };
      });
      state.paymentMethods = [...updatedMethods, { title: 'Add new payment method' }];
    },
  },
  actions: {
    setPaymentMethods({ commit }, methods) {
      commit('SET_PAYMENT_METHODS', methods);
    },
    setSnackbar({ commit }, info) {
      commit('SET_SNACKBAR', info);
    },
    addNewPaymentMethod({ commit }, method) {
      commit('ADD_NEW_PAYMENT_METHOD', method);
    },
    removePaymentMethod({ commit }, method) {
      commit('REMOVE_PAYMENT_METHOD', method);
    },
    setPaymentMethodAsDefault({ commit }, method) {
      commit('SET_PAYMENT_METHOD_AS_DEFAULT', method);
    },
    updatePaymentMethodDetailsInStore({ commit }, method) {
      commit('UPDATE_PAYMENT_METHOD_DETAILS_IN_STORE', method);
    },
  },
};
